import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  height: 100vh;
  /* padding: 75px 20px 0 250px; */
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    padding: 50px 10px;
  }
`;

export const Table = styled.table`
  margin-top: 25px;
  border-collapse: collapse;
  font-family: Tahoma, Geneva, sans-serif;

  td {
    padding: 5px 6px;
  }
  thead th {
    background-color: #54585d;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
    border: 1px solid #54585d;
  }
  tbody td {
    color: #636363;
    border: 1px solid #dddfe1;
  }
  tbody tr {
    background-color: #f9fafb;
  }
  tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
`;
