import { Form, Input, Select } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';

// import { useSelector } from 'react-redux';
// import { createItemRequest } from '~/store/modules/item/actions';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function ItemNew(params) {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [storages, setStorages] = useState([]);
  const [cpus, setCpus] = useState([]);
  const [rams, setRams] = useState([]);
  const [operatingSystems, setOperatingSystems] = useState([]);
  const [keyboards, setKeyboards] = useState([]);
  const [screenSizes, setScreenSizes] = useState([]);
  const [screenTypes, setScreenTypes] = useState([]);
  const [screenResolutions, setScreenResolutions] = useState([]);
  const [screenTechnologies, setScreenTechnologies] = useState([]);
  const [item, setItem] = useState([]);

  useEffect(() => {
    async function loadDropdownboxes() {
      const categoriesList = await api.get('categories');
      const brandsList = await api.get('brands');
      const manufacturersList = await api.get('manufacturers');
      const conditionsList = await api.get('conditions');
      const storagesList = await api.get('storages');
      const cpusList = await api.get('cpus');
      const ramsList = await api.get('rams');
      const operatingSystemsList = await api.get('operating_systems');
      const keyboardsList = await api.get('keyboard_types');
      const screenSizesList = await api.get('screen_sizes');
      const screenTypesList = await api.get('screen_types');
      const screenResolutionsList = await api.get('screen_resolutions');
      const screenTechnologiesList = await api.get('screen_technologies');

      const itemInfo = await api.get(`items/${params.match.params.sku}`);

      setCategories(
        categoriesList.data.map(list => ({ id: list.id, title: list.name }))
      );

      setBrands(
        brandsList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setManufacturers(
        manufacturersList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setConditions(
        conditionsList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setStorages(
        storagesList.data.map(list => ({ id: list.id, title: `${list.capacity} ${list.unit} ${list.type}` }))
      );
      setCpus(
        cpusList.data.map(list => ({ id: list.id, title: `${list.brand} ${list.number} ${list.speed}` }))
      );
      setRams(
        ramsList.data.map(list => ({ id: list.id, title: `${list.capacity} ${list.type}` }))
      );
      setOperatingSystems(
        operatingSystemsList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setKeyboards(
        keyboardsList.data.map(list => ({
            id: list.id,
            title: `${list.type} ${list.rubber ? 'Rubber' : ''} ${list.water_resistant ? 'Water Resistant' : ''} (${list.language})`,
          }))
      );
      setScreenSizes(
        screenSizesList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setScreenTypes(
        screenTypesList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setScreenResolutions(
        screenResolutionsList.data.map(list => ({ id: list.id, title: list.name }))
      );
      setScreenTechnologies(
        screenTechnologiesList.data.map(list => ({ id: list.id, title: list.name }))
      );

      setItem(itemInfo.data);
    }

    loadDropdownboxes();
  }, []); // eslint-disable-line

  // const dispatch = useDispatch();

  async function handleSubmit(data) {
    await api.post('items', data);
    history.push('/items');
    // dispatch(createItemRequest(data));
  }

  return (
    <Container>
      <Form initialData={item} onSubmit={handleSubmit}>
        {/* <Form> */}
        <Input name="sku" placeholder="SKU" />
        <Input name="name" placeholder="Item name" />
        <Input name="description" placeholder="Item description" />
        <Input name="model" placeholder="Item model" />
        <Input name="part_number" placeholder="Item part number" />
        <Select name="category_id" options={categories} defaultValue={5} placeholder="Category" />
        <Select name="brand_id" options={brands} placeholder="Brand" />
        <Select name="manufacturer_id" options={manufacturers} placeholder="Manufacturer" />
        <Select name="condition_id" options={conditions} placeholder="Condition" />
        <Select name="storage_id" options={storages} placeholder="Storage" />
        <Select name="cpu_id" options={cpus} placeholder="CPU" />
        <Select name="ram_id" options={rams} placeholder="RAM" />
        <Select name="operating_system_id" options={operatingSystems} placeholder="Operating System" />
        <Select name="keyboard_type_id" options={keyboards} placeholder="Keyboard" />
        <Select name="screen_size_id" options={screenSizes} placeholder="Screen Size" />
        <Select name="screen_type_id" options={screenTypes} placeholder="Screen Type" />
        <Select name="screen_resolution_id" options={screenResolutions} placeholder="Screen Resolution" />
        <Select name="screen_technology_id" options={screenTechnologies} placeholder="Screen Technologie" />

        <button type="submit">Add Item</button>
      </Form>
    </Container>
  );
}
