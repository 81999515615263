export function handleRAMCheck(values) {
  if ((values.ramSize1 === null || values.ramSize1 === '') && (values.ramSize2 === null || values.ramSize2 === '')) {
    return { hasError: true, message: 'Please enter any RAM serial number' };
  }
  if (values.ramSize1 !== null && values.ramSize1 !== '') {
    if (values.ramSerialNumber1 === '' || values.ramSerialNumber1 === undefined) {
      return { hasError: true, message: 'Please enter the RAM 1 Serial Number' };
    }
  }
  if (values.ramSize2 !== null && values.ramSize2 !== '') {
    if (values.ramSerialNumber2 === '' || values.ramSerialNumber2 === undefined) {
      return { hasError: true, message: 'Please enter the RAM 2 Serial Number' };
    }
  }
  return { hasError: false };
}
