import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  justify-content: center;
  /* background: #653; */

  /* width: 100%; */

  /* @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  } */
`;
export const StyledTextField = styled(TextField)`
  && {
    margin: 10px;
    /* padding: 20px; */
  }
`;
