/* eslint-disable react/prop-types */
// import React, { memo } from 'react';
import MUIDataTable from 'mui-datatables';
import React from 'react';

// // import { Container } from './styles';

// function Datatable({ title, data, columns, options }) {
//   return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
// }

// export default memo(Datatable);
export const Datatable = React.forwardRef(({ title, data, columns, options }, ref) => (
  <MUIDataTable title={title} data={data} columns={columns} ref={ref} options={options} />
));
