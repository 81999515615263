/* eslint-disable react/prop-types */
import React from 'react';

import { Descriptions, Form, Input, InputNumber, Space } from 'antd';

const { TextArea } = Input;

export default function Additional({ itemDisabled }) {
  return (
    <Descriptions
      size="small"
      bordered
    >
      <Descriptions.Item label="Hours" span={3}>
        <Space>
          <Form.Item hasFeedback name="hours">
            <InputNumber
              placeholder="Hours"
              min={0}
              disabled={itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item label="Notes" span={3}>
        <Space>
          <Form.Item hasFeedback name="notes">
            <TextArea
              showCount
              maxLength={255}
              allowClear
              style={{ width: 500 }}
              placeholder="Add additional information about this item"
              rows={4}
              disabled={itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
}
