/* eslint-disable no-alert */
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));

root.render(<App />);

serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          if (window.confirm('There is a new version of RBMS. Please reload to update.')) {
            window.location.reload();
          }
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
