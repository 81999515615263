/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { CloseSquareOutlined } from '@ant-design/icons';
import { Button, Descriptions, Form, Popover, Radio, Select, Space } from 'antd';

export default function Cosmetic({ itemResults, form, instructions, cleanFieldsNotPassed, itemDisabled, cosmeticsScreenOptions }) {
  const [checkCosmeticsScreen, setCheckCosmeticsScreen] = useState(false);

  useEffect(() => {
    setCheckCosmeticsScreen(itemResults.resultCosmeticsScreen === 'passed');
  }, [itemResults.resultAcAdapter, itemResults.resultCosmeticsScreen]);


  return (
    <Descriptions
      size="small"
      bordered
    >
      <Descriptions.Item label="Side covers" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_covers')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultCovers" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Screws" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_screws')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultScrews" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Tether and Stylus" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_tether')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultTetherStylus" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Screen" span={2}>
        <Space>
          <Form.Item hasFeedback name="cosmeticsScreen" rules={[{ required: checkCosmeticsScreen }]}>
            <Select
              mode="multiple"
              allowClear
              disabled={!checkCosmeticsScreen || itemDisabled}
              options={cosmeticsScreenOptions}
              style={{ width: 200 }}
              placeholder="Screen condition"
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_screen')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultCosmeticsScreen" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            name="operatingSystemTest"
            onChange={e =>
              e.target.value === 'passed' ? setCheckCosmeticsScreen(true) : (setCheckCosmeticsScreen(false), cleanFieldsNotPassed(['cosmeticsScreen']))
            }
            buttonStyle="solid"
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Battery" span={2}>
        <Space>No damage to battery or bulged, leaking, etc.</Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_battery')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultCosmeticsBattery" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Cracks" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="cracks" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="unfixable">Unfixable</Radio.Button>
              <Radio.Button value="fixable-noCracks">Fixable/No Cracks</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ cracks: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_cracks')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />

      <Descriptions.Item label="Label Condition" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="labelCondition" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="poor">Poor</Radio.Button>
              <Radio.Button value="fair">Fair</Radio.Button>
              <Radio.Button value="good">Good</Radio.Button>
              <Radio.Button value="excellent">Excellent</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ labelCondition: null })} />
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_label_condition')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
      <Descriptions.Item label="Dents &#38; Scratches" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="dentsScratches" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="major">Major</Radio.Button>
              <Radio.Button value="moderate">Moderate</Radio.Button>
              <Radio.Button value="minor">Minor</Radio.Button>
              <Radio.Button value="none">None</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ dentsScratches: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_dents_scratches')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
      <Descriptions.Item label="Discoloration" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="discoloration" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="major">Major</Radio.Button>
              <Radio.Button value="moderate">Moderate</Radio.Button>
              <Radio.Button value="minor">Minor</Radio.Button>
              <Radio.Button value="none">None</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ discoloration: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_discoloration')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
      <Descriptions.Item label="Missing Parts" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="missingParts" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="yes">Yes</Radio.Button>
              <Radio.Button value="no">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ missingParts: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_missing_parts')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
      <Descriptions.Item label="Latches &#38; Hinges damaged" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="latchesHingesDamaged" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="yes">Yes</Radio.Button>
              <Radio.Button value="no">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ latchesHingesDamaged: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_latches_hinges_damaged')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
      <Descriptions.Item label="Cleaned (Inside/Outside)" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="cleaned" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="yes">Yes</Radio.Button>
              <Radio.Button value="no">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ cleaned: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_cleaned')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
      <Descriptions.Item label="Packaging" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="packaging" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="none">None</Radio.Button>
              <Radio.Button value="noFactoryPackaging">No Factory Packaging</Radio.Button>
              <Radio.Button value="unsealedFactory">Unsealed Factory</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ packaging: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_packaging')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />
    </Descriptions>
  );
}
