/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
// import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Divider, Form, Input, Select, Space, Table } from 'antd';

import * as XLSX from 'xlsx';

// import 'antd/dist/antd.css';
import { CheckCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { Container } from './styles';

import api from '~/services/api';
import ReportFields from './utils/ReportFields';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function AssetsListLookup() {
  const classes = useStyles();
  const [form] = Form.useForm();

  const [listOfAssets, setListOfAssets] = useState([]);
  const [listOfAssetsInStock, setListOfAssetsInStock] = useState([]);
  const [listOfAssetsAwaitingShipment, setListOfAssetsAwaitingShipment] = useState([]);
  const [searchFiltersDisabled, setSearchFiltersDisabled] = useState(true);
  const [xslxFileName, setXslxFileName] = useState('inventoryReport');
  const [xslxData, setXslxData] = useState([]);

  const [categoryFilter, setCategoryFilter] = useState([]);

  const categoryLabels = {
    '2-in-1': '2-in-1',
    Laptops: 'Laptops',
    Tablets: 'Tablets',
    Accessories: 'Accessories',
    Desktop: 'Desktop',
    'Hard Drive': 'Hard Drive',
    Cards: 'Cards',
    Mobile: 'Mobile',
    Parts: 'Parts',
    Monitors: 'Monitors',
    'Power Module': 'Power Module',
    SSHD: 'SSHD',
    Radio: 'Radio',
    Service: 'Service',
    'Graphic Cards': 'Graphic Cards',
  };

  const selectListOptions = [
    { label: 'Failed Assets', value: 'failed' },
    { label: 'Assets For Parts', value: 'parts' },
    { label: 'Assets To Be Inspected', value: 'tbi' },
    { label: 'Assets In Stock / To Be Inspected', value: 'stock' },
    { label: 'Assets Reserved', value: 'reserved' },
    { label: 'Assets Awaiting Shipment', value: 'awaiting_shipment' },
  ];

  // eslint-disable-next-line consistent-return
  const onFinish = async values => {
    let query = '';
    let inventoryItemsData = {};
    setListOfAssets([]);
    setListOfAssetsInStock([]);
    setListOfAssetsAwaitingShipment([]);

    if (!['stock', 'reserved', 'awaiting_shipment'].includes(values.search)) {
      if (values.search === 'failed') {
        query = 'status=Failed';
      } else if (values.search === 'parts') {
        query = 'condition=For Parts&status=InStock';
      } else if (values.search === 'tbi') {
        query = 'status=ToBeInspected';
      }

      inventoryItemsData = await api.get(`inventory_items?${query}`);
      if (inventoryItemsData.data) {
        /** Category column filter */
        const categoryFilterCount = inventoryItemsData.data.reduce((acc, cur) => {
          const key = cur.item.category.name;
          acc[key] = acc[key] || 0;
          acc[key]++;
          return acc;
        }, []);

        const categoryKeys = Object.keys(categoryFilterCount);

        const categoryFilterLabel = [];
        categoryKeys.forEach(el => categoryFilterLabel.push({ text: `${categoryLabels[el]}(${categoryFilterCount[el]})`, value: el }));
        setCategoryFilter(categoryFilterLabel);

        setListOfAssets(inventoryItemsData.data);
        setXslxFileName(`${values.search} Inventory`);
        setXslxData(ReportFields(inventoryItemsData.data));
      } else {
        toast.error('There are no assets matching the search criteria.');
      }
    } else if (values.search === 'stock') {
      // query = `items.name like ('%${values.nameSearch}%') and status in ('InStock','ToBeInspected')`;
      if (values.searchFilter === undefined || values.searchFilter.length < 2) {
        toast.error('Name must have minimum input of 2 characters.');
        return false;
      }
      inventoryItemsData = await api.put('/report/Inventory', { query: values.searchFilter, flag: `'InStock','ToBeInspected'` });

      if (inventoryItemsData.data.length > 0) {
        setListOfAssetsInStock(inventoryItemsData.data);
        setXslxFileName(`${values.search} Inventory`);
        setXslxData(ReportFields(inventoryItemsData.data));
      } else {
        toast.error('There are no assets matching the search criteria.');
      }
    } else if (values.search === 'awaiting_shipment') {
      inventoryItemsData = await api.put('/report/AwaitingShipmentInventoryList');
      setListOfAssetsAwaitingShipment(inventoryItemsData.data);
      setXslxFileName(`Awaiting Shipment Inventory`);
      setXslxData(inventoryItemsData.data);
    } else {
      inventoryItemsData = await api.put('/report/Inventory', { query: values.searchFilter || '', flag: `'Reserved'` });

      if (inventoryItemsData.data.length > 0) {
        setListOfAssetsInStock(inventoryItemsData.data);
        setXslxData(ReportFields(inventoryItemsData.data));
      } else {
        toast.error('There are no assets matching the search criteria.');
      }
    }
  };

  const clearPage = async () => {
    setListOfAssets([]);
    setListOfAssetsInStock([]);
  };

  const onSelectListOption = async value => {
    if (value === 'stock' || value === 'reserved') {
      setSearchFiltersDisabled(false);
    } else {
      setSearchFiltersDisabled(true);
    }
  };

  const columnsListOfAssets = [
    {
      title: 'Part Number',
      dataIndex: ['item', 'part_number'],
      sorter: (a, b) => a.item.part_number.localeCompare(b.item.part_number),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: ['item', 'name'],
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Received Date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Received By',
      dataIndex: ['users', 'name'],
    },
    {
      title: 'POST',
      dataIndex: 'post_result',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
    },
    {
      title: 'Processed By',
      dataIndex: 'processed_by',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Category',
      dataIndex: ['item', 'category', 'name'],
      filters: categoryFilter,
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.item.category.name.startsWith(value),
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
    },
    {
      title: 'Notes',
      dataIndex: 'description',
    },
  ];

  const columnsListOfAssetsInStock = [
    {
      title: 'Part Number',
      dataIndex: 'part_number',
      sorter: (a, b) => a.part_number.localeCompare(b.part_number),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
    },
    {
      title: 'CPU',
      dataIndex: 'cpu',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Storage',
      dataIndex: 'storage',
    },
    {
      title: 'RAM',
      dataIndex: 'ram',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
    },
  ];

  const columnsListOfAssetsAwaitingShipment = [
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Part Number',
      dataIndex: 'item.part_number',
      sorter: (a, b) => a['item.part_number'].localeCompare(b['item.part_number']),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Item Description',
      dataIndex: 'item.description',
    },
    {
      title: 'Item Status',
      dataIndex: 'status',
    },
    {
      title: 'Item Condition',
      dataIndex: 'condition',
    },
    {
      title: 'Order Number',
      dataIndex: 'order_num',
    },
    {
      title: 'Order status',
      dataIndex: 'order_status',
    },
    {
      title: 'Is QC Approved',
      dataIndex: 'is_qc_approved',
      render: text => <CheckCircleTwoTone twoToneColor={text ? '#52c41a' : '#eb2f96'} style={{ fontSize: '20px' }} />,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      render: text => moment(text).format('MMM DD YYYY, h:mm a'),
      defaultSortOrder: 'ascend',
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
    },
    {
      title: 'Processed By',
      dataIndex: 'processed_by',
    },
    {
      title: 'Location',
      dataIndex: 'locations.name',
      render: (text, row) => {
        if (text === 'Tech') {
          return `${text} ${row['usersLocation.name']}`;
        }
        return text;
      },
    },
  ];

  function downloadExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${xslxFileName}.xlsx`);
  }

  return (
    <Container>
      <Paper className={classes.paper}>
        <h1>Inventory - Assets List Lookup</h1>

        <Form form={form} name="assetsListLookup" onFinish={onFinish}>
          <Form.Item name="search" label="Search For" rules={[{ required: true }]}>
            <Select
              onChange={onSelectListOption}
              showSearch
              allowClear
              style={{ width: 500 }}
              placeholder="Search For..."
              optionFilterProp="label"
              options={selectListOptions}
            />
          </Form.Item>
          <Form.Item label="Search Filter:" name="searchFilter">
            <Input id="searchFilter" type="text" placeholder="" style={{ width: 500 }} disabled={searchFiltersDisabled} />
          </Form.Item>
          <Space direction="vertical">
            <Space size="middle">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="button" onClick={clearPage}>
                  Clear Page
                </Button>
              </Form.Item>
            </Space>
          </Space>
        </Form>

        {listOfAssets.length > 0 && (
          <>
            <Divider />
            <Button variant="outlined" type="primary" className="ant-dropdown-link" style={{ width: 200 }} onClick={() => downloadExcel(xslxData)}>
              Download to Excel
            </Button>
            <Divider />
            <Table
              columns={columnsListOfAssets}
              dataSource={listOfAssets}
              size="small"
              bordered
              rowKey="serial_number"
              pagination={{
                pageSize: 20,
                showSizeChanger: false,
                total: listOfAssets.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </>
        )}
        {listOfAssetsInStock.length > 0 && (
          <>
            <Divider />
            <Button variant="outlined" type="primary" className="ant-dropdown-link" style={{ width: 200 }} onClick={() => downloadExcel(xslxData)}>
              Download to Excel
            </Button>
            <Divider />
            <Table
              columns={columnsListOfAssetsInStock}
              dataSource={listOfAssetsInStock}
              size="small"
              bordered
              rowKey="serial_number"
              pagination={{
                pageSize: 20,
                showSizeChanger: false,
                total: listOfAssetsInStock.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </>
        )}
        {listOfAssetsAwaitingShipment.length > 0 && (
          <>
            <Divider />
            <Button variant="outlined" type="primary" className="ant-dropdown-link" style={{ width: 200 }} onClick={() => downloadExcel(xslxData)}>
              Download to Excel
            </Button>
            <Divider />
            <Table
              columns={columnsListOfAssetsAwaitingShipment}
              dataSource={listOfAssetsAwaitingShipment}
              size="small"
              bordered
              rowKey="serial_number"
              pagination={{
                pageSize: 20,
                showSizeChanger: false,
                total: listOfAssetsAwaitingShipment.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </>
        )}
      </Paper>
    </Container>
  );
}
