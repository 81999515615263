import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background: #fff; */

  width: 100%;
  padding: 80px 20px 30px 270px;

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;
