import api from '~/services/api';

export async function checkUserPermissionToChangeLocation(fromLocationId, toLocationId, fromUserId, toUserId = '') {
  try {
    const userPermissions = await api.get(
      `/location-user-permission/checkUserPermission/${fromLocationId}/${toLocationId}?fromUserId=${fromUserId}&toUserId=${toUserId}`
    );

    if (userPermissions.data.ok) {
      return { ok: true };
    }
    return { ok: false, error: userPermissions.data.error };
  } catch (error) {
    return { ok: false, description: 'Something wrong with this item. Report this error to System managenment' };
  }
}
