export default function AssignCosmeticScreen(screen_array, cosmeticScreenOptions) {
  if (!screen_array) return '';
  const CSArray = screen_array.split(',').map(Number);
  const cosmeticScreen = [];

  CSArray.forEach(cs => {
    cosmeticScreen.push(cosmeticScreenOptions.find(screen => screen.id === cs).name);
  });

  return cosmeticScreen.join();
}
