import { Button, Card, DatePicker, Empty, Pagination, Select, Space, Table, Tag, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { MagnifyingGlass } from 'phosphor-react';
import { Link } from 'react-router-dom';
import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

// const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
//   const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
//   return (
//     <td {...restProps}>
//       {editing ? (
//         <Form.Item
//           name={dataIndex}
//           style={{
//             margin: 0,
//           }}
//           rules={[
//             {
//               required: true,
//               message: `Please Input ${title}!`,
//             },
//           ]}
//         >
//           {inputNode}
//         </Form.Item>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };

export default function Orders() {
  const [filterCriteria, setFilterCriteria] = useState('order_num');
  const [filterStore, setFilterStore] = useState('');
  const [filterStatus, setFilterStatus] = useState('awaiting_shipment');
  const [filterFulfillment, setFilterFulfillment] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterDate, setfilterDate] = useState([undefined, undefined]);
  const [paginationTotalItems, setPaginationTotalItems] = useState();
  const [paginationSize, setPaginationSize] = useState(25);
  const [paginationPage, setPaginationPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [storeList, setStoreList] = useState([{ value: '', label: 'All Stores' }]);
  const [refreshTimer, setRefreshTimer] = useState(60000);
  // const [refreshTimer, setRefreshTimer] = useState(5000);
  const [randomNumber, setRandomNumber] = useState(0);
  // const [refreshTimer, setRefreshTimer] = useState(0);

  const searchTextRef = useRef('');

  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { Paragraph } = Typography;

  const tagColor = {
    awaiting_shipment: {
      label: 'Awaiting Shipment',
      color: 'orange',
    },
    cancelled: {
      label: 'Cancelled',
      color: 'red',
    },
    shipped: {
      label: 'Shipped',
      color: 'green',
    },
    on_hold: {
      label: 'On Hold',
      color: 'cyan',
    },
    awaiting_payment: {
      label: 'Awaiting Payment',
      color: 'blue',
    },
    null: {
      label: 'No Status',
      color: 'magenta',
    },
  };

  const handleSearch = useCallback(async () => {
    const [filterStartDate, filterEndDate] = filterDate || [];

    if (filterCriteria === 'serial_number') {
      const serialNumberFormatted =
        searchTextRef.current.value.substring(0, 4) === 'P/N:'
          ? searchTextRef.current.value.substring(searchTextRef.current.value.indexOf('S/N:') + 4)
          : searchTextRef.current.value;

      searchTextRef.current.value = serialNumberFormatted;

      // const ordersData = await api.get(`ordersChecklist?filterCriteria=${filterCriteria}&textToSearch=${serialNumberFormatted.trim().toUpperCase()}`);
      const ordersData = await api.post('ordersChecklist/showByCriteria', { filterCriteria, textToSearch: serialNumberFormatted.trim().toUpperCase() });

      const newOrders = ordersData.data.rows.map((checklist) => checklist.order_items.order);

      setPaginationTotalItems(ordersData.data.totalItems);
      setPaginationSize(ordersData.data.sizePage);
      setPaginationPage(ordersData.data.currentPage + 1);
      setOrders(newOrders);
      return;
    }

    if (filterCriteria === 'product') {
      const ordersData = await api.get(`orderItems?size=${paginationSize}&page=${paginationPage - 1}`, {
        headers: {
          filterCriteria: 'title',
          filterText: searchTextRef.current.value || '',
          filterStore: filterStore || '',
          filterStatus: filterStatus || '',
          filterFulfillment: filterFulfillment || '',
          filterStartDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
          filterEndDate: filterEndDate ? moment(filterEndDate).add(1, 'day').format('YYYY-MM-DD') : '',
        },
      });

      setPaginationTotalItems(ordersData.data.totalItems);
      setPaginationSize(ordersData.data.sizePage);
      setPaginationPage(ordersData.data.currentPage + 1);
      setOrders(ordersData.data.rows);
      return;
    }
    const ordersData = await api.get(`orders?size=${paginationSize}&page=${paginationPage - 1}`, {
      headers: {
        filterCriteria,
        filterText: searchTextRef.current.value || '',
        filterStore: filterStore || '',
        filterStatus: filterStatus || '',
        filterFulfillment: filterFulfillment || '',
        filterStartDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
        filterEndDate: filterEndDate ? moment(filterEndDate).add(1, 'day').format('YYYY-MM-DD') : '',
      },
    });

    setPaginationTotalItems(ordersData.data.totalItems);
    setPaginationSize(ordersData.data.sizePage);
    setPaginationPage(ordersData.data.currentPage + 1);
    setOrders(ordersData.data.rows);
  }, [filterCriteria, filterDate, filterFulfillment, filterStatus, filterStore, paginationPage, paginationSize]);

  const handleChangePage = (page, pageSize) => {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  };

  const handleClearFilters = useCallback(() => {
    setIsFiltered(false);
    setFilterStore('');
    setFilterStatus('awaiting_shipment');
    setFilterFulfillment('');
    setfilterDate([undefined, undefined]);
  }, []);

  useEffect(() => {
    async function loadStores() {
      const stores = await api.get('stores?list=true');
      setStoreList([
        ...storeList,
        ...stores.data
          .filter((store) => store.active)
          .map((store) => ({
            value: store.id,
            label: store.name,
          })),
      ]);
    }
    loadStores();
    const localTimer = localStorage.getItem('refreshTimer');
    setRefreshTimer(() => +localTimer || 180000);
    handleSearch();
    searchTextRef.current.focus();
  }, []); //eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      handleSearch();
      setRandomNumber(() => Math.random());
    }, refreshTimer);
  }, [randomNumber]); // eslint-disable-line

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const columns = [
    {
      title: 'Marketplace',
      dataIndex: ['store', 'name'],
    },
    {
      title: 'Order Number',
      dataIndex: 'order_num',
      render: (text, record) => (
        <Link to={`/order/${record.id}`}>
          <Paragraph style={{ color: '#1890ff' }} copyable>
            {text}
          </Paragraph>
        </Link>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'order_items',
      width: 80,
      render: (text) => {
        if (text.length > 1) {
          const sum = text.reduce((acc, cur) => acc + +cur.quantity, 0);
          return sum;
        }
        return text[0]?.quantity;
      },
    },
    {
      title: 'Item Name',
      dataIndex: 'order_items',
      render: (text) => {
        if (text.length > 1) {
          return `${text.length} items...`;
        }
        return text[0]?.title;
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <Tag color={tagColor[text]?.color} key={tagColor[text]?.label}>
          {tagColor[text]?.label.toUpperCase() || text}
        </Tag>
      ),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
    },
    {
      title: 'Created on RBMS',
      dataIndex: 'created_at',
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    {
      title: 'Created on Marketplace',
      dataIndex: 'order_date',
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    // {
    //   title: 'Total $',
    //   dataIndex: 'order_total',
    // },
    // {
    //   title: 'Fulfillment Channel',
    //   dataIndex: 'fulfillment_channel',
    //   render: text => <p>{text === null ? 'Rugged Books' : text}</p>,
    // },
  ];

  const refreshOptions = [
    {
      label: '1 minute',
      value: 60000,
    },
    {
      label: '2 minutes',
      value: 120000,
    },
    {
      label: '3 minutes',
      value: 180000,
    },
    {
      label: '5 minutes',
      value: 300000,
    },
    {
      label: '10 minutes',
      value: 600000,
    },
  ];

  useEffect(() => {
    localStorage.setItem('refreshTimer', refreshTimer);
  }, [refreshTimer]);

  return (
    <Container>
      <Card size="small">
        <Space wrap style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <Button onClick={() => history.push('/ordersNew')} type="primary">
            New Order
          </Button> */}
          <Button onClick={() => history.push('/internalOrders')} type="primary">
            Internal Orders
          </Button>
          {/* <Button type="primary">Export</Button> */}
          <div>
            <span>Automatic Refresh in: </span>
            <Select value={refreshTimer} onChange={(value) => setRefreshTimer(() => value)} options={refreshOptions} style={{ width: 110 }} />
          </div>
        </Space>
      </Card>

      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            <Select
              onChange={(value) => {
                setFilterCriteria(value);
                setPaginationPage(1);
                searchTextRef.current.value = '';
                searchTextRef.current.focus();
              }}
              value={filterCriteria}
              style={{ width: 145 }}
            >
              <Option value="order_num">Order Number</Option>
              <Option value="serial_number">Serial Number</Option>
              <Option value="customer">Customer Name</Option>
              <Option value="product">Product Name</Option>
            </Select>

            <InputText
              onBlur={(e) => (e.target.value = e.target.value.trim().toUpperCase())} //eslint-disable-line
              placeholder="Search"
              ref={searchTextRef}
              onPressEnter={handleSearch}
              style={{ width: 200 }}
              IconAfterProp={<MagnifyingGlass size={20} />}
            />
          </Space>

          <Space wrap>
            {isFiltered && (
              <Tag
                closable
                color="error"
                onClose={(e) => {
                  e.preventDefault();
                  handleClearFilters();
                }}
              >
                Clear Filters
              </Tag>
            )}

            <Select
              onChange={(value) => {
                setFilterStore(value);
                setPaginationPage(1);
                setIsFiltered(true);
                handleSearch();
              }}
              value={filterStore}
              style={{ width: 140 }}
              options={storeList}
            />

            <RangePicker
              onChange={(date) => {
                setfilterDate(date);
                setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterDate}
              allowClear
            />

            <Select
              onChange={(value) => {
                setFilterStatus(value);
                setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterStatus}
              style={{ width: 160 }}
            >
              <Option key="">All Status</Option>
              <Option key="shipped">Shipped</Option>
              <Option key="awaiting_shipment">Awaiting Shipment</Option>
              <Option key="cancelled">Cancelled</Option>
              <Option key="on_hold">On Hold</Option>
              <Option key="awaiting_payment">Awaiting Payment</Option>
            </Select>

            <Select
              onChange={(value) => {
                setFilterFulfillment(value);
                setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterFulfillment}
              style={{ width: 140 }}
            >
              <Option key="">All Channels</Option>
              <Option key="FBA">FBA</Option>
              <Option key="Rugged Books">Rugged Books</Option>
            </Select>
          </Space>
        </div>
      </Card>
      {orders.length > 0 ? (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={orders}
            size="small"
            bordered
            pagination={false}
            scroll={{ y: 'calc(100vh - 22em)' }}
            rowKey={(record) => record.id}
            // components={{
            //   body: {
            //     cell: EditableCell,
            //   },
            // }}
            // rowClassName="editable-row"
          />

          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      ) : (
        <Empty
          description={<span>No data</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      )}
    </Container>
  );
}
