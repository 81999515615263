/* eslint-disable react/prop-types */
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, InputNumber, Space, Switch, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';

import { DebounceSelect } from '~/components/DebounceSelect';
import api from '~/services/api';

import { fetchRams } from '../utils/fetchRams';
import { handlePartsLocationCheck } from '../utils/handlePartsLocationCheck';

const { TextArea } = Input;

export default function Bulk({ form, itemInfo, handleBulkInventoryItemInfo, itemDisabled, profile }) {
  const [batterInfo, setBatterInfo] = useState([]);
  const [batterInfo2, setBatterInfo2] = useState([]);
  const [batterInfo3, setBatterInfo3] = useState([]);

  const validateItemSerialNumber = useCallback(
    async (serialNumber, bulkIndex, field, fieldNumber) => {
      if (serialNumber) {
        const serialNumberTrimmed = serialNumber.trim().toUpperCase();

        const serialNumberFormatted =
          serialNumberTrimmed.substring(0, 4) === 'P/N:' ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4) : serialNumberTrimmed;

        const itemData = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted });

        const fields = await form.getFieldsValue();
        const { bulkUnit } = fields;

        if (itemData.data?.status !== 'InStock') {
          return { status: false, message: 'Item serial number not in stock.' };
        }

        const CAN_TECH_ADD_PART = await handlePartsLocationCheck(itemData.data, profile);
        if (!CAN_TECH_ADD_PART.allowed) {
          return { status: false, message: 'Item location is not under your name' };
        }

        if (field === 'storage') {
          if (itemData.data?.item?.category?.name !== 'Hard Drive') {
            return { status: false, message: 'Serial number is not a Hard Drive.' };
          }

          if (itemData.data?.condition === 'Used') {
            if (itemData.data?.storage_health === null) {
              return { status: false, message: 'Hard drive doesn`t have health.' };
            }
            if (itemData.data?.wiping_report_file_id === null) {
              return { status: false, message: 'Hard drive doesn`t have wiping report.' };
            }
            if (fieldNumber === 1) {
              Object.assign(bulkUnit[bulkIndex], { storageHealth: itemData.data?.storage_health });
            } else {
              Object.assign(bulkUnit[bulkIndex], { storageHealth2: itemData.data?.storage_health });
            }
          }
        } else if (field === 'ram') {
          if (itemData.data?.item?.category?.name !== 'Cards') {
            return { status: false, message: 'Serial number is not a RAM.' };
          }
          if (fieldNumber === 1) {
            Object.assign(bulkUnit[bulkIndex], { ramSize1: itemData.data?.item?.ram?.name });
          } else {
            Object.assign(bulkUnit[bulkIndex], { ramSize2: itemData.data?.item?.ram?.name });
          }
        } else if (field === 'battery') {
          if (itemData.data?.item?.subcategory?.name !== 'Batteries') {
            return { status: false, message: 'Serial number is not a Battery.' };
          }
          if (!itemData.data.battery_file?.url) {
            return { status: false, message: 'Battery missing report' };
          }

          switch (fieldNumber) {
            case 1:
              setBatterInfo({
                health: itemData.data.battery,
                url: itemData.data.battery_file.url,
              });
              Object.assign(bulkUnit[bulkIndex], { batteryItemId: itemData.data?.item?.id });
              Object.assign(bulkUnit[bulkIndex], { batteryFileId: itemData.data?.battery_file?.id });
              Object.assign(bulkUnit[bulkIndex], { batteryHealth: itemData.data?.battery });
              break;
            case 2:
              const newData = {
                health: itemData.data.battery,
                url: itemData.data.battery_file.url,
              };
              setBatterInfo2((prevItems) => {
                const newItems = [...prevItems];

                // If the index is out of bounds, extend the array
                if (bulkIndex >= newItems.length) {
                  newItems[bulkIndex] = { ...newData };
                } else {
                  newItems[bulkIndex] = { ...newItems[bulkIndex], ...newData };
                }

                return newItems;
              });
              Object.assign(bulkUnit[bulkIndex], { batteryItemId2: itemData.data.item.id });
              Object.assign(bulkUnit[bulkIndex], { batteryFileId2: itemData.data.battery_file?.id });
              Object.assign(bulkUnit[bulkIndex], { batteryHealth2: itemData.data.battery });

              break;
            case 3:
              setBatterInfo3({
                health: itemData.data.battery,
                url: itemData.data.battery_file.url,
              });
              Object.assign(bulkUnit[bulkIndex], { batteryItemId3: itemData.data?.item?.id });
              Object.assign(bulkUnit[bulkIndex], { batteryFileId3: itemData.data?.battery_file?.id });
              Object.assign(bulkUnit[bulkIndex], { batteryHealth3: itemData.data?.battery });
              break;

            default:
              break;
          }
        } else {
          return { status: false, message: 'Serial number invalid.' };
        }

        form.setFieldsValue({ bulkUnit });
        return { status: true, message: '' };
      }
      return { status: true, message: '' };
    },
    [form, profile] // eslint-disable-line
  );

  const validateUnitSerialNumber = useCallback(
    async (unitSerialNumber) => {
      if (unitSerialNumber) {
        const serialNumberTrimmed = unitSerialNumber.trim().toUpperCase();
        const serialNumberFormatted =
          serialNumberTrimmed.substring(0, 4) === 'P/N:' ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4) : serialNumberTrimmed;

        const unitData = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted.trim() });

        if (unitData.data === null) return { status: false, message: 'Unit serial number invalid in Bulk' };
        if (unitData.data.is_ready !== true) return { status: false, message: 'Unit does not have the first assessment.' };
        if (unitData.data.status === 'Sold') return { status: false, message: 'Unit is Sold' };
        if (unitData.data.item.category.id !== itemInfo.item.category.id) {
          return { status: false, message: 'Serial Number does not have the same category as the base unit.' };
        }
        const CAN_TECH_ADD_PART = await handlePartsLocationCheck(unitData.data, profile);
        if (!CAN_TECH_ADD_PART.allowed) {
          return { status: false, message: `Unit's location is not under your name` };
        }
        handleBulkInventoryItemInfo(unitData.data);
      }
      return { status: true, message: '' };
    },
    [handleBulkInventoryItemInfo, itemInfo.item.category.id, profile]
  );

  // const handleUploadBatteryFile = useCallback(
  //   async (info, bulkIndex, formField) => {
  //     try {
  //       const formData = new FormData();
  //       formData.append('file', info.file.originFileObj);
  //       const responseUpload = await api.post('files', formData);

  //       let newData = null;

  //       switch (formField) {
  //         case 'batteryFileBulk':
  //           newData = [...batteryUploadFile];
  //           break;
  //         case 'batteryFileBulk2':
  //           newData = [...batteryUploadFile2];
  //           break;
  //         case 'batteryFileBulk3':
  //           newData = [...batteryUploadFile3];
  //           break;
  //         default:
  //           break;
  //       }

  //       const fields = await form.getFieldsValue();
  //       const { bulkUnit } = fields;
  //       Object.assign(bulkUnit[bulkIndex], { [`${formField}`]: responseUpload.data[0].id });
  //       form.setFieldsValue({ bulkUnit });

  //       newData[bulkIndex] = {
  //         uid: responseUpload.data[0].id,
  //         url: responseUpload.data[0].url,
  //         name: responseUpload.data[0].name,
  //       };

  //       switch (formField) {
  //         case 'batteryFileBulk':
  //           setBatteryUploadFile(newData);
  //           break;
  //         case 'batteryFileBulk2':
  //           setBatteryUploadFile2(newData);
  //           break;
  //         case 'batteryFileBulk3':
  //           setBatteryUploadFile3(newData);
  //           break;
  //         default:
  //           break;
  //       }
  //     } catch (error) {
  //       toast.error('Error', 'Something went wrong while uploading file');
  //     }
  //   },
  //   [batteryUploadFile, batteryUploadFile2, batteryUploadFile3, form]
  // );

  const copyBaseRam = useCallback(
    async (bulkIndex) => {
      const fields = await form.getFieldsValue();
      const { bulkUnit } = fields;
      const ramCopy = { ramSize1: fields.ramSize1 || undefined, ramSize2: fields.ramSize2 || undefined };
      if (bulkUnit[bulkIndex].copyRam !== true) {
        ramCopy.ramSize1 = undefined;
        ramCopy.ramSize2 = undefined;
      }
      Object.assign(bulkUnit[bulkIndex], ramCopy);
      form.setFieldsValue({ bulkUnit });
    },
    [form]
  );

  // const dummyRequest = ({ onSuccess }) => {
  //   onSuccess('ok');
  // };

  if (itemDisabled === true) {
    return <Alert message="Bulk disabled." description="To add units to the Bulk, please change unit to To Be Inspected." type="info" showIcon />;
  }

  return (
    <Form.List name="bulkUnit">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" wrap>
              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'unitSerialNumber']}
                label="Unit Serial Number"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
                rules={[
                  { required: true, message: 'Missing Unit Serial Number in Bulk' },
                  {
                    validator: async (_, value) => {
                      const { status, message } = await validateUnitSerialNumber(value, name);
                      return status ? Promise.resolve() : Promise.reject(message);
                    },
                  },
                ]}
              >
                <Input placeholder="Unit Serial Number" allowClear />
              </Form.Item>

              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'storageSerialNumber']}
                label="Storage 1 S/N"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
                rules={[
                  {
                    validator: async (_, value) => {
                      const { status, message } = await validateItemSerialNumber(value, name, 'storage', 1);
                      return status ? Promise.resolve() : Promise.reject(message);
                    },
                  },
                ]}
              >
                <Input placeholder="Storage S/N" allowClear />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'storageHealth']}
                label="Storage 1 Health"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
              >
                <InputNumber
                  placeholder="Storage Health"
                  min={0}
                  max={100}
                  formatter={(value) => (value === '' ? '' : `${value}%`)}
                  parser={(value) => value.replace('%', '')}
                  style={{ width: '210px' }}
                  disabled
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'storageSerialNumber2']}
                label="Storage 2 S/N"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
                rules={[
                  {
                    validator: async (_, value) => {
                      const { status, message } = await validateItemSerialNumber(value, name, 'storage', 2);
                      return status ? Promise.resolve() : Promise.reject(message);
                    },
                  },
                ]}
              >
                <Input placeholder="Storage 2 S/N" allowClear />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'storageHealth2']}
                label="Storage 2 Health"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
              >
                <InputNumber
                  placeholder="Storage 2 Health"
                  min={0}
                  max={100}
                  formatter={(value) => (value === '' ? '' : `${value}%`)}
                  parser={(value) => value.replace('%', '')}
                  style={{ width: '210px' }}
                  disabled
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'ramSerialNumber1']}
                label="Ram 1 S/N"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
                rules={[
                  {
                    validator: async (_, value) => {
                      const { status, message } = await validateItemSerialNumber(value, name, 'ram', 1);
                      return status ? Promise.resolve() : Promise.reject(message);
                    },
                  },
                ]}
              >
                <Input placeholder="Ram 1 S/N" allowClear />
              </Form.Item>
              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'ramSize1']}
                label="Ram 1 Size"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
              >
                <DebounceSelect allowClear showSearch style={{ width: 210 }} placeholder="RAM 1 Size" optionFilterProp="label" fetchOptions={fetchRams} />
              </Form.Item>

              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'ramSerialNumber2']}
                label="Ram 2 S/N"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
                rules={[
                  {
                    validator: async (_, value) => {
                      const { status, message } = await validateItemSerialNumber(value, name, 'ram', 2);
                      return status ? Promise.resolve() : Promise.reject(message);
                    },
                  },
                ]}
              >
                <Input placeholder="Ram 2 S/N" allowClear />
              </Form.Item>
              <Form.Item
                hasFeedback
                {...restField}
                name={[name, 'ramSize2']}
                label="Ram 2 Size"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '210px' }}
              >
                <DebounceSelect allowClear showSearch style={{ width: 210 }} placeholder="RAM 2 Size" optionFilterProp="label" fetchOptions={fetchRams} />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'copyRam']}
                label="Copy Base RAM"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                valuePropName="checked"
              >
                <Switch onChange={() => copyBaseRam(name)} />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'unitHasRam']}
                label="Unit has RAM"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              {itemInfo.item.battery_type?.split(',').includes('internal') && (
                <>
                  <Form.Item
                    hasFeedback
                    {...restField}
                    name={[name, 'batterySerialNumber']}
                    label="Battery S/N"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: '210px' }}
                    rules={[
                      {
                        validator: async (_, value) => {
                          const { status, message } = await validateItemSerialNumber(value, name, 'battery', 1);
                          return status ? Promise.resolve() : Promise.reject(message);
                        },
                      },
                    ]}
                  >
                    <Space direction="vertical">
                      <Input placeholder="Battery S/N" allowClear />
                      {batterInfo[key] && (
                        <Tooltip title="Click to open health report">
                          <Button target="_blank" icon={<LinkOutlined />} type="link" href={batterInfo[key]?.url}>{`${batterInfo[key]?.health}%`}</Button>
                        </Tooltip>
                      )}
                    </Space>
                  </Form.Item>

                  <Form.Item hidden name="batteryItemId">
                    <Input disabled placeholder="Battery Item Id" />
                  </Form.Item>

                  <Form.Item hidden name="batteryFileId">
                    <Input disabled placeholder="Field Id" />
                  </Form.Item>

                  <Form.Item hidden name="batteryHealth">
                    <Input disabled placeholder="Battery Health" />
                  </Form.Item>
                </>
              )}

              {itemInfo.item.battery_type?.split(',').includes('removable') && (
                <>
                  <Form.Item
                    hasFeedback
                    {...restField}
                    name={[name, 'batterySerialNumber2']}
                    label="Removable Battery S/N"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: '210px' }}
                    rules={[
                      {
                        validator: async (_, value) => {
                          const { status, message } = await validateItemSerialNumber(value, name, 'battery', 2);
                          return status ? Promise.resolve() : Promise.reject(message);
                        },
                      },
                    ]}
                  >
                    <Space direction="vertical">
                      <Input placeholder="Removable Battery S/N" allowClear />
                      {batterInfo2[key] && (
                        <Tooltip title="Click to open health report">
                          <Button target="_blank" icon={<LinkOutlined />} type="link" href={batterInfo2[key]?.url}>{`${batterInfo2[key]?.health}%`}</Button>
                        </Tooltip>
                      )}
                    </Space>
                  </Form.Item>

                  <Form.Item name={[name, 'batteryItemId2']} hidden>
                    <Input name="batteryItemId2" />
                  </Form.Item>

                  <Form.Item name={[name, 'batteryFileId2']} hidden>
                    <Input name="batteryFileId2" />
                  </Form.Item>

                  <Form.Item name={[name, 'batteryHealth2']} hidden>
                    <Input name="batteryHealth2" />
                  </Form.Item>
                </>
              )}

              {itemInfo.item.battery_type?.split(',').includes('removable2') && (
                <>
                  <Form.Item
                    hasFeedback
                    {...restField}
                    name={[name, 'batterySerialNumber3']}
                    label="2nd Removable Battery S/N"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: '210px' }}
                    rules={[
                      {
                        validator: async (_, value) => {
                          const { status, message } = await validateItemSerialNumber(value, name, 'battery', 3);
                          return status ? Promise.resolve() : Promise.reject(message);
                        },
                      },
                    ]}
                  >
                    <Space direction="vertical">
                      <Input placeholder="2nd Removable Battery S/N" allowClear />
                      {batterInfo3[key] && (
                        <Tooltip title="Click to open health report">
                          <Button target="_blank" icon={<LinkOutlined />} type="link" href={batterInfo3[key]?.url}>{`${batterInfo3[key]?.health}%`}</Button>
                        </Tooltip>
                      )}
                    </Space>
                  </Form.Item>

                  <Form.Item hidden name="batteryItemId3">
                    <Input disabled placeholder="Battery Item Id" />
                  </Form.Item>

                  <Form.Item hidden name="batteryFileId3">
                    <Input disabled placeholder="Field Id" />
                  </Form.Item>

                  <Form.Item hidden name="batteryHealth3">
                    <Input disabled placeholder="Battery Health" />
                  </Form.Item>
                </>
              )}

              <Form.Item {...restField} name={[name, 'hours']} label="Hours" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <InputNumber placeholder="Hours" min={0} />
              </Form.Item>

              <Form.Item {...restField} name={[name, 'notes']} label="Notes" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <TextArea showCount maxLength={255} allowClear style={{ width: 300 }} placeholder="Add additional information about this item" rows={2} />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'finishInspection']}
                label="Finish Inspection"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                valuePropName="checked"
              >
                <Switch defaultChecked />
              </Form.Item>

              <MinusCircleOutlined
                onClick={() => {
                  remove(name);
                }}
              />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add unit
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}
