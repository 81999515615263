import React, { useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { Table, Button } from 'antd';

// import 'antd/dist/antd.css';
import { Container } from './styles';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function Super() {
  const classes = useStyles();

  const [orders, setOrders] = useState([]);

  async function getPostalCode(order_number) {
    const orderPostalCode = await api.get(`super?action=findPostalCode&order_number=${order_number}`);
    const newOrders = orders.map(el => {
      if (el.order_num === order_number) {
        return { ...el, postalCode: orderPostalCode.data };
      }
      return el;
    });
    setOrders(newOrders);
  }

  const columns = [
    {
      title: 'Order',
      dataIndex: 'order_num',
      key: 'order_num',
    },
    {
      title: 'Get Postal Code',
      dataIndex: 'getPostalCode',
      key: 'getPostalCode',
      render: (text, row) => (
        <Button onClick={() => getPostalCode(row.order_num)} type="primary">
          Get PC for {row.order_num}
        </Button>
      ),
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Get qty customer with postal code',
      dataIndex: 'customerQuantity',
      key: 'customerQuantity',
    },
  ];

  useEffect(() => {
    async function getOrders() {
      const ordersData = await api.get(`super?action=findOrders`);
      setOrders(ordersData.data);
    }
    getOrders();
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        <Table columns={columns} dataSource={orders} />
      </Paper>
    </Container>
  );
}
