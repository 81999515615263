// import moment from 'moment';

import { handlePartsLocationCheck } from './handlePartsLocationCheck';
import { validateBatterySerialNumber } from './validateBatterySerialNumber';

export async function handleBatterySerialNumberValidation(
  value,
  setBatteryFileState,
  setBatteryUploadDisabledState,
  batteryNumber,
  itemInfo,
  profile,
  form
) {
  const batteryValidation = await validateBatterySerialNumber(value, itemInfo[`battery_serial_number${batteryNumber}`]);
  if (!batteryValidation.ok) {
    return Promise.reject(batteryValidation.message);
  }

  const batteryFieldList = ['batterySerialNumber', 'batterySerialNumber2', 'batterySerialNumber3'];
  batteryFieldList.splice(batteryFieldList.indexOf(`batterySerialNumber${batteryNumber}`), 1);

  value =
    value.substring(0, 4) === 'P/N:'
      ? value
        .substring(value.indexOf('S/N:') + 4)
        .trim()
        .toUpperCase()
      : value.trim().toUpperCase();

  if (value === form.getFieldValue(batteryFieldList[0]) || value === form.getFieldValue(batteryFieldList[1])) {
    throw new Error('One or more battery slots have the same serial number');
  }
  form.setFieldsValue({ [`batterySerialNumber${batteryNumber}`]: value });

  const batteryLocation = handlePartsLocationCheck(batteryValidation.batteryData, profile);
  if (!batteryLocation.allowed) {
    return Promise.reject(batteryLocation.message);
  }

  const pictureList = batteryValidation.batteryData.battery_file
    ? [
      {
        uid: batteryValidation.batteryData.battery_file.id,
        url: batteryValidation.batteryData.battery_file.url,
        name: batteryValidation.batteryData.battery_file.name,
      },
    ]
    : null;
  setBatteryFileState(pictureList);

  if (batteryValidation.batteryData.battery_file) {
    form.setFieldsValue({ [`batteryFile${batteryNumber}`]: pictureList });
  }

  if (batteryValidation.batteryData.condition === 'New') {
    setBatteryUploadDisabledState(true);
    form.setFieldsValue({ [`batteryPercentage${batteryNumber}`]: 100 });
  } else if (batteryValidation.batteryData.battery) {
    form.setFieldsValue({ [`batteryPercentage${batteryNumber}`]: batteryValidation.batteryData.battery });
  }
  form.setFieldsValue({ [`batteryPartNumber${batteryNumber}`]: batteryValidation.batteryData.item_id });
  form.setFieldsValue({ [`batteryItemName${batteryNumber}`]: batteryValidation.batteryData.item.name });

  return Promise.resolve();
}
