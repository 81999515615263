import styled from 'styled-components';
// import { Paper, Divider } from '@material-ui/core';
// import { darken } from 'polished';

export const Container = styled.div`
  /* background: #000; */
  /* background: #e5e5e5; */

  padding: 75px 20px 0 250px;

  input {
    /* text-transform: uppercase; */
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;
