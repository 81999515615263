export const defaultTheme = {
  white: '#fff',
  gray: '#808080',

  'gray-100': '#f3f4f6',
  'gray-200': '#e5e7eb',
  'gray-300': '#d1d5db',
  'gray-400': '#9ca3af',
  'gray-500': '#6b7280',
  'gray-600': '#4b5563',
  'gray-700': '#374151',
  'gray-800': '#1f2937',
  'gray-900': '#111827',
  'gray-950': '#030712',

  'green-300': '#00B37E',
  'green-500': '#00875F',
  'green-700': '#015F43',

  'cyan-100': '#cffafe',
  'cyan-200': '#a5f3fc',

  'sky-100': '#e0f2fe',
  'sky-200': '#bae6fd',
  'sky-300': '#7dd3fc',
  'sky-400': '#38bdf8',

  'blue-100': '#dbeafe',
  'blue-200': '#bfdbfe',
  'blue-300': '#93c5fd',
  'blue-400': '#60a5fa',
  'blue-500': '#3b82f6',
  'blue-700': '#1d4ed8',

  'yellow-300': '#fde047',
  'yellow-500': '#eab308',
  'yellow-700': '#a16207',

  'amber-100': '#fef3c7',
  'amber-400': '#fbbf24',

  'orange-500': '#f97316',

  'red-100': '#fee2e2',
  'red-300': '#F75A68',
  'red-500': '#AB222E',
  'red-700': '#7A1921',

  'rose-400': '#fb7185',
  'rose-500': '#f43f5e',

  'zinc-50': '#fafafa',
  'zinc-100': '#f4f4f5',
  'zinc-200': '#e4e4e7',
  'zinc-300': '#d4d4d8',
  'zinc-400': '#a1a1aa',
  'zinc-500': '#71717a',
  'zinc-600': '#52525b',
  'zinc-700': '#3f3f46',
  'zinc-800': '#27272a',
  'zinc-900': '#18181b',
  'zinc-950': '#09090b',
};
