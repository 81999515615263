import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { CardHeader } from '@material-ui/core';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

import CountryListSingle from '~/utils/countriesListSingle';

export default function SupplierNew(params) {
  const [supplierInfo, setSupplierInfo] = useState({});
  const [form] = Form.useForm();

  const [updateFlag, setUpdateFlag] = useState(false);

  const [size, setSize] = useState();
  const [page, setPage] = useState();
  const [query, setQuery] = useState();

  const countryList = CountryListSingle();

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };
  /* eslint-disable no-template-curly-in-string */

  useEffect(() => {
    async function loadSupplier() {
      const location = new URLSearchParams(params.location.search);
      const supplier_name = location.get('name');
      const pageSize = location.get('size');
      const pages = location.get('page');
      const searchQuery = location.get('searchQuery');
      if (supplier_name) {
        const supplier_info = await api.get(`suppliers/${supplier_name}`);
        const supplier = {
          name: supplier_info.data.name,
          company: supplier_info.data.company,
          email: supplier_info.data.email,
          phone: supplier_info.data.phone,
          street1: supplier_info.data.street1,
          street2: supplier_info.data.street2,
          street3: supplier_info.data.street3,
          city: supplier_info.data.city,
          state: supplier_info.data.state,
          country: supplier_info.data.country,
          postal_code: supplier_info.data.postalCode,
        };
        form.setFieldsValue(supplier);
        setSupplierInfo(supplier_info.data);
        setUpdateFlag(true);
        setSize(pageSize);
        setPage(pages);
        setQuery(searchQuery === 'undefined' ? undefined : searchQuery);
      } else {
        setSize(25);
        setPage(1);
      }
    }
    loadSupplier();
  }, []); // eslint-disable-line

  const onFinish = async values => {
    const newSupplier = {
      name: values.name,
      company: values.company || '',
      email: values.email || '',
      phone: values.phone,
      street1: values.street1,
      street2: values.street2 || '',
      street3: values.street3 || '',
      city: values.city,
      state: values.state,
      country: values.country,
      postal_code: values.postalCode,
    };

    try {
      if (updateFlag) {
        await api.put(`suppliers/${supplierInfo.name}`, newSupplier);
        toast.success('Supplier Updated.');
      } else {
        await api.post('suppliers', newSupplier);
        toast.success('New Supplier created.');
      }
      history.push('/suppliers');
    } catch {
      toast.error('Error loading Supplier. Contact RBMS administrator.');
    }
  };

  return (
    <Container>
      <Card>
        <Form {...layout} form={form} name="nest-messages" onFinish={onFinish}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={() => (query === undefined ? `/suppliers?size=${size}&page=${page}` : `/suppliers?searchQuery=${query}&size=${size}&page=${page}`)}>
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {updateFlag === true ? <CardHeader subheader={`${supplierInfo.name}`} title="Update Supplier" /> : <CardHeader title="New Supplier" />}
            <div />
          </div>
          <Card title="Supplier Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input allowClear placeholder="Supplier's Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="company" label="Company">
                  <Input allowClear placeholder="Company's Name" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Contact Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="email" label="Email">
                  <Input allowClear placeholder="Supplier's Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="phone" label="Phone">
                  <Input allowClear placeholder="Supplier's Phone Number" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Delivery Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="street1" label="Street 1">
                  <Input allowClear placeholder="Address line 1" />
                </Form.Item>
                <Form.Item name="street2" label="Street 2">
                  <Input allowClear placeholder="Address line 2" />
                </Form.Item>
                <Form.Item name="street3" label="Street 3">
                  <Input allowClear placeholder="Address line 3" />
                </Form.Item>
                <Form.Item name="city" label="City">
                  <Input allowClear placeholder="Enter the City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="state" label="State/Province">
                  <Input allowClear placeholder="Enter the State or Province" />
                </Form.Item>
                <Form.Item name="country" label="Country">
                  <Select placeholder="Select a country." options={countryList} showSearch allowClear />
                </Form.Item>
                <Form.Item name="postalCode" label="Postal Code">
                  <Input allowClear placeholder="Enter the Postal Code" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
}
