import React, { useState, useEffect } from 'react';

import { Form, Input, Button, Card } from 'antd';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { CardHeader } from '@material-ui/core';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function StoreNew(params) {
  const [storeInfo, setStoreInfo] = useState({});

  const [form] = Form.useForm();

  const [updateFlag, setUpdateFlag] = useState(false);

  const [size, setSize] = useState();
  const [page, setPage] = useState();
  const [query, setQuery] = useState();

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };
  /* eslint-disable no-template-curly-in-string */

  useEffect(() => {
    async function loadStore() {
      const location = new URLSearchParams(params.location.search);
      const store_name = location.get('name');
      const pageSize = location.get('size');
      const pages = location.get('page');
      const searchQuery = location.get('searchQuery');
      if (store_name) {
        const store_info = await api.get(`stores/${store_name}`);
        const store = {
          name: store_info.data.name,
          shipstationStoreId: store_info.data.shipstation_store_id,
        };
        form.setFieldsValue(store);
        setStoreInfo(store_info.data);
        setUpdateFlag(true);
        setSize(pageSize);
        setPage(pages);
        setQuery(searchQuery === 'undefined' ? undefined : searchQuery);
      } else {
        setSize(25);
        setPage(1);
      }
    }
    loadStore();
  }, []); // eslint-disable-line

  const onFinish = async values => {
    const newStore = {
      name: values.name,
      shipstation_store_id: values.shipstationStoreId,
    };

    try {
      if (updateFlag) {
        await api.put(`stores/${storeInfo.name}`, newStore);
        toast.success('store Updated.');
      } else {
        await api.post('stores', newStore);
        toast.success('New Store created.');
      }
      history.push('/stores');
    } catch {
      toast.error('Error loading Store. Contact RBMS administrator.');
    }
  };

  return (
    <Container>
      <Card>
        <Form {...layout} form={form} name="nest-messages" onFinish={onFinish}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={() => (query === undefined ? `/stores?size=${size}&page=${page}` : `/stores?searchQuery=${query}&size=${size}&page=${page}`)}>
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {updateFlag === true ? <CardHeader subheader={`${storeInfo.name}`} title="Update Store" /> : <CardHeader title="New Store" />}
            <div />
          </div>
          <Card title="Store Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear placeholder="Store's Name" />
            </Form.Item>
            <Form.Item name="shipstationStoreId" label="ShipStation Store Id" rules={[{ required: true }]}>
              <Input allowClear placeholder="ShipStation Id" />
            </Form.Item>
          </Card>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
}
