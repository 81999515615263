import AssignAssetDiagnosticRender from './AssignAssetDiagnosticRender';
import AssignKBType from './AssignKBType';
import AssignOtherPorts from './AssignOtherPorts';

/* eslint-disable no-loop-func */
export default function ReportFields(reportsData, keyboardPortOptions) {
  const report = [];

  reportsData.forEach((data) => {
    report.push({
      id: data.id,
      serial_number: data.serial_number,
      status: data.status,
      hours: data.hours,
      condition: data.condition,
      // cpu: `${data.item.cpu.brand} ${data.item.cpu.number} ${data.item.cpu.speed}`,
      grade: data.grade,
      location: `${data.locations?.name || ''} ${data.usersLocation?.name || ''}`,
      touch: data.touch,
      stylus: data.stylus,
      camera: data.camera,
      storage: data.storage,
      storage_2: data.storage_2,
      ram: data.ram,
      ram_2: data.ram_2,
      keyboard_type_id: AssignKBType(data.keyboard_type_id),
      other_ports: AssignOtherPorts(data.keyboard_ports, keyboardPortOptions),
      function_audio: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_audio),
      function_barcode_reader: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_barcode_reader),
      function_bluetooth: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_bluetooth),
      function_fingerprint: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_fingerprint),
      function_gps: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_gps),
      usb_ports: data.usb_ports,
      function_mousepad: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_mousepad),
      function_optical_drive: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_optical_drive),
      function_smartcard: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_smartcard),
      function_wlan: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_wlan),
      operating_system: data.operating_system,
      processed_by: data.processed_by,
      notes: data.description,
      updated_at: data.updated_at,
    });
  });

  return report;
}
