import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { Container } from './styles';

export default function Amazon() {
  const [form] = Form.useForm();

  const [paramsInfo, setParamsInfo] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    async function loadAmazonParams() {
      const getQBOParams = await api.get('params/Amazon');

      if (getQBOParams.data.length > 0) {
        const paramsData = {
          baseUrl: getQBOParams.data.filter(el => el.key_param === 'baseUrl')[0]?.value_param || '',
          refreshToken: getQBOParams.data.filter(el => el.key_param === 'refreshToken')[0]?.value_param || '',
          appClientID: getQBOParams.data.filter(el => el.key_param === 'appClientID')[0]?.value_param || '',
          appClientSecret: getQBOParams.data.filter(el => el.key_param === 'appClientSecret')[0]?.value_param || '',
          awsStsVersion: getQBOParams.data.filter(el => el.key_param === 'awsStsVersion')[0]?.value_param || '',
          roleArn: getQBOParams.data.filter(el => el.key_param === 'roleArn')[0]?.value_param || '',
          awsUserKey: getQBOParams.data.filter(el => el.key_param === 'awsUserKey')[0]?.value_param || '',
          awsUserSecret: getQBOParams.data.filter(el => el.key_param === 'awsUserSecret')[0]?.value_param || '',
          awsRegion: getQBOParams.data.filter(el => el.key_param === 'awsRegion')[0]?.value_param || '',
        };
        setParamsInfo(paramsData);
        form.setFieldsValue(paramsData);
      }
    }
    loadAmazonParams();
  },[])//eslint-disable-line

  async function onFinish(values) {
    const params = Object.keys(values).map(el => ({
      service: 'Amazon',
      key_param: el,
      value_param: values[el],
    }));
    await api.post('params', { params });
    toast.success('Saved');
  }

  return (
    <Container>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        size="small"
        style={{ width: '1200px' }}
      >
        <Form.Item name="baseUrl" label="API URI">
          <Input onChange={el => (el.target.value !== paramsInfo.baseUrl ? setSubmitDisabled(false) : setSubmitDisabled(true))} />
        </Form.Item>

        <Form.Item name="refreshToken" label="Refresh Token">
          <Input />
        </Form.Item>

        <Form.Item name="appClientID" label="App Client ID">
          <Input />
        </Form.Item>

        <Form.Item name="appClientSecret" label="App Client Secret">
          <Input />
        </Form.Item>

        <Form.Item name="awsStsVersion" label="AWS STS Version">
          <Input />
        </Form.Item>

        <Form.Item name="roleArn" label="Role Arn">
          <Input />
        </Form.Item>

        <Form.Item name="awsUserKey" label="AWS User Key">
          <Input />
        </Form.Item>

        <Form.Item name="awsUserSecret" label="AWS User Secret">
          <Input />
        </Form.Item>

        <Form.Item name="awsRegion" label="AWS Region">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" disabled={submitDisabled}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
}
