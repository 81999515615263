import api from '~/services/api';

export async function validateBatterySerialNumber(batteryProp, batterySerialNumberOnUnit) {
  const batterySerialNumberParam = batteryProp?.trim().toUpperCase();

  const batterySerialNumber =
    batterySerialNumberParam.substring(0, 4) === 'P/N:'
      ? batterySerialNumberParam.substring(batterySerialNumberParam.indexOf('S/N:') + 4)
      : batterySerialNumberParam;

  if (!batterySerialNumber) return { ok: false, message: 'Invalid battery serial number' };

  const batteryData = await api.post('inventory_items/showBySerialNumber', { serial_number: batterySerialNumber });

  if (batteryData.data.error) return { ok: false, message: batteryData.data.error };

  if (['Sold'].includes(batteryData.data.status)) {
    return { ok: false, message: `Battery status is Sold` };
  }

  if (['InUse'].includes(batteryData.data.status) && batteryData.data.serial_number !== batterySerialNumberOnUnit) {
    return { ok: false, message: `Battery status is In Use by a different unit` };
  }

  if (batteryData.data?.item?.subcategory?.name !== 'Batteries') return { ok: false, message: 'Invalid category for battery' };
  return { ok: true, batteryData: batteryData.data };
}
