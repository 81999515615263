import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import api from '~/services/api';
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: '# Inspections completed',
    dataIndex: 'inspected',
    sorter: (a, b) => a.inspected - b.inspected,
  },
  {
    title: '# Added to internal orders',
    dataIndex: 'addedToInternalOrder',
    sorter: (a, b) => a.addedToInternalOrder - b.addedToInternalOrder,
  },
  {
    title: '# Added to marketplace orders',
    dataIndex: 'addedToMarketplaceOrder',
    sorter: (a, b) => a.addedToMarketplaceOrder - b.addedToMarketplaceOrder,
  },
];

export function TechnicianReportDataTable({ startDate, endDate }) {
  const [data, setData] = useState(null);

  async function loadData() {
    const report = await api.put('/report/TechniciansProductivity', {
      startDate,
      endDate,
    });
    setData(report.data);
  }

  useEffect(() => {
    loadData();
  }, [startDate, endDate]); //eslint-disable-line

  return <Table size="small" rowKey={(record) => record.id} columns={columns} dataSource={data} pagination={false} />;
}
