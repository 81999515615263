import React from 'react';

import { HotKeys } from 'react-hotkeys';

import history from '~/services/history';
import { LatestOrders } from './components';

import { Container } from './styles';

export default function Dashboard() {
  const keyMap = {
    ADMIN: 'ctrl+alt+k',
  };

  const handlers = {
    ADMIN: () => history.push(`/super`),
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Container>
        <LatestOrders />
      </Container>
    </HotKeys>
  );
}
