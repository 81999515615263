import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  overflow: auto;
  width: 100%;
  padding: 75px 20px 0 250px;
  height: 100vh;

  strong {
    display: block;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  border: 0;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  padding: 0 1.25rem;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #1378d6;
    transition: background-color 0.2s;
  }

  &[data-status='Complete'] {
    background-color: ${(props) => props.theme['green-300']};

    &:hover {
      background-color: ${(props) => props.theme['green-500']};
    }
  }

  /* &:disabled {
    background-color: ${(props) => props.theme['gray-300']};

    &:hover {
      background-color: ${(props) => props.theme['gray-400']};
      transition: background-color 0.2s;
    }
  } */
`;

export const TransactionContainer = styled.main`
  width: 100%;
  max-width: 2240px;
`;

export const TransactionTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0 2px;
  width: 100%;
  border-radius: 10px;
  /* margin-top: 1.2rem; */
  background: #ededed;
  padding: 0.7rem;

  thead {
    background: #e0e0e0;
    border-bottom: 1px solid #fff;
    font-weight: bold;

    td:first-child {
      border-top-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  tr:nth-child(even) {
    background: ${(props) => props.theme['gray-100']};
  }

  tr:hover {
    background: ${(props) => props.theme['gray-300']};
  }

  tr,
  td {
    padding: 5px 10px;
  }
`;

export const SpaceBetween = styled.div`
  align-content: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  max-width: 2240px;
`;
