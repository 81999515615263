import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    padding: 50px 10px;
  }

  /* input {
    height: 2rem;
    padding: 0 0.5rem;
    width: 16rem;
  }

  input:focus {
    border: 1px solid #2372dc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(35, 114, 220, 0.6);
  } */
`;

export const ActionsBar = styled.div`
  background-color: ${props => props.theme['blue-300']};
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;

  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
`;

export const Button = styled.button`
  padding: 8px 12px;
  font-weight: 700;
  background-color: ${props => props.theme['blue-500']};
  color: ${props => props.theme.white};
  outline: ${props => props.theme['blue-500']};
  border-radius: 4px;
  border: 0;

  &:hover {
    background-color: ${props => props.theme['blue-700']};
    transition: background-color 0.2s;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 80vw;
`;
