import React, { useMemo } from 'react';

import { Table } from 'antd';
import { VList } from 'virtuallist-antd';

export default function VirtualTable(props) {
  const vComponents = useMemo(() => {
    return VList({
      height: 1000,
    });
  }, []);

  return (
    <Table
      {...props}
      scroll={{
        y: 1000,
      }}
      components={vComponents}
    />
  );
}
