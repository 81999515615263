/* eslint-disable consistent-return */
/* eslint-disable no-shadow */

import moment from 'moment';
// import api from '~/services/api';

export async function WarrantyCheck(serial_number, itemInventoryData) {
  function checkProductionYear(serial_number) {
    switch (serial_number[0]) {
      case '0':
        return 2020;
      case '1':
        return 2021;
      case '2':
        return 2022;
      case '3':
        return 2023;
      case '4':
        return 2024;
      case '5':
        return 2025;
      case '6':
        return 2026;
      case '7':
        return 2027;
      case '8':
        return 2028;
      case '9':
        return 2029;
      default:
        break;
    }
  }

  function checkProductionMonth(serial_number) {
    switch (serial_number[1]) {
      case 'A':
        return '0';
      case 'B':
        return '1';
      case 'C':
        return '2';
      case 'D':
        return '3';
      case 'E':
        return '4';
      case 'F':
        return '5';
      case 'G':
        return '6';
      case 'H':
        return '7';
      case 'I':
        return '8';
      case 'J':
        return '9';
      case 'K':
        return '10';
      case 'L':
        return '11';
      default:
        break;
    }
  }

  const itemWarranty = [];
  if (itemInventoryData.data?.item?.brand?.name === 'Panasonic') {
    if (
      itemInventoryData.data?.item?.name.includes('CF-20') ||
      itemInventoryData.data?.item?.name.includes('CF-33') ||
      itemInventoryData.data?.item?.name.includes('CF-31 MK6') ||
      itemInventoryData.data?.item?.name.includes('CF-54 MK3') ||
      itemInventoryData.data?.item?.name.includes('FZ-55') ||
      itemInventoryData.data?.item?.name.includes('FZ-G1 MK4') ||
      itemInventoryData.data?.item?.name.includes('FZ-G1 MK5') ||
      itemInventoryData.data?.item?.name.includes('FZ-G2')
    ) {
      const yearAdded = new Date(itemInventoryData.data?.createdAt).getFullYear();
      const yearCheck = checkProductionYear(serial_number);
      const year = yearCheck > yearAdded ? yearCheck - 10 : yearCheck;
      const month = checkProductionMonth(serial_number);

      const expireYear = year + 3;
      const expireDate = moment(new Date(expireYear, month)).endOf('month');
      if (expireDate > new Date(Date.now())) {
        itemWarranty.push({ description: 'Hardware Warranty', expDate: expireDate, status: true });
      } else {
        itemWarranty.push({ description: 'Hardware Warranty', expDate: expireDate, status: false });
      }
    }
  }
  if (itemInventoryData.data?.item?.brand?.name === 'Dell') {
    itemWarranty.push({ description: 'Not available', expDate: '', status: null });
    // const dellData = await api.get(`warranty?serial_number=${serial_number}`);
    // const entitlementsList = dellData.data.filter(unitInfo => unitInfo.type === 'entitlement');
    // // eslint-disable-next-line array-callback-return
    // entitlementsList.map(entitlement => {
    //   if (new Date(entitlement.end_date) > new Date(Date.now())) {
    //     itemWarranty.push({ description: entitlement.description, expDate: new Date(entitlement.end_date), status: true });
    //   } else {
    //     itemWarranty.push({ description: entitlement.description, expDate: new Date(entitlement.end_date), status: false });
    //   }
    // });
  }
  return itemWarranty;
}
