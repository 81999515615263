import { CloudDownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Divider, Empty, Modal, Spin, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

import api from '~/services/api';

import { Container, NewInventoryButton, StyledButton, TransactionContainer, TransactionTable } from './styles';

const { confirm } = Modal;

const statusColor = {
  Pending: 'red',
  Complete: 'green',
  Started: 'orange',
};

export default function PhysicalInventory() {
  const [inventoryList, setInventoryList] = useState([]);
  const profile = useSelector((state) => state.user.profile);
  const [reportLoading, setReportLoading] = useState(false);

  useEffect(() => {
    async function loadInventories() {
      const inventories = await api.get('/physical_inventory');
      setInventoryList(inventories.data);
    }
    loadInventories();
  }, []);

  const showPromiseConfirm = (inventoryId) => {
    confirm({
      title: 'Do you want to delete this inventory?',
      icon: <ExclamationCircleFilled />,
      content: 'When clicked the OK button, everything about this inventory will be removed',
      async onOk() {
        try {
          await api.delete(`/physical_inventory/${inventoryId}`);
          setInventoryList((state) => [...state].filter((inventory) => inventory.id !== inventoryId));
          toast.success('Inventory deleted');
        } catch (error) {
          toast.error('Could not delete this inventory');
        }
      },
      onCancel() {},
    });
  };

  const handleGenerateReport = async (inventory) => {
    try {
      setReportLoading(() => true);
      const report = await api.get(`physical_inventory/generatePhysicalInventoryReport/${inventory.id}`);
      const worksheet = XLSX.utils.json_to_sheet(report.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `${inventory.name}_Physical_Inventory_report.xlsx`);
    } catch (error) {
      toast.error(`${error.response.data.message}. Please contact your system admin and report this error`);
    }
    setReportLoading(() => false);
  };

  const handleUpdateNotScanned = async (inventory_id) => {
    try {
      await api.patch(`physical_inventory/updateNotScannedUnits/${inventory_id}`);
      toast.success('All units Not Scanned that had the location changed after the physical inventory have been updated to "Not Scanned-found later".');
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        {profile.type === 999 ? (
          <>
            <Link to="/newPhysicalInventory">
              <NewInventoryButton>New inventory</NewInventoryButton>
            </Link>
            {/* <Divider /> */}
          </>
        ) : (
          <div />
        )}
        <strong>Physical Inventory</strong>
        <div />
      </div>
      <Divider />
      <Modal title="Import Result Details" open={reportLoading} footer={[]}>
        <Spin tip="Preparing report for download" size="small" spinning={reportLoading}>
          <div />
        </Spin>
      </Modal>

      {inventoryList.length > 0 ? (
        <TransactionContainer>
          <TransactionTable>
            <thead>
              <tr>
                <td width="30%">Inventory name</td>
                <td>Status</td>
                <td>Created date</td>
                <td>Start date</td>
                <td>Report</td>
                <td width="30%">Actions</td>
              </tr>
            </thead>
            <tbody>
              {inventoryList.map((inventory) => (
                <tr key={inventory.id}>
                  <td width="40%">
                    {inventory.status === 'Complete' ? inventory.name : <Link to={`/physicalInventoryLocations/${inventory.id}`}>{inventory.name}</Link>}
                  </td>
                  <td>
                    <Tag color={statusColor[inventory.status]}>{inventory.status}</Tag>
                  </td>
                  <td>
                    {new Intl.DateTimeFormat('en-CA', {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    }).format(new Date(inventory.createdAt))}
                  </td>
                  <td>
                    {new Intl.DateTimeFormat('en-CA', {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      timeZone: 'America/Toronto',
                    }).format(new Date(inventory.start_date))}
                  </td>
                  <td>To Be Coded</td>
                  <td>
                    {(profile.type === 999 || inventory.inventory_manager_id === profile.id) && (
                      <>
                        <Link to={`/physicalInventoryLocationsDashboard/${inventory.id}`}>
                          <StyledButton>Dashboard</StyledButton>
                        </Link>
                        <StyledButton onClick={() => handleUpdateNotScanned(inventory.id)}>Update Not Scanned</StyledButton>
                      </>
                    )}
                    {profile.type === 999 && <StyledButton onClick={() => showPromiseConfirm(inventory.id)}>Delete</StyledButton>}
                    <StyledButton onClick={() => handleGenerateReport(inventory)}>
                      <CloudDownloadOutlined /> Report
                    </StyledButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </TransactionTable>
        </TransactionContainer>
      ) : (
        <Empty description="There is no inventory to show" />
      )}
    </Container>
  );
}
