import api from '~/services/api';

export async function fetchRams(search) {
  if (!search) {
    return '';
  }
  const productsData = await api.get(`/rams?search=${search}`);

  return productsData.data.map(el => ({
      value: `${el.name}`,
      label: `${el.name}`,
    }));
}
