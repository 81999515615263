import styled from 'styled-components';
import { Card, Paper, Divider } from '@material-ui/core';
// import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 80px 20px 30px 280px;

  @media only screen and (max-width: 450px) {
    padding: 50px 10px;
  }
`;

export const OrderContainer = styled(Card)`
  width: 100%;
  padding: 15px;
  margin-bottom: 5px;

  div {
    margin-right: 10px;
  }
`;

export const SearchCustomer = styled(Card)`
  width: 100%;
  padding: 15px;
`;

export const SummaryCustomer = styled.div`
  /* width: 60%; */
  div {
    display: flex;
    /* margin-top: 7px; */
    justify-content: space-between;
  }
`;

export const OrderArea = styled(Paper)`
  && {
    /* display: inline; */
    min-width: 300px;
    max-width: 450px;
    background: #f6f6f6;
    padding: 15px;
    margin-top: 10px;
    border: 1px solid #f6f6f6;
  }
`;

export const CustomDivider = styled(Divider)`
  && {
    margin: 15px 0;
  }
`;

export const SearchProducts = styled(Card)`
  width: 100%;
  padding: 15px;
  margin-top: 5px;
  background-color: '#c1c';

  span {
    display: flex;
    flex-direction: 'row';

    align-items: center;
    /* justify-content: flex-start; */

    div {
      margin-right: 5px;
    }
  }
`;

export const SummaryProducts = styled.div`
  /* width: 60%; */
  div {
    display: flex;
    /* margin-top: 7px; */
    justify-content: space-between;
  }
`;

export const OrderAreaProducts = styled(Paper)`
  && {
    /* display: inline; */
    min-width: 950px;
    background: #f6f6f6;
    padding: 15px;
    margin-top: 5px;
    border: 1px solid #f6f6f6;
  }
`;

export const ProductsTable = styled.table`
  width: 950px;
  border: 1px solid #e5e5e5;
  margin: 10px 0 5px;

  th + th,
  th + th + th,
  th + th + th + th {
    width: 10%;
  }

  th {
    padding: 15px;
  }

  tr {
    border: 1px solid #e5e5e5;

    td + td,
    th {
      text-align: center;
    }
    span {
      align-items: center;
      justify-content: center;
    }
  }

  tbody tr:hover {
    background-color: #dae9f2;
  }

  tbody td:first-child {
    padding: 10px;
  }
`;

export const OrderContainerActions = styled(Card)`
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 6.5vh;
  width: 100%;
  padding: 15px;
  margin-bottom: 5px;

  button {
    z-index: 1;
    margin-right: 10px;
  }
`;
