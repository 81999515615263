/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Alert, Modal, Spin } from 'antd';
import { toast } from 'react-toastify';

import api from '~/services/api';
import dhlicon from '~/assets/dhlicon.svg';
import upsicon from '~/assets/upsicon.svg';
import canadaposticon from '~/assets/canadaposticon.svg';
import fedexicon from '~/assets/fedexicon.svg';
import purolatoricon from '~/assets/purolatoricon.svg';

import { CardStyled, AvatarStyled, MetaStyled, Selected } from './styles';

const carrierLogo = {
  ups: upsicon,
  fedex: fedexicon,
  canada_post: canadaposticon,
  dhl_express_canada: dhlicon,
  purolator_ca: purolatoricon,
};

export default function GetRates({ handleShowGetRatesModal, info, setLabelForOrderInfo, labelForOrderInfo, setServiceCode, setConfirmationCode }) {
  const [loading, setLoading] = useState(false);
  const [carriersRates, setCarriersRates] = useState([]);

  useEffect(() => {
    async function loadRates() {
      setLoading(true);
      const rates = await api.post('shipstation/getrates', info);
      if (rates.data.length === 0) {
        toast.error('Packages must weigh more than zero oz.');
        setLoading(false);
        handleShowGetRatesModal(false);
        return;
      }

      setCarriersRates(rates.data.map(obj => ({ ...obj, selectedStyle: null, selected: null })));

      setLoading(false);
    }
    loadRates();
  }, [info]); //eslint-disable-line

  function handleOnClickRate(index) {
    const newRateList = carriersRates.map((el, idx) => {
      el.selectedStyle = idx === index ? Selected : null;
      el.selected = idx === index;
      return el;
    });

    setServiceCode(newRateList.find(el => el.selected === true).serviceCode);
    if (
      info.toCountry === 'US' &&
      (newRateList.find(el => el.selected === true).carrierCode === 'dhl_express_canada' || newRateList.find(el => el.selected === true).carrierCode === 'ups')
    ) {
      setConfirmationCode('signature');
    } else {
      setConfirmationCode('none');
    }

    setCarriersRates(newRateList);
  }

  function handleConfirmRate() {
    const selectedRate = carriersRates.find(el => el.selected === true);
    setLabelForOrderInfo({
      ...labelForOrderInfo,
      carrierCode: selectedRate.carrierCode,
      serviceCode: selectedRate.serviceCode,
      total: selectedRate.totalFormatted,
    });
    handleShowGetRatesModal(false);
  }

  return (
    <Modal title="Rate Browser" centered visible onOk={handleConfirmRate} onCancel={() => handleShowGetRatesModal(false)}>
      {loading ? (
        <Spin tip="Loading..." spinning={loading}>
          <Alert message="Fetching rates" description="Thank you for waiting while I get the rates for you." type="info" />
        </Spin>
      ) : (
        carriersRates.map((carrierRate, index) => (
          <CardStyled size="small" key={carrierRate.serviceCode} as={carrierRate.selectedStyle} onClick={() => handleOnClickRate(index)}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#6ba03a', fontSize: '16px' }}>
              <MetaStyled avatar={<AvatarStyled src={carrierLogo[carrierRate.carrierCode]} />} title={carrierRate.serviceName} description="Package" />
              <div>
                {new Intl.NumberFormat('us-CA', {
                  style: 'currency',
                  currency: 'CAD',
                }).format(carrierRate.total)}
              </div>
            </div>
          </CardStyled>
        ))
      )}
    </Modal>
  );
}
