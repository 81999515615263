import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import createStore from './createStore';
import persistReducers from './persistReducers';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

import api from '../services/api';

// const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor: null });

const middlewares = [sagaMiddleware];
const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

api.registerInterceptWithStore(store);
sagaMiddleware.run(rootSaga);

export { persistor, store };
//eslint-disable-line