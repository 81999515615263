import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { Upload, Button } from 'antd';
import { ExcelRenderer } from 'react-excel-renderer';

import { UploadOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';
import api from '~/services/api';
import { Container } from './styles';

export default function BulkImport() {
  const profile = useSelector(state => state.user.profile);

  const [rows, setRows] = useState([]);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {}, []); //eslint-disable-line

  const fileHandler = fileList => {
    const fileObj = fileList;
    if (!fileObj) {
      return false;
    }
    if (!(fileObj.type === 'application/vnd.ms-excel' || fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      return false;
    }
    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      // eslint-disable-next-line no-empty
      if (err) {
      } else {
        const newRows = [];
        resp.rows.slice(1).map(async (row, index) => {
          if (row && row !== 'undefined') {
            newRows.push({
              key: index,
              branch: row[0],
              manufacturer: row[1],
              brand: row[2],
              subcategory: row[3],
              part_number: row[4],
              condition: row[5],
              description: row[6],
              quantity: row[7],
            });
          }
          return false;
        });
        if (newRows.length === 0) {
          return false;
        }
        setRows(newRows);
      }
      return false;
    });
    return false;
  };

  const handleCheckFile = async () => {
    rows.map(async row => {
      if (row.branch === '' || typeof row.branch === 'undefined') {
        toast.error(`Branch is empty on Row number ${row.key}`);
        return;
      }
      if (row.part_number === '' || typeof row.part_number === 'undefined') {
        toast.error(`Part Number is empty on Row number ${row.key}`);
        return;
      }

      try {
        const getBranch = await api.get(`branches/${row.branch}`);

        if (getBranch.data) {
          const getManufacturer = await api.get(`manufacturers/${row.manufacturer}`);
          const getBrand = await api.get(`brands/${row.brand}`);
          const getSubcategory = await api.get(`subcategories/${row.subcategory}`);

          if (getManufacturer.data && getBrand.data && getSubcategory.data) {
            const manufacturer_id = getManufacturer.data.id;
            const brand_id = getBrand.data.id;
            const subcategory_id = getSubcategory.data.id;

            let getItem = await api.get(`itemsByPartNumber/${row.part_number}`);

            if (getItem.data) {
              try {
                await api.put('items', {
                  id: getItem.data.id,
                  manufacturer_id,
                  brand_id,
                  category_id: 9,
                  subcategory_id,
                  sku: row.part_number,
                  part_number: row.part_number,
                  name: row.description,
                  description: `${row.condition} - ${row.description}`,
                });
              } catch (error) {
                toast.error(error);
              }
            } else {
              try {
                await api.post('items', {
                  manufacturer_id,
                  brand_id,
                  category_id: 9,
                  subcategory_id,
                  sku: row.part_number,
                  part_number: row.part_number,
                  name: row.description,
                  description: `${row.condition} - ${row.description}`,
                });
              } catch (error) {
                toast.error(error);
              }
            }

            getItem = await api.get(`itemsByPartNumber/${row.part_number}`);

            if (getItem.data) {
              const item_category_id = getItem.data.category_id;

              if (item_category_id === 9) {
                if (getSubcategory.data) {
                  if (row.key === rows.length - 1) {
                    toast.success('File ready to be submitted!');
                    setSubmit(true);
                  }
                } else {
                  toast.error(`Subcategory ${row.subcategory} is not registered in the system on Row number ${row.key}`);
                  return;
                }
              } else {
                toast.error(`Part Number ${row.part_number} is not registered in the system as 'Part' (Category) on Row number ${row.key}`);
                return;
              }
            } else {
              toast.error(`Part Number ${row.part_number} not found on Row number ${row.key}`);
              return;
            }
          } else {
            toast.error(`Brand ${row.brand} or Manufacturer ${row.manufacturer} not found on Row number ${row.key}`);
            return;
          }
        } else {
          toast.error(`Branch ${row.branch} not found on Row number ${row.key}`);
          return;
        }
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    });
  };

  const handleSubmit = async () => {
    const log_id = uuidv4();

    rows.map(async row => {
      try {
        const getBranch = await api.get(`branches/${row.branch}`);
        const branch_id = getBranch.data.id;

        const getItem = await api.get(`itemsByPartNumber/${row.part_number}`);
        const item_id = getItem.data.id;

        const getInventory = await api.get(`inventory?item_id=${item_id}`);

        if (!getInventory.data) {
          await api.post('inventory', { branch_id, item_id, quantity: row.quantity, type: 'part' });
          await api.post('inventoryLog', {
            log_id,
            item_id,
            routine: 'InventoryParts/BulkImport',
            error: '',
            action: 'Create',
            previous_data: '',
            new_data: row.quantity,
            user_id: profile.id,
          });
        } else {
          const inventory_id = getInventory.data.id;

          await api.post('inventoryLog', {
            log_id,
            item_id,
            routine: 'InventoryParts/BulkImport',
            error: 'Item Found',
            action: 'Update',
            previous_data: getInventory.data.quantity,
            new_data: row.quantity,
            user_id: profile.id,
          });

          await api.put(`inventory/${inventory_id}`, { branch_id, item_id, quantity: row.quantity });
        }

        if (row.key === rows.length - 1) {
          toast.success('File uploaded with success!');
          // reset state
          setRows([]);
          setSubmit(false);
        }
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    });
  };

  return (
    <Container>
      <h1>
        Bulk Import
        <span id="itemTitle" />
      </h1>

      <br />
      <h3>1. Upload Excel file;</h3>
      <br />
      <h3>2. If file fits structure requirements, the button &quot;Check Data&quot; will be displayed. Click on Check Data;</h3>
      <br />
      <h3>
        3. If data in the file is consistent, the button &quot;Submit Data&quot; will be displayed. Click on Submit Data. If data is not consistent, error(s)
        will be displayed at the top right corner of the screen, please fix the errors and upload file again;
      </h3>

      <br />

      <div span={8} align="right" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {rows.length > 0 && submit && (
          <>
            <button type="submit" onClick={() => handleSubmit()} className="pure-button pure-button-primary">
              Submit Data
            </button>
          </>
        )}
      </div>
      <br />
      <br />
      <div span={8} align="right" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {rows.length > 0 && (
          <>
            <button type="submit" onClick={() => handleCheckFile()} className="pure-button pure-button-primary">
              Check Data
            </button>
          </>
        )}
      </div>

      <div gutter={16}>
        <a
          href="https://res.cloudinary.com/dyfmhehsn/raw/upload/v1592513303/sheets/RBMS_Sample_Excel_Sheet_prt2tb_mc7yg0.xlsx"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Sample excel sheet (click here to Download)
        </a>

        <br />
      </div>
      <br />
      <div>
        <Upload name="file" beforeUpload={fileHandler} onRemove={() => setRows([])} multiple={false}>
          <Button icon={<UploadOutlined />} type="submit" className="pure-button pure-button-primary">
            Click to Upload Excel File
          </Button>
        </Upload>
      </div>

      <div style={{ marginTop: 20 }}>
        <table className="pure-table">
          <thead>
            <tr>
              <th>Row</th>
              <th>Branch</th>
              <th>Manufacturer</th>
              <th>Brand</th>
              <th>Subcategory</th>
              <th>Part Number</th>
              <th>Condition</th>
              <th>Description</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => {
              return (
                <tr key={row.key}>
                  <td>{row.key}</td>
                  <td>{row.branch}</td>
                  <td>{row.manufacturer}</td>
                  <td>{row.brand}</td>
                  <td>{row.subcategory}</td>
                  <td>{row.part_number}</td>
                  <td>{row.condition}</td>
                  <td>{row.description}</td>
                  <td>{row.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Container>
  );
}
