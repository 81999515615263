/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useState } from 'react';

import { FileSearchOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Card, Space, Tag, Typography } from 'antd';
import { MagnifyingGlass } from 'phosphor-react';
import { toast } from 'react-toastify';

import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';
import GridBox from './components/GridBox';
import { Container, GridContainer } from './styles';

const { Title } = Typography;

export default function Location() {
  const [locationList, setLocationList] = useState([]);
  const [unitLocationName, setUnitLocationName] = useState('');

  const groupBy = (list, key) =>
    list.reduce((acc, cur) => {
      (acc[cur[key]] = acc[cur[key]] || []).push(cur);
      return acc;
    }, []);

  useEffect(() => {
    async function loadLocationList() {
      const locationData = await api.get('/locations');

      const list = groupBy(
        locationData.data.filter((el) => el.location_parent_id === null),
        'group'
      );
      setLocationList(list);
    }
    loadLocationList();
  }, []); //eslint-disable-line

  const handleSearch = useCallback(async (value) => {
    const serial_numberTrimmed = value.trim();
    const serialNumberFormatted =
      serial_numberTrimmed.substring(0, 4) === 'P/N:' ? serial_numberTrimmed.substring(serial_numberTrimmed.indexOf('S/N:') + 4) : serial_numberTrimmed;
    const serial_number_uppercase = serialNumberFormatted.toUpperCase();
    const response = await api.post('inventory_items/showBySerialNumber', { serial_number: serial_number_uppercase });
    if (response.data.error) {
      toast.warn(response.data.error);
      return;
    }

    if (['Sold', 'Returning', 'Gift', 'NotFound', 'SentToFBA', 'Returned'].includes(response.data.status)) {
      toast.error(`Serial Number is ${response.data.status}.`);
      return;
    }

    const IS_LOCATION_TECH = response.data.locations.name === 'Tech';

    const locationName = IS_LOCATION_TECH ? response.data.usersLocation?.name : response.data.locations?.name;

    setUnitLocationName(locationName);
  }, []);

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Button
              onClick={() => history.push('/changeLocation')}
              variant="contained"
              type="primary"
              style={{ backgroundColor: '#3b82f6' }}
              icon={<SwapOutlined />}
            >
              Change Location
            </Button>
            <Button
              onClick={() => history.push('/locationLogs')}
              variant="contained"
              type="primary"
              style={{ backgroundColor: '#3b82f6' }}
              icon={<FileSearchOutlined />}
            >
              Logs
            </Button>
          </Space>
          <Title level={3}>Location</Title>
          <div />
        </div>
      </Card>

      <Card title="Search Unit Location" style={{ width: 600 }}>
        <Space wrap size={50}>
          <InputText
            placeholder="Enter Unit Serial Number"
            onBlur={(e) => {
              e.target.value = e.target.value.trim().toUpperCase();
            }}
            IconAfterProp={<MagnifyingGlass size={20} />}
            autoFocus
            onPressEnter={handleSearch}
            style={{ width: 300 }}
          />
          {unitLocationName && (
            <Tag style={{ fontSize: '1.6em' }} color="blue">
              {unitLocationName}
            </Tag>
          )}
        </Space>
      </Card>

      <GridContainer>
        {locationList &&
          Object.keys(locationList)
            .sort((a, b) => locationList[b].length - locationList[a].length)
            .map((locationGroup) => <GridBox key={locationGroup} locationGroup={locationGroup} locationList={locationList[locationGroup]} />)}
      </GridContainer>
    </Container>
  );
}
