// import React, { useState, useEffect, useRef, useCallback } from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

// import { Form, Button, Card, Select, Input, Row, Col, DatePicker, InputNumber, Space, Popconfirm, Table } from 'antd';
import { Form, Button, Card, Input, InputNumber, Space, Popconfirm, Table, Select } from 'antd';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// import moment from 'moment';
// import { toast } from 'react-toastify';

import moment from 'moment';
import { toast } from 'react-toastify';
import { Container } from './styles';
import api from '~/services/api';
import history from '~/services/history';

// import api from '~/services/api';

export default function NewInternalOrder() {
  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 13,
    },
  };
  const profile = useSelector(state => state.user.profile);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [productOrderList, setProductOrderList] = useState([]);
  const [userList, setUserList] = useState([]);

  // const productTitleRef = useRef('');
  // const productUnitPriceRef = useRef('');
  // const productQtyRef = useRef('');
  // const orderInputRef = useRef('');

  useEffect(() => {
    async function getUser() {
      const userData = await api.get('users');
      const list = userData.data?.map(user => {
        return { value: user.id, label: user.name };
      });
      setUserList(list);
    }
    getUser();
  }, []);

  const handleAddProduct = useCallback(() => {
    const values = form.getFieldsValue();
    if (
      values.productTitleRef !== undefined &&
      values.productTitleRef !== '' &&
      values.productQtyRef !== undefined &&
      values.productQtyRef !== '' &&
      values.productQtyRef !== null &&
      values.manualNotes !== undefined &&
      values.manualNotes !== ''
    ) {
      const newProductOrder = {
        title: values.productTitleRef,
        quantity: values.productQtyRef,
        notes: values.manualNotes,
      };

      form.setFieldsValue({ productTitleRef: '' });
      form.setFieldsValue({ productQtyRef: '' });
      form.setFieldsValue({ manualNotes: '' });
      setProductOrderList([...productOrderList, newProductOrder]);
    } else {
      toast.error('Please enter information for product description, quantity and notes.');
    }
  }, [form, productOrderList]);
  // }, [form]);

  const handleRemoveProduct = useCallback(
    index => {
      const newList = [...productOrderList];
      newList.splice(index, 1);
      setProductOrderList(newList);
    },
    [productOrderList]
  );

  const onFinish = async values => {
    if (productOrderList.length > 0) {
      const newInternalOrder = {
        creation_date: moment(new Date()).format('YYYY-MM-DD'),
        status: 'pending',
        created_by_id: profile.id,
        requested_by_id: values.requestedBy,
        notes: values.notes,
      };

      const internal_order_id = await api.post('internal_orders', newInternalOrder);

      productOrderList.map(async product => {
        const newInternalOrderItem = {
          internal_order_id: internal_order_id.data,
          quantity: product.quantity,
          title: product.title,
          notes: product.notes,
        };

        const result = await api.post('internal_order_items', newInternalOrderItem);
        if (result.data.error) {
          toast.error(`${result.data.error}`);
        } else {
          toast.success('Internal Order created successfully');
          history.push('/internalOrders');
        }
      });
    } else {
      toast.error('Please enter one item to be changed');
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, row, index) => index + 1,
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Manual Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <Popconfirm
            onConfirm={() => handleRemoveProduct(record)}
            title="Are you sure you want to delete?"
            trigger="click"
            placement="left"
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" icon={<DeleteOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Container>
      <Card>
        <Form {...layout} form={form} name="nest-messages" onFinish={onFinish}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to="/internalOrders">
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
          </div>
          <Card title="Order Info" headStyle={{ backgroundColor: 'whitesmoke' }}>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
            <Space size={100}>
              <Form.Item
                name="requestedBy"
                label="Requested By"
                rules={[
                  {
                    required: true,
                    message: 'Requested By is required.',
                  },
                ]}
              >
                <Select options={userList} allowClear style={{ width: '400px' }} showSearch />
                {/* <Input placeholder="Product description" allowClear style={{ width: '700px' }} /> */}
              </Form.Item>
              <Form.Item name="notes" label="Internal Order Notes">
                <TextArea
                  showCount
                  maxLength={2000}
                  allowClear
                  style={{ width: 500 }}
                  placeholder="Add reason for this order, if it's related to a listing, etc."
                />
              </Form.Item>
            </Space>
            {/* </div> */}
          </Card>
          <Card title="Product Info" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Space>
              <Form.Item name="productTitleRef">
                <Input placeholder="Product description" allowClear style={{ width: '700px' }} />
              </Form.Item>
              <Form.Item name="productQtyRef">
                <InputNumber min={0} max={99999} placeholder="Qty" />
              </Form.Item>
              <Form.Item hasFeedback name="manualNotes" style={{ marginLeft: '5px' }}>
                <TextArea
                  showCount
                  maxLength={2000}
                  allowClear
                  style={{ width: 250 }}
                  placeholder="Add additional information about what will be done to unit(s)."
                />
              </Form.Item>
              <Button type="primary" shape="circle" onClick={handleAddProduct} icon={<PlusOutlined />} />
            </Space>
            <Table columns={columns} dataSource={productOrderList} rowKey={record => record.index} />
          </Card>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
}
