import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  input {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;
