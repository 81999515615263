import React, { useEffect, useState } from 'react';

import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { toast } from 'react-toastify';
import api from '~/services/api';
import LocationTypeNewEdit from './New';
import { ActionsBar, Container } from './styles';

export default function LocationTypes() {
  const [locationList, setLocationList] = useState([]);
  const [createNewEditFlag, setCreateNewEditFlag] = useState(false);
  const [locationTypeId, setLocationTypeId] = useState(0);

  useEffect(() => {
    async function loadLocationList() {
      try {
        const locationListData = await api.get('location-type');
        setLocationList(locationListData.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    loadLocationList();
  }, []);

  const handleCreateNew = locationTypeIdSelected => {
    setCreateNewEditFlag(() => !createNewEditFlag);
    setLocationTypeId(() => locationTypeIdSelected);
  };

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_text, row) => (
        <Button type="link" onClick={() => handleCreateNew(row.id)}>
          Edit
        </Button>
      ),
      width: 100,
    },
    {
      title: 'Location Types',
      dataIndex: 'name',
    },
    {
      title: 'Locations',
      dataIndex: 'location_types',
      render: (_text, row) =>
        Object.keys(row.location_types)
          .map(location => row.location_types[location].name)
          .sort((a, b) => a.localeCompare(b))
          .join(', '),
    },
    {
      title: 'Active?',
      dataIndex: 'active',
      render: text => <CheckCircleTwoTone twoToneColor={text ? '#52c41a' : '#eb2f96'} style={{ fontSize: '20px', cursor: 'pointer' }} />,
    },
  ];

  const handleAddLocationTypeToList = locationType => {
    const newLocationList = locationList.filter(location => location.id !== locationType.id);
    newLocationList.push(locationType);
    setLocationList(() => newLocationList.sort((a, b) => a.name.localeCompare(b.name)));
  };

  return (
    <Container>
      {createNewEditFlag ? (
        <LocationTypeNewEdit
          handleCreateNew={handleCreateNew}
          addToList={handleAddLocationTypeToList}
          locationTypeId={locationTypeId}
          backToList={handleCreateNew}
        />
      ) : (
        <>
          <ActionsBar>
            <Button type="primary" onClick={() => handleCreateNew(0)}>
              New Location Type
            </Button>
          </ActionsBar>
          <Table
            style={{ marginTop: '5px', width: '50%' }}
            columns={columns}
            dataSource={locationList}
            size="small"
            bordered
            pagination={false}
            // scroll={{ y: 'calc(100vh - 25em)' }}
            rowKey="id"
          />
        </>
      )}
    </Container>
  );
}
