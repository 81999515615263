/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import api from '~/services/api';
import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function FeedDatabase() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const serialNumberInput = useRef('');
  const locationInput = useRef('');

  const { register, handleSubmit, errors } = useForm();

  const [dialogExistOpen, setDialogExistOpen] = useState(false);
  const [dialogExistSoldOpen, setDialogExistSoldOpen] = useState(false);
  const [dialogNonExistOpen, setDialogNonExistOpen] = useState(false);
  const [partNumber, setPartNumber] = useState('');
  const [grade, setGrade] = useState('');

  useEffect(() => {
    serialNumberInput.current.focus();
  }, []);

  const handleCloseExist = () => {
    setDialogExistOpen(false);
    serialNumberInput.current.focus();
  };
  const handleCloseExistSold = () => {
    setDialogExistSoldOpen(false);
    serialNumberInput.current.focus();
  };
  const handleCloseNonExist = () => {
    setDialogNonExistOpen(false);
    serialNumberInput.current.focus();
  };

  const handleSubmitNonExist = async () => {
    const newInventoryItem = {};

    const getItemInfo = await api.get(`items?part_number=${partNumber}`);
    if (getItemInfo.data.length === 0) {
      setGrade('');
      setPartNumber('');
      serialNumberInput.current.focus();
      toast.error('Part number not found');
      return false;
    }

    if (grade === '') {
      setGrade('');
      setPartNumber('');
      serialNumberInput.current.focus();
      toast.error('Grade is required');
      return false;
    }

    newInventoryItem.branch_id = 1;
    newInventoryItem.status = 'InStock';
    newInventoryItem.processed_by = profile.name;
    newInventoryItem.item_id = getItemInfo.data[0].id;
    newInventoryItem.serial_number = serialNumberInput.current.value;
    newInventoryItem.location = locationInput.current.value;

    if (grade === 'New') {
      newInventoryItem.condition = 'New';
    } else if (grade === 'OpenBox') {
      newInventoryItem.condition = 'Open Box';
    } else {
      newInventoryItem.condition = 'Refurbished';
      newInventoryItem.grade = grade;
    }

    const inventory_items_id = await api.post('inventory_items', newInventoryItem);

    await api.post('inventoryLog', {
      log_id: 'inv-june-2020',
      item_id: getItemInfo.data[0].id,
      inventory_items_id: inventory_items_id.data.id,
      routine: 'Inventory/StockAnalyzer',
      error: '',
      action: 'Create',
      previous_data: '',
      new_data: `New S/N - ${serialNumberInput.current.value} - added to Inventory`,
      user_id: profile.id,
    });

    toast.success('Item registered successfully');

    serialNumberInput.current.value = '';

    setGrade('');
    setPartNumber('');

    setDialogNonExistOpen(false);
    serialNumberInput.current.focus();
    return false;
  };

  const handleSubmitExist = async () => {
    let error_log = '';
    let newCondition = '';
    let newGrade = '';

    const getItemSerialNumber = await api.get(`inventory_items?serial_number=${serialNumberInput.current.value}`);

    if (grade === '') {
      setGrade('');
      setPartNumber('');
      serialNumberInput.current.focus();
      toast.error('Grade is required');
      return false;
    }

    if (getItemSerialNumber.data[0].condition !== grade && getItemSerialNumber.data[0].grade !== grade && getItemSerialNumber.data[0].is_processed === null) {
      error_log = 'Grade Modified';

      if (grade === 'New') {
        newCondition = 'New';
        newGrade = '';
      } else if (grade === 'Open Box') {
        newCondition = 'Open Box';
        newGrade = '';
      } else if (grade === 'E') {
        newCondition = 'For Parts';
        newGrade = grade;
      } else {
        newCondition = 'Refurbished';
        newGrade = grade;
      }

      await api.post('inventoryLog', {
        log_id: 'inv-june-2020',
        item_id: getItemSerialNumber.data[0].item_id,
        inventory_items_id: getItemSerialNumber.data[0].id,
        routine: 'Inventory/StockAnalyzer',
        error: error_log,
        action: 'Modified',
        previous_data: `Condition: ${getItemSerialNumber.data[0].condition} / Grade: ${getItemSerialNumber.data[0].grade}`,
        new_data: `Condition: ${newCondition}/ Grade:  ${newGrade}`,
        user_id: profile.id,
      });

      await api.put('inventory_items', {
        items: [{ serial_number: getItemSerialNumber.data[0].serial_number, condition: newCondition, grade: newGrade, location: locationInput.current.value }],
      });
    } else {
      await api.post('inventoryLog', {
        log_id: 'inv-june-2020',
        item_id: getItemSerialNumber.data[0].item_id,
        inventory_items_id: getItemSerialNumber.data[0].id,
        routine: 'Inventory/StockAnalyzer',
        error: '',
        action: 'No Action',
        previous_data: '',
        new_data: '',
        user_id: profile.id,
      });

      await api.put('inventory_items', {
        items: [{ serial_number: getItemSerialNumber.data[0].serial_number, location: locationInput.current.value }],
      });
    }

    toast.success('Item registered successfully');

    serialNumberInput.current.value = '';

    setGrade('');
    setPartNumber('');

    setDialogExistOpen(false);
    serialNumberInput.current.focus();
    return false;
  };

  const handleSubmitExistSold = async () => {
    let error_log = 'Status Modified';
    let newCondition = '';
    let newGrade = '';

    if (grade === '') {
      setGrade('');
      setPartNumber('');
      serialNumberInput.current.focus();
      toast.error('Grade is required');
      return false;
    }

    const getItemSerialNumber = await api.get(`inventory_items?serial_number=${serialNumberInput.current.value}`);

    if (getItemSerialNumber.data[0].condition !== grade && getItemSerialNumber.data[0].grade !== grade) {
      error_log = 'Status Modified / Grade Modified';

      if (grade === 'New') {
        newCondition = 'New';
      } else if (grade === 'Open Box') {
        newCondition = 'Open Box';
      } else {
        newCondition = 'Refurbished';
        newGrade = grade;
      }

      await api.post('inventoryLog', {
        log_id: 'inv-june-2020',
        item_id: getItemSerialNumber.data[0].item_id,
        inventory_items_id: getItemSerialNumber.data[0].id,
        routine: 'Inventory/StockAnalyzer',
        error: error_log,
        action: 'Modified',
        previous_data: `Status: Sold / Condition: ${getItemSerialNumber.data[0].condition} / Grade: ${getItemSerialNumber.data[0].grade}`,
        new_data: `Status: In Stock / Condition: ${newCondition}/ Grade:  ${newGrade}`,
        user_id: profile.id,
      });

      await api.put('inventory_items', {
        items: [
          {
            serial_number: getItemSerialNumber.data[0].serial_number,
            status: 'InStock',
            condition: newCondition,
            grade: newGrade,
            location: locationInput.current.value,
          },
        ],
      });
    } else {
      await api.post('inventoryLog', {
        log_id: 'inv-june-2020',
        item_id: getItemSerialNumber.data[0].item_id,
        inventory_items_id: getItemSerialNumber.data[0].id,
        routine: 'Inventory/StockAnalyzer',
        error: error_log,
        action: 'Modified',
        previous_data: `Status: Sold`,
        new_data: `Status: In Stock`,
        user_id: profile.id,
      });

      await api.put('inventory_items', {
        items: [{ serial_number: getItemSerialNumber.data[0].serial_number, status: 'InStock', location: locationInput.current.value }],
      });
    }

    toast.success('Item registered successfully');

    serialNumberInput.current.value = '';

    setGrade('');
    setPartNumber('');

    setDialogExistSoldOpen(false);
    serialNumberInput.current.focus();
    return false;
  };

  const handlePartNumberChange = event => {
    setPartNumber(event.target.value);
  };

  const handleChangeGrade = event => {
    setGrade(event.target.value);
  };

  const onSubmit = async data => {
    const getItemSerialNumber = await api.get(`inventory_items?serial_number=${data.serial_number}`);

    if (getItemSerialNumber.data.length > 0) {
      const inventory_log_item = await api.get(`inventoryLog?inventory_items_id=${getItemSerialNumber.data[0].id}`);

      if (inventory_log_item.data.length > 0) {
        toast.error('Serial Number was already scanned.');
        serialNumberInput.current.value = '';
        setGrade('');
        setPartNumber('');
        serialNumberInput.current.focus();
        return false;
      }

      if (getItemSerialNumber.data[0].is_processed === true) {
        await api.post('inventoryLog', {
          log_id: 'inv-june-2020',
          item_id: getItemSerialNumber.data[0].item_id,
          inventory_items_id: getItemSerialNumber.data[0].id,
          routine: 'Inventory/StockAnalyzer',
          error: '',
          action: 'No Action',
          previous_data: '',
          new_data: '',
          user_id: profile.id,
        });

        await api.put('inventory_items', {
          items: [{ serial_number: getItemSerialNumber.data[0].serial_number, location: locationInput.current.value }],
        });

        serialNumberInput.current.value = '';
        setGrade('');
        setPartNumber('');
        toast.success('Unit processed in the database, location has been updated!');
        serialNumberInput.current.focus();
        return false;
      }
      if (getItemSerialNumber.data[0].status === 'Sold') {
        setDialogExistSoldOpen(true);
      } else {
        setDialogExistOpen(true);
      }
    } else {
      setDialogNonExistOpen(true);
    }
    return false;
  };

  return (
    <Container>
      <div id="msg" />

      <h1>Stock Analyzer - Feed Database</h1>
      <h3>Search for the Serial Number using the field below</h3>
      <br />
      <form onSubmit={handleSubmit(onSubmit)} className="pure-form pure-form-aligned" autoComplete="off">
        <fieldset>
          <div className="pure-control-group">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              name="location"
              id="location"
              placeholder="Location"
              ref={e => {
                register(e, { required: true });
                locationInput.current = e; // you can still assign to ref
              }}
            />
            {errors.location && <span className="pure-form-message-inline">This field is required</span>}
          </div>
          <br />
          <div className="pure-control-group">
            <label htmlFor="serial_number">Serial Number</label>
            <input
              type="text"
              name="serial_number"
              id="serial_number"
              placeholder="Serial Number"
              ref={e => {
                register(e, { required: true });
                serialNumberInput.current = e; // you can still assign to ref
              }}
            />
            {errors.serial_number && <span className="pure-form-message-inline">This field is required</span>}
          </div>
          <br />

          <button type="submit" className="pure-button pure-button-primary">
            Search
          </button>
        </fieldset>
      </form>
      <div>
        <Dialog open={dialogExistOpen} onClose={handleCloseExist} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogTitle id="form-dialog-title">S/N Found</DialogTitle>
          <DialogContent>
            <DialogContentText>Serial Number has been found in the database, please inform the grade of the item.</DialogContentText>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={grade}
              onChange={handleChangeGrade}
              className={classes.selectEmpty}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="OpenBox">OpenBox</MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="D">D</MenuItem>
              <MenuItem value="E">E</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExist} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitExist} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={dialogNonExistOpen} onClose={handleCloseNonExist} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogTitle id="form-dialog-title">S/N Not Found</DialogTitle>
          <DialogContent>
            <DialogContentText>Serial Number was not found in the database.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="part_number"
              label="Part Number"
              inputRef={serialNumberInput}
              fullWidth
              onChange={handlePartNumberChange}
              autoComplete="off"
            />

            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={grade}
              onChange={handleChangeGrade}
              className={classes.selectEmpty}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="OpenBox">OpenBox</MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="D">D</MenuItem>
              <MenuItem value="E">E</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNonExist} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitNonExist} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={dialogExistSoldOpen} onClose={handleCloseExistSold} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
          <DialogTitle id="form-dialog-title">S/N Found - SOLD</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Serial Number has been found, however the current Status is Sold. Please inform the grade of the item. In addition, the status will be changed to
              'In Stock'`}
            </DialogContentText>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={grade}
              onChange={handleChangeGrade}
              className={classes.selectEmpty}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="New">New</MenuItem>
              <MenuItem value="OpenBox">OpenBox</MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="D">D</MenuItem>
              <MenuItem value="E">E</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExistSold} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitExistSold} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}
