import { DatePicker, Divider, Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { TechnicianReportDataTable } from './dataTable';
import { Container } from './styles';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const disabledDate = (current) => {
  // Can not select days after today
  return current && current > moment();
};

export function TechniciansProductivity() {
  const [form] = Form.useForm();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    async function loadData() {
      const secondDate = moment();
      const firstDate = secondDate.clone().weekday(1);

      if (firstDate.isSame(secondDate, 'day')) {
        firstDate.subtract(7, 'days'); // Go to the previous week if today is before Monday
      }
      form.setFieldsValue({ period: [firstDate, secondDate] });

      const parsedStartDate = moment(firstDate).format(dateFormat);
      const parsedEndDate = moment(secondDate).format(dateFormat);
      setStartDate(() => parsedStartDate);
      setEndDate(() => parsedEndDate);
    }
    loadData();
  }, []); //eslint-disable-line

  const onFinish = async (values) => {
    // console.log('values ', values);
  };

  const handleOnChangePeriod = (dates) => {
    const parsedStartDate = moment(dates[0]).format(dateFormat);
    const parsedEndDate = moment(dates[1]).format(dateFormat);
    setStartDate(() => parsedStartDate);
    setEndDate(() => parsedEndDate);
  };

  return (
    <Container>
      <h1>Technicians Report</h1>

      <Form form={form} name="salesReport" onFinish={onFinish} layout="inline">
        <Form.Item name="period" label="Period" rules={[{ required: true }]}>
          <RangePicker format={dateFormat} disabledDate={disabledDate} onChange={handleOnChangePeriod} />
        </Form.Item>

        {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item> */}
      </Form>

      <Divider />

      {startDate && <TechnicianReportDataTable startDate={startDate} endDate={endDate} />}
    </Container>
  );
}
