import api from '~/services/api';

export async function fetchCpus(search) {
  if (!search) {
    return '';
  }
  const productsData = await api.get(`/cpus?search=${search}`);

  return productsData.data.map(el => ({
      value: `${el.id}`,
      label: `${el.name}`,
    }));
}
