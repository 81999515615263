export function handlePartsLocationCheck(partData, profile) {
  if (partData.error) {
    return { allowed: false, message: 'Serial Number not found on RBMS' };
  }
  const CHECK_IF_PART_LOCATION_WITH_TECHNICIAN = partData.locations?.name === 'Tech';
  if (CHECK_IF_PART_LOCATION_WITH_TECHNICIAN) {
    const CHECK_IF_PART_IS_ASSIGNED_TO_LOGGED_USER = partData.usersLocation?.name === profile.name;
    if (CHECK_IF_PART_IS_ASSIGNED_TO_LOGGED_USER) {
      return { allowed: true };
    }
  }
  return {
    allowed: false,
    message: `${partData.serial_number}'s location is not under your name. Change location to your name in order to complete this inspection.`,
  };
}
