import styled from 'styled-components';

export const Container = styled.div`
  /* background-color: ${props => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px; */
`;

export const ActionsBar = styled.div`
  background-color: ${props => props.theme['blue-300']};
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;

  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
`;

export const Button = styled.button`
  padding: 8px 12px;
  font-weight: 700;
  background-color: ${props => props.theme['blue-500']};
  color: ${props => props.theme.white};
  outline: ${props => props.theme['blue-500']};
  border-radius: 4px;
  border: 0;

  &:hover {
    background-color: ${props => props.theme['blue-700']};
    transition: background-color 0.2s;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 2px;
    transition: all 0.2s;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    width: 400px;
  }

  input:hover {
    border-color: #4096ff;
    border-inline-end-width: 1px;
  }

  label {
    text-align: right;
    width: 130px;
    margin-right: 1rem;
  }

  label:after {
    content: ':';
  }
`;
