/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Alert, Button, Card, Checkbox, Dropdown, Empty, Menu, Pagination, Row, Select, Space, Spin, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import { MagnifyingGlass } from 'phosphor-react';
import debounce from 'lodash.debounce';
import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function Assessment({ location }) {
  const [assessment, setAssessment] = useState([]);
  // const profile = useSelector(state => state.user.profile);
  const [filterStatus, setFilterStatus] = useState(['partially_received', 'pending_assessment']);
  const [filterCriteria, setFilterCriteria] = useState();

  const [visible, setVisible] = useState(false);

  const [paginationSize, setPaginationSize] = useState(new URLSearchParams(location.search).get('size') || 25);
  const [paginationPage, setPaginationPage] = useState(new URLSearchParams(location.search).get('page') || 1);
  const [paginationTotalItems, setPaginationTotalItems] = useState();

  const [loading, setLoading] = useState(true);

  const searchTextRef = useRef('');

  const { Title } = Typography;
  const { Option } = Select;

  const tagColor = {
    complete: {
      label: 'Assessment Complete',
      color: 'green',
    },
    complete_receiving_parts: {
      label: 'Complete Receiving of Parts',
      color: 'green',
    },
    // assessment_skipped: {
    //   label: 'Assessment Skipped',
    //   color: 'red',
    // },
    // partially_complete: {
    //   label: 'Partially Completed',
    //   color: 'orange',
    // },
    pending_assessment: {
      label: 'Assessment Pending',
      color: 'yellow',
    },
    pending_receiving: {
      label: 'Receiving Pending',
      color: 'blue',
    },
    partially_received: {
      label: 'Partially Received',
      color: 'yellow',
    },
    null: {
      label: 'No Status',
      color: 'magenta',
    },
  };

  const handleSearch = useCallback(
    async (value) => {
      const stringStatus = filterStatus.toString();

      const valueTrimmed = value?.trim().toUpperCase() || searchTextRef.current.value;

      const serialNumberFormatted = valueTrimmed?.substring(0, 4) === 'P/N:' ? valueTrimmed?.substring(valueTrimmed?.indexOf('S/N:') + 4) : valueTrimmed;

      searchTextRef.current.value = serialNumberFormatted || '';

      const response = await api.get(`receivingItems?size=${paginationSize}&page=${paginationPage - 1}`, {
        headers: {
          filterCriteria,
          filterText: serialNumberFormatted || searchTextRef.current.value || '',
          filterStatus: stringStatus || '',
        },
      });
      if (response.data.error) {
        toast.warn(`Receiving Lots not found! - ${response.data.error}`);
        return;
      }
      const assessmentList = response.data.rows;
      const additionalItems = await api.get('receivingItemsParts?assessflag=true');
      additionalItems.data.map((item) => {
        if (assessmentList.filter((assess) => assess.id === item.receiving_item_id)[0] !== undefined) {
          assessmentList.filter((assess) => assess.id === item.receiving_item_id)[0].additional_item = true;
        }
      });

      // response.data.rows.filter(el => el.id === ).map(row => )

      setPaginationTotalItems(() => response.data.totalItems);
      setPaginationSize(() => response.data.sizePage);
      setPaginationPage(() => response.data.currentPage + 1);
      setAssessment(() => assessmentList);
      setLoading(() => false);
    },
    [filterStatus, paginationPage, paginationSize] //eslint-disable-line
  );

  const debouncedHandleSearchTextInput = debounce((value) => handleSearchTextInput(value), 500);

  const handleSearchTextInput = useCallback(
    async (value) => {
      try {
        setLoading(() => true);
        const valueTrimmed = value?.trim().toUpperCase();

        const serialNumberFormatted = valueTrimmed?.substring(0, 4) === 'P/N:' ? valueTrimmed?.substring(valueTrimmed?.indexOf('S/N:') + 4) : valueTrimmed;

        searchTextRef.current.value = serialNumberFormatted || '';

        const filterStatusOptions = ['complete', 'partially_received', 'pending_receiving', 'pending_assessment'];

        const response = await api.get(`receivingItems?size=${paginationSize}&page=${paginationPage - 1}`, {
          headers: {
            filterCriteria,
            filterText: serialNumberFormatted || '',
            filterStatus: filterStatusOptions.toString() || '',
          },
        });
        if (response.data.error) {
          toast.warn(`Receiving Lots not found! - ${response.data.error}`);
          return;
        }
        const assessmentList = response.data.rows;
        const additionalItems = await api.get('receivingItemsParts?assessflag=true');
        additionalItems.data.map((item) => {
          if (assessmentList.filter((assess) => assess.id === item.receiving_item_id)[0] !== undefined) {
            assessmentList.filter((assess) => assess.id === item.receiving_item_id)[0].additional_item = true;
          }
        });

        setFilterStatus(() => filterStatusOptions);
        setPaginationTotalItems(() => response.data.totalItems);
        setPaginationSize(() => response.data.sizePage);
        setPaginationPage(() => response.data.currentPage + 1);
        setAssessment(() => assessmentList);
      } catch (error) {
        toast.error('Something went wrong');
      } finally {
        setLoading(() => false);
      }
    },
    [paginationPage, paginationSize, filterCriteria]
  );

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = location;
    const params = new URLSearchParams(search);
    if (params.get('filterCriteria')) {
      setFilterCriteria(params.get('filterCriteria'));
    }

    const searchQuery = params.get('searchQuery');
    if (searchQuery) {
      searchTextRef.current.value = searchQuery;
      handleSearch();
    }
  }, [location.search, handleSearch]); //eslint-disable-line

  const handleChangePage = (page, pageSize) => {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  };

  useEffect(() => {
    handleSearch();
  }, []); //eslint-disable-line

  const lotAssessment = (record) => {
    history.push(
      `/lotAssessment?id=${record.id}&receiving_id=${record.receiving_id}&filterCriteria=${filterCriteria || 'receiving_id'}&searchQuery=${
        searchTextRef.current.value
      }&size=${paginationSize}&page=${paginationPage}`
    );
  };

  const additionalAssessment = (lotId) => {
    history.push(
      `/lotAssessment?id=${lotId}&filterCriteria=${filterCriteria || 'receiving_id'}&searchQuery=${
        searchTextRef.current.value
      }&size=${paginationSize}&page=${paginationPage}&additionalFlag=true`
    );
  };

  const columns = [
    {
      title: 'Receiving id',
      dataIndex: 'receiving_id',
      width: 100,
    },
    {
      title: 'PO #',
      dataIndex: ['receiving_items', 'purchase_order'],
      width: 100,
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Supplier',
      dataIndex: ['receiving_items', 'supplier'],
    },
    {
      title: 'Quantity Received',
      dataIndex: 'quantity',
      width: 160,
    },
    {
      title: 'Quantity to Receive',
      dataIndex: 'quantity_to_receive',
      width: 160,
    },
    {
      title: 'Received',
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => moment(text).fromNow(),
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <Tag color={tagColor[text]?.color} key={tagColor[text]?.label}>
          {tagColor[text]?.label.toUpperCase() || text}
        </Tag>
      ),
      width: 200,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          <Button disabled={record.status === 'pending_receiving'} type="primary" className="button-error pure-button" onClick={() => lotAssessment(record)}>
            Lot Assessment
          </Button>
        </Space>
      ),
      // width: 250,
    },
    {
      title: 'Additional Items',
      dataIndex: 'additional_item',
      render: (text, record) => (
        <Button
          disabled={!text}
          type="primary"
          className="button-error pure-button"
          onClick={() => additionalAssessment(record.id)}
          style={{ backgroundColor: '#3EB19E', borderColor: '#3EB19E' }}
        >
          Additional
        </Button>
      ),
    },
  ];

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Checkbox.Group
          onChange={(value) => {
            setFilterStatus(value);
          }}
          value={filterStatus}
        >
          <Row>
            <Checkbox value="complete">Assessment Complete</Checkbox>
          </Row>
          <Row>
            <Checkbox value="partially_received">Partially Received</Checkbox>
          </Row>
          <Row>
            <Checkbox value="pending_receiving">Pending Receiving</Checkbox>
          </Row>
          <Row>
            <Checkbox value="pending_assessment">Assessment Pending</Checkbox>
          </Row>
        </Checkbox.Group>
      </Menu.Item>
    </Menu>
  );

  function handleChangeFilterCriteria(value) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('filterCriteria', value);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    setFilterCriteria(value);
    setPaginationPage(1);
    searchTextRef.current.focus();
  }

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div />
          <Title level={3}>Receiving Items to Assess</Title>
          <div />
        </div>
      </Card>
      <Card elevation={3} size="small">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            <Select onChange={handleChangeFilterCriteria} value={filterCriteria || 'receiving_id'} style={{ width: 160 }}>
              <Option value="receiving_id">Receiving id</Option>
              <Option value="purchase_order">Purchase Order #</Option>
              <Option value="serial_number">Serial Number</Option>
              <Option value="supplier">Supplier</Option>
              <Option value="description">Description</Option>
            </Select>
            <InputText
              placeholder="Search"
              ref={searchTextRef}
              onBlur={(e) => {
                const serialNumberTrimmed = e.target.value.trim().toUpperCase() || searchTextRef.current.value;

                const serialNumberFormatted =
                  serialNumberTrimmed.substring(0, 4) === 'P/N:' ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4) : serialNumberTrimmed;
                e.target.value = serialNumberFormatted;
              }}
              autoFocus
              onPressEnter={debouncedHandleSearchTextInput}
              style={{ width: 250 }}
              IconAfterProp={
                loading ? <Spin /> : <MagnifyingGlass cursor="pointer" onClick={() => debouncedHandleSearchTextInput(searchTextRef.current.value)} size={20} />
              }
            />
          </Space>
          <Space>
            <Dropdown overlay={menu} trigger={['click']} onVisibleChange={(e) => handleVisibleChange(e)} visible={visible}>
              <Button href="#" className="ant-dropdown-link" style={{ width: 140 }} onClick={() => setVisible(true)}>
                Status
              </Button>
            </Dropdown>
          </Space>
        </div>
      </Card>

      {loading ? (
        <Spin tip="Loading...">
          <Alert
            message="Search is looking for the items. Hold on a second"
            description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
            type="info"
          />
        </Spin>
      ) : assessment.length <= 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Use the search box above to list assessment</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      ) : (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={assessment}
            size="small"
            bordered
            pagination={false}
            scroll={{ y: 'calc(100vh - 22em)' }}
            rowKey={(record) => record.id}
          />
          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}
