import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 80px 20px 30px 280px;

  input {
    /* text-transform: uppercase; */
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;

// import styled from 'styled-components';
// import { darken } from 'polished';

// export const Container = styled.div`
//   /* max-width: 600px;
//   margin: 50px auto; */
//   display: flex;
//   height: 100%;
//   width: 50%;
//   padding: 80px 20px 30px 280px;

//   form {
//     display: flex;
//     flex-direction: column;
//     /* margin-top: 50px; */

//     input {
//       background: #573fa8;
//       border: 0;
//       border-radius: 4px;
//       height: 44px;
//       padding: 0 15px;
//       color: #fff;
//       margin: 0 0 10px;

//       &::placeholder {
//         color: rgba(255, 255, 255, 0.7);
//       }
//     }

//     select {
//       /* flex: 1;
//       padding: 0 0.5em;
//       color: #fff;
//       cursor: pointer;
//       -webkit-appearance: none;
//       -moz-appearance: none;
//       -ms-appearance: none;
//       appearance: none;
//       outline: 0;
//       box-shadow: none;
//       border: 0 !important;
//       background: #2c3e50;
//       background-image: none; */

//       background: #573fa8;
//       border: 0;
//       border-radius: 4px;
//       height: 44px;
//       padding: 0 15px;
//       color: #fff;
//       margin: 0 0 10px;
//     }

//     button {
//       margin: 5px 0 0;
//       height: 44px;
//       background: #573fa8;
//       font-weight: bold;
//       color: #fff;
//       border: 0;
//       border-radius: 4px;
//       font-size: 16px;
//       transition: background 0.2s;

//       &:hover {
//         background: ${darken(0.03, '#573fa8')};
//       }
//     }
//   }
// `;
