import { Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { StatusBullet } from 'components'; //eslint-disable-line
import history from '~/services/history';

import api from '~/services/api';

const statusColors = {
  shipped: 'success',
  awaiting_shipment: 'warning',
  cancelled: 'danger',
};

function LatestOrders(props) {
  const { ...rest } = props;

  const [orderState, setOrderState] = useState([
    {
      billTo: {},
      shipTo: {},
      items: [
        {
          weight: {},
        },
      ],
      weight: {},
      insuranceOptions: {},
      internationalOptions: {},
      advancedOptions: {},
    },
  ]);

  useEffect(() => {
    async function loadItems() {
      const orderList = await api.get('orders?size=50', {
        headers: {
          filterStatus: 'awaiting_shipment',
        },
      });
      setOrderState(orderList.data.rows);
    }

    loadItems();
  }, []); //eslint-disable-line

  function handleRowOnClick(orderId) {
    history.push(`/order/${orderId}`);
  }

  return (
    <Card {...rest}>
      <CardHeader title="Latest Orders" />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Ref</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Created on Marketplace</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Created on RBMS
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderState.map((order) => (
                  <TableRow hover key={order.id || 0} onClick={() => handleRowOnClick(order.id)}>
                    <TableCell>{order.order_num}</TableCell>
                    <TableCell>{order.customer?.name}</TableCell>
                    <TableCell>{moment(order.order_date).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>{moment(order.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>
                      <div>
                        <StatusBullet color={statusColors[order.status] || 'neutral'} size="sm" />
                        {order.status}
                      </div>
                    </TableCell>
                    <TableCell>{order.notes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
    </Card>
  );
}

LatestOrders.propTypes = {
  className: PropTypes.string,
};

LatestOrders.defaultProps = {
  className: '',
};

export default LatestOrders;
