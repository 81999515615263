export default function AssignAssetDiagnosticRender(text) {
  switch (text) {
    case 'na':
      return 'No';
    case 'passed':
      return 'Yes';
    case 'failed':
      return 'Failed';
    default:
      return '';
  }
}
