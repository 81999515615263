/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Space, Input, Card, Table, Empty, Pagination, Button, Typography } from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { Container } from './styles';
import api from '~/services/api';
import history from '~/services/history';

export default function Department(props) {
  const [departments, setDepartments] = useState([]);
  const profile = useSelector(state => state.user.profile);

  // const [isFiltered, setIsFiltered] = useState(false);
  const [paginationSize, setPaginationSize] = useState(new URLSearchParams(props.location.search).get('size') || 25);
  const [paginationPage, setPaginationPage] = useState(new URLSearchParams(props.location.search).get('page') || 1);
  const [paginationTotalItems, setPaginationTotalItems] = useState();

  const searchTextRef = useRef('');

  const { Search } = Input;
  const { Title } = Typography;

  const handleSearch = useCallback(
    async value => {
      const response = await api.get(`departments?size=${paginationSize}&page=${paginationPage - 1}`, {
        headers: {
          filterText: value?.trim() || searchTextRef.current.input.value || '',
        },
      });
      if (response.data.error) {
        toast.warn('Department has not been found!');
        return;
      }
      setPaginationTotalItems(response.data.totalItems);
      setPaginationSize(response.data.sizePage);
      setPaginationPage(response.data.currentPage + 1);
      setDepartments(response.data.rows);
    },
    [paginationPage, paginationSize]
  );

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    if (searchQuery) {
      searchTextRef.current.input.value = searchQuery;
      handleSearch();
    }
  }, []); //eslint-disable-line

  function handleChangePage(page, pageSize) {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  }

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, row, index) => index + 1,
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: text => (
        <Link to={`/departmentsNew?name=${text}&searchQuery=${searchTextRef.current.input.value}&size=${paginationSize}&page=${paginationPage}`}>{text}</Link>
      ),
    },
  ];

  return (
    <Container>
      <Card size="small" bordered={false}>
        {/* <h1>Stores</h1> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {profile.type === 3 || profile.type === 999 ? (
            <Button onClick={() => history.push('/departmentsNew')} variant="contained" type="primary" icon={<PlusOutlined />}>
              Add
            </Button>
          ) : (
            <div />
          )}
          <Title level={3}>Departments</Title>
          <div />
        </div>
      </Card>
      <Card elevation={3} size="small">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            <Search
              placeholder="Search Departments' Name"
              ref={searchTextRef}
              allowClear
              onSearch={() => {
                handleSearch();
                // setPaginationPage(1);
              }}
              style={{ width: 300 }}
              enterButton
            />
          </Space>
        </div>
      </Card>

      {departments.length <= 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Use the search box above to list departments</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      ) : (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={departments}
            size="small"
            bordered
            pagination={false}
            scroll={{ y: 'calc(100vh - 22em)' }}
            rowKey={record => record.id}
          />
          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}
