import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;
`;
export const Form = styled.form`
  /* form { */
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  /* span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    } */

  hr {
    border: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 10px 0 20px;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #3b9eff;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
  }

  /* display: flex;
  flex: 1;
  .calculated-width {
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px);
  } */
  /* } */
`;

export const Button = styled.button`
  background-color: #e7e7e7;
  border: none;
  color: black;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 8px;
  margin-bottom: 2px;
`;
