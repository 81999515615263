/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Table } from './styles';

export default function InventorySummaryByLocation({ data, setLocationFilters, handleLocationFilterChange }) {
  const [checkedLocations, setCheckedLocations] = useState(Object.keys(data).filter((el) => el !== 'Total'));
  const checkAll = checkedLocations?.length === Object.keys(data).filter((el) => el !== 'Total')?.length;

  const toggleCheckAll = () => {
    if (checkAll) {
      setCheckedLocations([]);
      setLocationFilters([]);
      handleLocationFilterChange([]);
    } else {
      setCheckedLocations(Object.keys(data).filter((el) => el !== 'Total'));
      setLocationFilters(Object.keys(data).filter((el) => el !== 'Total'));
      handleLocationFilterChange(Object.keys(data).filter((el) => el !== 'Total'));
    }
  };

  const toggleLocationCheck = (location) => {
    if (checkedLocations.includes(location)) {
      setCheckedLocations(checkedLocations.filter((loc) => loc !== location));
      setLocationFilters(checkedLocations.filter((loc) => loc !== location));
      handleLocationFilterChange(checkedLocations.filter((loc) => loc !== location));
    } else {
      setCheckedLocations([...checkedLocations, location]);
      setLocationFilters([...checkedLocations, location]);
      handleLocationFilterChange([...checkedLocations, location]);
    }
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Total</th>
          {Object.keys(data)
            .filter((el) => el !== 'Total')
            .map((el) => (
              <th key={el}>{el}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td key="total">{data.Total}</td>
          {Object.keys(data)
            .filter((el) => el !== 'Total')
            .map((el) => (
              <td key={el}>{data[el]}</td>
            ))}
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="check-all" name="check-all" onChange={toggleCheckAll} checked={checkAll} />
          </td>
          {Object.keys(data)
            .filter((el) => el !== 'Total')
            .map((el) => (
              <td key={el}>
                <input type="checkbox" name="location-check" onChange={() => toggleLocationCheck(el)} checked={checkedLocations.includes(el)} />
              </td>
            ))}
        </tr>
      </tbody>
    </Table>
  );
}
