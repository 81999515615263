import { Card, Divider, Paper } from '@material-ui/core';
import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  input {
    /* text-transform: uppercase; */
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;

export const OrderContainer = styled(Paper)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
    /* border: 1px solid #f6f6f6; */
  }
`;

export const OrderContainerProducts = styled(Paper)`
  && {
    /* display: flex;
    flex-direction: row; */
    width: 100%;
    margin-top: 15px;
  }
`;

export const OrderTitle = styled(Paper)`
  && {
    width: 100%;
    /* background: #a1a; */
    padding: 15px;
    font-size: 28px;
    font-weight: bold;
  }
`;

export const OrderArea = styled(Paper)`
  && {
    /* display: inline; */
    min-width: 300px;
    background: #f6f6f6;
    /* background: #e5e5e5; */
    padding: 15px;
    margin: 5px;
    border: 1px solid #f6f6f6;
  }
`;

export const OrderAreaProducts = styled(Paper)`
  && {
    /* display: inline; */
    min-width: 950px;
    background: #f6f6f6;
    padding: 5px;
    margin: 5px;
    border: 1px solid #f6f6f6;
  }
`;

export const CustomDivider = styled(Divider)`
  && {
    margin: 15px 0;
  }
`;

export const OrderSummary = styled.div`
  /* width: 60%; */
  div {
    display: flex;
    /* margin-top: 7px; */
    justify-content: space-between;
  }
`;

export const Products = styled.div`
  width: 880px;
  display: flex;
  border: 1px solid black;

  /* background-color: green; */

  div + div {
    /* border-left: 1px solid black; */
  }

  > * {
    flex: auto 1 1 1;
    height: 100%;

    padding: 7px;
  }
`;

export const ProductsTable = styled.table`
  width: 1150px;
  /* width: 1500px; */
  border: 1px solid #e5e5e5;
  margin: 10px 0 5px;

  th + th,
  th + th + th,
  th + th + th + th {
    width: 10%;
  }

  th {
    padding: 15px;
  }

  tr {
    border: 1px solid #e5e5e5;

    td + td,
    th {
      text-align: center;
    }
  }

  tbody tr:hover {
    background-color: #dae9f2;
  }

  tbody td:first-child {
    padding: 10px;
  }
`;

export const SearchProducts = styled(Card)`
  width: 100%;
  padding: 15px;
  margin-top: 5px;
  background-color: '#c1c';

  span {
    display: flex;
    flex-direction: 'row';

    align-items: center;
    /* justify-content: flex-start; */

    div {
      margin-right: 5px;
    }
  }
`;

export const ProductsTableShipped = styled.table`
  width: 1150px;
  border: 1px solid #e5e5e5;
  margin: 10px 0 5px;

  th + th {
    width: 50%;
  }

  th {
    padding: 15px;
  }

  tr {
    border: 1px solid #e5e5e5;

    td,
    th {
      text-align: center;
    }
  }

  tbody tr:hover {
    background-color: #dae9f2;
  }
`;
export const ComponentTextArea = styled.textarea`
  width: 400px;
  border-color: ${(props) => props.theme['gray-300']};
  padding: 0.5em;
`;
