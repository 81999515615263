/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { CardHeader } from '@material-ui/core';
import { Button, Card, Checkbox, Form, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function TechnicianNew({location}) {
  const [technicianInfo, setTechnicianInfo] = useState({});
  const profile = useSelector(state => state.user.profile);

  const [userList, setUserList] = useState([]);
  const [form] = Form.useForm();

  const [updateFlag, setUpdateFlag] = useState(false);

  const [active, setActive] = useState(true);

  const [size, setSize] = useState();
  const [page, setPage] = useState();
  const [query, setQuery] = useState();

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };
  /* eslint-disable no-template-curly-in-string */

  useEffect(() => {
    async function loadTechnician() {
      const userInfo = await api.get('users');
      const list = userInfo.data?.map(user => ({ value: user.id, label: user.name }));
      setUserList(list);

      const locationUrl = new URLSearchParams(location.search);
      const technician_name = locationUrl.get('name');
      const pageSize = locationUrl.get('size');
      const pages = locationUrl.get('page');
      const searchQuery = locationUrl.get('searchQuery');
      if (technician_name) {
        const technician_info = await api.get(`technicians/${technician_name}`);
        const technician = {
          name: technician_info.data.name,
          email: technician_info.data.email,
          phone: technician_info.data.phone,
          user_id: technician_info.data.user_id,
        };
        form.setFieldsValue(technician);
        setTechnicianInfo(technician_info.data);
        setActive(technician_info.data.is_active);
        setUpdateFlag(true);
        setSize(pageSize);
        setPage(pages);
        setQuery(searchQuery === 'undefined' ? undefined : searchQuery);
      } else {
        setSize(25);
        setPage(1);
      }
    }
    loadTechnician();
  }, []); // eslint-disable-line

  const onFinish = async values => {
    const newTechnician = {
      name: values.name,
      email: values.email,
      phone: values.phone || '',
      user_id: values.user_id,
      is_active: active,
    };

    try {
      if (updateFlag) {
        await api.put(`technicians/${technicianInfo.name}`, newTechnician);
        toast.success('Technician Updated.');
      } else {
        await api.post('technicians', newTechnician);
        toast.success('New Technician created.');
      }
      history.push('/technicians');
    } catch {
      toast.error('Error loading Technician. Contact RBMS administrator.');
    }
  };

  return (
    <Container>
      <Card>
        <Form {...layout} form={form} name="nest-messages" onFinish={onFinish}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={() => (query === undefined ? `/technicians?size=${size}&page=${page}` : `/technicians?searchQuery=${query}&size=${size}&page=${page}`)}>
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {updateFlag === true ? <CardHeader subheader={`${technicianInfo.name}`} title="Update Technician" /> : <CardHeader title="New Technician" />}
            <div />
          </div>
          <Card title="Technician Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear placeholder="Technician's Name" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
              <Input allowClear placeholder="Technician's Email" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input allowClear placeholder="Technician's Phone Number" />
            </Form.Item>
            {profile.type === 3 || profile.type === 999 ? (
              <>
                <Form.Item
                  name="user_id"
                  label="User"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select a User." options={userList} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
                {updateFlag && (
                  <Form.Item name="is_active" wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                    <Checkbox checked={active} onChange={e => setActive(e.target.checked)}>
                      Is Active
                    </Checkbox>
                  </Form.Item>
                )}
              </>
            ) : (
              ''
            )}
          </Card>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
}
