/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Drawer, Input, Col, Row, Form, Space, Button, Select } from 'antd';
import api from '~/services/api';

export default function DrawerComponent({ onClose, drawerVisible, form, onFinish, record }) {
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    form.setFieldsValue(record);

    async function loadDropdownboxes() {
      const subcategoriesList = await api.get('subcategories');

      setSubcategories(
        subcategoriesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
    }

    loadDropdownboxes();
  }, [record, drawerVisible, form]);

  return (
    <Drawer
      getContainer={false}
      title="Work instruction"
      width={720}
      size="large"
      onClose={onClose}
      visible={drawerVisible}
      bodyStyle={{ paddingBottom: 80 }}
      // extra={
      //   <Space>
      //     <Button onClick={onClose}>Cancel</Button>
      //     <Button onClick={onClose} type="primary">
      //       Submit
      //     </Button>
      //   </Space>
      // }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter a name' }]}>
              <Input placeholder="Please enter work instruction name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please enter a description' }]}>
              <Input placeholder="Please enter description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="subcategory_id" label="Subcategory" rules={[{ required: true, message: 'Please enter a subcategory' }]}>
              <Select allowClear placeholder="Select a subcategory" options={subcategories} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="url"
              label="Url"
              rules={[
                {
                  type: 'url',
                  message: 'This field must be a valid url.',
                },
              ]}
            >
              <Input style={{ width: '100%' }} placeholder="Please enter url" />
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button htmlType="submit" type="primary">
            {record === null ? 'Create' : 'Save'}
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
}
