/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { Divider, Form, Input, InputNumber, Select, Space, Typography } from 'antd';

// import { toast } from 'react-toastify';
// import api from '~/services/api';

import CountryList from '~/utils/countriesList';

const { Text } = Typography;

export default function Customs({ orderStatus, internationalOptions, setInternationalOptions }) {
  const { Option } = Select;

  function handleChangeNonDelivery(value) {
    setInternationalOptions({ ...internationalOptions, nonDelivery: value });
  }

  function handleChangeCountry(value, index) {
    const newInternationalOptions = { ...internationalOptions };
    newInternationalOptions.customsItems[index].countryOfOrigin = value;
    setInternationalOptions(newInternationalOptions);
  }

  function handleChangeItem(value, index, key) {
    const newInternationalOptions = { ...internationalOptions };
    newInternationalOptions.customsItems[index][key] = value;
    setInternationalOptions(newInternationalOptions);
  }

  function handleChangeTotalPrice(value, index, key) {
    const newInternationalOptions = { ...internationalOptions };
    newInternationalOptions.customsItems[index][key] = value;
    setInternationalOptions(newInternationalOptions);
  }

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 17,
        }}
        // layout="horizontal"
        // initialValues={{
        //   size: componentSize,
        // }}
        // onValuesChange={onFormLayoutChange}
        size="small"
      >
        <Form.Item label="If Undeliverable" style={{ marginBottom: 0 }}>
          <Space>
            <Select
              defaultValue="return_to_sender"
              value={internationalOptions.nonDelivery}
              onChange={handleChangeNonDelivery}
              // disabled={Object.keys(labelForOrderInfo).length === 0}
              style={{ width: 250 }}
              disabled={orderStatus === 'shipped'}
            >
              <Option value="return_to_sender">Return to sender</Option>
              <Option value="treat_as_abandoned">Treat as abandoned</Option>
            </Select>
          </Space>
        </Form.Item>
      </Form>

      {/*
        Description
        Quantity
        Item Value(ea)
        Total Value
        Harmonization
        Country of Origin

         */}
      {/* </Space> */}

      <Space align="top">
        <Form
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 17,
          }}
          // layout="horizontal"
          // initialValues={{
          //   size: componentSize,
          // }}
          // onValuesChange={onFormLayoutChange}
          size="small"
        >
          {internationalOptions.customsItems?.length > 0 &&
            internationalOptions.customsItems?.map((item, index) => (
              // <>
              <Space key={index} direction="vertical">
                <Divider style={{ marginBottom: '2px' }} />
                <Text strong>{`Declaration ${index + 1}`}</Text>

                <Form.Item label="Description" style={{ marginBottom: '5px' }}>
                  <Input
                    maxLength={90}
                    disabled={orderStatus === 'shipped'}
                    onChange={el => handleChangeItem(el.currentTarget.value, index, 'description')}
                    value={item.description}
                    style={{ width: 300 }}
                    placeholder="Description"
                  />
                </Form.Item>

                <Form.Item label="Quantity" style={{ marginBottom: '5px' }}>
                  <InputNumber
                    disabled={orderStatus === 'shipped'}
                    value={item.quantity}
                    onBlur={el => handleChangeTotalPrice(el.currentTarget.value, index, 'quantity')}
                    min={1}
                    style={{ width: 200 }}
                    placeholder="Quantity"
                  />
                </Form.Item>

                <Form.Item label="Item Value(ea)" style={{ marginBottom: '5px' }}>
                  <InputNumber
                    disabled={orderStatus === 'shipped'}
                    value={item.value}
                    // value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(item.value)}
                    onBlur={el =>
                      handleChangeTotalPrice(
                        el.currentTarget.value
                          .substring(el.currentTarget.value.indexOf('$') + 1)
                          .trim()
                          .replace(',', ''),
                        index,
                        'value'
                      )
                    }
                    min={0}
                    formatter={value => `CA$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,|C|A*)/g, '')}
                    style={{ width: 200 }}
                    placeholder="Item Value(ea)"
                  />
                </Form.Item>

                <Form.Item label="Total Value" style={{ marginBottom: '5px' }}>
                  <InputNumber
                    value={+item.value * +item.quantity}
                    disabled
                    min={0}
                    formatter={value => `CA$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,|C|A*)/g, '')}
                    style={{ width: 200 }}
                    placeholder="Total Value"
                  />
                </Form.Item>

                <Form.Item label="Harmonization" style={{ marginBottom: '5px' }}>
                  <Input disabled value={item.harmonizedTariffCode} style={{ width: 300 }} placeholder="Harmonization" />
                </Form.Item>

                <Form.Item label="Country of Origin" style={{ marginBottom: '5px' }}>
                  <Select
                    disabled={orderStatus === 'shipped'}
                    value={item.countryOfOrigin}
                    onChange={value => handleChangeCountry(value, index)}
                    options={CountryList()}
                    style={{ width: 300 }}
                    showSearch
                    optionFilterProp="label"
                  >
                    {/* <Select.Option value="package">Package</Select.Option> */}
                  </Select>
                </Form.Item>
              </Space>
            ))}
        </Form>
      </Space>
    </>
  );
}
