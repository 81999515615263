import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import { Table, Button, Typography } from 'antd';

// import { UserOutlined } from '@ant-design/icons';

import { Paper } from '@material-ui/core';

import { Container } from './styles';

import api from '~/services/api';
// import history from '~/services/history';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const { Title } = Typography;

const columns = [
  {
    title: 'Search Type',
    dataIndex: 'search_type',
  },
  {
    title: 'Search Text',
    dataIndex: 'search_text',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: text => moment(text).format('MMM DD, YYYY - HH:mm'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (text, row) => (
      <Link to={`inventoryAuditItems/${row.id}`}>
        <Button type="primary">Audit scanned units</Button>
      </Link>
    ),
    // render: (text, row) => <Link to={`inventoryAuditItems/${row.id}`}>Audit scanned units</Link>,
  },
];

// const data = [];
// for (let i = 0; i < 46; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

export default function InventoryAudit() {
  const classes = useStyles();

  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadAudits() {
      const openAudit = await api.get('inventoryAudit?status=submitted');
      setData(openAudit.data);
    }
    loadAudits();
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        <Title level={3}>Inventory Audit</Title>
        <Table rowKey={record => record.createdAt} size="small" columns={columns} dataSource={data} />
      </Paper>
    </Container>
  );
}
