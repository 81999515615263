import React from 'react';

import { Tabs } from 'antd';

import { Container } from './styles';

import { UnitsByTechnicians } from './DashboardTechniciansUnits';
import { KPI } from './KPI';
import { Sales } from './Sales';
import { TechniciansProductivity } from './TechniciansProductivity';

export default function InventoryExport() {
  const items = [
    {
      label: 'KPI',
      key: 'kpi',
      children: <KPI />,
    },
    {
      label: 'Sales',
      key: 'sales',
      children: <Sales />,
    },
    {
      label: 'Items per User',
      key: 'itemsPerUser',
      children: <UnitsByTechnicians />,
    },
    {
      label: 'Technicians Productivity',
      key: 'techniciansProductivity',
      children: <TechniciansProductivity />,
    },
  ];
  return (
    <Container>
      <Tabs items={items} defaultActiveKey="kpi" type="card" size="small" />
    </Container>
  );
}
