/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';

export default function InventoryPartsEdit(props) {
  const profile = useSelector((state) => state.user.profile);
  const [inventory, setInventory] = useState([]);
  const [sku, setSku] = useState('');
  const [description, setDescription] = useState('');
  const [oldQty, setOldQty] = useState();

  const [minimum_quantity, setMinimum_quantity] = useState();
  const [quantity, setQuantity] = useState(0);
  const [bin_quantity, setBin_quantity] = useState();
  const [send_email, setSend_email] = useState(false);

  const { search } = props.location;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const searchQuery = params.get('searchQuery');

  const log_id = uuidv4();

  useEffect(() => {
    async function loadinventory() {
      const inventoryInfo = await api.get(`inventory/${id}?id=true`);

      setInventory(inventoryInfo.data);
      setSku(inventoryInfo.data.item.sku);
      setDescription(inventoryInfo.data.item.description);
      setOldQty(inventoryInfo.data.quantity);
      setQuantity(inventoryInfo.data.quantity);
      setMinimum_quantity(inventoryInfo.data.minimum_quantity);
      setBin_quantity(inventoryInfo.data.bin_quantity);
      setSend_email(inventoryInfo.data.send_email);
    }
    loadinventory();
  }, []); // eslint-disable-line

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      inventory.minimum_quantity = minimum_quantity ?? 0;
      inventory.bin_quantity = bin_quantity ?? 0;
      inventory.quantity = quantity ?? 0;
      inventory.send_email = send_email ?? false;

      await api.put(`inventory/${id}`, inventory);

      await api.post('inventoryLog', {
        log_id,
        item_id: inventory.item_id,
        routine: 'InventoryParts/BulkImport',
        error: 'Item Found',
        action: 'Update',
        previous_data: oldQty,
        new_data: quantity || 0,
        user_id: profile.id,
      });
      history.push('/inventoryParts');

      toast.success('Inventory updated!');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Card>
        <CardActions>
          <Button color="primary" variant="contained" onClick={() => history.push(`/inventoryParts?searchQuery=${searchQuery}`)}>
            Back
          </Button>
        </CardActions>
        <form autoComplete="off" noValidate>
          <CardHeader subheader={`${sku} - ${description}`} title="Update Inventory of Parts" />
          <Divider />
          <CardContent autoComplete="off">
            <Grid container spacing={6}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="quantity"
                  label="Quantity"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={quantity || 0}
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  id="minimum_quantity"
                  label="Minimum Quantity"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={minimum_quantity || 0}
                  onChange={(e) => setMinimum_quantity(e.target.value)}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  id="bin_quantity"
                  label="Bin Quantity"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={bin_quantity || 0}
                  onChange={(e) => setBin_quantity(e.target.value)}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={send_email || false} onChange={(e) => setSend_email(e.target.checked)} />}
                  label="Send email when stock below minumum?"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Update Inventory of Parts
            </Button>
            <Button color="primary" variant="contained" onClick={() => history.push(`/inventoryPartsLog?sku=${sku}`)}>
              Check log for this item
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
