/* eslint-disable react/prop-types */
import { ArrowLeftOutlined, ExclamationCircleFilled, QuestionCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Divider, Empty, Modal, Select, Space, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Container, SpaceBetween, StyledButton, TransactionContainer, TransactionTable } from './styles';

const { confirm } = Modal;
const { Title } = Typography;
const statusColor = {
  Pending: 'red',
  Complete: 'green',
  Started: 'orange',
  'Not Found': 'red',
  'Item not in RBMS': 'red',
  'Different Location': 'orange',
  'Marked not found previously': 'orange',
  'Items is TBI for more than 30 days': 'orange',
  OK: 'green',
  'Incorrect Status': 'red',
};

export default function PhysicalInventoryLocationsDashboard({ match }) {
  const [locationsList, setLocationsList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [availableUsersList, setAvailableUsersList] = useState([]);
  const [isModalReassignOpen, setIsModalReassignOpen] = useState(false);
  const [locationBeingAssigned, setLocationBeingAssigned] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    async function loadLocations() {
      const locationReport = await api.get(`physical_inventory/${match.params.physicalInventoryId}/locations/report`);
      setLocationsList(locationReport.data);
    }
    loadLocations();
  }, []); //eslint-disable-line

  const modalHelpText = (
    <ul>
      <li>
        <b>Not Found</b>: Not found units in this inventory event
      </li>
      <li>
        <b>Item not in RBMS</b>
      </li>
      <li>
        <b>Different Location</b>: Item not in assigned location
      </li>
      <li>
        <b>Marked not found previously</b>: Item not found in a previous inventory event
      </li>
      <li>
        <b>Items is TBI for more than 30 days</b>
      </li>
      <li>
        <b>OK</b>: Everything is OK with item
      </li>
      <li>
        <b>Incorrect Status</b>: Units that Status are not as Awaiting Shipment, In Stock, Failed , To be inspected and Not Found
      </li>
    </ul>
  );

  const openReassignModal = async (locationId) => {
    const availableUsers = await api.get(`physical_inventory/checkAvailableTechnicians/${match.params.physicalInventoryId}`);
    setAvailableUsersList(availableUsers.data.map((user) => ({ value: user.id, label: user.name })));
    setIsModalReassignOpen(!isModalReassignOpen);
    setLocationBeingAssigned(locationId);
  };

  const handleReassignOk = async () => {
    if (locationBeingAssigned !== null && userSelected !== null) {
      setConfirmLoading(true);
      const reassignedTechnician = await api.put(`/physical_inventory/reassignTechnician/${locationBeingAssigned}`, {
        user_id: userSelected.value,
        physical_inventory_id: +match.params.physicalInventoryId,
      });
      const { result, message } = reassignedTechnician.data;
      if (result === 'error') toast.error(message);
      else {
        setLocationsList((state) =>
          state.map((location) => {
            if (location.physical_inventory_location_id === locationBeingAssigned) {
              location.technician = userSelected.label;
            }
            return location;
          })
        );
        toast.success(message);
        setIsModalReassignOpen(!isModalReassignOpen);
        setUserSelected(null);
      }
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalReassignOpen(!isModalReassignOpen);
    setLocationBeingAssigned(null);
  };

  const handleChangeReassignTechnician = async (value) => {
    const selectedUser = availableUsersList.find((user) => user.value === value);
    setUserSelected(selectedUser);
  };

  const handleOpenCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleRefreshList = async () => {
    try {
      const locationReport = await api.get(`physical_inventory/${match.params.physicalInventoryId}/locations/report`);
      setLocationsList(locationReport.data);

      toast.success('Table refreshed');
    } catch (error) {
      toast(error.message);
    }
  };

  async function handleCompleteLocation(physicalInventoryLocationId) {
    const hasNotScannedItems = await api.get(`/physical_inventory/checkItemsNotScannedAtLocation/${physicalInventoryLocationId}`);

    if (hasNotScannedItems.data.status === 'nok') {
      confirm({
        title: (
          <span>
            Some items were not scanned
            {/* <span style={{ fontWeight: 'bold', fontSize: 'large', color: 'red' }}>&lsquo;{serialNumberScanned}&rsquo;</span> */}
          </span>
        ),
        icon: <ExclamationCircleFilled />,
        content: `There are ${hasNotScannedItems.data.notScannedCount} items not scanned in this location. Do you want to complete this location anyway?`,
        okText: `Yes, I'm sure`,
        okType: 'success',
        cancelText: 'No, I will keep scanning',
        cancelButtonProps: { type: 'danger' },
        autoFocusButton: 'cancel',
        async onOk() {
          await api.patch(`/physical_inventory/finishScanningLocation/${physicalInventoryLocationId}`);
          toast.success('Location complete');

          setLocationsList((state) => {
            state.find((item) => item.physical_inventory_location_id === physicalInventoryLocationId).location_status = 'Complete';
            return [...state];
          });
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    } else {
      await api.patch(`/physical_inventory/finishScanningLocation/${physicalInventoryLocationId}`);
      setLocationsList((state) => {
        state.find((item) => item.physical_inventory_location_id === physicalInventoryLocationId).location_status = 'Complete';
        return [...state];
      });
      toast.success('Location complete 1');
    }
  }

  return (
    <Container>
      <SpaceBetween>
        <Link to="/physicalInventory">
          <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </Link>
        <Title level={2}>{locationsList[0]?.physical_inventory_name} Manager Dashboard</Title>
        <div />
      </SpaceBetween>
      <Divider />
      <SpaceBetween>
        <strong>Manager: {locationsList[0]?.user || ''}</strong>
        <Space>
          <Button variant="contained" type="primary" icon={<RedoOutlined />} onClick={handleRefreshList}>
            Refresh
          </Button>
          <Button variant="contained" type="primary" icon={<QuestionCircleOutlined />} onClick={handleOpenCloseModal}>
            STATUS HELP
          </Button>
        </Space>
      </SpaceBetween>
      <Modal title="Help" open={isModalOpen} onOk={handleOpenCloseModal} onCancel={handleOpenCloseModal}>
        {modalHelpText}
      </Modal>
      {locationsList.length > 0 ? (
        <TransactionContainer>
          <TransactionTable>
            <thead>
              <tr>
                <td width="30%">Location</td>
                <td>Status</td>
                <td>Total Units</td>
                <td>Scanned Units</td>
                <td>Units Statuses</td>
                <td>Technician</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {locationsList.map((location) => (
                <tr key={location.physical_inventory_location_id}>
                  <td width="30%">
                    <Link
                      to={`/physicalInventoryLocationItems/${match.params.physicalInventoryId}/${location.physical_inventory_location_id}?return=dashboard`}
                    >{`${location.location_name} - ${location.location_description}`}</Link>
                  </td>
                  <td>
                    <Tag color={statusColor[location.location_status]}>{location.location_status}</Tag>
                  </td>
                  <td>{location.total}</td>
                  <td>{location.total - (location.summary_check['Not Scanned'] || 0)}</td>
                  <td>
                    {Object.keys(location.summary_check).map((el) => (
                      <Tag key={el} color={statusColor[el]}>{`${el}: ${location.summary_check[el]}`}</Tag>
                    ))}
                  </td>
                  <td>{location.technician}</td>
                  <td style={{ width: '25%' }}>
                    <StyledButton onClick={() => openReassignModal(location.physical_inventory_location_id)}>Reassign Technician</StyledButton>
                    <StyledButton data-status={location.location_status} onClick={() => handleCompleteLocation(location.physical_inventory_location_id)}>
                      {location.location_status === 'Complete' ? 'Complete again' : 'Complete'}
                    </StyledButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </TransactionTable>
          <Modal title="Reassign Technician" open={isModalReassignOpen} onOk={handleReassignOk} onCancel={() => handleCancel()} confirmLoading={confirmLoading}>
            <Select
              options={availableUsersList}
              onChange={handleChangeReassignTechnician}
              value={userSelected}
              style={{
                width: 300,
              }}
              placeholder="Select a technician"
              showSearch
              optionFilterProp="label"
              allowClear
            />
          </Modal>
        </TransactionContainer>
      ) : (
        <Empty description="There is no location to show" />
      )}
    </Container>
  );
}
