/* eslint-disable no-alert */
import { Modal, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import api from '~/services/api';

export function ImportExcel({ downstreamId }) {
  const profile = useSelector(state => state.user.profile);
  const [downstreamJson, setDownstreamJson] = useState([]);
  const [validatedFile, setValidatedFile] = useState(false);
  const [importResult, setImportResult] = useState([]);
  const [isImportResultModalOpen, setIsImportResultModalOpen] = useState(false);

  function excelFileToJSON(file) {
    try {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function(e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary',
        });
        // const result = {};
        const firstSheetName = workbook.SheetNames[0];
        // reading only first sheet data
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
        // displaying the json result into HTML table
        setDownstreamJson(jsonData);
        setValidatedFile(true);
        toast.success('File Validated Sucessfully');
      };
    } catch (e) {
      toast.error(e);
    }
  }

  function handleValidateImportFile() {
    const { files } = document.getElementById('file_upload');
    if (files.length === 0) {
      toast.warning('Please choose any file...');
      return;
    }
    const fileSize = files[0].size;
    const FILE_SIZE_IS_NOT_VALID = fileSize > 5242880;
    if (FILE_SIZE_IS_NOT_VALID) {
      toast.error('File size is bigger than 5MB, please breakdown the file');
      return;
    }

    const fileName = files[0].name;
    const extension = fileName.substring(fileName.lastIndexOf('.')).toUpperCase();

    const FILE_EXTENSION_IS_NOT_VALID = extension !== '.XLS' && extension !== '.XLSX';
    if (FILE_EXTENSION_IS_NOT_VALID) {
      toast.error('Please select a valid excel file.');
      return;
    }
    excelFileToJSON(files[0]);
  }

  async function handleUploadImport() {
    const requestBody = {
      jsonList: downstreamJson,
      userId: profile.id,
      downstreamId,
    };

    const response = await api.put('downstream_items/bulk', requestBody);
    setImportResult(response.data);
    const BULK_IMPORT_WAS_SUCCESSFUL = response.data.length === 0;
    if (BULK_IMPORT_WAS_SUCCESSFUL) {
      toast.success('Import successfull, please refresh the page');
    } else {
      alert(`Import had ${response.data.length} problems with serial numbers`);
      setIsImportResultModalOpen(true);
    }
  }

  const importResultColumns = [
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Reason',
      dataIndex: 'message',
    },
  ];

  const closeImportResult = () => {
    setIsImportResultModalOpen(false);
  };

  function downloadExcel() {
    const data = [{ serial_number: '' }];
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    // XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `downstream_template.xlsx`);
  }

  return (
    <div>
      <input type="file" id="file_upload" />
      <Space>
        <button type="button" onClick={() => downloadExcel()}>
          Template
        </button>
        <button type="button" onClick={() => handleValidateImportFile()}>
          Validate
        </button>
        <button type="button" disabled={!validatedFile} onClick={() => handleUploadImport()}>
          Upload
        </button>
      </Space>
      <Modal title="Import Result Details" open={isImportResultModalOpen} onOk={closeImportResult} onCancel={closeImportResult} width={1000}>
        <Table columns={importResultColumns} dataSource={importResult} pagination={false} />
      </Modal>
    </div>
  );
}

ImportExcel.propTypes = {
  downstreamId: PropTypes.number.isRequired,
};
