import api from '~/services/api';

export async function fetchItems(search, showQty, categoryId, subcategoryId) {
  if (!search) {
    return '';
  }

  const params = new URLSearchParams();
  params.append('search', search);
  params.append('is_active', true);

  if (subcategoryId) {
    params.append('subcategory_id', subcategoryId);
    params.append('type', 'part');
  }

  if (categoryId) {
    params.append('category_id', categoryId);
    params.append('type', 'part');
  }

  const productsData = await api.get(`/items?${params}`);

  return productsData.data.map((el) => ({
    value: `${el.id}`,
    label: showQty
      ? `Qty: ${el.inventory?.quantity || 0} - ${el.part_number} - ${el.name} - ${el.description}`
      : `${el.part_number} - ${el.name} - ${el.description}`,
  }));
}
