/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { Typography as AntTypography, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CancelIcon from '@material-ui/icons/Cancel';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import Table from '~/components/Table';
import api from '~/services/api';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '500px',
    maxHeight: '600px',
  },
  textField: {
    width: '48ch',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    background: '#fff3e0',
    marginTop: '10px',
    paddingLeft: '10px',
  },
  grid: {
    width: '98%',
    margin: '5px 5px',
  },
  secondaryHeading: {
    margin: 'auto',
  },
  table: {
    width: '100px',
    display: 'inline',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export default function New({ open, handleClose, rma }) {
  const classes = useStyles();
  const { Paragraph } = AntTypography;

  const [order, setOrder] = useState('');
  const [store, setStore] = useState('');
  const [customer, setCustomer] = useState('');
  const [company, setCompany] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [items, setItems] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [serialReturned, setSerialReturned] = useState([]);
  const [returnReason, setReturnReason] = useState('');
  const [carrier, setCarrier] = useState('');
  const [tracking, setTracking] = useState('');
  const [customerComments, setCustomerComments] = useState('');
  const [actionRequired, setActionRequired] = useState('');
  const [technicianLog, setTechnicianLog] = useState('');
  const [trackingStatus, setTrackingStatus] = useState('');
  const [returnStatus, setReturnStatus] = useState('');
  const [customerWants, setCustomerWants] = useState('');
  const [closeReturnButton, setCloseReturnButton] = useState();
  const [overThirtyDays, setOverThirtyDays] = useState(false);

  const [storeList, setStoreList] = useState([]);

  const refOrder = useRef('');
  const refSaveUpdateButton = useRef('');

  async function handleOrderSearch() {
    if (!order || !store) {
      toast.error('Must input Order and Store fields');
      refOrder.current.focus();
      return;
    }

    const orderDetails = await api.get(`orders/${encodeURIComponent(order)}/${store}`);
    if (orderDetails.data.error) {
      toast.error('Order not found. Verify if Order number and Store are correct');
      refOrder.current.focus();
      return;
    }
    setStore(orderDetails.data.store.id);
    setCustomer(orderDetails.data.customer.name);
    setCompany(orderDetails.data.customer.company);
    setOrderDate(orderDetails.data.modify_date);
    setItems(orderDetails.data.order_items);
    setChecklist(orderDetails.data.checklist);

    const orderDateMoment = moment(orderDetails.data.modify_date, 'YYYY-MM-DD');
    const currentDateMoment = moment(moment(), 'YYYY-MM-DD');
    setOverThirtyDays(currentDateMoment.diff(orderDateMoment, 'days') > 30);
  }

  const columns = [
    {
      name: 'SN',
      dataIndex: 'serial_number',
    },
    {
      name: 'PN',
      dataIndex: 'part_number',
    },
    {
      name: 'Times Returned',
      dataIndex: 'return_counter',
    },
  ];

  const info = (returnCounterArray) => {
    Modal.info({
      title: 'ALERT: this item contains components that have been previously returned by clients. Shipping this item will require tech manager’s approval',
      content: <Table columns={columns} data={returnCounterArray} rowKey="serial_number" />,
      onOk() {},
      width: '800px',
    });
  };

  async function handleSave(action) {
    if (!order || !store) {
      toast.error('Must input Order and Store fields');
      refOrder.current.focus();
      return;
    }

    const orderDetails = await api.get(`orders/${encodeURIComponent(order)}/${store}`);
    if (orderDetails.data.error) {
      toast.error('Order not found. Verify if Order number and Store are correct');
      refOrder.current.focus();
      return;
    }

    const returnDetails = {
      order_id: orderDetails.data.id,
      order_num: order?.trim(),
      items_returned: !serialReturned ? '' : serialReturned.join(),
      return_reason: returnReason,
      carrier,
      store,
      tracking,
      customer_comments: customerComments,
      action: actionRequired,
      technician_log: technicianLog,
      tracking_status: trackingStatus,
      return_status: returnStatus,
      customer_wants: customerWants,
    };

    switch (action) {
      case 'SaveUpdateReturn':
        returnDetails.status = 'open';
        if (!order || !store) {
          toast.error('Must input Order and Store fields');
          refOrder.current.focus();
          return;
        }
        if (refSaveUpdateButton.current.value === 'Save') {
          const newRma = await api.post('/returns', returnDetails);

          if (serialReturned) {
            serialReturned.map(async (serialNumber) => {
              await api.put('inventory_items', {
                items: [
                  {
                    serial_number: serialNumber,
                    status: 'Returning',
                    action: 'RMACreated',
                  },
                ],
              });
            });
          }

          toast.success(`RMA ${newRma.data.id} created successfully`);
          handleClose();
        }
        if (refSaveUpdateButton.current.value === 'Update') {
          const updateRma = await api.put(`/returns/${rma.id}`, returnDetails);
          toast.success(`RMA ${updateRma.data.id} updated successfully`);
          handleClose();
        }
        break;

      case 'CancelReturn': {
        returnDetails.status = 'cancelled';
        await api.put(`/returns/${rma.id}`, returnDetails);

        if (serialReturned.length === 1 && serialReturned[0] === '') {
          toast.success(`RMA ${rma.id} cancelled successfully`);
          handleClose();
          break;
        }

        serialReturned.map(async (serialNumber) => {
          await api.put('inventory_items', {
            items: [
              {
                serial_number: serialNumber,
                status: 'Sold',
                action: 'RMACancelled',
                location: '',
              },
            ],
          });
        });

        toast.success(`RMA ${rma.id} cancelled successfully`);
        handleClose();
        break;
      }

      case 'CloseReturn': {
        returnDetails.status = 'closed';
        const closeRma = await api.put(`/returns/${rma.id}`, returnDetails);
        toast.success(`RMA ${closeRma.data.id} closed successfully`);
        info(closeRma.data.returnCounterList);
        handleClose();
        break;
      }
      default:
        break;
    }
  }

  function handleClearReturn() {
    setOrder('');
    setStore('');
    setCustomer('');
    setCompany('');
    setOrderDate('');
    setItems([]);
    setCustomerComments('');
    setActionRequired('');
    setTechnicianLog('');
    setTrackingStatus('');
    setReturnStatus('');
    setCustomerWants('');
    setReturnReason('');
    setCarrier('');
    setTracking('');
    setSerialReturned([]);
    setChecklist([]);
    refOrder.current.focus();
  }

  function handleRemoveReturnedSerial(serialChecklist) {
    const data = serialReturned.filter((i) => i !== serialChecklist);
    setSerialReturned(data);
  }

  function handleAddReturnedSerial(serialChecklist) {
    if (typeof serialReturned === 'undefined') {
      setSerialReturned([serialChecklist]);
      return;
    }

    if (!serialReturned) {
      setSerialReturned([serialChecklist]);
      return;
    }

    if (!serialReturned.includes(serialChecklist)) {
      setSerialReturned([...serialReturned, serialChecklist]);
    }
  }

  useEffect(() => {
    setOrder(rma?.order_num?.trim());
    setStore(rma?.order?.store.id);
    setCustomer(rma?.customer);
    setCompany(rma?.company);
    setOrderDate(rma?.order_date);
    setCustomerComments(rma?.customer_comments);
    setActionRequired(rma?.action);
    setTechnicianLog(rma?.technician_log);
    setItems(rma?.order_items);
    setChecklist(rma?.checklist ? rma.checklist.filter((el) => el.serial_number !== null) : null);
    setTrackingStatus(rma?.tracking_status);
    setReturnStatus(rma?.return_status);
    setCustomerWants(rma?.customer_wants);
    setReturnReason(rma?.return_reason);
    setCarrier(rma?.carrier);
    setTracking(rma?.tracking);
    setSerialReturned(rma?.items_returned?.length > 0 ? rma.items_returned.filter((el) => el !== '') : null);

    async function loadStores() {
      const stores = await api.get('stores?list=true');
      setStoreList(stores.data);
    }
    loadStores();

    const closeButtonEnabled =
      (rma?.return_status === 'Full Refunded' || rma?.return_status === 'Partial Refunded' || rma?.return_status === 'Replaced') &&
      rma?.tracking_status === 'Received RB';
    setCloseReturnButton(!closeButtonEnabled);
  }, [open]); //eslint-disable-line

  useEffect(() => {
    const closeButtonEnabled =
      (returnStatus === 'Full Refunded' || returnStatus === 'Partial Refunded' || returnStatus === 'Replaced') && trackingStatus === 'Received RB';
    setCloseReturnButton(!closeButtonEnabled);
  }, [returnStatus, trackingStatus]); //eslint-disable-line

  return (
    <>
      {rma && (
        <Dialog classes={{ paper: classes.dialogPaper }} fullWidth maxWidth="lg" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
          <DialogTitle id="max-width-dialog-title">
            RMA: {rma.id}
            {rma.id && <DialogContentText>created at {moment(rma.createdAt).format('MMMM DD, YYYY')}</DialogContentText>}
          </DialogTitle>

          <DialogContent>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="order_number"
                disabled={Object.keys(rma).length > 0}
                label="Order #"
                autoFocus
                variant="outlined"
                size="small"
                inputRef={refOrder}
                className={classes.textField}
                onChange={(e) => setOrder(e.target.value)}
                value={order || ''}
              />

              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel htmlFor="storeSearch">Store</InputLabel>
                <Select
                  native
                  value={store}
                  onChange={(e) => setStore(e.target.value)}
                  label="Store"
                  inputProps={{
                    name: 'storeSearch',
                    id: 'storeSearch',
                  }}
                >
                  <option key={-1} value="0">
                    Select a store:
                  </option>
                  {storeList.map((el, idx) => (
                    <option key={idx} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Button
                disabled={Object.keys(rma).length > 0}
                onClick={() => handleOrderSearch()}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon>Search</SearchIcon>}
              >
                Search
              </Button>
              <Button
                disabled={Object.keys(rma).length > 0}
                onClick={() => handleClearReturn()}
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<ClearAllIcon>Clear</ClearAllIcon>}
              >
                Clear
              </Button>

              <Divider />
              <TextField
                id="store"
                label="Store"
                disabled
                variant="outlined"
                size="small"
                className={classes.textField}
                onChange={() => ({})}
                value={store || '0'}
              />
              <TextField
                id="customer"
                label="Customer"
                disabled
                variant="outlined"
                size="small"
                className={classes.textField}
                onChange={() => ({})}
                value={company ? `${customer} (${company})` : customer || ''}
              />
              <TextField
                id="orderDate"
                label="Order Date"
                helperText={overThirtyDays && 'Over 30 days'}
                error={overThirtyDays}
                disabled
                variant="outlined"
                size="small"
                className={classes.textField}
                onChange={() => ({})}
                value={moment(orderDate).format('MMMM DD, YYYY') || ''}
              />
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel htmlFor="returnReason">Return reason</InputLabel>
                <Select
                  native
                  value={returnReason || ''}
                  onChange={(e) => setReturnReason(e.target.value)}
                  label="Return reason"
                  inputProps={{
                    name: 'returnReason',
                    id: 'returnReason',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="Different/Wrong Item">Different/Wrong Item</option>
                  <option value="Claim damage">Claim damage</option>
                  <option value="No longer needed">No longer needed</option>
                  <option value="Defective">Defective</option>
                  <option value="Wrong description">Wrong description</option>
                  <option value="Product dissatisfaction">Product dissatisfaction</option>
                </Select>
              </FormControl>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="carrier">Carrier</InputLabel>
                {/* <Select labelId="carrier" id="carrier"> */}
                <Select
                  native
                  value={carrier || ''}
                  onChange={(e) => setCarrier(e.target.value)}
                  label="Carrier"
                  inputProps={{
                    name: 'carrier',
                    id: 'carrier',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="Amazon">Amazon</option>
                  <option value="Canadapost">Canadapost</option>
                  <option value="DHL">DHL</option>
                  <option value="Fedex">Fedex</option>
                  <option value="Purolator">Purolator</option>
                  <option value="UPS">UPS</option>
                  <option value="USPS">USPS</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
              <TextField
                id="trackingCode"
                label="Tracking Code"
                variant="outlined"
                size="small"
                className={classes.textField}
                onChange={(e) => setTracking(e.target.value)}
                value={tracking || ''}
              />
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel htmlFor="customerWants">Customer wants</InputLabel>
                <Select
                  native
                  value={customerWants || ''}
                  onChange={(e) => setCustomerWants(e.target.value)}
                  label="Customer wants"
                  inputProps={{
                    name: 'customerWants',
                    id: 'customerWants',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="Full Refund">Full Refund</option>
                  <option value="Partial Refund">Partial Refund</option>
                  <option value="Replacement">Replacement</option>
                </Select>
              </FormControl>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="returnStatus">Return Status</InputLabel>
                <Select
                  native
                  value={returnStatus || ''}
                  onChange={(e) => setReturnStatus(e.target.value)}
                  label="Return Status"
                  inputProps={{
                    name: 'returnStatus',
                    id: 'returnStatus',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="Pending Full Refund">Pending Full Refund</option>
                  <option value="Pending Partial Refund">Pending Partial Refund</option>
                  <option value="Pending Replacement">Pending Replacement</option>
                  <option value="Full Refunded">Full Refunded</option>
                  <option value="Partial Refunded">Partial Refunded</option>
                  <option value="Replaced">Replaced</option>
                </Select>
              </FormControl>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="trackingStatus">Tracking Status</InputLabel>
                <Select
                  native
                  value={trackingStatus || ''}
                  onChange={(e) => setTrackingStatus(e.target.value)}
                  label="Tracking Status"
                  inputProps={{
                    name: 'trackingStatus',
                    id: 'trackingStatus',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="Returning">Returning</option>
                  <option value="Received CBI">Received CBI</option>
                  <option value="Received RB">Received RB</option>
                </Select>
              </FormControl>

              <TextField
                id="customerComments"
                className={classes.textField}
                size="small"
                variant="outlined"
                label="Customer comments"
                multiline
                rows={4}
                value={customerComments || ''}
                onChange={(e) => setCustomerComments(e.target.value)}
              />
              <TextField
                id="actionRequired"
                className={classes.textField}
                size="small"
                variant="outlined"
                label="Action required"
                multiline
                rows={4}
                value={actionRequired || ''}
                onChange={(e) => setActionRequired(e.target.value)}
              />
              <TextField
                id="technicianLog"
                className={classes.textField}
                size="small"
                variant="outlined"
                label="Technician log"
                multiline
                rows={4}
                value={technicianLog || ''}
                onChange={(e) => setTechnicianLog(e.target.value)}
              />
            </form>

            {store && (
              <Typography variant="h5" gutterBottom>
                Items as ordered
                <br />
              </Typography>
            )}

            {items &&
              items.map((item, idx) => (
                <Paper className={classes.paper} key={idx}>
                  <Grid container wrap="nowrap" spacing={2} className={classes.grid} color="warning">
                    <Grid item className={classes.gridItem}>
                      <Avatar>{item.quantity}</Avatar>
                    </Grid>
                    <Grid item xs>
                      <Typography>{item.title}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}

            {store && (
              <Typography variant="h5" gutterBottom>
                Items shipped
                <br />
              </Typography>
            )}

            {checklist &&
              checklist.map((unit, idx) => (
                <Paragraph key={idx} copyable={{ text: unit.serial_number }}>
                  <Chip label={unit.serial_number} color="primary" onClick={() => handleAddReturnedSerial(unit.serial_number)} />
                </Paragraph>
              ))}
            <br />
            <Typography variant="h5" gutterBottom>
              Items being returned
              <br />
            </Typography>
            {serialReturned?.length > 0 &&
              serialReturned.map((serial, idx) => (
                <Paragraph key={idx} copyable={{ text: serial }}>
                  <Chip key={idx} label={serial} color="secondary" onClick={() => handleRemoveReturnedSerial(serial)} />
                </Paragraph>
              ))}
            <div className={classes.root} />
          </DialogContent>

          <DialogActions>
            <div>{closeReturnButton}</div>
            <Button onClick={() => handleSave('CancelReturn')} variant="contained" startIcon={<CancelIcon>Cancel</CancelIcon>}>
              Cancel Return
            </Button>
            <Button
              onClick={() => handleSave('CloseReturn')}
              disabled={closeReturnButton || rma.status === 'closed'}
              variant="contained"
              startIcon={<DoneAllIcon>Close</DoneAllIcon>}
            >
              Close Return
            </Button>
            <Button
              onClick={() => handleSave('SaveUpdateReturn')}
              ref={refSaveUpdateButton}
              value={Object.keys(rma).length > 0 ? 'Update' : 'Save'}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon>{Object.keys(rma).length > 0 ? 'Update' : 'Save'}</SaveIcon>}
              disabled={rma.status === 'closed'}
            >
              {Object.keys(rma).length > 0 ? 'Update' : 'Save'}
            </Button>
            <Button onClick={handleClose} variant="contained" color="secondary" startIcon={<CloseIcon>Close</CloseIcon>}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
