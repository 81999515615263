import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Divider, Form, Tabs, Typography } from 'antd';

// import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

import Devices from './Devices';

import LooseParts from './LooseParts';

// eslint-disable-next-line react/prop-types
export default function InventoryOfReceiving({ location }) {
  const profile = useSelector((state) => state.user.profile);

  const { Title } = Typography;

  const { TabPane } = Tabs;

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  // Info States
  const [receivingInfo, setReceivingInfo] = useState(null);
  const [receivingItemInfo, setReceivingItemInfo] = useState(null);
  // const [itemInfo, setItemInfo] = useState([]);
  const [hasItemReceived, setHasItemReceived] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const params = new URLSearchParams(location.search);
    const receiving_id = params.get('id');
    const receiving_item_id = params.get('receivingItemId');

    async function loadReceivingInfo() {
      // --------------------------------------------------------------------
      //  SET RECEIVING INFORMATION (HEADER)
      // --------------------------------------------------------------------
      const getReceivingInfo = await api.get(`receiving/${receiving_id}`);
      setReceivingInfo(() => getReceivingInfo.data);

      const inventoryItemsData = await api.get(`inventory_items/${receiving_item_id}/inventory`);
      if (inventoryItemsData.data.length > 0) {
        setHasItemReceived(true);
      }

      // form.setFieldsValue({ purchaseOrderItemId: getReceivingInfo.data.receiving_items.find(el => el.id === +receiving_item_id).purchase_order_item_id });
      setReceivingItemInfo(() => getReceivingInfo.data.receiving_items.find((el) => el.id === +receiving_item_id));

      // const item_list = await api.get('items');
      // const activeItems = item_list.data.filter((item) => item.is_active === true);
      // setItemInfo(() => activeItems);
    }
    loadReceivingInfo();
  }, []); //eslint-disable-line

  return (
    <Container>
      <Button style={{ width: 100 }} block type="primary" onClick={() => history.push('/receiving')} icon={<ArrowLeftOutlined />}>
        Back
      </Button>
      <Divider />

      {receivingInfo !== null && receivingItemInfo !== null && (
        <>
          <Title level={3}>{`Scanning Item ${receivingItemInfo.description} - Received by ${receivingInfo.created_by}`}</Title>
          <Title level={4}>{`Supplier: ${receivingInfo.supplier} - Receiving ID: ${receivingInfo.id}`}</Title>
        </>
      )}

      <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }}>
        {receivingItemInfo && (
          <>
            <TabPane forceRender tab="PO Items" key="1">
              <Devices
                form={form}
                profile={profile}
                // itemList={itemInfo}
                receivingInfo={receivingInfo}
                receivingItemInfo={receivingItemInfo}
                setReceivingItem={setReceivingItemInfo}
                setHasItemReceived={setHasItemReceived}
              />
            </TabPane>

            {hasItemReceived && (
              <TabPane forceRender tab="Additional Items" key="2">
                <LooseParts
                  form1={form1}
                  profile={profile}
                  // itemList={itemInfo}
                  receivingItemInfo={receivingItemInfo}
                />
              </TabPane>
            )}
          </>
        )}
      </Tabs>
    </Container>
  );
}
