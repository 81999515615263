/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { Button, Space, Table, Transfer } from 'antd';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ActionsBar, Container, InputContainer } from './styles';

export default function UserGroups() {
  const [userGroupId, setUserGroupId] = useState(null);
  const [userGroupName, setUserGroupName] = useState('');
  const [userGroupDescription, setUserGroupDescription] = useState('');

  const [showGroupList, setShowGroupList] = useState(true);

  const [userGroupList, setUserGroupList] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [usersByGroup, setUsersByGroup] = useState([]);

  useEffect(() => {
    async function loadLists() {
      const groupListData = await api.get('location-user-permission/listUserGroups');
      setUserGroupList(groupListData.data.groups);

      const groupedUsers = {};

      groupListData.data.groups.forEach((item) => {
        const parentId = item.id;
        item.location_user_group_users.forEach((user) => {
          const { name } = user.users;
          if (!groupedUsers[parentId]) {
            groupedUsers[parentId] = [];
          }
          groupedUsers[parentId].push(name);
        });
      });

      Object.keys(groupedUsers).forEach((id) => {
        if (Array.isArray(groupedUsers[id])) {
          groupedUsers[id] = groupedUsers[id].join(', ');
        }
      });

      setUsersByGroup(() => groupedUsers);
    }
    loadLists();
  }, []);

  useEffect(() => {
    async function loadUsers() {
      const allUserListData = await api.get('users');
      const allUsersFormatted = allUserListData.data
        .filter((user) => user.active === true)
        .map((user) => ({
          key: user.id,
          title: user.name,
          description: user.name,
          chosen: false,
        }));

      const groupListData = await api.get('location-user-permission/listUserGroups');
      setUserGroupList(groupListData.data.groups);

      if (userGroupId === 'new') {
        setAllUserList(() => allUsersFormatted);
        return;
      }

      setUserGroupName(groupListData.data.groups.find((el) => el.id === userGroupId).name);
      setUserGroupDescription(groupListData.data.groups.find((el) => el.id === userGroupId).description);

      const usersOnThisGroup = groupListData.data.groups
        .filter((item) => item.id === userGroupId)[0]
        ?.location_user_group_users.map((item) => {
          allUsersFormatted.find((user) => user.key === item.users.id).chosen = true;
          return item.users.id;
        });

      setSelectedUserGroup(usersOnThisGroup);
      setAllUserList(() => allUsersFormatted);
    }
    if (userGroupId) {
      loadUsers();
    }
  }, [userGroupId]);

  function handleSelectdUserGroup(groupId) {
    setUserGroupId(() => groupId);
    setShowGroupList(false);
  }

  function handleStartOver() {
    setShowGroupList(true);
    setUserGroupId(null);
    setSelectedUserGroup([]);
    setAllUserList([]);
    setUserGroupName('');
    setUserGroupDescription('');
  }

  async function createNewGroup() {
    const groupToCreate = await api.post('/location-user-permission/createUsersGroup', {
      users: selectedUserGroup,
      groupName: userGroupName,
      groupDescription: userGroupDescription,
    });

    if (groupToCreate.data.error) {
      toast.error(groupToCreate.data.error);
    }

    setUserGroupId(groupToCreate.data.groupId);

    const groupListData = await api.get('location-user-permission/listUserGroups');
    setUserGroupList(groupListData.data.groups);

    const groupedUsers = {};
    groupListData.data.groups.forEach((item) => {
      const parentId = item.id;
      item.location_user_group_users.forEach((user) => {
        const { name } = user.users;
        if (!groupedUsers[parentId]) {
          groupedUsers[parentId] = [];
        }
        groupedUsers[parentId].push(name);
      });
    });

    Object.keys(groupedUsers).forEach((id) => {
      if (Array.isArray(groupedUsers[id])) {
        groupedUsers[id] = groupedUsers[id].join(', ');
      }
    });

    setUsersByGroup(() => groupedUsers);
    handleStartOver();
  }

  async function handleSaveUserGroup() {
    if (userGroupName === '') {
      toast.error('Group name cannot be empty');
      return;
    }
    if (userGroupId === 'new') {
      createNewGroup();
      return;
    }

    const groupToUpdate = await api.put(`/location-user-permission/updateUsersGroup/${userGroupId}`, {
      users: selectedUserGroup,
      groupName: userGroupName,
      groupDescription: userGroupDescription,
    });

    if (groupToUpdate.data.error) {
      toast.error('Could not add users to this group. Report this error to system admin');
      return;
    }

    const groupListData = await api.get('location-user-permission/listUserGroups');
    setUserGroupList(groupListData.data.groups);

    const groupedUsers = {};
    groupListData.data.groups.forEach((item) => {
      const parentId = item.id;
      item.location_user_group_users.forEach((user) => {
        const { name } = user.users;
        if (!groupedUsers[parentId]) {
          groupedUsers[parentId] = [];
        }
        groupedUsers[parentId].push(name);
      });
    });

    Object.keys(groupedUsers).forEach((id) => {
      if (Array.isArray(groupedUsers[id])) {
        groupedUsers[id] = groupedUsers[id].join(', ');
      }
    });

    setUsersByGroup(() => groupedUsers);

    setUserGroupId(() => null);

    toast.success('Users added to group successfully');

    handleStartOver();
  }

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_text, row) => (
        <Button type="link" onClick={() => handleSelectdUserGroup(row.id)}>
          Edit
        </Button>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      render: (_text, row) => usersByGroup[row.id],
    },
  ];

  const filterOption = (inputValue, option) => (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase());
  const handleChange = (newTargetKeys) => {
    setSelectedUserGroup(() => newTargetKeys);
  };
  function handleSearch() {}

  function handleCreateNewGroup() {
    setUserGroupId(() => 'new');
    setShowGroupList(false);
    setSelectedUserGroup([]);
  }

  return (
    <Container>
      {showGroupList ? (
        <>
          <ActionsBar>
            <Button type="primary" onClick={handleCreateNewGroup}>
              New Group
            </Button>
          </ActionsBar>

          <Table dataSource={userGroupList} size="small" columns={columns} style={{ width: '50%' }} pagination={false} rowKey="id" />
        </>
      ) : (
        <>
          <ActionsBar>
            <Space>
              <Button type="primary" onClick={handleSaveUserGroup}>
                Save
              </Button>
              <Button type="primary" danger onClick={handleStartOver}>
                Cancel
              </Button>
            </Space>
          </ActionsBar>

          <Space direction="vertical">
            <InputContainer>
              <label htmlFor="groupName">Group name</label>
              <input
                autoComplete="off"
                type="text"
                name="groupName"
                id="groupName"
                value={userGroupName}
                onChange={(el) => setUserGroupName(el.target.value)}
                onBlur={(el) => setUserGroupName(el.target.value.trim())}
                placeholder="Group name"
                style={{ width: '400px' }}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="groupDescription">Group description</label>
              <input
                autoComplete="off"
                name="groupDescription"
                id="groupDescription"
                value={userGroupDescription}
                onChange={(el) => setUserGroupDescription(el.target.value)}
                onBlur={(el) => setUserGroupDescription(el.target.value.trim())}
                placeholder="Group description"
                style={{ width: '400px' }}
              />
            </InputContainer>

            <Transfer
              dataSource={allUserList}
              showSearch
              filterOption={filterOption}
              targetKeys={selectedUserGroup}
              onChange={handleChange}
              onSearch={handleSearch}
              render={(item) => item.title}
              listStyle={{
                width: 250,
                height: 700,
              }}
            />
          </Space>
        </>
      )}
    </Container>
  );
}
