/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { Button, Descriptions, Form, Input, Space } from 'antd';

import api from '~/services/api';

const { TextArea } = Input;

export default function GraphicCard({ itemInfo, itemDisabled }) {
  const [unitWhereGCIsInstalled, setUnitWhereGCIsInstalled] = useState(null);

  useEffect(() => {
    setUnitWhereGCIsInstalled(null);
  }, [itemInfo]); //eslint-disable-line

  const handleFindUnit = async () => {
    const unitData = await api.get(`inventory_items?ram_serial_number=${itemInfo.serial_number}`);

    if (unitData.data.length > 0) {
      setUnitWhereGCIsInstalled({ id: unitData.data[0].serial_number, serialNumber: unitData.data[0].serial_number });
    }
  };

  return (
    <Descriptions size="small" bordered>
      {(itemInfo?.status === 'InUse' || itemInfo?.status === 'Sold') && (
        <Descriptions.Item label="Being Used At" span={3} style={{ width: '400px' }}>
          <Space>
            <Button onClick={handleFindUnit}>Find unit</Button>
            {unitWhereGCIsInstalled !== null && (
              <Button type="link" target="_blank" rel="noopener noreferrer" href={`/inventoryProcessing/${unitWhereGCIsInstalled.id}`}>
                {unitWhereGCIsInstalled.serialNumber}
              </Button>
            )}
          </Space>
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Notes" span={3}>
        <Space>
          <Form.Item hasFeedback name="notes">
            <TextArea
              showCount
              maxLength={255}
              allowClear
              style={{ width: 500 }}
              placeholder="Add additional information about this item"
              rows={4}
              disabled={itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
}
