/* eslint-disable react/prop-types */
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Space, Spin } from 'antd';
import React from 'react';
import { FaCalculator } from 'react-icons/fa';

export default function ConfigureShipment({
  warehouses,
  warehouse,
  lbWeight,
  setLbWeight,
  ozWeight,
  handleOzChange,
  serviceCode,
  packageCode,
  setPackageCode,
  recipient,
  length,
  setLength,
  width,
  setWidth,
  height,
  setHeight,
  confirmationCode,
  setConfirmationCode,
  labelForOrderInfo,
  handleChangeWarehouse,
  handleSetGetRatesVisible,
  handleOzBlur,
  handleChangeService,
  handleGenerateLabel,
  carriersAndServices,
  loadingRate,
}) {
  const { Option, OptGroup } = Select;
  return (
    <>
      <Space align="top">
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 17,
          }}
          // layout="horizontal"
          // initialValues={{
          //   size: componentSize,
          // }}
          // onValuesChange={onFormLayoutChange}
          size="small"
        >
          <Form.Item label="Ship From">
            <Space>
              <Select options={warehouses} value={warehouse} onChange={handleChangeWarehouse} style={{ width: 300 }} />
              <Button style={{ alignmentBaseline: 'central' }} type="primary" icon={<FaCalculator />} size="small" onClick={handleSetGetRatesVisible} />
            </Space>
          </Form.Item>
          <Form.Item label="Weight">
            <Input.Group compact>
              <Form.Item noStyle rules={[{ required: true, message: 'Weight is required' }]}>
                <InputNumber
                  // ref={lbInputRef}
                  value={lbWeight}
                  onChange={e => setLbWeight(e)}
                  min={0}
                  style={{ width: '30%' }}
                  placeholder="lb"
                />
              </Form.Item>
              <Form.Item noStyle rules={[{ required: true, message: 'Weight is required' }]}>
                <InputNumber
                  // ref={ozInputRef}
                  min={0}
                  // max={15}
                  style={{ width: '30%' }}
                  placeholder="oz"
                  onBlur={e => handleOzBlur(e.currentTarget.value)}
                  value={ozWeight}
                  onChange={handleOzChange}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item label="Service">
            <Select style={{ width: 300 }} value={serviceCode} onChange={serviceId => handleChangeService(serviceId)}>
                    {carriersAndServices.map(carrier => { //eslint-disable-line
                // if (carrier.services.length > 0) {
                if (recipient.country === 'CA' && carrier.services.filter(service => service.domestic === true).length > 0) {
                  return (
                    <OptGroup key={carrier.code} label={carrier.name}>
                      {carrier.services
                        .filter(service => service.domestic === true)
                        .map(service => (
                          <Option key={service.code} value={service.code}>
                            {service.name}
                          </Option>
                        ))}
                    </OptGroup>
                  );
                }
                if (
                  recipient.country !== 'CA' &&
                  carrier.services.filter(service => service.international === true && service.carrierCode !== 'purolator_ca').length > 0
                ) {
                  return (
                    <OptGroup key={carrier.code} label={carrier.name}>
                      {carrier.services
                        .filter(service => service.international === true)
                        .map(service => (
                          <Option key={service.code} value={service.code}>
                            {service.name}
                          </Option>
                        ))}
                    </OptGroup>
                  );
                }
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Package">
            <Select value={packageCode} onChange={packageId => setPackageCode(packageId)}>
              <Select.Option value="package">Package</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Size">
            <Input.Group compact>
              <Form.Item noStyle rules={[{ required: true, message: 'Size is required' }]}>
                <InputNumber min={0} style={{ width: '30%' }} placeholder="L" value={length} onChange={e => setLength(e)} />
              </Form.Item>
              <Form.Item noStyle rules={[{ required: true, message: 'Size is required' }]}>
                <InputNumber min={0} style={{ width: '30%' }} placeholder="W" value={width} onChange={e => setWidth(e)} />
              </Form.Item>
              <Form.Item noStyle rules={[{ required: true, message: 'Size is required' }]}>
                <InputNumber min={0} style={{ width: '30%' }} placeholder="H" value={height} onChange={e => setHeight(e)} />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item label="Confirmation">
            <Select value={confirmationCode} onChange={confirmationId => setConfirmationCode(confirmationId)}>
              <Select.Option value="none">None</Select.Option>
              <Select.Option value="signature">Electronic Signature</Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item label="Insurance">
                  <Select defaultValue="None" value={insuranceCode} onChange={insuranceId => setInsuranceCode(insuranceId)}>
                    <Select.Option value="None">None</Select.Option>
                    <Select.Option value="Shipinsurance">Shipinsurance</Select.Option>
                    <Select.Option value="Carrier">Carrier</Select.Option>
                    <Select.Option value="External">External</Select.Option>
                  </Select>
                </Form.Item> */}
        </Form>
      </Space>
      {loadingRate ? (
        <Spin />
      ) : (
        <Space
          size="small"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#6ba03a', fontSize: '16px', fontWeight: 'bold' }}
        >
          <div>
            Rate
            <br />
            {labelForOrderInfo.total || '--'}
          </div>
          {/* <Button type="primary" size="small" onClick={handleSetGetRatesVisible}>
                <CalculatorOutlined /> Get Rates
              </Button> */}
          {/* <Button style={{ alignmentBaseline: 'central' }} type="primary" icon={<FaCalculator />} size="small" onClick={handleSetGetRatesVisible} /> */}
          <Button type="primary" icon={<PrinterOutlined />} size="small" onClick={handleGenerateLabel}>
            Generate Label
          </Button>
        </Space>
      )}
    </>
  );
}
