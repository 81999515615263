/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button, Row, Col } from 'antd';

import printJS from 'print-js';

export default function PrintLabel({ visiblePrintLabel, handleVisiblePrintLabel, labelData }) {
  function handlePrintLabel() {
    printJS({ printable: Buffer.from(labelData), type: 'pdf', base64: true });
  }

  return (
    <Modal
      title="Print Preview"
      // okText="Print Label"
      centered
      keyboard
      visible={visiblePrintLabel}
      // onOk={() => handleVisiblePrintLabel(false)}
      onCancel={() => handleVisiblePrintLabel(false)}
      width="1200px"
      bodyStyle={{ height: '700px' }}
      footer={[
        // <Button key="1" danger type="primary" onClick={handleVoidLabel}>
        //   Void Label
        // </Button>,
        <Button key="2" onClick={() => handleVisiblePrintLabel(false)}>
          Close
        </Button>,
        <Button key="3" type="primary" onClick={handlePrintLabel}>
          Print Label
        </Button>,
      ]}
    >
      <Row>
        <Col span={18} style={{ backgroundColor: 'red', height: '100%' }}>
          <iframe
            frameBorder="0"
            style={{
              display: 'block',
              width: '100%',
              height: '670px',
              // maxHeight: 'calc(700px * .5625)',
              border: 'none',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            height="100%"
            width="100%"
            id="inlineFrameExample"
            title="Inline Frame Example"
            // width="1024"
            // height="768"
            // src={`data:application/pdf;base64,${label}`}
            src={`data:application/pdf;base64,${Buffer.from(labelData)}`}
            // src="https://www.ruggedbooksms.com"
          />
        </Col>
        {/* <Col span={6}>col-12</Col> */}
      </Row>
    </Modal>
  );
}
