import React from 'react';

import { Tabs } from 'antd';

import { OrderOnTime } from './OrderOnTime';
import { RMA } from './RMA';
import { RMAWithinADay } from './RMAWithinADay';

export function KPI() {
  const items = [
    {
      label: 'RMA',
      key: 'rma',
      children: <RMA />,
    },
    {
      label: 'Orders',
      key: 'orders',
      children: <OrderOnTime />,
    },
    {
      label: 'RMA On Time',
      key: 'rmaOnTime',
      children: <RMAWithinADay />,
    },
  ];

  return <Tabs items={items} type="card" size="small" />;
}
