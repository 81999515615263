/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Space, Input, Card, Table, Empty, Pagination, Button, Typography, Select, Tag } from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { Container } from './styles';
import api from '~/services/api';
import history from '~/services/history';

export default function Storage(props) {
  const [storages, setStorages] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const [filterType, setFilterType] = useState('');
  const [filterFormFactor, setFilterFormFactor] = useState('');
  const [filterController, setFilterController] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);

  // const [isFiltered, setIsFiltered] = useState(false);
  const [paginationSize, setPaginationSize] = useState(new URLSearchParams(props.location.search).get('size') || 25);
  const [paginationPage, setPaginationPage] = useState(new URLSearchParams(props.location.search).get('page') || 1);
  const [paginationTotalItems, setPaginationTotalItems] = useState();

  const searchTextRef = useRef('');

  const { Search } = Input;
  const { Title } = Typography;
  const { Option } = Select;

  const handleSearch = useCallback(
    async value => {
      const response = await api.get(`storagesList?size=${paginationSize}&page=${paginationPage - 1}`, {
        headers: {
          filterText: value?.trim() || searchTextRef.current.input.value || '',
          filterType: filterType || '',
          filterFormFactor: filterFormFactor || '',
          filterController: filterController || '',
        },
      });
      if (response.data.error) {
        toast.warn('Technician has not been found!');
        return;
      }
      setPaginationTotalItems(response.data.totalItems);
      setPaginationSize(response.data.sizePage);
      setPaginationPage(response.data.currentPage + 1);
      setStorages(response.data.rows);
    },
    [filterController, filterFormFactor, filterType, paginationPage, paginationSize]
  );

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    if (searchQuery) {
      searchTextRef.current.input.value = searchQuery;
      handleSearch();
    }
  }, []); //eslint-disable-line

  const handleClearFilters = useCallback(() => {
    setIsFiltered(false);
    setFilterType('');
    setFilterFormFactor('');
    setFilterController('');
  }, []);

  function handleChangePage(page, pageSize) {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  }

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const columns = [
    {
      title: 'Action',
      dataIndex: 'index',
      render: (text, row) => (
        <Link to={`/storageNew?id=${row.id}&searchQuery=${searchTextRef.current.input.value}&size=${paginationSize}&page=${paginationPage}`}>Edit</Link>
      ),
      width: 100,
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      width: 100,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: 100,
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Form Factor',
      dataIndex: 'form_factor',
    },
    {
      title: 'Controller',
      dataIndex: 'controller',
    },
    {
      title: 'Speed (HDD)',
      dataIndex: 'speed',
      render: text => (text === null ? '' : text),
    },
  ];

  return (
    <Container>
      <Card size="small" bordered={false}>
        {/* <h1>Storage</h1> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {profile.type === 3 || profile.type === 999 ? (
            <Button onClick={() => history.push('/storageNew')} variant="contained" type="primary" icon={<PlusOutlined />}>
              Add
            </Button>
          ) : (
            <div />
          )}
          <Title level={3}>Storages</Title>
          <div />
        </div>
      </Card>
      <Card elevation={3} size="small">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            <Search
              placeholder="Search Storage Capacity"
              ref={searchTextRef}
              allowClear
              onSearch={() => {
                handleSearch();
                // setPaginationPage(1);
              }}
              style={{ width: 300 }}
              enterButton
            />
          </Space>

          <Space wrap>
            {isFiltered && (
              <Tag
                closable
                color="error"
                onClose={e => {
                  e.preventDefault();
                  handleClearFilters();
                }}
              >
                Clear Filters
              </Tag>
            )}

            <Select
              onChange={value => {
                setFilterType(value);
                // setPaginationPage(1);
                setIsFiltered(true);
                handleSearch();
              }}
              value={filterType}
              style={{ width: 140 }}
            >
              <Option key="">All Types</Option>
              <Option key="HDD">HDD</Option>
              <Option key="SSD">SSD</Option>
            </Select>

            <Select
              onChange={value => {
                setFilterFormFactor(value);
                // setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterFormFactor}
              style={{ width: 160 }}
            >
              <Option key="">All Form Factors</Option>
              <Option key="2.5">2.5</Option>
              <Option key="3.5">3.5</Option>
              <Option key="mSATA">mSATA</Option>
              <Option key="M.2">M.2</Option>
              <Option key="Memory Card">Memory Card</Option>
            </Select>

            <Select
              onChange={value => {
                setFilterController(value);
                // setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterController}
              style={{ width: 160 }}
            >
              <Option key="">All Controllers</Option>
              <Option key="SATA">SATA</Option>
              <Option key="SAS">SAS</Option>
              <Option key="NVMe">NVMe</Option>
              <Option key="PCIe">PCIe</Option>
              <Option key="PCIe_SATA">PCIe_SATA</Option>
              <Option key="NVMe_PCIe">NVMe_PCIe</Option>
              <Option key="Flash">Flash</Option>
            </Select>
          </Space>
        </div>
      </Card>

      {storages.length <= 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Use the search box above to list storages</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      ) : (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={storages}
            size="small"
            bordered
            pagination={false}
            scroll={{ y: 'calc(100vh - 22em)' }}
            rowKey={record => record.id}
          />
          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}
