/* eslint-disable func-names */
import { Line } from '@ant-design/charts';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DatePicker, Divider, Form, Space, Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import api from '~/services/api';

const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export function Sales() {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [reportByProduct, setReportByProduct] = useState([]);
  const [reportByStore, setReportByStore] = useState({ data: [] });
  const [reportByProductBySpecs, setReportByProductBySpecs] = useState({ data: [] });
  const [reportByStoreBySpecs, setReportByStoreBySpecs] = useState({ data: [] });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentProduct, setCurrentProduct] = useState('');
  const [dataAmazon, setDataAmazon] = useState([]);
  const [dataNewEgg, setDataNewEgg] = useState([]);
  const [dataBestBuy, setDataBestBuy] = useState([]);
  // const [dataBigCommerce, setDataBigCommerce] = useState([]);
  const [dataShopify, setDataShopify] = useState([]);
  const [dataEbay, setDataEbay] = useState([]);

  useEffect(() => {
    async function loadData() {
      const totalSalesByPeriodAmazon = await api.put('/report/TotalSalesByPeriodAndStore', { store_id: '1, 2, 3' });
      const totalSalesByPeriodNewEgg = await api.put('/report/TotalSalesByPeriodAndStore', { store_id: '6, 7, 8' });
      const totalSalesByPeriodBestBuy = await api.put('/report/TotalSalesByPeriodAndStore', { store_id: '12' });
      // const totalSalesByPeriodBigCommerce = await api.put('/report/TotalSalesByPeriodAndStore', { store_id: '4' });
      const totalSalesByPeriodShopify = await api.put('/report/TotalSalesByPeriodAndStore', { store_id: '16' });
      const totalSalesByPeriodEbay = await api.put('/report/TotalSalesByPeriodAndStore', { store_id: '10' });

      setDataAmazon(totalSalesByPeriodAmazon.data);
      setDataNewEgg(totalSalesByPeriodNewEgg.data);
      setDataBestBuy(totalSalesByPeriodBestBuy.data);
      // setDataBigCommerce(totalSalesByPeriodBigCommerce.data);
      setDataShopify(totalSalesByPeriodShopify.data);
      setDataEbay(totalSalesByPeriodEbay.data);
    }
    loadData();
  }, []);

  const configAmazon = {
    data: dataAmazon,
    xField: 'period',
    yField: 'value',
    seriesField: 'store',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));
        },
      },
    },
    legend: { position: 'top' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  const configNewEgg = {
    data: dataNewEgg,
    xField: 'period',
    yField: 'value',
    seriesField: 'store',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));
        },
      },
    },
    legend: { position: 'top' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  const configBestBuy = {
    data: dataBestBuy,
    xField: 'period',
    yField: 'value',
    seriesField: 'store',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));
        },
      },
    },
    legend: { position: 'top' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  // const configBigCommerce = {
  //   data: dataBigCommerce,
  //   xField: 'period',
  //   yField: 'value',
  //   seriesField: 'store',
  //   yAxis: {
  //     label: {
  //       formatter: function formatter(v) {
  //         return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));
  //       },
  //     },
  //   },
  //   legend: { position: 'top' },
  //   smooth: true,
  //   animation: {
  //     appear: {
  //       animation: 'path-in',
  //       duration: 5000,
  //     },
  //   },
  // };

  const configShopify = {
    data: dataShopify,
    xField: 'period',
    yField: 'value',
    seriesField: 'store',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));
        },
      },
    },
    legend: { position: 'top' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  const configEbay = {
    data: dataEbay,
    xField: 'period',
    yField: 'value',
    seriesField: 'store',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));
        },
      },
    },
    legend: { position: 'top' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  const onFinish = async (values) => {
    const parsedStartDate = moment(values.period[0]).format('YYYY-MM-DD');
    const parsedEndDate = moment(values.period[1]).format('YYYY-MM-DD');

    setReportByProduct([]);
    setReportByStore([]);
    setReportByProductBySpecs([]);
    setReportByStoreBySpecs([]);

    const reportData = await api.put('/report/SalesByPeriod', { startDate: parsedStartDate, endDate: parsedEndDate });

    setReportByProduct(reportData.data);

    setStartDate(parsedStartDate);
    setEndDate(parsedEndDate);
  };

  const showReportByStore = useCallback(
    async (productName) => {
      setCurrentProduct(productName);

      setReportByStore([]);
      setReportByProductBySpecs([]);
      setReportByStoreBySpecs([]);

      const reportDataProduct = await api.put('/report/SalesByProduct', { startDate, endDate, productName });
      setReportByStore({ productName, data: reportDataProduct.data });

      const reportDataProductBySpec = await api.put('/report/SalesByProductBySpec', { startDate, endDate, productName });
      setReportByProductBySpecs({
        productName,
        data: reportDataProductBySpec.data.map((el, idx) => {
          el.key = idx;
          return el;
        }),
      });
    },
    [endDate, startDate]
  );

  const showReportByStoreBySpec = useCallback(
    async (storeName) => {
      const reportDataByStoreBySpec = await api.put('/report/SalesByStoreBySpec', { startDate, endDate, productName: currentProduct, storeName });
      setReportByStoreBySpecs({
        storeName,
        productName: currentProduct,
        data: reportDataByStoreBySpec.data.map((el, idx) => {
          el.key = idx;
          return el;
        }),
      });
    },
    [currentProduct, endDate, startDate]
  );

  const columnsReportByProduct = [
    {
      title: 'Product',
      dataIndex: 'name',
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (text) => (
        <Space size="small">
          <Button onClick={() => showReportByStore(text)} type="link">
            {text}
          </Button>
        </Space>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: 'Sales US$',
      dataIndex: 'amount_sales_us',
      sorter: (a, b) => a.amount_sales_us - b.amount_sales_us,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
    {
      title: 'Sales CAD$',
      dataIndex: 'amount_sales_cad',
      sorter: (a, b) => a.amount_sales_cad - b.amount_sales_cad,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(text),
    },
    {
      title: 'Sales MXN$',
      dataIndex: 'amount_sales_mxn',
      sorter: (a, b) => a.amount_sales_mxn - b.amount_sales_mxn,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MXN' }).format(text),
    },
    {
      title: 'Total converted to US$',
      dataIndex: 'total_amount_converted_us',
      sorter: (a, b) => a.total_amount_converted_us - b.total_amount_converted_us,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
    {
      title: 'ASP',
      dataIndex: 'ASP',
      sorter: (a, b) => a.ASP - b.ASP,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
  ];

  const columnsReportByBySpecs = [
    {
      title: 'RAM',
      dataIndex: 'ram',
      sorter: (a, b) => a.ram?.localeCompare(b.ram),
    },
    {
      title: 'RAM 2',
      dataIndex: 'ram_2',
      sorter: (a, b) => a.ram_2?.localeCompare(b.ram_2),
    },
    {
      title: 'CPU',
      dataIndex: 'number',
      sorter: (a, b) => a.number?.localeCompare(b.number),
    },
    {
      title: 'Storage',
      dataIndex: 'storage',
      sorter: (a, b) => a.storage?.localeCompare(b.storage),
    },
    {
      title: 'Storage 2',
      dataIndex: 'storage_2',
      sorter: (a, b) => a.storage_2?.localeCompare(b.storage_2),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: 'Total converted to US$',
      dataIndex: 'total_amount_converted_us',
      sorter: (a, b) => a.total_amount_converted_us - b.total_amount_converted_us,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
    {
      title: 'ASP',
      dataIndex: 'ASP',
      sorter: (a, b) => a.ASP - b.ASP,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
  ];

  const columnsReportByStore = [
    {
      title: 'Store',
      dataIndex: 'name',
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (text) => (
        <Space size="small">
          <Button onClick={() => showReportByStoreBySpec(text)} href="#stores" type="link">
            {text}
          </Button>
        </Space>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: 'Total converted to US$',
      dataIndex: 'total_amount_converted_us',
      sorter: (a, b) => a.total_amount_converted_us - b.total_amount_converted_us,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
    {
      title: 'ASP',
      dataIndex: 'ASP',
      sorter: (a, b) => a.ASP - b.ASP,
      render: (text) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text),
    },
  ];
  return (
    <>
      <Paper className={classes.paper}>
        <h1>
          Sales Report | By Item, Store and Configuration | Parameters
          {/* Inventory Report Parameters <InfoCircleOutlined onClick={info} /> */}
        </h1>
        {/* <div>
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            Manual orders are not considered on this report due to price discrepancy
          </Tag>
        </div>
        <br />
        <div>
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            FBA orders are not considered yet. They will be available when Amazon integration is implemented.
          </Tag>
        </div> */}
        <br />
        <Form form={form} name="salesReport" onFinish={onFinish}>
          <Form.Item name="period" label="Period" rules={[{ required: true }]}>
            <RangePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Space direction="vertical">
            <Space size="middle">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
              <Form.Item>
                {/* <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button> */}
              </Form.Item>
            </Space>
          </Space>
        </Form>
        {reportByProduct.length > 0 && (
          <Table
            size="small"
            bordered
            rowKey={(record) => record.name}
            columns={columnsReportByProduct}
            dataSource={reportByProduct}
            pagination={{ pageSize: 5 }}
          />
        )}

        {reportByProductBySpecs.data?.length > 0 && (
          <Table
            title={() => <b>Sales By Specifications of: {reportByProductBySpecs.productName} </b>}
            size="small"
            bordered
            rowKey={(record) => record.key}
            columns={columnsReportByBySpecs}
            dataSource={reportByProductBySpecs.data}
            pagination={false}
          />
        )}

        {reportByStore.data?.length > 0 && (
          <>
            <Divider />
            <Table
              title={() => <b>Sales By Store of: {reportByStore.productName} </b>}
              size="small"
              bordered
              rowKey={(record) => record.name}
              columns={columnsReportByStore}
              dataSource={reportByStore.data}
              pagination={false}
            />
          </>
        )}

        {reportByStoreBySpecs.data?.length > 0 && (
          <>
            <Divider />
            <Table
              title={() => (
                <b>
                  Sales By Specifications of {reportByStoreBySpecs.productName} at {reportByStoreBySpecs.storeName}
                </b>
              )}
              size="small"
              bordered
              rowKey={(record) => record.key}
              columns={columnsReportByBySpecs}
              dataSource={reportByStoreBySpecs.data}
              pagination={false}
            />
          </>
        )}
      </Paper>
      <Divider />
      <Paper className={classes.paper}>
        <h1>Sales Chart - Amazon</h1>
        {/* <div>
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            FBA orders are not considered yet. They will be available when Amazon integration is implemented.
          </Tag>
        </div> */}
        <Line {...configAmazon} />
      </Paper>
      <Divider />
      <Paper className={classes.paper}>
        <h1>Sales Chart - NewEgg</h1>
        <Line {...configNewEgg} />
      </Paper>

      <Divider />

      {/* <Paper className={classes.paper}>
        <h1>Sales Chart - BigCommerce</h1>
        <Line {...configBigCommerce} />
      </Paper> */}
      <Paper className={classes.paper}>
        <h1>Sales Chart - Shopify</h1>
        <Line {...configShopify} />
      </Paper>

      <Divider />

      <Paper className={classes.paper}>
        <h1>Sales Chart - Best Buy</h1>
        <Line {...configBestBuy} />
      </Paper>
      <Divider />
      <Paper className={classes.paper}>
        <h1>Sales Chart - Ebay</h1>
        <Line {...configEbay} />
      </Paper>
    </>
  );
}
