/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { toast } from 'react-toastify';
import api from '~/services/api';
import { StyledButton, TransactionContainer, TransactionTable } from './styles';

export default function NewHistory({ inventoryItemsId }) {
  const [historyInfo, setHistoryInfo] = useState({ inventoryHistoryData: [], rows: [] });

  const loadHistoryInfo = async () => {
    try {
      const historyInfoData = await api.get(`/inventory_history_log/${inventoryItemsId}`);
      setHistoryInfo(historyInfoData.data);
    } catch (error) {
      toast.error(error.result.data.message);
    }
  };

  return (
    <>
      <StyledButton type="button" onClick={() => loadHistoryInfo()}>
        Generate History Log
      </StyledButton>
      {historyInfo.inventoryHistoryData.length > 0 && (
        <TransactionContainer>
          <TransactionTable>
            <thead>
              <tr key="action">
                <th>Action</th>
                {historyInfo.inventoryHistoryData.map((item) => (
                  <th key={item.action}>{item.action}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {historyInfo.rows.map((el) => (
                <tr key={el}>
                  <td>{el}</td>
                  {historyInfo.inventoryHistoryData.map((item) => {
                    if (typeof item[el] === 'object' && item[el] !== null) {
                      const value = item[el][Object.keys(item[el])[0]];
                      return <td key={item.id}>{value}</td>;
                    }
                    if (item[el] === undefined && item.inventory_history_log_items.length > 0) {
                      return <td key={item.id}>{item.inventory_history_log_items.find((ihli) => ihli.field === el)?.new_info || '-'}</td>;
                    }
                    if (item[el] === undefined && item.inventory_items_log) {
                      return <td key={item.id}>{item.inventory_items_log[el]}</td>;
                    }
                    return <td key={item.id}>{item[el] || '-'}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </TransactionTable>
        </TransactionContainer>
      )}
    </>
  );
}
