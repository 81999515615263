import produce from 'immer';

const INITIAL_STATE = {
  currentCount: 0,
  loading: false,
};

export default function itemsUnderMyNameNotification(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@get/CURRENT_COUNT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@get/CURRENT_COUNT_SUCCESS': {
        draft.currentCount = action.payload.currentCount;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
