/* eslint-disable no-nested-ternary */
import React from 'react';

import { Info, Warning, XCircle } from 'phosphor-react';
import PropTypes from 'prop-types';

import { AlertContainer, AlertParagraph, IconBefore } from './styles';

function Alert({ message, variant }) {
  return (
    <AlertContainer variant={variant}>
      <IconBefore variant={variant}>
        {variant === 'info' ? <Info size="20" /> : variant === 'danger' ? <XCircle size="20" /> : <Warning size="20" />}
      </IconBefore>
      <AlertParagraph>{message}</AlertParagraph>
    </AlertContainer>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
};

Alert.defaultProps = {
  message: '',
  variant: 'info',
};

export default Alert;
