/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button, Row, Col } from 'antd';

import printJS from 'print-js';

export default function PrintCustomsForm({ visibleCustomsForm, handleVisibleCustomsForm, formData }) {
  function handlePrintCustomsForm() {
    printJS({ printable: Buffer.from(formData), type: 'pdf', base64: true });
  }

  return (
    <Modal
      title="Print Preview"
      // okText="Print Label"
      centered
      keyboard
      visible={visibleCustomsForm}
      // onOk={() => handleVisiblePrintLabel(false)}
      onCancel={() => handleVisibleCustomsForm(false)}
      width="1200px"
      bodyStyle={{ height: '700px' }}
      footer={[
        // <Button key="1" danger type="primary" onClick={handleVoidLabel}>
        //   Void Label
        // </Button>,
        <Button key="2" onClick={() => handleVisibleCustomsForm(false)}>
          Close
        </Button>,
        <Button key="3" type="primary" onClick={handlePrintCustomsForm}>
          Print Customs Form
        </Button>,
      ]}
    >
      <Row>
        <Col span={18} style={{ backgroundColor: 'red', height: '100%' }}>
          <iframe
            id="iframe"
            frameBorder="0"
            style={{
              display: 'block',
              width: '100%',
              height: '670px',
              border: 'none',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            height="100%"
            width="100%"
            title="Inline Frame PDF"
            src={`data:application/pdf;base64,${Buffer.from(formData)}`}
          />
        </Col>
        {/* <Col span={6}>col-12</Col> */}
      </Row>
    </Modal>
  );
}
