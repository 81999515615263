/* eslint-disable react/prop-types */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import { Alert, Col, Row, Space, Spin, Typography } from 'antd';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Container, SearchButton } from './styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Inventory({ location }) {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchTxt, setSearchTxt] = useState('');

  const { Paragraph } = Typography;

  const searchInputRef = useRef('');

  const onSearch = async (value) => {
    try {
      if (value?.length < 2) {
        toast.warn('The search must have at least 2 characters');
        return;
      }
      setLoading(true);
      // const response = await api.get(`inventory?searchQuery=${value}&type=part&zeroQty=true`);
      const response = await api.get(`inventoryOfParts?searchQuery=${value}`);
      setLoading(false);

      if (response.data.length < 1) {
        toast.error('There are no parts matching the search criteria.');
        return;
      }

      setSearchTxt(value);
      setItems(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    searchInputRef.current.value = searchQuery;
    if (searchQuery !== null && searchQuery !== undefined) {
      onSearch(searchQuery);
    }
  }, [location]);

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'item.id',
      label: 'itemId',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'item.brand.name',
      label: 'Brand',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'item.category.name',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'item.subcategory.name',
      label: 'Subcategory',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'item.part_number',
      label: 'Part Number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <Link
            to={() => {
              const id = tableMeta.tableData.find((el) => el[5] === value || el?.item?.part_number === value);
              if (searchTxt === null || searchTxt === undefined) {
                return `inventoryPartsEdit?id=${id[0] || id.id}`;
              }
              return `inventoryPartsEdit?id=${id[0] || id.id}&searchQuery=${searchTxt}`;
            }}
          >
            <Paragraph style={{ color: '#1890ff' }} copyable>
              {value}
            </Paragraph>
          </Link>
        ),
      },
    },
    {
      name: 'item.description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'item.name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'quantity',
      label: 'Qty',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    fixedHeaderOptions: { xAxis: true, yAxis: true },
    filterType: 'dropdown',
    expandableRowsOnClick: false,
    pagination: true,
    download: true,
    print: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 150],
    checkboxSelection: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    search: false,

    customToolbarSelect: () => {},
  };

  const handleOnKeyDownSearch = (element) => {
    if (element.key !== 'Enter') {
      return;
    }

    const inventorySearch = element.target.value;
    onSearch(inventorySearch);
  };

  const handleSearchButton = () => {
    if (searchInputRef.current.value === '') {
      toast.error('Please enter a serial number.');
      return;
    }
    onSearch(searchInputRef.current.value);
  };

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Link to="/inventoryPartsNew">
            <Button variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}>
              Add
            </Button>
          </Link>

          <Link to="/inventoryPartsBulkImport">
            <Button variant="contained" color="primary" className={classes.button} startIcon={<PublishIcon />}>
              Bulk Import
            </Button>
          </Link>
        </Col>
      </Row>

      <Paper elevation={3}>
        <Row justify="start" gutter={[32, 32]}>
          <Col>
            <Space align="center" style={{ margin: '15px' }} size={0}>
              <input ref={searchInputRef} placeholder="Search for a part number or description" onKeyDown={handleOnKeyDownSearch} />
              <SearchButton type="button" onClick={handleSearchButton}>
                <SearchOutlined />
              </SearchButton>
            </Space>
          </Col>
        </Row>
      </Paper>

      {items.length > 0 && (
        <Row justify="start" gutter={[32, 32]} wrap style={{ marginTop: '10px' }}>
          <Col span={24}>
            {loading ? (
              <Spin tip="Loading...">
                <Alert
                  message="Search is looking for the items. Hold on a second"
                  description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
                  type="info"
                />
              </Spin>
            ) : (
              <MUIDataTable title="Inventory of Parts (not controlled by S/N ONLY)" data={items} columns={columns} options={options} />
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
}
