import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

import { DebounceSelect } from '~/components/DebounceSelect';
import InputText from '~/components/InputText';
import { fetchItems } from '~/pages/Inventory/Processing/utils/fetchItems';
import api from '~/services/api';
import history from '~/services/history';

import ReportFields from './ReportFields';
import VirtualTable from './VirtualTable';

const { confirm } = Modal;

export default function Devices({ form, profile, receivingInfo, receivingItemInfo, setReceivingItem, setHasItemReceived }) {
  const { Link, Title } = Typography;
  const { TabPane } = Tabs;

  const [purchaseOrderItemsOptions, setPurchaseOrderItemsOptions] = useState([]);

  // Table/List States
  const [inventoryItems, setInventoryItems] = useState([]);
  const serialNumberRef = useRef('');
  // Hard Drive States
  const [hasHD, setHasHD] = useState(true);

  // Info States
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const [unitsReceived, setUnitsReceived] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [completeFlag, setCompleteFlag] = useState(0);
  const [receivingPartsFlag, setReceivingPartsFlag] = useState(false);
  const [addedItemsFlag, setAddedItemsFlag] = useState(false);
  const [receivingPartsTab, setReceivingPartsTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const [transferringItems, setTransferringItems] = useState(false);
  const [loadingRemoval, setLoadingRemoval] = useState(false);
  const [receivingParts, setReceivingParts] = useState([]);
  const [purchaseOrderItem, setPurchaseOrderItem] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warehouseLocationList, setWarehouseLocationList] = useState([]);
  const [warehouseWorkersIdList, setWarehouseWorkersIdList] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [pOItemId, setPOItemId] = useState(null); // state just to show/hide the Tabs
  const [itemReceivedPartNumber, setItemReceivedPartNumber] = useState(null);

  useEffect(() => {
    const HAS_RECEIVED_AT_LEAST_ONE = inventoryItems.length > 0;
    setHasItemReceived(() => HAS_RECEIVED_AT_LEAST_ONE);
  }, [inventoryItems, setHasItemReceived]);

  useEffect(() => {
    const receiving_item_id = receivingItemInfo.id;

    async function loadDropdownboxes() {
      const warehouseUsers = await api.get('/location-user-permission/listUsersInGroup/1');
      setWarehouseWorkersIdList(warehouseUsers.data.users);

      const inventory_log = await api.get(`inventoryLog?receivingItemId=${receivingItemInfo.id}`);

      if (inventory_log.data.length > 0) {
        setReceivingParts(inventory_log.data);
        setAddedItemsFlag(true);
        setReceivingPartsTab('2');
      }

      const unitsTotal = +receivingItemInfo.quantity;
      setTotalUnits(unitsTotal);

      form.setFieldsValue({ purchaseOrderItemId: receivingItemInfo.purchase_order_item_id });
      setPOItemId(receivingItemInfo.purchase_order_item_id);

      setPurchaseOrderNumber(receivingInfo.purchase_order);

      // --------------------------------------------------------------------
      //  SET DROPDOWN LIST OF PURCHASE ORDER ITEMS
      // --------------------------------------------------------------------
      const purchaseOrderInfo = await api.get(`purchaseOrder/${+receivingInfo.purchase_order}`);

      if (purchaseOrderInfo.data.error) {
        setPurchaseOrderNumber('-');
      } else {
        setPurchaseOrderItemsOptions(
          purchaseOrderInfo.data.purchase_order_items.map((item) => ({ label: `${item.name} - ${item.description}`, value: item.id }))
        );
        setPurchaseOrderItem(purchaseOrderInfo.data.purchase_order_items);
        setPurchaseOrder(purchaseOrderInfo.data);
      }

      // --------------------------------------------------------------------
      //  SET RELATED INVENTORY ITEMS TO THE RECEIVING ITEM
      // --------------------------------------------------------------------
      const inventoryItemsData = await api.get(`inventory_items/${receiving_item_id}/inventory`);
      setInventoryItems(inventoryItemsData.data);
      if (receivingItemInfo.status === 'complete_receiving_parts') {
        setReceivingPartsFlag(true);
        setAddedItemsFlag(true);
      }
      if (inventoryItemsData.data.length > 0) {
        setReceivingPartsFlag(true);
      }

      const receivedUnits = inventoryItemsData.data.length;

      setUnitsReceived(receivedUnits);
      const unitsToReceive = unitsTotal - receivedUnits;
      if (unitsToReceive === 0 || unitsToReceive < 0) {
        setCompleteFlag(false);
      } else {
        setCompleteFlag(true);
      }

      setLoading(false);
    }

    loadDropdownboxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleRemoveItem(record) {
    setLoadingRemoval(true);
    const response = await api.delete(`/receivingItems/${record.id}`);
    if (response.data.ok) {
      setInventoryItems((state) => state.filter((el) => el.serial_number !== record.serial_number));
    } else {
      toast.error(response.data.error);
    }
    setLoadingRemoval(false);
  }

  const columnsInventoryItems = [
    {
      title: '#',
      dataIndex: 'index',
      render: (_text, _row, index) => inventoryItems.length - index,
    },
    {
      title: 'Item Part Number',
      dataIndex: ['item', 'part_number'],
      width: 150,
    },
    {
      title: 'CPU',
      dataIndex: ['item', 'cpu', 'name'],
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: 200,
      render: (text, record) => (
        <a rel="noopener noreferrer" target="_blank" href={`/inventoryProcessing/${record.id}`}>
          {text}
        </a>
      ),
    },
    {
      title: 'Received Date',
      dataIndex: 'created_at',
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    {
      title: 'Received By',
      dataIndex: ['users', 'name'],
    },
    {
      title: 'POST',
      dataIndex: 'post_result',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
    },
    {
      title: 'Current Status',
      dataIndex: 'status',
    },
    {
      title: 'Current Location',
      dataIndex: ['locations', 'name'],
      render: (text, row) => {
        if (text === 'Tech') return `${text} ${row.usersLocation?.name || ''}`;
        return text;
      },
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_text, record) => {
        if (profile.id === record.users.id && moment.duration(moment().diff(record.created_at)).asHours() < 1) {
          return (
            <Popconfirm
              onConfirm={() => handleRemoveItem(record)}
              title="Are you sure you want to delete?"
              trigger="click"
              placement="left"
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className="button-error pure-button">
                Remove
              </button>
            </Popconfirm>
          );
        }
        return '';
      },
    },
  ];

  async function addItemToInventory(values, isNew, serialNumber) {
    try {
      if (!isNew) {
        const newInventoryItem = {
          branch_id: 1,
          post_result: values.postResult,
          status: 'ToBeInspected',
          condition: '',
          grade: 'TBI',
          received_by: profile.id,
          item_id: values.itemId,
          serial_number: serialNumber,
          part_number: itemReceivedPartNumber,
          location_id: 219,
          user_id: profile.id,
          receiving_item_id: receivingItemInfo.id,
          is_ready: false,
          is_processed: false,
        };

        const updatedItem = await api.put('/inventory_items/updateSoldItemBySerialNumber', {
          item: newInventoryItem,
        });

        const updateReceivingItem = {
          purchase_order_item_id: values.purchaseOrderItemId,
          id: receivingItemInfo.id,
        };

        const { id } = updatedItem.data;

        await api.put(`receivingItems/${receivingItemInfo.id}`, updateReceivingItem);

        toast.success('Item registered successfully');

        form.resetFields(['serialNumberHD']);

        setInventoryItems((prevState) => [
          ...prevState,
          {
            id,
            serial_number: newInventoryItem.serial_number,
            created_at: moment(new Date()).format('MMM DD, YYYY'),
            post_result: newInventoryItem.post_result,
            status: newInventoryItem.status,
            condition: newInventoryItem.condition,
            item: { part_number: itemReceivedPartNumber },
            grade: itemReceivedPartNumber.grade,
            inventory_items_device: [],
            locations: { name: 'Tech' },
            users: { id: profile.id, name: profile.name },
            usersLocation: {
              id: profile.id,
              name: profile.name,
            },
            createdAt: moment(new Date()).format('MMM DD, YYYY'),
          },
        ]);
        setReceivingPartsFlag(true);
        setUnitsReceived(unitsReceived + 1);
        const unitsToReceive = totalUnits - (unitsReceived + 1);
        if (unitsToReceive === 0 || unitsToReceive < 0) {
          setCompleteFlag(false);
        } else {
          setCompleteFlag(true);
        }
        serialNumberRef.current.value = '';
        serialNumberRef.current.focus();
        return;
      }

      const newInventoryItem = {};
      const newInventoryItemAttachment = {};
      const updateReceivingItem = {};

      newInventoryItem.branch_id = 1;
      newInventoryItem.post_result = values.postResult;

      if (values.postResult === 'passed') {
        newInventoryItem.status = 'ToBeInspected';
        newInventoryItem.condition = '';
        newInventoryItem.grade = 'TBI';
      } else if (values.postResult === 'failed') {
        newInventoryItem.status = 'Failed';
        newInventoryItem.condition = '';
        newInventoryItem.grade = 'FA';
      } else if (values.postResult === 'new') {
        newInventoryItem.status = 'InStock';
        newInventoryItem.condition = 'New';
        newInventoryItem.grade = 'New';
      } else if (values.postResult === 'na') {
        newInventoryItem.status = 'ToBeInspected';
        newInventoryItem.condition = '';
        newInventoryItem.grade = 'TBI';
      }
      newInventoryItem.received_by = profile.id;
      newInventoryItem.item_id = values.itemId;
      newInventoryItem.serial_number = serialNumber;
      newInventoryItem.location_id = 219;
      newInventoryItem.user_id = profile.id;
      newInventoryItem.receiving_item_id = receivingItemInfo.id;

      if (values.itemHDId !== undefined) {
        newInventoryItem.storage_serial_number = values.serialNumberHD;

        const newHDInventoryItem = {
          item_id: values.itemHDId,
          serial_number: values.serialNumberHD,
          post_result: 'na',
          status: 'InUse',
          location_id: 219, // It's always a user that it's going to receive an item. So, default location is for Tech (id 219)
          user_id: profile.id,
          condition: 'Used',
          received_by: profile.id,
          branch_id: 1,
          grade: 'na',
          is_ready: true,
        };

        const hdData = await api.post('inventory_items', newHDInventoryItem);

        const { id } = hdData.data;
        newInventoryItemAttachment.inventory_items_id_attachment = id;
      }

      updateReceivingItem.purchase_order_item_id = values.purchaseOrderItemId;
      updateReceivingItem.id = receivingItemInfo.id;

      const inventoryItemsData = await api.post('inventory_items', newInventoryItem);

      const { id } = inventoryItemsData.data;

      await api.put(`receivingItems/${receivingItemInfo.id}`, updateReceivingItem);
      if (newInventoryItemAttachment.inventory_items_id_attachment !== undefined) {
        newInventoryItemAttachment.receiving_item_id = receivingItemInfo.id;
        newInventoryItemAttachment.inventory_items_id_device = id;

        await api.post('receivingItemsAttachments', newInventoryItemAttachment);
      }

      const attachmentInfo = values.serialNumberHD ? await api.post('inventory_items/showBySerialNumber', { serial_number: values.serialNumberHD }) : null;
      toast.success('Item registered successfully');

      form.resetFields(['serialNumberHD']);

      setInventoryItems(() => [
        ...inventoryItems,
        {
          id,
          item: { part_number: itemReceivedPartNumber },
          users: { id: profile.id, name: profile.name },
          serial_number: newInventoryItem.serial_number,
          grade: itemReceivedPartNumber.grade,
          createdAt: moment(new Date()).format('MMM DD, YYYY'),
          status: newInventoryItem.status,
          locations: {
            name: 'Tech',
          },
          usersLocation: {
            id: profile.id,
            name: profile.name,
          },
          condition: newInventoryItem.condition,
          post_result: newInventoryItem.post_result,
          inventory_items_device: [
            {
              inventory_items_attachment: {
                serial_number: attachmentInfo ? attachmentInfo.data?.serial_number : null,
                item: {
                  part_num: attachmentInfo ? attachmentInfo.data?.item?.part_number : null,
                  descript: attachmentInfo ? attachmentInfo.data?.item?.description : null,
                },
              },
            },
          ],
        },
      ]);
      setReceivingPartsFlag(true);
      setUnitsReceived(unitsReceived + 1);
      const unitsToReceive = totalUnits - (unitsReceived + 1);
      if (unitsToReceive === 0 || unitsToReceive < 0) {
        setCompleteFlag(false);
      } else {
        setCompleteFlag(true);
      }
      serialNumberRef.current.value = '';
      serialNumberRef.current.focus();
    } catch (error) {
      toast.error(error.response.data.message);
      serialNumberRef.current.value = '';
      serialNumberRef.current.focus();
    }
  }

  const onFinish = async (values) => {
    try {
      const serialNumber = serialNumberRef.current.value;
      const serialNumberFormated = serialNumber.trim().toUpperCase();
      if (!serialNumberFormated) {
        toast.error('Please enter a valid serial number');
        return;
      }
      const checkSerialNumberExists = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormated });

      if (checkSerialNumberExists.data.status === 'Sold' && checkSerialNumberExists.data.item.part_number === itemReceivedPartNumber) {
        confirm({
          title: (
            <span>
              Serial number already exists <span style={{ fontWeight: 'bold', fontSize: 'large', color: 'red' }}>{serialNumberFormated}</span> and it's marked
              as <span style={{ fontWeight: 'bold', fontSize: 'large', color: 'red' }}>SOLD</span>
            </span>
          ),
          icon: <ExclamationCircleFilled />,
          content: `It seems that this serial number was in stock before. Are you sure that you are adding the correct serial number?`,
          okText: `Yes, I'm sure`,
          okType: 'success',
          cancelText: 'No, I will scan again',
          cancelButtonProps: { type: 'danger' },
          autoFocusButton: 'cancel',
          async onOk() {
            addItemToInventory(values, false, serialNumberFormated); // parameters (item data, isNew item)
          },
          onCancel() {},
        });
        return;
      }
      addItemToInventory(values, true, serialNumberFormated);
    } catch (error) {
      toast.error(error);
    }
  };

  const validateSerialNumber = async (SerialNumber) => {
    if (SerialNumber !== '' && SerialNumber !== null && SerialNumber !== undefined) {
      const upperCaseSerialNumber = SerialNumber.trim().toUpperCase();
      const inventoryItemsInfo = await api.post('inventory_items/showBySerialNumber', { serial_number: upperCaseSerialNumber });

      if (['Sold', 'SentToFBA'].includes(inventoryItemsInfo.data.status) && inventoryItemsInfo.data.item.id === form.getFieldValue('itemId')) {
        return true;
      }

      if (!inventoryItemsInfo.data.error) {
        toast.error('Serial Number already registered');
        return false;
      }
      return true;
    }
    return true;
  };

  const completeReceiving = async () => {
    if (receivingItemInfo.status === 'complete' || receivingItemInfo.status === 'pending_assessment') {
      toast.error('This receiving was already Complete.');
      return;
    }
    const purchase_order_item_id = form.getFieldValue('purchaseOrderItemId');

    const LOT_WITH_NO_NEW_ITEMS = inventoryItems.filter((item) => item.condition !== 'New');
    const LOT_WITH_ONLY_NEW_ITEMS = LOT_WITH_NO_NEW_ITEMS.length === 0;
    if (LOT_WITH_ONLY_NEW_ITEMS) {
      try {
        const inventoryAssessmentData = await api.put(`inventory_items/handleAssessmentComplete/${receivingItemInfo.id}`, inventoryItems);

        await api.post('inventory_assessment', inventoryAssessmentData.data);

        const inventory_items_id_list = inventoryItems.map((item) => item.id);

        await api.post('cost_breakdown/handleCostBreakdownForNewItems', {
          inventory_items_id_list,
          purchase_order_item_id,
        });

        await api.patch(`inventory_assessment/handlePOEmail/${receivingItemInfo.id}`);
        receivingItemInfo.status = 'complete';

        setReceivingItem(() => receivingItemInfo);
        toast.success('Receiving is complete and assessment is complete.');
        return;
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        return;
      }
    }
    await api.put(`receivingItems/${receivingItemInfo.id}`, {
      status: 'pending_assessment',
    });
    receivingItemInfo.status = 'pending_assessment';

    setReceivingItem(() => receivingItemInfo);
    toast.success('Receiving is complete.');
  };

  const partialReceiving = async () => {
    if (receivingItemInfo.status === 'partially_received' || receivingItemInfo.status === 'partially_complete') {
      toast.error('This receiving is already being assessed.');
    } else {
      await api.put(`receivingItems/${receivingItemInfo.id}`, {
        status: 'partially_received',
      });
      toast.success('Receiving is partially complete.');
    }
  };

  const handleRemoveReceivingParts = async (rowInfo) => {
    try {
      const inventoryInfo = await api.get(`inventory/${rowInfo.item_id}?item=true`);

      const quantity = rowInfo.new_data - rowInfo.previous_data;

      const inventoryToUpdate = {
        item_id: rowInfo.item_id,
        branch_id: 1,
        quantity: inventoryInfo.data.quantity - quantity,
      };

      await api.put(`inventory/${inventoryInfo.data.id}`, inventoryToUpdate);

      await api.delete(`inventoryLog/${rowInfo.log_id}`);

      setReceivingParts(receivingParts.filter((part) => part.log_id !== rowInfo.log_id));

      toast.success('Received part deleted.');
    } catch (error) {
      toast.error(error);
    }
  };

  const columnReceiveParts = [
    {
      title: 'Part Number',
      dataIndex: ['item', 'part_number'],
    },
    {
      title: 'Quantity',
      dataIndex: 'new_data',
      render: (data, row) => data - row.previous_data,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Popconfirm
          onConfirm={() => handleRemoveReceivingParts(record)}
          title="Are you sure you want to delete?"
          trigger="click"
          placement="left"
          okText="Yes"
          cancelText="No"
        >
          <button type="button" className="button-error pure-button" disabled={receivingPartsFlag}>
            Remove
          </button>
        </Popconfirm>
      ),
    },
  ];

  const handleCompleteReceivingParts = async () => {
    const purchase_order_item_id = form.getFieldValue('purchaseOrderItemId');

    if (receivingItemInfo.status === 'complete') {
      toast.error('This receiving was already Complete.');
    } else {
      let unitPrice = parseFloat(purchaseOrderItem.find((po) => po.id === purchase_order_item_id).unit_price);
      if (purchaseOrder.currency === 'USD') {
        const exchange_rate = await api.get(`exchange_rate_cad_to?date=${moment(purchaseOrder.qbo_created_at).format('YYYY-MM-DD')}`);
        unitPrice /= parseFloat(exchange_rate.data.usd);
      }

      receivingParts.map(async (part) => {
        const itemBasePrice = await api.get(`item_base_cost/${part.item_id}`);

        const quantity = part.new_data - part.previous_data;
        const newItemPrice = {
          item_id: part.item_id,
          cost: parseFloat(unitPrice.toFixed(2)),
        };

        if (itemBasePrice.data.exists) {
          await api.post('item_base_cost', newItemPrice);
        } else {
          const newBasePrice = (part.previous_data * itemBasePrice.data.cost + quantity * unitPrice) / part.new_data;
          newItemPrice.cost = newBasePrice;
          newItemPrice.new_average_log = `(${part.previous_data} * ${itemBasePrice.data.cost} + ${quantity} * ${unitPrice}) / ${part.new_data}) (Current stock quantity * Item base cost + Quantity added * PO unit cost) / New stock quantity`;
          await api.put(`item_base_cost/${part.item_id}`, newItemPrice);
        }
      });
      await api.put(`receivingItems/${receivingItemInfo.id}`, {
        status: 'complete_receiving_parts',
      });
      toast.success('Receiving is complete.');
      history.push('/receiving');
    }
  };

  const addReceivingParts = async () => {
    const log_id = uuidv4();
    const item_id = form.getFieldValue('partsItemId');
    const quantity = form.getFieldValue('partsQuantity');
    const purchase_order_item_id = form.getFieldValue('purchaseOrderItemId');

    if (purchase_order_item_id === null || item_id === undefined || quantity === undefined) {
      toast.error('Please enter the PO item, a Part number and the quantity');
    } else {
      await api.put(`receivingItems/${receivingItemInfo.id}`, { purchase_order_item_id });

      const inventoryInfo = await api.get(`inventory/${item_id}?item=true`);

      const inventoryToUpdate = {
        item_id,
        branch_id: inventoryInfo.data.branch_id,
        quantity: parseInt(inventoryInfo.data.quantity) + quantity,
      };

      await api.put(`inventory/${inventoryInfo.data.id}`, inventoryToUpdate);

      const inventoryLogInfo = {
        log_id,
        item_id,
        routine: 'Receiving',
        error: 'Item Found',
        action: 'Update',
        previous_data: inventoryInfo.data.quantity,
        new_data: parseInt(inventoryInfo.data.quantity) + quantity,
        user_id: profile.id,
        receiving_item_id: receivingItemInfo.id,
      };

      if (receivingParts.length > 0) {
        const receivingPartsExists = receivingParts.find((part) => part.item_id === item_id);

        if (receivingPartsExists) {
          toast.error('Part Number already added.');
        } else {
          const newInventoryLog = await api.post('inventoryLog', inventoryLogInfo);
          setReceivingParts([
            ...receivingParts,
            {
              id: newInventoryLog.data.id,
              log_id,
              item_id,
              item: { part_number: inventoryInfo.data.item.part_number },
              previous_data: inventoryInfo.data.quantity,
              new_data: parseInt(inventoryInfo.data.quantity) + quantity,
            },
          ]);
          form.resetFields(['partsItemId', 'partsQuantity']);
          toast.success('Item received.');
        }
      } else {
        const newInventoryLog = await api.post('inventoryLog', inventoryLogInfo);

        setReceivingParts([
          {
            id: newInventoryLog.data.id,
            log_id,
            item_id,
            item: { part_number: inventoryInfo.data.item.part_number },
            previous_data: inventoryInfo.data.quantity,
            new_data: parseInt(inventoryInfo.data.quantity) + quantity,
          },
        ]);
        form.resetFields(['partsItemId', 'partsQuantity']);
        toast.success('Item received.');
      }
    }
  };

  const handleGenerateReport = async () => {
    try {
      setReportLoading(() => true);
      const report = await api.get(`receivingItems/getReceivingItemReport/${receivingItemInfo.id}`);
      const reports = ReportFields(report.data);
      const worksheet = XLSX.utils.json_to_sheet(reports);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `${receivingItemInfo.description}_${receivingInfo.created_by}.xlsx`);
    } catch (error) {
      toast.error(`${error.response.data.message}. Please contact your system admin and report this error`);
    }
    setReportLoading(() => false);
  };

  const handleBulkChangeLocation = async () => {
    try {
      setTransferringItems(true);
      const newLocationSelectedValue = form.getFieldValue('warehouseLocation');
      if (newLocationSelectedValue !== null) {
        // if (IS_LOCATION_TYPE_WAREHOUSE) {
        const response = await api.put(`locations/bulkChangeLocation/${newLocationSelectedValue}`, inventoryItems);

        setInventoryItems(() => response.data.newInventoryItems);
        toast.success(response.data.message);
        form.setFieldsValue({ warehouseLocation: null });
        setIsModalOpen(!isModalOpen);
      } else {
        toast.error('Please select a location');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setTransferringItems(false);
    }
  };

  const handleOpenBulkChangeModal = async () => {
    if (warehouseLocationList.length === 0) {
      const locationList = await api.get('locations');
      const locationSelectList = locationList.data
        .filter((location) => location.location_type_id === 2)
        .map((location) => ({ value: location.id, label: `${location.name} - ${location.description}` })); // location_type_id 2 is for warehouse locations
      setWarehouseLocationList(() => locationSelectList);
    }
    form.setFieldsValue({ warehouseLocation: null });
    setIsModalOpen(!isModalOpen);
  };

  if (loadingRemoval) {
    return (
      <Spin tip="Removing item...">
        <Alert message="Hold on a second" description="This serial number is being removed." type="info" />
      </Spin>
    );
  }

  if (transferringItems) {
    return (
      <Spin tip="Transferring items. Depending on the quantity of item, this may take a while.">
        <Alert message="Hold on a second" description="Transferring items to new location" type="info" />
      </Spin>
    );
  }

  if (loading) {
    return (
      <Spin tip="Searching...">
        <Alert
          message="Search is looking for the items. Hold on a second"
          description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
          type="info"
        />
      </Spin>
    );
  }

  return (
    <>
      <Divider />
      <Title level={3}>
        <Link href={`/purchaseOrders/${purchaseOrderNumber}`}>{`PO #${purchaseOrderNumber}`}</Link>
      </Title>

      <Form
        form={form}
        name="receivingItemForm"
        onFinish={onFinish}
        onFinishFailed={() => {
          if (receivingPartsFlag) toast.error('Please revise the alerts in the form, the data could not be submitted.');
        }}
        layout="horizontal"
        initialValues={{ size: 'small' }}
        size="small"
      >
        <Title level={5}>Please inform the PO Item related to the Received Item</Title>
        <Form.Item
          name="purchaseOrderItemId"
          rules={[
            {
              required: purchaseOrderItemsOptions.length > 0,
              message: 'PO Item is required',
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            style={{ width: 500 }}
            placeholder="Purchase Order Item"
            optionFilterProp="label"
            options={purchaseOrderItemsOptions}
            filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0}
            disabled={purchaseOrderNumber === '-'}
            onChange={(el) => setPOItemId(el)}
          />
        </Form.Item>
        <Divider />
        {pOItemId && (
          <Tabs defaultActiveKey={receivingPartsTab} type="card" tabBarStyle={{ fontWeight: 'bold' }}>
            <TabPane forceRender tab="Receive Serial Number" key="1">
              <Title level={3}>POST</Title>
              <Form.Item
                name="postResult"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" name="postResult">
                  <Radio.Button value="passed">Passed</Radio.Button>
                  <Radio.Button value="failed">Failed</Radio.Button>
                  <Radio.Button value="new">New</Radio.Button>
                  <Radio.Button value="na">N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Divider />

              <Title level={3}>Receiving Stock of Serial Numbers</Title>
              <Row>
                <Col span={12}>
                  <Title level={5}>Unit's Part Number *</Title>
                  <Form.Item
                    name="itemId"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DebounceSelect
                      showSearch
                      allowClear
                      style={{ width: 500 }}
                      placeholder="Item / Part Number"
                      optionFilterProp="label"
                      fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                      onSelect={(_key, value) => setItemReceivedPartNumber(value.label.split(' - ')[0])}
                    />
                  </Form.Item>
                  <Title level={5}>Please inform the Serial Number of the Received Item *</Title>
                  <InputText
                    ref={serialNumberRef}
                    style={{ width: 500 }}
                    placeholder="Serial Number"
                    name="serialNumber"
                    autoComplete="off"
                    // onBlur={(e) => validateSerialNumber(e.target.value)}
                  />
                  {/* </Form.Item> */}
                </Col>
                <Col span={12}>
                  <Title level={5}>Hard Drive in the Unit</Title>
                  <Form.Item name="itemHDId">
                    <DebounceSelect
                      showSearch
                      allowClear
                      style={{ width: 500 }}
                      placeholder="Hard Drive / Part Number"
                      optionFilterProp="label"
                      fetchOptions={(el) => el && fetchItems(el, false, [6], null, false)}
                      filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0}
                      onSelect={() => setHasHD(false)}
                      onClear={() => setHasHD(true)}
                    />
                  </Form.Item>
                  <Title level={5}>Please inform the HD Serial Number in the unit</Title>
                  <Form.Item
                    name="serialNumberHD"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        validator: async (_, value) =>
                          (await validateSerialNumber(value)) ? Promise.resolve() : Promise.reject('Serial number already registered'),
                      },
                    ]}
                  >
                    <Input allowClear style={{ width: 500 }} placeholder="Hard Drive Serial Number" name="serialNumberHD" autoComplete="off" disabled={hasHD} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />

              <Space>
                <Form.Item>
                  <Button style={{ width: 300 }} block type="primary" htmlType="submit" disabled={!completeFlag || addedItemsFlag}>
                    Add Received Item to Inventory
                  </Button>
                </Form.Item>
              </Space>
            </TabPane>
            <TabPane forceRender tab="Receive Parts" key="2">
              <Title level={3}>Receiving of Parts</Title>
              <Space size={10} align="end">
                <Space direction="vertical" size={4}>
                  <Title level={5}>Please inform the Part Number</Title>
                  <Form.Item
                    name="partsItemId"
                    rules={[
                      {
                        required: receivingPartsTab === '2',
                      },
                    ]}
                  >
                    <DebounceSelect
                      showSearch
                      allowClear
                      style={{ width: 500 }}
                      placeholder="Item / Part Number"
                      optionFilterProp="label"
                      fetchOptions={(el) => el && fetchItems(el, false, [4, 9], null, false)}
                      filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0}
                    />
                  </Form.Item>
                </Space>
                <Space direction="vertical" size={4}>
                  <Title level={5}>Please inform the quantity received</Title>
                  <Form.Item
                    name="partsQuantity"
                    rules={[
                      {
                        required: receivingPartsTab === '2',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: 300 }} />
                  </Form.Item>
                </Space>

                <Form.Item>
                  <Button style={{ width: 200 }} block type="primary" htmlType="submit" disabled={receivingPartsFlag} onClick={() => addReceivingParts()}>
                    Add Part Received
                  </Button>
                </Form.Item>
              </Space>

              <Table columns={columnReceiveParts} dataSource={receivingParts} size="small" bordered pagination={false} rowKey="part_number" />

              <Divider />

              <Form.Item>
                <Button
                  style={{ width: 300 }}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={receivingPartsFlag}
                  onClick={() => handleCompleteReceivingParts()}
                >
                  Complete Receiving of Parts
                </Button>
              </Form.Item>
            </TabPane>
          </Tabs>
        )}

        <Modal title="Change Location" onOk={handleBulkChangeLocation} onCancel={handleOpenBulkChangeModal} open={isModalOpen}>
          <Form.Item name="warehouseLocation" label="Location">
            <Select placeholder="Select a Warehouse Location." options={warehouseLocationList} showSearch allowClear optionFilterProp="label" />
          </Form.Item>
        </Modal>
      </Form>
      <Modal title="Import Result Details" open={reportLoading} footer={[]}>
        <Spin tip="Preparing report for download" size="small" spinning={reportLoading}>
          <div />
        </Spin>
      </Modal>

      <Divider />

      {inventoryItems.length > 0 && (
        <>
          <Title level={4}>Received Items to Inventory</Title>
          <Space direction="vertical">
            <Space>
              <Button
                variant="outlined"
                type="primary"
                className="ant-dropdown-link"
                style={{ background: '#3EB19E', borderColor: '#3EB19E', color: 'white', width: 150 }}
                onClick={() => handleGenerateReport()}
              >
                Download to Excel
              </Button>
              <Button disabled={completeFlag} onClick={() => completeReceiving()} type="primary">
                Complete Receiving
              </Button>
              <Button disabled={!completeFlag} onClick={() => partialReceiving()} type="primary">
                Partial Receiving
              </Button>
            </Space>
            {warehouseWorkersIdList.includes(profile?.id) && ( // Malek's, Ibrahim Khachab, Ibrahim Mistou, Abdulwahed's id
              <Space>
                <Button onClick={() => handleOpenBulkChangeModal()} type="primary">
                  Change Location for this Receiving Lot
                </Button>
                <Tooltip title="This bulk change location will change all units that are located with Malek or Ibrahim to any Warehouse location.'">
                  <Tag color="blue" icon={<QuestionCircleOutlined />}>
                    HELP
                  </Tag>
                </Tooltip>
              </Space>
            )}
          </Space>
          <Divider />
          <VirtualTable
            columns={columnsInventoryItems}
            dataSource={inventoryItems.reverse()}
            size="small"
            bordered
            pagination={false}
            rowKey={(record) => record.serial_number}
            // scroll={{
            //   y: 300,
            //   x: '100vw',
            // }}
          />
          {/* <Table
            columns={columnsInventoryItems}
            dataSource={inventoryItems}
            size="small"
            bordered
            pagination={false}
            rowKey={(record) => record.serial_number}
          /> */}
        </>
      )}
    </>
  );
}
