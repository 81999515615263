import React from 'react';
import { Container } from './styles';
import history from '~/services/history';

export default function StockAnalyzer() {
  const handleSubmitFeed = () => {
    history.push('/inventoryFeedDatabase');
  };

  const handleSubmitAnalyze = {};

  return (
    <Container>
      <h1>Stock Analyzer - Feed Database</h1>
      <h3>
        Click on the button below to feed database with Inventory to be submitted to analysis. Once you finish the database feeding process, please execute the
        following option -- Analyze Stock--.
      </h3>
      <br />
      <form onSubmit={handleSubmitFeed} className="pure-form pure-form-aligned" autoComplete="off">
        <fieldset>
          <br />
          <button type="submit" className="pure-button pure-button-primary">
            Feed Database
          </button>
        </fieldset>
      </form>
      <br />
      <br />
      <hr />
      <form onSubmit={handleSubmitAnalyze} className="pure-form pure-form-aligned" autoComplete="off">
        <fieldset>
          <h1>Analyze Stock</h1>
          <h3>
            Once upon Analyze Stock button is clicked, you will be required to choose the batch of database feeding log that should be submitted to analysis.
            This routine will data cross the actual Inventory with the log created from the Feed Database routine.
          </h3>
          <br />
          <button type="submit" className="pure-button pure-button-primary" disabled="on">
            Analyze Stock
          </button>
        </fieldset>
      </form>
    </Container>
  );
}
