/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function SupplierEdit(props) {
  const [supplier, setSupplier] = useState([]);

  const { search } = props.location;
  const params = new URLSearchParams(search);
  const name = params.get('name');

  useEffect(() => {
    async function loadSupplier() {
      const supplierInfo = await api.get(`suppliers/${name}`);

      setSupplier(supplierInfo.data);
    }
    loadSupplier();
  }, []); // eslint-disable-line

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`suppliers/${name}`, supplier);
      history.push('/suppliers');
      toast.success(JSON.stringify('Supplier updated!'));
    } catch (error) {
      toast.error(JSON.stringify(error.message));
    }
  };

  const countries = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Côte dIvoire',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo (Congo-Brazzaville)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czechia (Czech Republic)',
    'Democratic Republic of the Congo',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini (fmr. "Swaziland")',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Holy See',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar (formerly Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Korea',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine State',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States of America',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];

  return (
    <Container>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader subheader={`${supplier.name}`} title="Update Supplier" />
          <Divider />
          <CardContent autoComplete="off">
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, name: e.target.value })}
                  value={supplier.name || ''}
                  fullWidth
                  autoComplete="off"
                  disabled="on"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  id="phone"
                  label="Phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, phone: e.target.value })}
                  value={supplier.phone || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, email: e.target.value })}
                  value={supplier.email || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="company"
                  label="Company"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, company: e.target.value })}
                  value={supplier.company || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="street1"
                  label="Street 1"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, street1: e.target.value })}
                  value={supplier.street1 || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="street2"
                  label="Street 2"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, street2: e.target.value })}
                  value={supplier.street2 || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="city"
                  label="City"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, city: e.target.value })}
                  value={supplier.city || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="state"
                  label="State"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setSupplier({ ...supplier, state: e.target.value })}
                  value={supplier.state || ''}
                  fullWidth
                  autoComplete="off"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="country"
                  options={countries}
                  value={supplier.country || ''}
                  getOptionLabel={(option) => option || ''}
                  style={{ width: '100%' }}
                  onChange={(e, newValue) => {
                    setSupplier({ ...supplier, country: newValue });
                  }}
                  renderInput={(params2) => <TextField {...params2} label="Country" margin="normal" fullWidth />}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Update Supplier
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
