import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;
  th,
  td {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 1024px) {
    padding: 50px 10px;
  }
`;

// export const Table = styled.table`
//   background: #fff;
//   border-radius: 4px;
//   border-collapse: collapse;
//   width: 100%;

//   th,
//   td {
//     padding: 8px;
//     text-align: left;
//     border-bottom: 1px solid #ddd;
//   }

//   tbody tr:hover {
//     background-color: #f5f5f5;
//   }

//   th {
//     background-color: #60b365;
//     color: white;
//     text-transform: uppercase;
//   }
// `;

export const Button = styled.button`
  background-color: #e7e7e7;
  border: none;
  color: black;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 8px;
  margin-bottom: 2px;
`;
