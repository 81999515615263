import styled from 'styled-components';

import { Card, Avatar } from 'antd';

const { Meta } = Card;

export const CardStyled = styled(Card)`
  &:hover {
    background-color: #eef8fd;
    cursor: pointer;
  }
`;

export const Selected = styled(Card)`
  background-color: #eef8fd;
  cursor: pointer;
  border: 2px solid #046bc0;
`;

export const AvatarStyled = styled(Avatar)``;

export const MetaStyled = styled(Meta)``;
