import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 75px 20px 0 250px;
  background-color: #fff;

  input {
    height: 2rem;
    padding: 0 0.5rem;
    width: 16rem;
  }

  input:focus {
    border: 1px solid #2372dc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(35, 114, 220, 0.6);
  }

  @media only screen and (max-width: 1024px) {
    padding: 50px 10px;
  }
`;

export const SearchButton = styled.button`
  background-color: #1890ff;
  border: 1px solid #1890ff;
  color: #fff;
  height: 2rem;
  width: 2.5rem;
`;
