/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Fab, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SaveIcon from '@material-ui/icons/Save';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import { toast } from 'react-toastify';

import api from '~/services/api';
import {
  Container,
  OrderArea,
  OrderAreaProducts,
  OrderContainer,
  // CustomDivider,
  OrderContainerActions,
  ProductsTable,
  SearchCustomer,
  SearchProducts,
  SummaryCustomer,
} from './styles';


const useStyles = makeStyles(theme => ({
  click: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const tax = {
  AB: 5,
  BC: 12,
  MB: 12,
  NB: 15,
  NL: 15,
  NT: 5,
  NS: 15,
  NU: 5,
  ON: 13,
  PE: 15,
  QC: 14.975,
  SK: 11,
  YT: 5,
};

export default function NewOrder() {
  const classes = useStyles();

  const [customerList, setCustomerList] = useState([{}]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerName, setCustomerName] = useState({});
  const [productName, setProductName] = useState('');

  const [productList, setProductList] = useState([]);
  const [productOrderList, setProductOrderList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [orderSummary, setOrderSummary] = useState({});
  const [orderTax, setOrderTax] = useState(0);

  const productTitleRef = useRef('');
  const productUnitPriceRef = useRef('');
  const productQtyRef = useRef('');
  const orderInputRef = useRef('');

  useEffect(() => {
    async function loadCustomerList() {
      const customerData = await api.get('/customers');
      setCustomerList(customerData.data.rows.map(el => ({ text: el.name.toUpperCase(), id: el.id })));

      const productsData = await api.get('/items');
      setProductList(
        productsData.data.map(el => ({
          text: `${el.part_number && el.part_number.toUpperCase()} - ${el.description && el.description.toUpperCase()}`,
          id: el.id,
        }))
      );
    }
    loadCustomerList();
  }, [orderTax]);

  useEffect(() => {
    let orderSummaryTotal = 0;
    for (let index = 0; index < productOrderList.length; index++) {
      orderSummaryTotal += productOrderList[index].total_price;
    }
    setOrderSummary({ productsTotal: orderSummaryTotal, orderTax: orderSummaryTotal * (orderTax / 100) });
  }, [orderTax, productOrderList]); //eslint-disable-line

  function isOrderValid() {
    if (orderInputRef.current.value === '') {
      toast.error('Order cannot be blank');
      return false;
    }
    if (selectedDate === null) {
      toast.error('Order date cannot be blank');
      return false;
    }
    if (Object.entries(customerInfo).length === 0) {
      toast.error('Customer cannot be blank');
      return false;
    }
    if (productOrderList.length === 0) {
      toast.error('At least one product must be selected');
      return false;
    }
    return true;
  }

  const handleCustomerChange = useCallback(async data => {
    if (!data) {
      return;
    }
    setCustomerName(data);
    const customerData = await api.get(`customers/${data.id}`);
    if (customerData) {
      setCustomerInfo({
        name: customerData.data.name,
        address: `${customerData.data.street1 || ''} ${customerData.data.street2 || ''} ${customerData.data.street3 || ''}
                ${customerData.data.city}, ${customerData.data.state} ${customerData.data.postal_code} ${customerData.data.country}`,
        phone: customerData.data.phone,
        email: customerData.data.email,
        id: data.id,
      });
      if (customerData.data.country === 'CA') {
        setOrderTax(tax[customerData.data.state]);
      } else {
        setOrderTax(0);
      }
    }
  }, []);

  const handleAddProduct = useCallback(() => {
    if (productUnitPriceRef.current.value === '' || productQtyRef.current.value === '' || productTitleRef.current.value === '') {
      toast.error('Neither Product, Quantity or Price can be 0');
      return;
    }
    setProductOrderList([
      ...productOrderList,
      {
        title: productTitleRef.current.value,
        unit_price: productUnitPriceRef.current.value,
        quantity: productQtyRef.current.value,
        total_price: productQtyRef.current.value * productUnitPriceRef.current.value,
      },
    ]);
  }, [productOrderList]);

  const handleRemoveProduct = useCallback(
    index => {
      const newList = [...productOrderList];
      newList.splice(index, 1);
      setProductOrderList(newList);
    },
    [productOrderList]
  );

  const handleSaveOrder = useCallback(async () => {
    const newOrderData = {
      order_num: orderInputRef.current.value,
      store_id: 13,
      customer_id: customerInfo.id,
      creation_date: moment(new Date()).format('YYYY-MM-DD'),
      status: 'Saved',
      order_date: moment(new Date()).format('YYYY-MM-DD'),
      amount_paid: orderSummary.productsTotal.toFixed(2),
      tax_amount: orderSummary.orderTax.toFixed(2),
      order_total: (orderSummary.productsTotal + orderSummary.orderTax).toFixed(2),
    };
    if (isOrderValid()) {
      const newOrder = await api.post('orders', newOrderData);
      if (newOrder.data.error) {
        toast.error(newOrder.data.error);
      }

      orderInputRef.current.value = '';
      setCustomerInfo({});
      setCustomerName({});
      setProductName({});

      setProductOrderList([]);
      productQtyRef.current.value = '';
      productUnitPriceRef.current.value = '';
      setOrderSummary({});

      productOrderList.forEach(async product => {
        const productOrder = {
          order_id: newOrder.data.order_id,
          unit_price: product.unit_price,
          quantity: product.quantity,
          total_price: product.total_price,
          title: product.title,
        };
        await api.post('orderItems', productOrder);
      });

      toast.success('Order Saved');
    }
  }, [customerInfo, isOrderValid, orderSummary]); //eslint-disable-line

  return (
    <Container>
      <OrderContainerActions>
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSaveOrder}>
          Save
        </Button>
        <Button variant="contained" color="primary" startIcon={<DoneAllIcon />}>
          Complete
        </Button>

        {/* <Chip
          variant="outlined"
          size="small"
          avatar={<DoneIcon style={{ color: '#519657' }} />}
          label="Saved"
          style={{ marginLeft: '15px', backgroundColor: '#b2fab4', borderColor: '#b2fab4', color: '#519657' }}
        /> */}
      </OrderContainerActions>
      <OrderContainer>
        <TextField
          label="Order #"
          inputRef={orderInputRef}
          margin="normal"
          helperText="Incorrect entry."
          error={false}
          variant="outlined"
          size="small"
          style={{ zIndex: 0 }}
        />
        <TextField
          id="date"
          label="Order Date"
          type="date"
          defaultValue={moment(new Date()).format('YYYY-MM-DD')}
          className={classes.textField}
          onChange={e => setSelectedDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </OrderContainer>
      <SearchCustomer>
        <Autocomplete
          id="customer"
          options={customerList}
          onChange={(e, newValue) => {
            handleCustomerChange(newValue);
          }}
          value={customerName}
          clearOnEscape
          getOptionLabel={option => option.text || ''}
          getOptionSelected={(option, value) => value.value === option.value}
          renderInput={params => (
            <TextField {...params} style={{ width: '600px', textTransform: 'uppercase' }} label="Customer" margin="normal" variant="outlined" size="small" />
          )}
        />

        <OrderArea variant="outlined">
          <Typography gutterBottom variant="h5" component="h2">
            Customer/Recipient
          </Typography>
          <SummaryCustomer style={{ whiteSpace: 'pre-wrap', textAlign: 'right' }}>
            <div>
              <span>Name:</span> <span>{customerInfo.name}</span>
            </div>
            <div>
              <span>Address:</span> <span>{customerInfo.address}</span>
            </div>
            <div>
              <span>Phone:</span> <span>{customerInfo.phone}</span>
            </div>
            <div>
              <span>E-mail:</span> <span>{customerInfo.email}</span>
            </div>
          </SummaryCustomer>
        </OrderArea>
        <OrderArea variant="outlined">
          <Typography gutterBottom variant="h5" component="h2">
            Order Summary
          </Typography>
          <SummaryCustomer style={{ whiteSpace: 'pre-wrap', textAlign: 'right' }}>
            <div>
              <span>Products:</span> <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(orderSummary.productsTotal)}</span>
            </div>
            <div>
              <span>Tax:</span> <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(orderSummary.orderTax)}</span>
            </div>
            <div>
              <span>Total:</span>{' '}
              <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(orderSummary.productsTotal + orderSummary.orderTax)}</span>
            </div>
          </SummaryCustomer>
        </OrderArea>
      </SearchCustomer>

      <SearchProducts>
        <span>
          <Autocomplete
            id="productTitle"
            options={productList}
            clearOnEscape
            value={productName}
            getOptionSelected={(option, value) => value.value === option.value}
            getOptionLabel={option => option.text || ''}
            onChange={(e, newValue) => {
              setProductName(newValue);
            }}
            renderInput={params => (
              <TextField
                inputRef={productTitleRef}
                {...params}
                style={{ width: '700px', textTransform: 'uppercase' }}
                label="Products"
                margin="normal"
                variant="outlined"
                size="small"
              />
            )}
          />
          <TextField
            inputRef={productUnitPriceRef}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 99999 } }}
            label="Unit $"
            margin="normal"
            variant="outlined"
            size="small"
          />
          <TextField
            inputRef={productQtyRef}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 99999 } }}
            label="Qty"
            margin="normal"
            variant="outlined"
            size="small"
          />
          <Fab size="small" color="primary" aria-label="add" onClick={handleAddProduct}>
            <AddIcon />
          </Fab>
        </span>
        {productOrderList.length > 0 && (
          <OrderAreaProducts>
            <Typography gutterBottom variant="h5" component="h2">
              Products
            </Typography>

            <ProductsTable>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Unit $</th>
                  <th>Qty</th>
                  <th>Total $</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productOrderList.map((itemOrder, index) => (
                  <tr key={index}>
                    <td>{itemOrder.title}</td>
                    <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(itemOrder.unit_price)}</td>
                    <td>{itemOrder.quantity}</td>
                    <td style={{ textAlign: 'center' }}>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(itemOrder.total_price)}
                    </td>
                    <td>
                      <DeleteForeverIcon className={classes.click} color="secondary" fontSize="small" onClick={() => handleRemoveProduct(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </ProductsTable>
          </OrderAreaProducts>
        )}
      </SearchProducts>
    </Container>
  );
}
