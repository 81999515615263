export function assignKBType(keyboard_type_id) {
  switch (keyboard_type_id) {
    case 1:
      return {
        type: 'rubber',
        language: 'EN',
      };
    case 2:
      return {
        type: 'rubber',
        language: 'CA-FR',
      };
    case 3:
      return {
        type: 'standard',
        language: 'EN',
      };
    case 4:
      return {
        type: 'standard',
        language: 'CA-FR',
      };
    case 5:
      return {
        type: 'emissive',
        language: 'EN',
      };
    case 6:
      return {
        type: 'emissive',
        language: 'CA-FR',
      };
    case 8:
      return {
        type: 'rubber',
        language: 'Dual',
      };
    case 9:
      return {
        type: 'standard',
        language: 'Dual',
      };
    case 10:
      return {
        type: 'emissive',
        language: 'Dual',
      };
    default:
      return '';
  }
}