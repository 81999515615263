export function getCurrentCountRequest() {
  // console.log('second');
  return {
    type: '@get/CURRENT_COUNT_REQUEST',
  };
}

export function getCurrentCountSuccess(currentCount) {
  // console.log('fourth ', currentCount);
  return {
    type: '@get/CURRENT_COUNT_SUCCESS',
    payload: { currentCount },
  };
}
