import styled from 'styled-components';

export const AlertParagraph = styled.p`
  display: inline;
  margin: auto;
`;

const colors = {
  danger: {
    'background-color': '#fee2e2', // red-100
    border: '#fb7185', // rose-400
    color: `#f43f5e`, // rose-500
  },
  info: {
    'background-color': `#e0f2fe`, // sky-100
    border: `#60a5fa`, // blue-400
    color: `#3b82f6`, // blue-500
  },
  warning: {
    'background-color': '#fef3c7', // amber-100
    border: '#fbbf24', // amber-400
    color: `#f97316`, // orange-500
  },
};

export const AlertContainer = styled.div`
  background-color: ${props => colors[props.variant]['background-color']};
  padding: 1px 5px;
  border: 1px solid ${props => colors[props.variant].border};
  margin: auto;
  font-size: 0.9rem;
  display: flex;
`;

// export const IconAfter = styled.div`
//   position: absolute;
//   right: 5px;
//   top: 50%;
//   transform: translateY(-50%);
// `;

export const IconBefore = styled.div`
  display: inline;
  margin: 4px 3px 0 0;
  color: ${props => colors[props.variant].color};
  padding: 0;
`;
