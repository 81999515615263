/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';

import { CloseSquareOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Form, Input, InputNumber, Popover, Radio, Select, Space, Tooltip, Upload } from 'antd';

import api from '~/services/api';
import { handleBatterySerialNumberValidation } from '../utils/handleBatterySerialNumberValidation';

export default function Keyboard({
  form,
  itemInfo,
  itemResults,
  keyboardOptions,
  keyboardLanguageOptions,
  keyboardPortOptions,
  instructions,
  cleanFieldsNotPassed,
  itemDisabled,
  profile,
}) {
  const [checkUsb, setCheckUsb] = useState(false);
  const [checkKeyboard, setCheckKeyboard] = useState(false);
  const [checkBattery, setCheckBattery] = useState(false);
  const [checkKeyboardPorts, setCheckKeyboardPorts] = useState(false);
  const [batteryUploadDisabled, setBatteryUploadDisabled] = useState(false);

  const [batteryFile, setBatteryFile] = useState([]);

  useEffect(() => {
    async function getBatteryFile() {
      if (itemInfo.battery_file_id !== '' && itemInfo.battery_file_id !== null && itemInfo.battery_file_id !== undefined) {
        setBatteryFile([
          {
            uid: itemInfo.battery_file.id,
            url: itemInfo.battery_file.url,
            name: itemInfo.battery_file.name,
          },
        ]);
        form.setFieldsValue({
          batteryFile: [
            {
              uid: itemInfo.battery_file.id,
              url: itemInfo.battery_file.url,
              name: itemInfo.battery_file.name,
            },
          ],
        });
      }
      setCheckUsb(itemResults.resultUsb === 'passed');
      setCheckKeyboard(itemResults.resultKeyboard === 'passed');
      setCheckBattery(itemResults.resultBattery === 'passed');
      setCheckKeyboardPorts(itemResults.resultKeyboardPorts === 'passed');
    }
    getBatteryFile();
  }, [
    form,
    itemInfo,
    itemInfo.item.name,
    itemResults,
    itemResults.resultBattery,
    itemResults.resultKeyboard,
    itemResults.resultKeyboardPorts,
    itemResults.resultUsb,
  ]);

  const handleChangeBatteryFile = useCallback(
    async info => {
      const formData = new FormData();

      formData.append('file', info.file.originFileObj);

      let picturesList = [];

      try {
        const responseUploadPictures = await api.post('files', formData);
        picturesList = responseUploadPictures.data.map(el => ({
          uid: el.id,
          url: el.url,
          name: el.name,
        }));
      } catch (error) {
        Alert.alert('Error', 'Something went wrong - Pictures');
        return;
      }

      setBatteryFile(picturesList);
      form.setFieldsValue({ batteryFile: picturesList });
    },
    [form]
  );

  return (
    <>
      <Descriptions size="small" bordered>
        <Descriptions.Item label="Keyboard" span={2}>
          <Space align="baseline">
            <Form.Item name="keyboardType" rules={[{ required: checkKeyboard }]}>
              <Radio.Group buttonStyle="solid" options={keyboardOptions} optionType="button" disabled={!checkKeyboard || itemDisabled} />
            </Form.Item>
            <Form.Item name="keyboardLanguage" rules={[{ required: checkKeyboard }]}>
              <Radio.Group buttonStyle="solid" options={keyboardLanguageOptions} optionType="button" disabled={!checkKeyboard || itemDisabled} />
            </Form.Item>
            <Button
              type="text"
              danger
              icon={<CloseSquareOutlined />}
              size="small"
              onClick={() => form.setFieldsValue({ keyboardType: null, keyboardLanguage: null })}
            />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'function_keyboard')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultKeyboard" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group
              buttonStyle="solid"
              name="keyboardTest"
              onChange={e =>
                e.target.value === 'passed' ? setCheckKeyboard(true) : (setCheckKeyboard(false), cleanFieldsNotPassed(['keyboardType', 'keyboardLanguage']))
              }
              disabled={itemDisabled}
            >
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="Mouse pad" span={2}>
          <Space />
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'function_mousepad')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultMousePad" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="USB Ports" span={2}>
          <Space>
            <Tooltip placement="top" title="Quantity of ports">
              <Form.Item name="usbPorts" rules={[{ required: checkUsb, message: 'USB Ports field is required' }]}>
                <InputNumber min={0} max={20} placeholder="# ports" disabled={!checkUsb || itemDisabled} />
              </Form.Item>
            </Tooltip>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'function_usb')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultUsb" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group
              buttonStyle="solid"
              name="usbTest"
              onChange={e => (e.target.value === 'passed' ? setCheckUsb(true) : (setCheckUsb(false), cleanFieldsNotPassed(['usbPorts'])))}
              disabled={itemDisabled}
            >
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="Other Ports" span={2}>
          <Space>
            <Tooltip placement="top" title="Other types of ports">
              <Form.Item name="keyboardPorts" rules={[{ required: checkKeyboardPorts, message: 'Other ports field is required.' }]}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select the ports."
                  options={keyboardPortOptions}
                  disabled={!checkKeyboardPorts || itemDisabled}
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Tooltip>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'function_keyboard_ports')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultKeyboardPorts" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group
              buttonStyle="solid"
              name="KeyboardPortsTest"
              onChange={e =>
                e.target.value === 'passed' ? setCheckKeyboardPorts(true) : (setCheckKeyboardPorts(false), cleanFieldsNotPassed(['keyboardPorts']))
              }
              disabled={itemDisabled}
            >
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="Battery" span={2}>
          <Space>
            <Form.Item
              hasFeedback
              name="batterySerialNumber"
              rules={[
                { required: false },
                {
                  validator: async (_rule, value) => {
                    if (!value) return Promise.resolve();

                    return handleBatterySerialNumberValidation(value, setBatteryFile, setBatteryUploadDisabled, '', itemInfo, profile, form);
                  },
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input placeholder="Battery Serial Number" allowClear disabled={!checkBattery || itemDisabled} />
            </Form.Item>

            <Form.Item hidden name="batteryPartNumber">
              <Input placeholder="Part Number" />
            </Form.Item>

            <Form.Item name="batteryItemName" rules={[{ required: checkBattery, message: 'Battery Part Number is required' }]}>
              <Input disabled placeholder="Part Number" />
            </Form.Item>

            <Form.Item name="batteryPercentage" rules={[{ required: checkBattery }]}>
              <InputNumber
                disabled={!checkBattery || itemDisabled}
                placeholder="Health"
                min={0}
                max={100}
                formatter={value => (value === '' ? '' : `${value}%`)}
                parser={value => value.replace('%', '')}
              />
            </Form.Item>

            <Form.Item name="batteryFile" rules={[{ required: !batteryUploadDisabled && checkBattery, message: 'Battery File Evidence is required' }]}>
              <Upload
                onChange={el =>
                  handleChangeBatteryFile(el, form.getFieldValue('batterySerialNumber'), form.getFieldValue('batteryPercentage'), setBatteryFile, 'batteryFile')
                }
                showUploadList={{ showRemoveIcon: false }}
                multiple={false}
                fileList={batteryFile}
                disabled={!checkBattery || itemDisabled}
                customRequest={({ onSuccess }) => onSuccess('ok')}
              >
                <Button icon={<UploadOutlined />} disabled={batteryUploadDisabled}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'function_battery')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultBattery" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group
              buttonStyle="solid"
              name="batteryTest"
              onChange={e =>
                e.target.value === 'passed'
                  ? setCheckBattery(true)
                  : (setCheckBattery(false), cleanFieldsNotPassed(['batteryPercentage', 'batterySerialNumber', 'batteryPartNumber', 'batteryFile']))
              }
              disabled={itemDisabled}
            >
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="Side covers" span={2}>
          <Space />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_covers')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultCovers" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="Screws" span={2}>
          <Space />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_screws')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        >
          <Form.Item hasFeedback name="resultScrews" rules={[{ required: true, message: 'Result must be selected' }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="passed">Passed</Radio.Button>
              <Radio.Button value="failed">Failed</Radio.Button>
              <Radio.Button value="na">N/A</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item label="Cracks" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="cracks" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="unfixable">Unfixable</Radio.Button>
                <Radio.Button value="fixable-noCracks">Fixable/No Cracks</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ cracks: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_cracks')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />

        <Descriptions.Item label="Label Condition" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="labelCondition" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="poor">Poor</Radio.Button>
                <Radio.Button value="fair">Fair</Radio.Button>
                <Radio.Button value="good">Good</Radio.Button>
                <Radio.Button value="excellent">Excellent</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ labelCondition: null })} />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_label_condition')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
        <Descriptions.Item label="Dents &#38; Scratches" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="dentsScratches" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="major">Major</Radio.Button>
                <Radio.Button value="moderate">Moderate</Radio.Button>
                <Radio.Button value="minor">Minor</Radio.Button>
                <Radio.Button value="none">None</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ dentsScratches: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_dents_scratches')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
        <Descriptions.Item label="Discoloration" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="discoloration" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="major">Major</Radio.Button>
                <Radio.Button value="moderate">Moderate</Radio.Button>
                <Radio.Button value="minor">Minor</Radio.Button>
                <Radio.Button value="none">None</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ discoloration: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_discoloration')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
        <Descriptions.Item label="Missing Parts" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="missingParts" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ missingParts: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_missing_parts')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
        <Descriptions.Item label="Latches &#38; Hinges damaged" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="latchesHingesDamaged" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ latchesHingesDamaged: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_latches_hinges_damaged')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
        <Descriptions.Item label="Cleaned (Inside/Outside)" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="cleaned" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ cleaned: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_cleaned')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
        <Descriptions.Item label="Packaging" span={2}>
          <Space align="baseline">
            <Form.Item hasFeedback name="packaging" rules={[{ required: true }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="none">None</Radio.Button>
                <Radio.Button value="noFactoryPackaging">No Factory Packaging</Radio.Button>
                <Radio.Button value="unsealedFactory">Unsealed Factory</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ packaging: null })} />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Popover
              content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'cosmetics_packaging')?.instructions }} />}
              title="Instructions"
              trigger="click"
            >
              <Button type="link">Instructions</Button>
            </Popover>
          }
        />
      </Descriptions>
    </>
  );
}
