/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Alert, Button, Card, Empty, Form, Input, Modal, Select, Space, Spin, Switch, Table, Tag, Tooltip, Typography } from 'antd';

import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function LotAssessment(props) {
  const [inventoryItems, setInventoryItems] = useState([]);
  const profile = useSelector((state) => state.user.profile);

  const [size, setSize] = useState();
  const [page, setPage] = useState();
  const [query, setQuery] = useState();
  const [filterCriteria, setFilterCriteria] = useState();
  const [receivingId, setReceivingId] = useState();
  const [receivingItemId, setReceivingItemId] = useState();
  const [completeFlag, setCompleteFlag] = useState(false);
  const [partialCompleteFlag, setPartialCompleteFlag] = useState(true);
  const [receivingItem, setReceivingItem] = useState({});

  const [visibleCopy, setVisibleCopy] = useState(false);
  const [visibleNotes, setVisibleNotes] = useState(false);
  const [form] = Form.useForm();

  const [serialNumberList, setSerialNumberList] = useState({});

  const [serialNumberError, setSerialNumberError] = useState('success');

  const [ramFlag, setRamFlag] = useState(false);
  const [copyComplete, setCopyComplete] = useState(false);
  const [additionalItemsFlag, setAdditionalItemsFlag] = useState(false);
  const [unitPriceCad, setUnitPriceCad] = useState();
  const [unitPriceUsd, setUnitPriceUsd] = useState();
  const [poCurrency, setPoCurrency] = useState();

  const [loading, setLoading] = useState(true);

  const [gradingScore, setGradingScore] = useState([]);

  const { Title } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    const filterCriteriaParam = params.get('filterCriteria');
    const receiving_item_id = params.get('id');
    const receiving_id = params.get('receiving_id');
    const pageSize = params.get('size');
    const pages = params.get('page');
    const additionalFlag = params.get('additionalFlag');

    setSize(pageSize || 25);
    setPage(pages || 1);
    setQuery(searchQuery === 'undefined' ? undefined : searchQuery === null ? undefined : searchQuery);
    setFilterCriteria(filterCriteriaParam === 'undefined' ? undefined : filterCriteriaParam === null ? undefined : filterCriteriaParam);
    setReceivingId(receiving_id);
    setReceivingItemId(receiving_item_id);
    setAdditionalItemsFlag(additionalFlag === 'true');

    async function loadAssessments() {
      const receiving_item = await api.get(`receivingItems/${receiving_item_id}`);
      const inventoryItemsInfo = await api.get(`inventory_assessment_info/${receiving_item_id}?additionalFlag=${additionalFlag}`);

      setReceivingItem(receiving_item.data);
      form.setFieldsValue({ lotNotes: receiving_item.data.notes });

      const inventoryItemsData = inventoryItemsInfo.data;

      let disableCompleteBtn = false;
      let disableParcialCompleteBtn = true;

      if (receiving_item.data.status !== 'complete') {
        if (receiving_item.data.status === 'partially_received') {
          disableCompleteBtn = true;
          disableParcialCompleteBtn = false;
        } else {
          const found = inventoryItemsData.find(
            (item) =>
              (item.is_processed === true || item.condition !== 'New') &&
              (item.is_processed !== true || item.condition === 'New') &&
              item.post_result !== 'failed' &&
              (item.is_processed !== true || item.post_result !== 'na')
          );
          if (found === undefined) {
            disableCompleteBtn = false;
            disableParcialCompleteBtn = true;
          } else {
            disableCompleteBtn = true;
            disableParcialCompleteBtn = false;
          }
        }
      } else {
        disableCompleteBtn = true;
      }
      setCompleteFlag(disableCompleteBtn);
      setPartialCompleteFlag(disableParcialCompleteBtn);
      setInventoryItems(inventoryItemsData);
      setSerialNumberList(
        inventoryItemsData
          .filter((el) => el.grade === 'TBI' || (el.post_result === 'failed' && el.grade === 'FA'))
          .map((item) => ({ value: item.id, label: item.serial_number }))
      );
      // Price parameters
      const unitPrice = receiving_item.data.purchase_order_item.unit_price;
      const { currency, qbo_created_at } = receiving_item.data.purchase_order_item.purchase_order;
      const date = moment(qbo_created_at).format('YYYY-MM-DD');
      const exchangeRateInfo = await api.get(`exchange_rate_cad_to?date=${date}`);

      if (currency === 'CAD') {
        setUnitPriceCad(parseFloat(unitPrice));
        setUnitPriceUsd(parseFloat((unitPrice * exchangeRateInfo.data.usd).toFixed(2)));
      }

      if (currency === 'USD') {
        setUnitPriceCad(parseFloat((unitPrice / exchangeRateInfo.data.usd).toFixed(2)));
        setUnitPriceUsd(parseFloat(unitPrice));
      }

      if (currency === 'MXD') {
        setUnitPriceCad(parseFloat((unitPrice / exchangeRateInfo.data.mxd).toFixed(2)));
        setUnitPriceUsd(parseFloat(((unitPrice * exchangeRateInfo.data.usd) / exchangeRateInfo.data.mxd).toFixed(2)));
      }

      setPoCurrency(currency);
      // Grading score
      const gradingScoreInfo = await api.get('grading_score_weight');
      setGradingScore(gradingScoreInfo.data.map((score) => ({ grade: score.status, weight: parseFloat(score.weight) })));

      setLoading(false);
    }
    loadAssessments();
  }, []); //eslint-disable-line

  const assessUnit = (record) => {
    history.push(`/unitAssessment/${record.id}?receivingId=${receivingItemId}&additional=${additionalItemsFlag}`);
  };

  const getTagColor = (text) => {
    // eslint-disable-line
    if (text === 'InStock') {
      return 'success';
    }
    if (text === 'Failed') {
      return 'error';
    }
    if (text === 'ToBeInspected') {
      return 'processing';
    }
    if (text === 'Sold' || text === 'NotFound' || text === 'Downstream') {
      return 'default';
    }
    if (text === 'AwaitingShipment' || text === 'InUse' || text === 'Returning' || text === 'Reserved' || text === 'Returned') {
      return 'warning';
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (_text, _row, index) => index + 1,
      width: 60,
    },
    {
      title: 'Item Part Number',
      dataIndex: ['item', 'part_number'],
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
    },
    {
      title: 'Current Status',
      dataIndex: 'status',
      render: (text) => <Tag color={getTagColor(text)}>{text}</Tag>,
      filters: [
        { text: 'ToBeInspected', value: 'ToBeInspected' },
        { text: 'InStock', value: 'InStock' },
        { text: 'Failed', value: 'Failed' },
        { text: 'AwaitingShipment', value: 'AwaitingShipment' },
        { text: 'Sold', value: 'Sold' },
        { text: 'InUse (HDD only)', value: 'InUse' },
        { text: 'Downstream', value: 'Downstream' },
        { text: 'Returning (RMA)', value: 'Returning' },
        { text: 'Reserved', value: 'Reserved' },
        { text: 'Returned', value: 'Returned' },
      ],
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: 'Current Location',
      dataIndex: ['locations', 'name'],
      ellipsis: {
        showTitle: false,
      },
      render: (text, row) => (
        <Tooltip title={`${row?.locations?.description} ${row?.usersLocation?.name || ''}`} color="blue">
          {text} {row?.usersLocation?.name || ''}
        </Tooltip>
      ),
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      render: (text) => moment(text).fromNow(),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Button disabled={record.status === 'Sold'} type="primary" className="button-error pure-button" onClick={() => assessUnit(record)}>
          Assess Unit
        </Button>
      ),
      width: 250,
    },
  ];

  const completeLot = async () => {
    try {
      let completeAssessment = true;
      if (receivingItem.status === 'complete') {
        completeAssessment = false;
      }
      gradingScore.forEach((scores) => {
        const gradeList = inventoryItems.filter((item) => item.grade === scores.grade).length;
        scores.total_units = gradeList;
      });
      const inventoryGradeList = gradingScore.filter((score) => score.total_units !== 0).sort((a, b) => b.weight - a.weight);

      const highest_weight = inventoryGradeList[0].weight;

      for (let i = 0; i < inventoryGradeList.length; i++) {
        // if its in the first position of the array, then its the highest. Now I assing the highest weight of 10, but for calculation it becomes 1.
        inventoryGradeList[i].weight = i === 0 ? 1 : inventoryGradeList[i].weight / highest_weight;
      }
      const inventoryItemsNotReady = inventoryItems.filter((el) => el.is_ready === false);

      const inventoryAssessmentData = await api.put(`inventory_items/handleAssessmentComplete/${receivingItemId}`, inventoryItemsNotReady);

      const assessment = await api.post('inventory_assessment', inventoryAssessmentData.data);

      if (assessment.data.error !== undefined) {
        completeAssessment = false;
      }
      if (completeAssessment === true) {
        await api.patch(`inventory_assessment/handlePOEmail/${receivingItemId}`);
        setReceivingItem({ ...receivingItem, status: 'complete' });
        inventoryGradeList.map((grade) => {
          grade.total_grade_price_cad = unitPriceCad * grade.total_units;
          grade.total_grade_price_usd = unitPriceUsd * grade.total_units;
        });
        // Calculate the new Total weighted Price = Total Grade Price * Grade Weight + Weight Difference from the next grade * Sum of other grades Total Price
        if (inventoryGradeList.length > 1) {
          for (let i = 0; i < inventoryGradeList.length; i++) {
            let weightDifference = 0;
            let sumTotalGradePricesCad = 0;
            let sumTotalGradePricesUsd = 0;
            if (i !== inventoryGradeList.length - 1) {
              weightDifference = inventoryGradeList[i].weight - inventoryGradeList[i + 1].weight;
              for (let j = i + 1; j < inventoryGradeList.length; j++) {
                sumTotalGradePricesCad += inventoryGradeList[j].total_grade_price_cad;
                sumTotalGradePricesUsd += inventoryGradeList[j].total_grade_price_usd;
              }
            }
            inventoryGradeList[i].weighted_total_price_cad =
              inventoryGradeList[i].total_grade_price_cad * inventoryGradeList[i].weight + weightDifference * sumTotalGradePricesCad;
            inventoryGradeList[i].weighted_unit_price_cad = inventoryGradeList[i].weighted_total_price_cad / inventoryGradeList[i].total_units;
            inventoryGradeList[i].weighted_total_price_usd =
              inventoryGradeList[i].total_grade_price_usd * inventoryGradeList[i].weight + weightDifference * sumTotalGradePricesUsd;
            inventoryGradeList[i].weighted_unit_price_usd = inventoryGradeList[i].weighted_total_price_usd / inventoryGradeList[i].total_units;
          }
        }
        const cost_breakdown_list = [];
        let unitCostCad = unitPriceCad;
        let unitCostUsd = unitPriceUsd;
        inventoryItems.map((item) => {
          if (inventoryGradeList.length > 1) {
            unitCostCad = parseFloat(inventoryGradeList.find((score) => score.grade === item.grade).weighted_unit_price_cad.toFixed(2));
            unitCostUsd = parseFloat(inventoryGradeList.find((score) => score.grade === item.grade).weighted_unit_price_usd.toFixed(2));
          }
          cost_breakdown_list.push({
            inventory_items_id: item.id,
            cost: unitCostCad,
            description: `Unit's PO cost, supplier: ${receivingItem.receiving_items.supplier}`,
            cost_usd: unitCostUsd,
            po_currency: poCurrency,
          });
        });
        await api.post('/cost_breakdown', { cost_breakdown_list });
        toast.success('Complete Assessment');
        history.push(`/assessment?size=${size}&page=${page}`);
      } else {
        toast.error('Lot Assessment already done');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  const partialLot = async () => {
    try {
      const inventoryItemsNotReady = inventoryItems.filter((el) => el.grade !== 'TBI' && el.is_ready !== true);

      const inventoryAssessmentData = await api.put(`inventory_items/handleAssessmentComplete/${receivingItemId}`, inventoryItemsNotReady);

      await api.post('inventory_assessment', inventoryAssessmentData.data);

      toast.success('Assessment Partially Completed');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const completeAdditionalLot = async () => {
    try {
      const inventoryItemsNotReady = inventoryItems.filter((el) => el.grade !== 'TBI' && el.is_ready !== true);

      const inventoryAssessmentData = await api.put(`inventory_items/handleAssessmentComplete/${receivingItemId}`, inventoryItemsNotReady);

      await api.post('inventory_assessment', inventoryAssessmentData.data);

      toast.success('Additional lot Assessment Completed');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const showModal = () => {
    setVisibleCopy(true);
  };

  const showNotesModal = () => {
    setVisibleNotes(true);
  };

  const handleOkCopy = useCallback(async () => {
    try {
      const baseSerialNumber = form.getFieldValue('baseSerialNumber');
      const serialNumbers = form.getFieldValue('mirrorSerialNumbers');
      if (baseSerialNumber && serialNumbers) {
        const baseUnitInfo = await api.post('assetDiagnostic/showBySerialNumber', { serialNumber: baseSerialNumber });

        const baseUnit = baseUnitInfo.data;

        const assetDiagnosticData = {
          user_id: profile.id,
          certified_grade: baseUnit.certified_grade,
          company_grade: baseUnit.company_grade,
          cosmetics_ac_adapter: baseUnit.cosmetics_ac_adapter,
          function_audio: baseUnit.function_audio,
          function_barcode_reader: baseUnit.function_barcode_reader,
          cosmetics_battery: baseUnit.cosmetics_battery,
          function_battery: baseUnit.function_battery,
          function_battery2: baseUnit.function_battery2,
          function_battery3: baseUnit.function_battery3,
          function_bluetooth: baseUnit.function_bluetooth,
          function_camera: baseUnit.function_camera,
          function_cfc2_bios_update: baseUnit.function_cfc2_bios_update,
          function_coa_report: baseUnit.function_coa_report,
          cosmetics_covers: baseUnit.cosmetics_covers,
          function_diagnostic_utility: baseUnit.function_diagnostic_utility,
          function_drivers_installed: baseUnit.function_drivers_installed,
          function_fingerprint: baseUnit.function_fingerprint,
          function_gps: baseUnit.function_gps,
          function_keyboard: baseUnit.function_keyboard,
          function_mousepad: baseUnit.function_mousepad,
          function_operating_system: baseUnit.function_operating_system,
          function_optical_drive: baseUnit.function_optical_drive,
          function_ram: baseUnit.function_ram,
          function_ram_2: baseUnit.function_ram_2,
          cosmetics_screen: baseUnit.cosmetics_screen,
          function_screen: baseUnit.function_screen,
          cosmetics_screws: baseUnit.cosmetics_screws,
          function_smartcard: baseUnit.function_smartcard,
          function_storage: baseUnit.function_storage,
          function_storage_2: baseUnit.function_storage_2,
          function_storage_extended: baseUnit.function_storage_extended,
          cosmetics_stylus: baseUnit.cosmetics_stylus,
          cosmetics_tether: baseUnit.cosmetics_tether,
          function_usb: baseUnit.function_usb,
          function_wlan: baseUnit.function_wlan,
          function_windows_activation: baseUnit.function_windows_activation,
          function_wwan: baseUnit.function_wwan,
          cosmetics_visual_appearance: baseUnit.cosmetics_visual_appearance,
          cosmetics_label_condition: baseUnit.cosmetics_label_condition,
          cosmetics_dents_scratches: baseUnit.cosmetics_dents_scratches,
          cosmetics_discoloration: baseUnit.cosmetics_discoloration,
          cosmetics_missing_parts: baseUnit.cosmetics_missing_parts,
          cosmetics_latches_hinges_damaged: baseUnit.cosmetics_latches_hinges_damaged,
          cosmetics_cleaned: baseUnit.cosmetics_cleaned,
          cosmetics_packaging: baseUnit.cosmetics_packaging,
          function_recovery_media: baseUnit.function_recovery_media,
          function_graphics_card: baseUnit.function_graphics_card,
          result_wiping_report: baseUnit.result_wiping_report,
          result_battery_report: baseUnit.result_battery_report,
          cosmetics_cracks: baseUnit.cosmetics_cracks,
          bios_password: baseUnit.bios_password,
          function_keyboard_ports: baseUnit.function_keyboard_ports,
          function_cpu: baseUnit.function_cpu,
          function_face_recognition: baseUnit.function_face_recognition,
          function_rfid: baseUnit.function_rfid,
          // function_nits: baseUnit.function_nits,
          // function_outdoor_viewable: baseUnit.function_outdoor_viewable,
          function_lcd_screen: baseUnit.function_lcd_screen,
          function_dedicated_gpu: baseUnit.function_dedicated_gpu,
        };

        const inventoryItemData = {
          action: 'copiedAssessment',
          coa_reported: baseUnit.inventory_items.coa_reported,
          original_coa: baseUnit.inventory_items.original_coa,
          touch: baseUnit.inventory_items.touch,
          cosmetic_screen: baseUnit.inventory_items.cosmetic_screen,
          stylus: baseUnit.inventory_items.stylus,
          camera: baseUnit.inventory_items.camera,
          processed_by: profile.name,
          keyboard_type_id: baseUnit.inventory_items.keyboard_type_id,
          operating_system: baseUnit.inventory_items.operating_system,
          status: 'ToBeInspected',
          is_processed: false,
          ac_adapter: baseUnit.inventory_items.ac_adapter,
          ac_adapter_amp: baseUnit.inventory_items.ac_adapter_amp,
          ac_adapter_item_id: baseUnit.inventory_items?.ac_adapter_item_id,
          usb_ports: baseUnit.inventory_items.usb_ports,
          windows_activated: baseUnit.inventory_items.windows_activated,
          wwan_model: baseUnit.inventory_items.wwan_model,
          graphics_card: baseUnit.inventory_items.graphics_card,
          wiping_report_file_id: baseUnit.inventory_items.wiping_report_file_id,
          keyboard_ports: baseUnit.inventory_items.keyboard_ports,
          cpu_id: baseUnit.inventory_items.cpu_id,
          smart_card_type: baseUnit.inventory_items.smart_card_type,
          // nits: baseUnit.inventory_items.nits,
          dedicated_gpu_model: baseUnit.inventory_items.dedicated_gpu_model,
          storage_2: baseUnit.inventory_items.storage_2,
          storage: baseUnit.inventory_items.storage,
          battery_item_id: baseUnit.inventory_items.battery_item_id,
          battery_item_id2: baseUnit.inventory_items.battery_item_id2,
          battery_item_id3: baseUnit.inventory_items.battery_item_id3,
          battery: baseUnit.inventory_items.battery,
          battery2: baseUnit.inventory_items.battery2,
          battery3: baseUnit.inventory_items.battery3,
          description: baseUnit.inventory_items.description,
        };

        if (ramFlag === true) {
          inventoryItemData.ram = baseUnit.inventory_items.ram;
          // inventoryItemData.ram_serial_number = baseUnit.inventory_items.ram_serial_number;
          inventoryItemData.ram_2 = baseUnit.inventory_items.ram_2;
          // inventoryItemData.ram_serial_number_2 = baseUnit.inventory_items.ram_serial_number_2;
        }

        if (copyComplete === true) {
          inventoryItemData.is_processed = baseUnit.inventory_items.is_processed;
          inventoryItemData.grade = baseUnit.inventory_items.grade;
          assetDiagnosticData.certified_grade = baseUnit.certified_grade;
          assetDiagnosticData.company_grade = baseUnit.company_grade;
          inventoryItemData.status = 'InStock';
          inventoryItemData.condition = baseUnit.inventory_items.condition;
        }

        const newInventoryItemData = [...inventoryItems];
        // const newInventoryItemData = { ...inventoryItems };
        serialNumbers.forEach(async (id) => {
          const serial_number = serialNumberList.find((item) => item.value === id).label;
          assetDiagnosticData.inventory_items_id = id;
          assetDiagnosticData.serial_number = serial_number;

          const copyAssetDiagnosticData = { ...assetDiagnosticData };
          inventoryItemData.serial_number = serial_number;

          const copyInventoryItemData = { ...inventoryItemData };

          await api.put('/inventory_items/completeInspection', { items: [copyInventoryItemData], assetDiagnosticData: copyAssetDiagnosticData });
          const copyItem = newInventoryItemData.find((item) => item.serial_number === serial_number);
          copyItem.updated_at = new Date();
          if (copyComplete === true) {
            copyItem.is_processed = true;
            copyItem.grade = baseUnit.inventory_items.grade;
            copyItem.status = 'InStock';
            copyItem.condition = baseUnit.inventory_items.condition;
          }
          toast.success('Unit was copied Successfully');
          setInventoryItems([...newInventoryItemData]);
          setSerialNumberList(newInventoryItemData.filter((el) => el.grade === 'TBI').map((item) => ({ value: item.id, label: item.serial_number })));
        });

        if (receivingItem.status !== 'partially_received') {
          const lotFinish = newInventoryItemData.filter((el) => el.status === 'ToBeInspected');

          if (lotFinish.length === 0) {
            setCompleteFlag(false);
            setPartialCompleteFlag(true);
          }
        }
        form.setFieldsValue({ baseSerialNumber: '' });
        form.setFieldsValue({ mirrorSerialNumbers: undefined });
        setRamFlag(false);
        setCopyComplete(false);
        setVisibleCopy(false);
      } else {
        toast.error('Please enter a Base Serial Number and select a list of Serial Numbers to copy.');
      }
    } catch (error) {
      toast.error(`${error}. Something went wrong. Contact support to explain what happened`);
    }
  }, [copyComplete, form, inventoryItems, profile.id, profile.name, ramFlag, serialNumberList, visibleCopy]); //eslint-disable-line

  const handleCancel = () => {
    form.setFieldsValue({ baseSerialNumber: '' });
    form.setFieldsValue({ mirrorSerialNumbers: undefined });
    setRamFlag(false);
    setVisibleCopy(false);
  };

  const handleOkNotes = async () => {
    const notesText = form.getFieldValue('lotNotes');
    if (notesText.length > 0) {
      try {
        await api.put(`receivingItems/${receivingItemId}`, {
          notes: notesText,
        });
        toast.success('Note was added Successfully');
      } catch (error) {
        toast.error(`${error}. Something went wrong. Contact support to explain what happened`);
      }
    }
    setVisibleNotes(false);
  };

  const handleCancelNotes = () => {
    setVisibleNotes(false);
  };

  const validateSerialNumber = useCallback(
    (serial_number) => {
      try {
        const processedUnit = inventoryItems.find((item) => item.serial_number?.toUpperCase() === serial_number?.toUpperCase());
        if (processedUnit.is_processed === true) {
          setSerialNumberError('success');
          return { status: true, message: 'Unit has been processed' };
        }
        setSerialNumberError('error');
        return { status: false, message: `Unit hasn't been processed` };
      } catch {
        setSerialNumberError('error');
        return { status: false, message: 'Unit is not on this Lot' };
      }
    },
    [inventoryItems]
  );

  const validadeSerialNumbersList = useCallback((serial_numbers) => {
    if (serial_numbers.length > 0) {
      return { status: true, message: '' };
    }
    return { status: false, message: 'Please Select Serial Number from the list' };
  }, []);

  const modalMenu = (
    // <Form form={form} name="copyInspection">
    <>
      <Form.Item
        hasFeedback
        name="baseSerialNumber"
        label="Base Serial Number"
        rules={[
          {
            validator: (_, value) => {
              const { status, message } = validateSerialNumber(value);
              return status ? Promise.resolve(message) : Promise.reject(message);
            },
          },
        ]}
        validateTrigger="onBlur"
        validateStatus={serialNumberError}
      >
        <Input placeholder="Unit Serial Number" allowClear />
      </Form.Item>
      <Form.Item
        name="mirrorSerialNumbers"
        label="Serial Numbers to copy"
        rules={[
          {
            validator: (_, value) => {
              const { status, message } = validadeSerialNumbersList(value);
              return status ? Promise.resolve(message) : Promise.reject(message);
            },
          },
        ]}
      >
        <Select placeholder="Serial Numbers" mode="multiple" options={serialNumberList} optionFilterProp="label" />
      </Form.Item>
      <Form.Item name="ramFlag" label="Copy RAM">
        <Switch checked={ramFlag} onChange={(value) => setRamFlag(value)} />
      </Form.Item>
      <Form.Item name="copyComplete" label="Finish Assessment for Copies">
        <Switch checked={copyComplete} onChange={(value) => setCopyComplete(value)} />
      </Form.Item>
    </>
    // </Form>
  );

  const modalNotes = (
    <Form.Item name="lotNotes">
      <TextArea name="lotNotes" />
    </Form.Item>
  );

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link
            to={() =>
              query === undefined
                ? `/assessment?size=${size}&page=${page}`
                : `/assessment?filterCriteria=${filterCriteria}&searchQuery=${query}&size=${size}&page=${page}`
            }
          >
            <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
              Back
            </Button>
          </Link>
          {additionalItemsFlag === true ? <Title level={3}>Additional Lot Assessment</Title> : <Title level={3}>Lot Assessment</Title>}

          <div />
        </div>
      </Card>
      <Card elevation={3} size="small">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            {additionalItemsFlag === true ? (
              <Tooltip title="Pressing this button will make units on the Additional lot ready for Tech inspection." color="orange">
                <Button type="primary" onClick={() => completeAdditionalLot()}>
                  Partial Complete Additional Lot
                </Button>
              </Tooltip>
            ) : (
              <>
                <Button type="primary" disabled={completeFlag} onClick={() => completeLot()}>
                  Complete
                </Button>
                <Button type="primary" disabled={partialCompleteFlag} onClick={() => partialLot()}>
                  Partial Complete
                </Button>
                <Button type="default" href={`InventoryOfReceiving?id=${receivingId}&receivingItemId=${receivingItemId}`} target="_blank">
                  Go To Receiving Lot
                </Button>
              </>
            )}
          </Space>
          <Form form={form} name="assessmentForm">
            <Space>
              <Button type="primary" style={{ backgroundColor: 'orange', borderColor: 'orange' }} onClick={showNotesModal}>
                Add Notes
              </Button>
              <Button type="primary" style={{ backgroundColor: '#3EB19E', borderColor: '#3EB19E' }} onClick={showModal}>
                Copy Inspection
              </Button>
              <Modal title="Add Notes" visible={visibleNotes} onOk={handleOkNotes} onCancel={handleCancelNotes} width={700}>
                {modalNotes}
              </Modal>
              <Modal title="Copy inspection" visible={visibleCopy} onOk={handleOkCopy} onCancel={handleCancel} width={700}>
                {modalMenu}
              </Modal>
            </Space>
          </Form>
        </div>
      </Card>

      {loading ? (
        <Spin tip="Loading...">
          <Alert
            message="Search is looking for the items. Hold on a second"
            description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
            type="info"
          />
        </Spin>
      ) : inventoryItems.length <= 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Use the search box above to list assessment</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      ) : (
        <Table
          style={{ marginTop: '5px' }}
          columns={columns}
          dataSource={inventoryItems}
          size="small"
          bordered
          pagination={false}
          scroll={{ y: 'calc(100vh - 18em)' }}
          rowKey={(record) => record.id}
        />
      )}
    </Container>
  );
}
