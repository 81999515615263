import { Line } from '@ant-design/plots';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Table, Container } from './styles';

const { Title } = Typography;

export function RMA() {
  const [yearlyAverage, setYearlyAverage] = useState({});
  const [percentList, setPercentList] = useState([]);

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      if (obj.percent) {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = {
            totalPercent: 0,
            totalAmount: 0,
          };
        }
        acc[key].totalPercent += obj.percent;
        acc[key].totalAmount += 1;
      }
      return acc;
    }, {});
  }

  async function loadData() {
    const kpiRma = await api.get(`kpi/rmaYearly`);

    setPercentList(kpiRma.data);

    const grouped = groupBy(kpiRma.data, 'year');
    setYearlyAverage(grouped);
  }

  useEffect(() => {
    loadData();
  }, []); //eslint-disable-line

  const config = {
    data: percentList,
    xField: 'month',
    yField: 'percent',
    seriesField: 'year',
    tooltip: {
      fields: ['month', 'percent', 'year', 'shipped', 'returned'],
      formatter: (datum) => {
        if (datum.percent) {
          return { name: `${datum.year} `, value: `${datum.percent}% (Shipped: ${datum.shipped} Returned: ${datum.returned})` };
        }
        return '';
      },
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', 5],
        end: ['max', 'max'],
        color: '#F4664A',
      },
      {
        type: 'line',
        start: ['min', 5],
        end: ['max', 5],
        style: {
          stroke: '#F4664A',
          lineDash: [2, 2],
        },
      },
    ],
  };

  return (
    <Container>
      <Title level={2}>Items returned</Title>
      <Line {...config} style={{ width: '60vw' }} />

      <Table>
        <thead>
          <tr>
            <th>Year</th>
            <th>Average</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(yearlyAverage).map((el) => (
            <tr key={el}>
              <td>{el}</td>
              <td>{`${(yearlyAverage[el].totalPercent / yearlyAverage[el].totalAmount).toFixed(2)}%`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
