export default function AssignKBType(keyboard_type_id) {
  switch (keyboard_type_id) {
    case 1:
      return 'rubber - EN';
    case 2:
      return 'rubber - CA-FR';
    case 3:
      return 'standard - EN';
    case 4:
      return 'standard - CA-FR';
    case 5:
      return 'emissive - EN';
    case 6:
      return 'emissive - CA-FR';
    default:
      return '';
  }
}
