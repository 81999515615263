/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Alert, Card, Checkbox, DatePicker, Input, Pagination, Select, Space, Spin, Switch, Table, Tag, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { Container } from './styles';

// import 'antd/dist/antd.css';

const { Title } = Typography;

export default function PurchaseOrders(props) {
  const profile = useSelector((state) => state.user.profile);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(true);

  const [filterCriteria, setFilterCriteria] = useState('po_number');
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterDate, setfilterDate] = useState([undefined, undefined]);
  const [paginationTotalItems, setPaginationTotalItems] = useState();
  const [paginationSize, setPaginationSize] = useState(new URLSearchParams(props.location.search).get('size') || 25);
  const [paginationPage, setPaginationPage] = useState(new URLSearchParams(props.location.search).get('page') || 1);
  const [receivedValues, setReceivedValues] = useState(['pending']);
  const [sortOrder, setSortOrder] = useState('descend');

  const searchTextRef = useRef('');

  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;

  const handleSearch = useCallback(
    async (filterText) => {
      setLoading(true);
      const [filterStartDate, filterEndDate] = filterDate || [];
      try {
        const purchaseOrderData = await api.get(`purchaseOrder?size=${paginationSize}&page=${paginationPage - 1}`, {
          headers: {
            filterCriteria,
            filterText: filterText?.trim() || searchTextRef.current.input.value || '',
            filterStartDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndDate: filterEndDate ? moment(filterEndDate).add(1, 'day').format('YYYY-MM-DD') : '',
            filterReceived: receivedValues.join(','),
            order: sortOrder,
          },
        });

        setPaginationTotalItems(() => purchaseOrderData.data.totalItems);
        setPaginationSize(() => purchaseOrderData.data.sizePage);
        setPaginationPage(() => purchaseOrderData.data.currentPage + 1);
        setPurchaseOrders(() => purchaseOrderData.data.rows);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    },
    [filterCriteria, filterDate, paginationPage, paginationSize, receivedValues, sortOrder]
  );

  const handleSearchTextField = useCallback(
    async (filterText) => {
      setLoading(true);
      const [filterStartDate, filterEndDate] = filterDate || [];
      const receivedOptions = ['pending', 'fully_received', 'partially_received'];
      try {
        const purchaseOrderData = await api.get(`purchaseOrder?size=${paginationSize}&page=${paginationPage - 1}`, {
          headers: {
            filterCriteria,
            filterText: filterText?.trim() || searchTextRef.current.input.value || '',
            filterStartDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndDate: filterEndDate ? moment(filterEndDate).add(1, 'day').format('YYYY-MM-DD') : '',
            filterReceived: receivedOptions.join(','),
            order: sortOrder,
          },
        });

        setReceivedValues(() => receivedOptions);
        setPaginationTotalItems(() => purchaseOrderData.data.totalItems);
        setPaginationSize(() => purchaseOrderData.data.sizePage);
        setPaginationPage(() => purchaseOrderData.data.currentPage + 1);
        setPurchaseOrders(() => purchaseOrderData.data.rows);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    },
    [filterCriteria, filterDate, paginationPage, paginationSize, sortOrder]
  );

  const receivedOptions = [
    {
      label: 'Pending',
      value: 'pending',
      color: 'red',
    },
    {
      label: 'Fully Received',
      value: 'fully_received',
      color: 'green',
    },
    {
      label: 'Partially Received',
      value: 'partially_received',
      color: 'yellow',
    },
  ];

  const handleChangePage = (page, pageSize) => {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  };

  const handleClearFilters = useCallback(() => {
    setIsFiltered(false);
    setfilterDate([undefined, undefined]);
    setReceivedValues(['pending']);
    setSortOrder(() => 'ascend');
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    if (searchQuery) {
      searchTextRef.current.input.value = searchQuery;
      handleSearch();
    }
  }, []); //eslint-disable-line

  const handleSort = (_pagination, _filter, sorter) => {
    setIsFiltered(() => true);
    setSortOrder(() => sorter.order);
    // setSortFiled(() => sorter.field);
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  function isUserWatchingPO(text) {
    if (text !== '') {
      return { watching: text.includes(profile?.email), no_email: false };
    }
    return { watching: false, no_email: true };
  }

  async function handleChangeWatchPO(row) {
    let newWatchingPo = '';
    const IS_USER_WATCHING_PO = isUserWatchingPO(row.watching_po);
    const emailArray = row.watching_po.split(';');
    if (IS_USER_WATCHING_PO.watching) {
      const filterEmailArray = emailArray.filter((email) => email !== profile?.email).join(';');
      newWatchingPo = filterEmailArray;
    } else if (IS_USER_WATCHING_PO.no_email) {
      newWatchingPo = profile?.email;
    } else {
      emailArray.push(profile?.email);
      newWatchingPo = emailArray.join(';');
    }
    const newPOList = [...purchaseOrders];
    newPOList.find((item) => item.po_number === row.po_number).watching_po = newWatchingPo;
    try {
      await api.put(
        `purchaseOrder/${row.id}`,
        newPOList.find((item) => item.po_number === row.po_number)
      );
      setPurchaseOrders(newPOList);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'qbo_created_at',
      width: 200,
      render: (text) => moment(text).format('MMM DD, YYYY'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Purchase Order',
      dataIndex: 'po_number',
      key: 'po_number',
      render: (text) => (
        <Link to={`/purchaseOrders/${text}?searchQuery=${searchTextRef.current.input.value}&size=${paginationSize}&page=${paginationPage}`}>{text}</Link>
      ),
    },
    {
      title: 'Supplier',
      dataIndex: ['suppliers', 'name'],
      key: 'suppliers',
    },

    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text) => `$${parseFloat(text).toFixed(2)}`,
    },
    {
      title: 'Receiving Status',
      dataIndex: 'received',
      key: 'received',
      render: (text) => {
        const tagOption = receivedOptions.find((el) => el.value === text);
        return (
          <Tag color={tagOption?.color} key={tagOption?.label}>
            {tagOption?.label.toUpperCase() || text}
          </Tag>
        );
      },
    },
    {
      title: 'Watch PO',
      dataIndex: 'watching_po',
      key: 'watching_po',
      render: (text, row) => <Switch onChange={() => handleChangeWatchPO(row)} checked={isUserWatchingPO(text).watching} />,
    },
  ];

  return (
    <Container>
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div />
          <Title level={3}>Purchase Orders</Title>
          <div />
        </div>
        <Card size="small" bordered={false}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space wrap>
              <Select
                onChange={(value) => {
                  setFilterCriteria(value);
                  // setPaginationPage(1);
                  searchTextRef.current.input.value = '';
                  searchTextRef.current.focus();
                }}
                value={filterCriteria}
                style={{ width: 145 }}
              >
                <Option value="po_number">Purchase Order</Option>
                <Option value="supplier">Supplier</Option>
              </Select>

              <Search placeholder="Search" ref={searchTextRef} allowClear onSearch={handleSearchTextField} style={{ width: 250 }} enterButton />
            </Space>

            <Space wrap>
              {isFiltered && (
                <Tag
                  closable
                  color="error"
                  onClose={(e) => {
                    e.preventDefault();
                    handleClearFilters();
                  }}
                >
                  Clear Filters
                </Tag>
              )}
              <CheckboxGroup
                options={receivedOptions}
                onChange={(values) => {
                  setReceivedValues(values);
                  setIsFiltered(true);
                }}
                value={receivedValues}
              />
              <RangePicker
                onChange={(date) => {
                  setfilterDate(date);
                  // setPaginationPage(1);
                  setIsFiltered(true);
                }}
                value={filterDate}
                allowClear
              />
            </Space>
          </div>
        </Card>
        {/* <Table columns={columns} dataSource={purchaseOrders} pagination={{ pageSize: 25 }} size="small" /> */}
        {loading ? (
          <Spin tip="Loading...">
            <Alert message="Loading Purchase Order data" description="Once all items are loaded, you will be able to read the purchase orders." type="info" />
          </Spin>
        ) : (
          <>
            <Table
              style={{ marginTop: '5px' }}
              columns={columns}
              dataSource={purchaseOrders}
              size="small"
              bordered
              pagination={false}
              scroll={{ y: 'calc(100vh - 22.8em)' }}
              rowKey={(record) => record.id}
              onChange={handleSort}
            />

            <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
              <Pagination
                size="small"
                total={paginationTotalItems}
                showSizeChanger
                showQuickJumper
                defaultPageSize={paginationSize}
                pageSizeOptions={[25, 50, 100]}
                onChange={handleChangePage}
                current={paginationPage}
              />
            </Card>
          </>
        )}
      </Card>
    </Container>
  );
}
