import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import api from '~/services/api';
import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: '#fff',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  table: {
    minWidth: 550,
    maxWidth: 1050,
  },
  search: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paper: {
    marginTop: '5px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SimpleContainer() {
  const classes = useStyles();

  const [searchTxt /* setSearchTxt */] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [entitlements, setEntitlements] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [open, setOpen] = React.useState(false);

  const inputSearch = useRef(searchTxt);

  const handleSearch = async value => {
    setOpen(true);
    if (!value) return;

    const dellData = await api.get(`warranty?serial_number=${value.trim().toUpperCase()}`);
    if (dellData.data.length === 0) {
      toast.error('Service Tag NOT FOUND');
      setOpen(false);
      return;
    }
    setProductDescription(dellData.data[0].product_description);
    setEntitlements(dellData.data.filter(unitInfo => unitInfo.type === 'entitlement'));
    setSpecs(dellData.data.filter(unitInfo => unitInfo.type === 'components'));
    setOpen(false);
  };

  useEffect(() => {
    inputSearch.current.focus();
  }, []); //eslint-disable-line

  return (
    <Container>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Paper elevation={3} className={classes.paper}>
          <TextField
            label="Search"
            id="outlined-margin-dense"
            className={classes.textField}
            onBlur={e => {
              e.target.value = e.target.value.trim().toUpperCase();
            }}
            autoFocus
            margin="dense"
            variant="outlined"
            inputRef={inputSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch(e.target.value);
              }
            }}
            InputProps={{
              endAdornment: <Search className={classes.search} onClick={() => handleSearch(inputSearch.current.value)} />,
            }}
          />
        </Paper>
        {productDescription && (
          <Paper elevation={3} className={classes.paper}>
            <Card className={classes.root}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {productDescription}
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        )}
        {entitlements.length > 0 && (
          <Paper elevation={3} className={classes.paper}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>WARRANTY SERVICE</TableCell>
                    <TableCell>START DATE</TableCell>
                    <TableCell>EXPIRATION DATE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entitlements.map(entitlement => (
                    <TableRow key={entitlement.code}>
                      <TableCell>{entitlement.description}</TableCell>
                      <TableCell>{moment(entitlement.start_date).format('MMMM DD, YYYY')}</TableCell>
                      <TableCell>{moment(entitlement.end_date).format('MMMM DD, YYYY')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        {specs.length > 0 && (
          <Paper elevation={3} className={classes.paper}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>PART NUMBER</TableCell>
                    <TableCell>DELL IDENTIFICATION</TableCell>
                    <TableCell>DESCRIPTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specs.map(entitlement => (
                    <TableRow key={entitlement.code}>
                      <TableCell>{entitlement.code}</TableCell>
                      <TableCell>{entitlement.service_level_description}</TableCell>
                      <TableCell>{entitlement.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </div>
    </Container>
  );
}
