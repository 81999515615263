import { Badge } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  overflow: auto;
  width: 100%;
  padding: 75px 20px 0 250px;
  height: 100vh;

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  /* height: 50px; */
  border: 0;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  padding: 0 1.25rem;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: #1378d6;
    transition: background-color 0.2s;
  }
`;

export const TransactionContainer = styled.main`
  width: 100%;
  max-width: 1120px;
  /* height: 800px; */
  height: calc(100vh - 320px);
  /* border: 5px solid green; */
  box-sizing: border-box;
  overflow: scroll;
  margin-bottom: 0.8rem;

  /* margin: 4rem auto 0;
  padding: 0 1.5rem; */
`;

export const BadgeStyled = styled(Badge)`
  display: flex;
  width: 30px;
  height: 50px;
`;

export const SearchContainer = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-self: flex-start;
  position: fixed;
  top: 100;
  z-index: 1; */
`;

export const SearchSummary = styled.div`
  display: flex;

  table {
    margin-left: 100px;
    border: 1px solid black;
  }

  /* background: #ededed; */
  /* display: flex; */
  /* border-collapse: collapse;
  border-spacing: 0 2px;
  border-radius: 10px;
  margin-top: 1.2rem;
  background: #ededed;

  padding: 0.7rem; */
`;

export const TransactionTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0 2px;
  width: 100%;
  border-radius: 10px;
  /* margin-top: 1.2rem; */
  background: #ededed;

  padding: 0.7rem;

  thead {
    /* background: #e0e0e0; */
    border-bottom: 1px solid #fff;
    font-weight: bold;

    td:first-child {
      border-top-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  tbody {
    tr {
      td {
        div,
        span {
          font-weight: normal !important;
          font-size: inherit !important;
        }
      }
    }
  }

  tr:nth-child(even) {
    background: ${props => props.theme['gray-100']};
  }

  tr,
  td {
    padding: 5px 10px;
  }

  & .highlighted {
    background-color: palegreen;
  }
`;

export const LevelContainer = styled.div`
  width: 100%;
  max-width: 520px;
  margin-bottom: 1rem;
`;

export const ContainerForTables = styled.div`
  display: flex;
  gap: 1rem;

  @media (width <= 1650px) {
    display: flex;
    /* grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr; */
    flex-direction: column-reverse;
  }
`;
