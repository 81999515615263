/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Paper } from '@material-ui/core';
import { Badge, Button, Card, Col, Divider, Empty, Row, Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import printJS from 'print-js';
import api from '~/services/api';

import { Container } from './styles';

const { Title } = Typography;

export default function PurchaseOrderDetail(params) {
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({ suppliers: {}, purchase_order_items: [{}] });
  const [poItems, setPoItems] = useState([]);

  const [size, setSize] = useState();
  const [page, setPage] = useState();
  const [query, setQuery] = useState();
  const [flagR, setFlagR] = useState(false);

  useEffect(() => {
    async function loadData() {
      const location = new URLSearchParams(params.location.search);
      const pageSize = location.get('size');
      const pages = location.get('page');
      const searchQuery = location.get('searchQuery');
      const receivingFlag = location.get('flag');

      setSize(pageSize || 25);
      setPage(pages || 1);
      setQuery(searchQuery === 'undefined' ? undefined : searchQuery);
      setFlagR(receivingFlag);
      try {
        const response = await api.get(`purchaseOrder/${params.match.params.po_number}`);

        if (response.data.error) {
          toast.error(response.data.error);
        }

        setPurchaseOrderDetails(response.data);
        setPoItems(response.data.purchase_order_items.map(e => ({ key: e.id, ...e })));
      } catch (error) {
        // toast.error('Purchase order not found');
      }

      // setPoItems([...response.data.purchase_order_items.map((e, i) => ({ key: i, ...e }))]);
    }
    loadData();
  }, []); //eslint-disable-line

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Unit price',
      dataIndex: 'unit_price',
      render: text => `$${parseFloat(text).toFixed(2)}`,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: text => `$${parseFloat(text).toFixed(2)}`,
    },
  ];

  if (purchaseOrderDetails.error) {
    return (
      <Empty
        description={<span>Purchase Order not found</span>}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh',
        }}
      />
    );
  }

  const handleDownloadPDF = async () => {
    try {
      const pdf = await api.get(`getPurchaseOrderPDF/${purchaseOrderDetails.qbo_id}`);

      // Convert the buffer data to a Uint8Array
      const uint8Array = new Uint8Array(pdf.data.data);

      // Convert Uint8Array to Blob
      const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Print the PDF using print-js
      printJS(pdfUrl);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <Container>
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Link
              to={() =>
                flagR === 'true'
                  ? '/receiving'
                  : query === undefined || query === null
                  ? `/purchaseOrders?size=${size}&page=${page}`
                  : `/purchaseOrders?searchQuery=${query}&size=${size}&page=${page}`
              }
            >
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            <Button variant="contained" type="primary" onClick={handleDownloadPDF}>
              Print PO
            </Button>
          </Space>
          <Title level={3}>Purchase Order {params.match.params.po_number}</Title>
          <div />
        </div>
        <Paper>
          <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={8}>
                <Card bordered={false}>
                  <Badge.Ribbon
                    text={`P.O. ${purchaseOrderDetails.status}`}
                    color={purchaseOrderDetails.status === 'Deleted' ? 'red' : purchaseOrderDetails.status === 'Open' ? 'green' : 'blue'}
                  >
                    <Card title="Purchase Order Details" size="small">
                      <p>
                        <b>P.O.Number:</b> {purchaseOrderDetails.po_number}
                      </p>
                      {purchaseOrderDetails.invoice !== undefined && (
                        <p>
                          <b>Invoice:</b> {purchaseOrderDetails.invoice.join(', ')}
                        </p>
                      )}
                      {/* Date first shows today's date, then it loads to the DB date. */}
                      <p>
                        <b>Date:</b> {moment(purchaseOrderDetails.qbo_created_at).format('MMM DD, YYYY')}
                      </p>
                      <p>
                        <b>Currency:</b> {purchaseOrderDetails.currency}
                      </p>
                      <p>
                        <b>Total:</b> ${purchaseOrderDetails.total}
                      </p>

                      {purchaseOrderDetails.attachments !== undefined && (
                        <p>
                          <b>Attachments:</b>{' '}
                          {purchaseOrderDetails.attachments.map(attachment => (
                            <Button type="link" key={attachment.fileUrl} target="_blank" rel="noopener noreferrer" href={attachment.fileUrl}>
                              {attachment.fileName}
                            </Button>
                          ))}
                        </p>
                      )}
                    </Card>
                  </Badge.Ribbon>
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false}>
                  <Badge>
                    <Card title="Supplier" size="small">
                      <p>
                        <b>Supplier:</b> {purchaseOrderDetails.suppliers?.name}
                      </p>
                      <p>
                        <b>name:</b> {purchaseOrderDetails.suppliers?.name}
                      </p>
                      <p>
                        <b>company:</b> {purchaseOrderDetails.suppliers?.company}
                      </p>
                      <p>
                        <b>phone:</b> {purchaseOrderDetails.suppliers?.phone}
                      </p>
                      <p>
                        <b>email:</b> {purchaseOrderDetails.suppliers?.email}
                      </p>
                      <p>
                        <b>Address:</b>
                        {purchaseOrderDetails.suppliers?.street1} {purchaseOrderDetails.suppliers?.street2} {purchaseOrderDetails.suppliers?.street3}
                        {purchaseOrderDetails.suppliers?.city}, {purchaseOrderDetails.suppliers?.state}, {purchaseOrderDetails.suppliers?.postal_code},{' '}
                        {purchaseOrderDetails.suppliers?.country}
                      </p>
                    </Card>
                  </Badge>
                </Card>
              </Col>
            </Row>
            <Divider />
          </div>
          <Table columns={columns} dataSource={poItems} size="small" pagination={false} />
        </Paper>
      </Card>
    </Container>
  );
}
