/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Card, Empty, Input, Pagination, Select, Space, Table, Tag, Typography } from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import api from '~/services/api';
import history from '~/services/history';

import CountryList from '~/utils/countriesList';
import { Container } from './styles';

export default function Customers(props) {
  const [customers, setCustomers] = useState([]);
  const profile = useSelector(state => state.user.profile);

  const [isFiltered, setIsFiltered] = useState(false);
  const [filterCountry, setFilterCountry] = useState('');
  const [paginationSize, setPaginationSize] = useState(new URLSearchParams(props.location.search).get('size') || 25);
  const [paginationPage, setPaginationPage] = useState(new URLSearchParams(props.location.search).get('page') || 1);
  const [paginationTotalItems, setPaginationTotalItems] = useState();

  // const [searchTxt, setSearchTxt] = useState('');
  const searchTextRef = useRef('');

  const { Search } = Input;
  const { Title } = Typography;

  const countryList = [{ value: '', label: 'All Countries' }, ...CountryList()];

  const handleSearch = useCallback(
    async value => {
      const response = await api.get(`customers?size=${paginationSize}&page=${paginationPage - 1}`, {
        headers: {
          filterText: value?.trim() || searchTextRef.current.input.value || '',
          filterCountry: filterCountry || '',
        },
      });
      if (response.data.error) {
        toast.warn('Customer has not been found!');
        return;
      }
      setPaginationTotalItems(response.data.totalItems);
      setPaginationSize(response.data.sizePage);
      setPaginationPage(response.data.currentPage + 1);
      setCustomers(response.data.rows);
      // setSearchTxt(value);
    },
    [filterCountry, paginationPage, paginationSize]
  );

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    if (searchQuery) {
      searchTextRef.current.input.value = searchQuery;
      handleSearch();
    }
  }, []); //eslint-disable-line

  const handleChangePage = (page, pageSize) => {
    setPaginationPage(page);
    setPaginationSize(pageSize);
  }

  const handleClearFilters = useCallback(() => {
    setIsFiltered(false);
    setFilterCountry('');
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, rowInfo) => (
        <Link to={`/customersNew?id=${rowInfo.id}&searchQuery=${searchTextRef.current.input.value}&size=${paginationSize}&page=${paginationPage}`}>{text}</Link>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'City',
      dataIndex: 'city',
    },
    {
      title: 'State/Province',
      dataIndex: 'state',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      render: text => (text === null ? text : countryList.find(e => e.value === text)?.label || ''),
    },
  ];

  return (
    <Container>
      <Card size="small" bordered={false}>
        {/* <h1>Customers</h1> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {profile.type === 3 || profile.type === 999 ? (
            <Button onClick={() => history.push('/customersNew')} variant="contained" type="primary" icon={<PlusOutlined />}>
              Add
            </Button>
          ) : (
            <div />
          )}
          <Title level={3}>Customers</Title>
          <div />
        </div>
      </Card>
      <Card elevation={3} size="small">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            <Search
              placeholder="Search Customer's Name"
              ref={searchTextRef}
              allowClear
              onSearch={() => {
                handleSearch();
                // setPaginationPage(1);
              }}
              style={{ width: 300 }}
              enterButton
            />
          </Space>
          <Space wrap>
            {isFiltered && (
              <Tag
                closable
                color="error"
                onClose={e => {
                  e.preventDefault();
                  handleClearFilters();
                }}
              >
                Clear Filters
              </Tag>
            )}
            <Select
              onChange={value => {
                setFilterCountry(value);
                // setPaginationPage(1);
                setIsFiltered(true);
                handleSearch();
              }}
              value={filterCountry}
              style={{ width: 250 }}
              options={countryList}
              showSearch
              optionFilterProp="label"
            />
          </Space>
        </div>
      </Card>

      {customers.length <= 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Use the search box above to list customers</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      ) : (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={customers}
            size="small"
            bordered
            pagination={false}
            scroll={{ y: 'calc(100vh - 22em)' }}
            rowKey={record => record.id}
          />
          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}
