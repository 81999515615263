/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Form, Input, InputNumber, Radio, Space, Upload } from 'antd';

import api from '~/services/api';

const { TextArea } = Input;

export default function HardDrive({ itemInfo, form, itemDisabled }) {
  const [reportList, setReportList] = useState([]);
  const [unitWhereHDIsInstalled, setUnitWhereHDIsInstalled] = useState(null);

  useEffect(() => {
    if (itemInfo.wiping_report_file !== '' && itemInfo.wiping_report_file !== null && itemInfo.wiping_report_file !== undefined) {
      setReportList([
        {
          uid: itemInfo.wiping_report_file.id,
          url: itemInfo.wiping_report_file.url,
          name: itemInfo.wiping_report_file.name,
        },
      ]);
      form.setFieldsValue({ wipingReportId: itemInfo.wiping_report_file.id });
    }
    setUnitWhereHDIsInstalled(null);
  }, [itemInfo]); //eslint-disable-line

  const handleChange = useCallback(
    async ({ file }) => {
      const formData = new FormData();

      formData.append('file', file.originFileObj);

      let picturesList = [];

      try {
        const responseUploadPictures = await api.post('files', formData);
        picturesList = responseUploadPictures.data.map((el) => ({
          uid: el.id,
          url: el.url,
          name: el.name,
        }));
      } catch (error) {
        Alert.alert('Error', 'Something went wrong - Pictures');
      }

      form.setFieldsValue({ wipingReportId: picturesList[0]?.uid });

      setReportList(picturesList);
    },
    [form]
  );

  const handleRemove = async () => {
    form.setFieldsValue({ wipingReportId: null });
  };

  const dummyRequest = ({ onSuccess }) => {
    onSuccess('ok');
  };

  const handleFindUnit = async () => {
    const unitData = await api.get(`inventory_items?storage_serial_number=${itemInfo.serial_number}`);

    if (unitData.data.length > 0) {
      setUnitWhereHDIsInstalled({ id: unitData.data[0].id, serialNumber: unitData.data[0].serial_number });
    }
  };

  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="Storage Health" span={3}>
        <Space>
          <Form.Item hasFeedback name="storageHealth1" rules={[{ required: true }]}>
            <InputNumber
              placeholder="Health"
              min={0}
              max={100}
              formatter={(value) => (value === '' ? '' : `${value}%`)}
              parser={(value) => value.replace('%', '')}
              disabled={itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>

      {(itemInfo?.status === 'InUse' || itemInfo?.status === 'Sold') && (
        <Descriptions.Item label="Being Used At" span={3} style={{ width: '400px' }}>
          <Space>
            <Button onClick={handleFindUnit}>Find unit</Button>
            {unitWhereHDIsInstalled !== null && (
              <Button type="link" target="_blank" rel="noopener noreferrer" href={`/inventoryProcessing/${unitWhereHDIsInstalled.id}`}>
                {unitWhereHDIsInstalled.serialNumber}
              </Button>
            )}
          </Space>
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Report" span={2} style={{ width: '400px' }}>
        <Space>
          <Form.Item name="wipingReportId">
            <Upload fileList={reportList} onRemove={handleRemove} customRequest={dummyRequest} onChange={handleChange} multiple={false}>
              <Button icon={<UploadOutlined />} disabled={itemDisabled}>
                Upload
              </Button>
            </Upload>
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item label={<Button type="link" />}>
        <Form.Item hasFeedback name="resultWipingReport" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" name="reportTest" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Notes" span={3}>
        <Space>
          <Form.Item hasFeedback name="notes">
            <TextArea
              showCount
              maxLength={255}
              allowClear
              style={{ width: 500 }}
              placeholder="Add additional information about this item"
              rows={4}
              disabled={itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
}
