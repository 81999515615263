/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import { Form, Input, InputNumber, Space, Table, Typography } from 'antd';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function GradingWeight() {
  const [gradingScoreList, setGradingScoreList] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  // const log_id = uuidv4();
  const profile = useSelector(state => state.user.profile);

  const statusList = [
    { value: 'New', label: 'New' },
    { value: 'OB', label: 'Open Box' },
    { value: 'U', label: 'Used (Hard Drives)' },
    { value: 'FP', label: 'For Parts' },
    { value: 'A', label: 'Grade A' },
    { value: 'B', label: 'Grade B' },
    { value: 'C', label: 'Grade C' },
    { value: 'D', label: 'Grade D' },
    { value: 'FA', label: 'Failed' },
  ];

  useEffect(() => {
    async function loadGradingScore() {
      const gradingScoreInfo = await api.get('grading_score_weight');
      setGradingScoreList(gradingScoreInfo.data.sort((a, b) => a.id - b.id));
    }

    loadGradingScore();
  }, []);

  const EditableCell = ({ editing, dataIndex, title, inputType, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber max={10} min={0} /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = record => record.id === editingKey;

  const edit = record => {
    form.setFieldsValue({
      weight: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async id => {
    try {
      const row = await form.validateFields();
      const newScore = [...gradingScoreList];
      const index = newScore.findIndex(item => id === item.id);

      if (index > -1) {
        const item = newScore[index];
        await api.put(`grading_score_weight/${id}`, row);
        await api.post('logs', {
          // log_id,
          routine: `${item.status} Grading weight changed`,
          action: 'Update',
          previous_data: item.weight,
          new_data: row.weight,
          user_id: profile.id,
          reference_id: id,
        });
        newScore.splice(index, 1, { ...item, ...row });
        setGradingScoreList(newScore);
        setEditingKey('');
        toast.success('Weight changed Successfully');
      } else {
        newScore.push(row);
        setGradingScoreList(newScore);
        setEditingKey('');
      }
    } catch (errInfo) {
      toast.error('Change Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      width: '30%',
      render: status => {
        return statusList.find(item => item.value === status).label;
      },
      // editable: true,
    },
    {
      title: 'Weight (Min: 0, Max: 10)',
      dataIndex: 'weight',
      width: '30%',
      editable: true,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Typography.Link
              onClick={() => cancel()}
              style={{
                marginRight: 8,
              }}
            >
              Cancel
            </Typography.Link>
          </Space>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'weight' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={gradingScoreList}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination="false"
        rowKey="id"
      />
    </Form>
  );
}
