/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { Button, Divider, List, Popconfirm, Select, Table } from 'antd';
import { toast } from 'react-toastify';
import { Container, InputContainer, Wrapper } from './styles';

import api from '~/services/api';

export default function UserPermissions() {
  const [userGroupList, setUserGroupList] = useState([]);
  const [locationsTypeList, setLocationsTypeList] = useState([]);
  const [userGroupUsersData, setUserGroupUsersData] = useState([]);
  const [locationsPermissionsList, setLocationsPermissionsList] = useState([]);

  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedFromLocationTypeId, setSelectedFromLocationTypeId] = useState(null);
  const [selectedToLocationTypeId, setSelectedToLocationTypeId] = useState(null);
  const [usersOnSelectedGroup, setUsersOnSelectedGroup] = useState([]);

  useEffect(() => {
    async function loadLists() {
      const groupListData = await api.get('location-user-permission/listUserGroups');
      const groupListDataFormatted = groupListData.data.groups.map(el => ({
        value: el.id,
        label: el.name,
      }));
      setUserGroupList(groupListDataFormatted);
      setUserGroupUsersData(groupListData.data.groups);

      const locationsTypeData = await api.get('location-type');
      const locationsTypeDataFormatted = locationsTypeData.data.map(el => ({
        value: el.id,
        label: el.name,
      }));
      setLocationsTypeList(locationsTypeDataFormatted);
    }
    loadLists();
  }, []);

  async function onChange(value, option) {
    const groupListData = await api.get('location-user-permission/listUserGroups');
    const groupListDataFormatted = groupListData.data.groups.map(el => ({
      value: el.id,
      label: el.name,
    }));
    setUserGroupList(groupListDataFormatted);
    setUserGroupUsersData(groupListData.data.groups);

    setSelectedGroupId(() => option);
    setUsersOnSelectedGroup(
      userGroupUsersData.filter(el => el.id === value)[0].location_user_group_users.map(el => ({ key: el.users.id, name: el.users.name }))
    );

    const locationsPermissionsData = await api.get('/location-user-permission/listLocationPermissions');
    const locationsPermissionsDataFormatted = locationsPermissionsData.data
      .filter(el => el.location_user_groups_id === option.value)
      .map(el => {
        return {
          id: el.id,
          from: el.from_location_type.name,
          to: el.to_location_type.name,
        };
      });
    setLocationsPermissionsList(locationsPermissionsDataFormatted);
  }

  async function handleDeletePermission(permissionId) {
    await api.delete(`/location-user-permission/deleteLocationPermission/${permissionId}`);

    setLocationsPermissionsList(state => state.filter(el => el.id !== permissionId));

    toast.success('Permission deleted');
  }

  async function handleCreateNewPermission() {
    if (!selectedGroupId || !selectedFromLocationTypeId || !selectedToLocationTypeId) {
      toast.error('To create a permission, you must select a group and the location types');
      return;
    }

    const createNewLocationPermission = await api.post(
      `/location-user-permission/createNewPermission/${selectedGroupId.value}/${selectedFromLocationTypeId}/${selectedToLocationTypeId}`
    );

    if (createNewLocationPermission.data.error) {
      toast.error(createNewLocationPermission.data.error);
      return;
    }

    const locationsPermissionsData = await api.get('/location-user-permission/listLocationPermissions');

    const locationsPermissionsDataFormatted = locationsPermissionsData.data
      .filter(el => el.location_user_groups_id === selectedGroupId.value)
      .map(el => {
        return {
          id: el.id,
          from: el.from_location_type.name,
          to: el.to_location_type.name,
        };
      });
    setLocationsPermissionsList(locationsPermissionsDataFormatted);
  }

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_text, row) => {
        return (
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => handleDeletePermission(row.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
    },
  ];

  return (
    <Container>
      <Wrapper>
        <div>
          <Select
            options={userGroupList}
            onChange={onChange}
            value={selectedGroupId}
            style={{ display: 'block', width: '80ch', paddingBottom: '1rem' }}
            placeholder="Select a group"
            showSearch
            optionFilterProp="label"
            allowClear
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />

          <InputContainer>
            <label htmlFor="from">From location type</label>
            <Select
              options={locationsTypeList}
              onChange={option => setSelectedFromLocationTypeId(option)}
              value={selectedFromLocationTypeId}
              style={{ display: 'block', width: '60ch', paddingBottom: '1rem' }}
              placeholder="Select a location type"
              showSearch
              optionFilterProp="label"
              allowClear
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </InputContainer>

          <InputContainer>
            <label htmlFor="groupDescription">To location type</label>
            <Select
              options={locationsTypeList}
              onChange={option => setSelectedToLocationTypeId(option)}
              value={selectedToLocationTypeId}
              style={{ display: 'block', width: '60ch', paddingBottom: '1rem' }}
              placeholder="Select a location type"
              showSearch
              optionFilterProp="label"
              allowClear
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </InputContainer>
          <Button type="primary" onClick={handleCreateNewPermission}>
            Create permission
          </Button>

          <Divider />

          <Table dataSource={locationsPermissionsList} size="small" columns={columns} style={{ width: '50%' }} pagination={false} rowKey="id" />
        </div>

        {usersOnSelectedGroup.length > 0 && (
          <List
            size="small"
            header={
              <div>
                <strong>Users in selected group</strong>
              </div>
            }
            dataSource={usersOnSelectedGroup}
            renderItem={item => (
              <List.Item>
                <div>{item.name}</div>
              </List.Item>
            )}
          />
        )}
      </Wrapper>
    </Container>
  );
}
