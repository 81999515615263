import api from '~/services/api';

export async function fetchBatteries(search) {
  if (!search) {
    return '';
  }
  const productsData = await api.get(`/items?subcategory_id=4&subcategory_id=12&search=${search}&is_active=true`);

  return productsData.data.map(el => ({
      value: `${el.name}`,
      label: `${el.name}`,
    }));
}
