import React, { useState, useEffect, useCallback } from 'react';

import { Form, Button, Card, Select, Input, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { CardHeader } from '@material-ui/core';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

const { Option } = Select;

export default function StorageNew(params) {
  const [storageInfo, setStorageInfo] = useState({});

  const [form] = Form.useForm();

  const [updateFlag, setUpdateFlag] = useState(false);
  const [typeFlag, setTypeFlag] = useState(true);

  const [size, setSize] = useState();
  const [page, setPage] = useState();
  const [query, setQuery] = useState();

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 13,
    },
  };
  /* eslint-disable no-template-curly-in-string */

  useEffect(() => {
    async function loadStorage() {
      const location = new URLSearchParams(params.location.search);
      const storage_id = location.get('id');
      const pageSize = location.get('size');
      const pages = location.get('page');
      const searchQuery = location.get('searchQuery');
      if (storage_id) {
        const storage_info = await api.get(`storages/${storage_id}`);
        const storage = {
          capacity: storage_info.data.capacity,
          type: storage_info.data.type,
          unit: storage_info.data.unit,
          form_factor: storage_info.data.form_factor,
          controller: storage_info.data.controller,
          speed: storage_info.data.speed,
        };
        form.setFieldsValue(storage);
        setStorageInfo(storage_info.data);
        setUpdateFlag(true);
        setSize(pageSize);
        setPage(pages);
        setQuery(searchQuery === 'undefined' ? undefined : searchQuery);
      } else {
        setSize(25);
        setPage(1);
      }
    }
    loadStorage();
  }, []); // eslint-disable-line

  const validateCapacity = useCallback(input => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(input)) {
      return { status: false, message: 'Input Capacity is not a number' };
    }
    return { status: true, message: '' };
  }, []);

  const onFinish = async values => {
    const newStorage = {
      capacity: values.capacity,
      type: values.type,
      unit: values.unit,
      form_factor: values.form_factor,
      controller: values.controller,
      speed: values.speed,
    };

    try {
      if (updateFlag) {
        const updateStatus = await api.put(`storages/${storageInfo.id}`, newStorage);
        if (updateStatus.data.error) {
          throw new Error(updateStatus.data.error);
        }
        toast.success('storage Updated.');
      } else {
        const insertStatus = await api.post('storages', newStorage);
        if (insertStatus.data.error) {
          throw new Error(insertStatus.data.error);
        }
        toast.success('New Storage created.');
      }
      history.push('/storage');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Card>
        <Form {...layout} form={form} name="nest-messages" onFinish={onFinish}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={() => (query === undefined ? `/storage?size=${size}&page=${page}` : `/storage?searchQuery=${query}&size=${size}&page=${page}`)}>
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {updateFlag === true ? <CardHeader subheader={`${storageInfo.name}`} title="Update Storage" /> : <CardHeader title="New Storage" />}
            <div />
          </div>
          <Card title="Storage Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={8}>
                <Form.Item
                  hasFeedback
                  name="capacity"
                  label="Capacity"
                  rules={[
                    {
                      required: true,
                      message: 'Storage Capacity is required.',
                    },
                    {
                      validator: (_, value) => {
                        const { status, message } = validateCapacity(value);
                        return status ? Promise.resolve() : Promise.reject(message);
                      },
                    },
                  ]}
                >
                  <Input allowClear placeholder="Storage's Capacity" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="unit"
                  label="Unit"
                  rules={[
                    {
                      required: true,
                      message: 'Storage Unit is required.',
                    },
                  ]}
                >
                  <Select placeholder="Select a unit">
                    <Option key="GB">GB</Option>
                    <Option key="TB">TB</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: 'Storage Type is required.',
                    },
                  ]}
                >
                  <Select placeholder="Select a Type" onChange={value => (value === 'HDD' ? setTypeFlag(true) : setTypeFlag(false))}>
                    <Option key="HDD">HDD</Option>
                    <Option key="SSD">SSD</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Storage Specs" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="form_factor"
                  label="Form Factor"
                  rules={[
                    {
                      required: true,
                      message: 'Storage Form Factor is required.',
                    },
                  ]}
                >
                  <Select placeholder="Select a Form Factor">
                    <Option key="2.5">2.5</Option>
                    <Option key="3.5">3.5</Option>
                    <Option key="mSATA">mSATA</Option>
                    <Option key="M.2">M.2</Option>
                    <Option key="Memory Card">Memory Card</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="controller"
                  label="Controller"
                  rules={[
                    {
                      required: true,
                      message: 'Storage Controller is required.',
                    },
                  ]}
                >
                  <Select placeholder="Select a Controller">
                    <Option key="SATA">SATA</Option>
                    <Option key="SAS">SAS</Option>
                    <Option key="NVMe">NVMe</Option>
                    <Option key="PCIe">PCIe</Option>
                    <Option key="PCIe_SATA">PCIe_SATA</Option>
                    <Option key="NVMe_PCIe">NVMe_PCIe</Option>
                    <Option key="Flash">Flash</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="speed" label="Speed">
                  <Select placeholder="Select a Speed" disabled={!typeFlag}>
                    <Option key="5400 RPM">5400 RPM</Option>
                    <Option key="7200 RPM">7200 RPM</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
}
