/* eslint-disable react/prop-types */
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Select, Space, Table, Tabs, Upload } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Alert from '~/components/Alert';
import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';

import { ImportExcel } from './components/importExcelFile';
import { Container } from './styles';

const { Dragger } = Upload;

const dummyRequest = ({ onSuccess }) => {
  onSuccess('ok');
};

export default function DownstreamEdit({ match }) {
  const profile = useSelector((state) => state.user.profile);
  const CAN_USER_IMPORT_EXCEL_FILE =
    profile?.type === 999 ||
    ['malek.nahle@ruggedbooks.com', 'ibrahim.Khachab@ruggedbooks.com', 'ibrahim.mistou@ruggedbooks.com', 'abdulwahed.ahmed@ruggedbooks.com'].includes(
      profile?.email
    );

  const { TabPane } = Tabs;

  const [downstreamInfo, setDownstreamInfo] = useState({ files: {} });
  const [palletsList, setPalletsList] = useState([]);
  const [downstreamItemsList, setDownstreamItemsList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [downstreamReport, setDownstreamReport] = useState([]);
  const [supplierId, setSupplierId] = useState([]);
  const [shippingDate, setShippingDate] = useState(null);

  const serialNumberInputRef = useRef('');
  const palletDescriptionInputRef = useRef('');
  const palletWeightInputRef = useRef('');

  useEffect(() => {
    async function loadDownstreamPallets() {
      const downstreamPalletsList = await api.get(`downstream_pallets/${match.params.id}`);
      setPalletsList(
        downstreamPalletsList.data.map((el) => ({
          id: el.id,
          description: el.description,
          weight: el.weight,
          downstream_id: el.downstream_id,
        }))
      );
    }

    async function loadDownstreamInfo() {
      const downstream = await api.get(`downstream/${match.params.id}`);

      if (downstream.data === null) {
        toast.error('Error loading downstream. Contact RBMS administrator.');
        return;
      }

      setSupplierId(downstream.data.supplier_id || null);
      setShippingDate(downstream.data.shipped_date !== null ? moment(downstream.data.shipped_date) : null);
      setDownstreamInfo(downstream.data);
      setDownstreamItemsList(
        downstream.data.downstream_items.map((el) => ({
          id: el.id,
          previous_status: el.previous_status,
          downstream_id: el.downstream_id,
          user_id: el.user_id,
          serial_number: el.inventory_item.serial_number,
          inventory_items_id: el.inventory_items_id,
          part_number: el.inventory_item.item.part_number,
        }))
      );

      if (downstream.data.files !== null) {
        setDownstreamReport([downstream.data?.files]);
      }
    }

    async function loadSuppliers() {
      const suppliers = await api.get('suppliers?list=true');

      setSupplierList(
        suppliers.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );
    }

    loadDownstreamPallets();
    loadDownstreamInfo();
    loadSuppliers();
  }, []); //eslint-disable-line

  const handleAddSerialNumber = async () => {
    // async function handleAddSerialNumber() {
    try {
      const SERIAL_NUMBER_IS_EMPTY = serialNumberInputRef.current.value === undefined || serialNumberInputRef.current.value === '';
      if (SERIAL_NUMBER_IS_EMPTY) {
        throw new Error('Serial Number must filled');
      }

      const serialNumberTrimmed = serialNumberInputRef.current.value.trim();
      const serialNumberFormatted =
        serialNumberTrimmed.substring(0, 4) === 'P/N:' ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4) : serialNumberTrimmed;

      if (downstreamItemsList.find((sn) => sn.serial_number === serialNumberFormatted) !== undefined) {
        throw new Error('Serial Number already added');
      }

      const serialNumberIsValid = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted });

      if (serialNumberIsValid.data.error) {
        throw new Error(serialNumberIsValid.data.error);
      }

      if (serialNumberIsValid.data.status === 'Sold') {
        throw new Error('This unit is SOLD and cannot be added to downstream');
      }

      if (serialNumberIsValid.data.status === 'Downstream') {
        throw new Error('This unit is already set to another downstream and cannot be added again');
      }

      if (serialNumberIsValid.data.condition !== 'For Parts') {
        throw new Error('You need to change the condition of this unit to For Parts before attempting to add it to DownStream');
      }

      const newDownstreamItem = await api.put('downstream_items', {
        previous_status: serialNumberIsValid.data.status,
        downstream_id: downstreamInfo.id,
        user_id: profile.id,
        inventory_items_id: serialNumberIsValid.data.id,
      });

      await api.put('inventory_items', {
        items: [
          {
            serial_number: serialNumberIsValid.data.serial_number,
            status: 'Downstream',
            action: 'Downstream',
            grade: 'FP',
          },
        ],
      });

      setDownstreamItemsList([
        ...downstreamItemsList,
        {
          id: newDownstreamItem.data.id,
          previous_status: serialNumberIsValid.data.status,
          downstream_id: downstreamInfo.id,
          user_id: profile.id,
          serial_number: serialNumberIsValid.data.serial_number,
          inventory_items_id: serialNumberIsValid.data.id,
          part_number: serialNumberIsValid.data.item.part_number,
        },
      ]);

      toast.success('Item added successfully');
    } catch (error) {
      toast.error(error.message);
      serialNumberInputRef.current.focus();
      return;
    }

    serialNumberInputRef.current.value = '';
    serialNumberInputRef.current.focus();
  };

  const handleRemoveSerialNumber = useCallback(
    async (id, serial_number) => {
      try {
        const currentDownstreamItem = await api.get(`downstream_items/${id}`);

        await api.put('inventory_items', {
          items: [
            {
              serial_number,
              status: currentDownstreamItem.data.previous_status,
              action: 'DownstreamRemoved',
            },
          ],
        });

        await api.delete(`downstream_items/${id}`);

        setDownstreamItemsList([...downstreamItemsList].filter((el) => el.serial_number !== serial_number));

        toast.success('Item removed');
      } catch (error) {
        toast.error(error.message);
      }
    },
    [downstreamItemsList]
  );

  const handleAddPallet = async () => {
    // async function handleAddPallet() {
    try {
      if (
        palletDescriptionInputRef.current.value === undefined ||
        palletDescriptionInputRef.current.value === '' ||
        palletWeightInputRef.current.value === '' ||
        palletWeightInputRef.current.value === undefined
      ) {
        if (palletDescriptionInputRef.current.value === undefined || palletDescriptionInputRef.current.value === '') {
          toast.error('Description must filled');
          palletDescriptionInputRef.current.focus();
          return;
        }
        toast.error('Weight must filled');
        palletWeightInputRef.current.focus();
        return;
      }

      if (+palletWeightInputRef.current.value < 0) {
        toast.error('Weight cannot be a negative number');
        palletDescriptionInputRef.current.focus();
        return;
      }

      const newDownstreamPallet = await api.post('downstream_pallets', {
        description: palletDescriptionInputRef.current.value,
        weight: palletWeightInputRef.current.value,
        downstream_id: downstreamInfo.id,
      });

      setPalletsList([
        ...palletsList,
        {
          id: newDownstreamPallet.data.id,
          downstream_id: downstreamInfo.id,
          description: palletDescriptionInputRef.current.value,
          weight: palletWeightInputRef.current.value,
        },
      ]);
      palletDescriptionInputRef.current.value = '';
      palletWeightInputRef.current.value = undefined;
      palletDescriptionInputRef.current.focus();
      toast.success('Pallet added successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  async function handleRemovePallet(id) {
    try {
      await api.delete(`downstream_pallets/${id}`);

      const newpalletList = [...palletsList].filter((el) => el.id !== id);

      setPalletsList(newpalletList);
      toast.success('Pallet removed');
    } catch (error) {
      toast.error(error.message);
    }
  }

  const handleUploadEndOfLifeFile = useCallback(
    async (info) => {
      try {
        const formData = new FormData();

        formData.append('file', info.file.originFileObj);

        const responseUpload = await api.post('files', formData);

        const fileUploadResponse = responseUpload.data.map((el) => ({
          uid: el.id,
          url: el.url,
          name: el.name,
        }));

        await api.put(`downstream/${downstreamInfo.id}`, {
          endOfLife: fileUploadResponse[0]?.uid || null,
        });

        setDownstreamReport(fileUploadResponse);
      } catch (error) {
        toast.error('Error', 'Something went wrong while uploading file');
      }
    },
    [downstreamInfo.id]
  );

  const handleCompleteDownstream = async () => {
    // async function handleCompleteDownstream() {
    try {
      if (!supplierId) {
        toast.error('Collecting company must be filled');
        return;
      }

      if (!shippingDate) {
        toast.error('Shipping date must be filled');
        return;
      }

      await api.put(`downstream/${downstreamInfo.id}`, {
        supplierId,
        shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
      });

      await api.post('downstream');

      history.push('/downstream');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const downstreamItemsColumns = [
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: 'Part Number',
      dataIndex: 'part_number',
      sorter: (a, b) => a.part_number.localeCompare(b.part_number),
      sortDirections: ['descend', 'ascend'],
    },
  ];
  const palletsColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
  ];

  if (downstreamInfo.status !== 'complete') {
    palletsColumns.push({
      align: 'center',
      title: 'Remove',
      dataIndex: 'remove',
      key: 'remove',
      render: (_text, record) => <DeleteOutlined onClick={() => handleRemovePallet(record.id)} />,
    });

    downstreamItemsColumns.push({
      align: 'center',
      title: 'Remove',
      dataIndex: 'remove',
      key: 'remove',
      render: (_text, row) => <DeleteOutlined onClick={() => handleRemoveSerialNumber(row.id, row.serial_number)} />,
    });
  }

  return (
    <Container>
      <Card size="small" bordered={false}>
        <Space>
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Select collecting company"
            optionFilterProp="children"
            onChange={(supplierIdParam) => setSupplierId(supplierIdParam)}
            value={supplierId}
            options={supplierList}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={downstreamInfo.status === 'complete'}
          />
          <DatePicker
            disabled={downstreamInfo.shipped_date !== null && downstreamInfo.shipped_date !== ''}
            onChange={(date) => setShippingDate(date ? moment(date) : null)}
            value={shippingDate}
          />
          <Button key="1" type="primary" disabled={downstreamInfo.status === 'complete'} onClick={handleCompleteDownstream}>
            Complete Downstream
          </Button>
        </Space>
      </Card>

      <Card>
        <Tabs type="card" defaultActiveKey="1">
          <TabPane tab="Items" key="1">
            <Space direction="vertical">
              <Space>
                <InputText
                  placeholder="Enter Serial Number"
                  onBlur={(e) => {
                    e.target.value = e.target.value.toUpperCase().trim();
                  }}
                  ref={serialNumberInputRef}
                  onPressEnter={handleAddSerialNumber}
                />
                <Alert message="You can only add units with For Parts condition to Downstream" />
              </Space>
              {CAN_USER_IMPORT_EXCEL_FILE && <ImportExcel downstreamId={downstreamInfo.id} />}
              <Table
                bordered
                rowKey="serial_number"
                style={{ minWidth: '400px' }}
                size="small"
                columns={downstreamItemsColumns}
                dataSource={downstreamItemsList}
                pagination={false}
                title={() => `Total: ${downstreamItemsList.length}`}
              />
            </Space>
          </TabPane>
          <TabPane tab="Pallets" key="2">
            <Space direction="vertical">
              {/* <Input placeholder="Enter Pallet description" ref={palletDescriptionInputRef} /> */}
              <InputText style={{ width: '400px' }} placeholder="Enter Pallet description" ref={palletDescriptionInputRef} />
              <InputText type="number" min="0" style={{ width: '400px' }} placeholder="Enter Pallet weight" ref={palletWeightInputRef} />
              {/* <Input Input addonAfter="lb" type="number" min="0" style={{ width: '200px' }} placeholder="Enter Pallet weight" ref={palletWeightInputRef} /> */}
              <Button type="primary" onClick={handleAddPallet}>
                Add Pallet
              </Button>
              <Table
                bordered
                style={{ minWidth: '400px' }}
                rowKey={(record) => record.id}
                size="small"
                columns={palletsColumns}
                dataSource={palletsList}
                pagination={false}
                title={() => `Total: ${palletsList.length}`}
              />
            </Space>
          </TabPane>
          <TabPane tab="End of Life Report" key="3">
            <Space direction="vertical">
              <Dragger
                accept="image/*,.pdf"
                style={{ minWidth: '400px' }}
                name="file"
                multiple={false}
                onChange={handleUploadEndOfLifeFile}
                customRequest={dummyRequest}
                fileList={downstreamReport}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Dragger>
            </Space>
          </TabPane>
        </Tabs>
      </Card>
    </Container>
  );
}
