import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, DownOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Alert,
  Badge,
  Button,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DebounceSelect } from '~/components/DebounceSelect';
import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';
import { WarrantyCheck } from '~/utils/warrantyCheck';

import Additional from './Additional';
import Battery from './Battery';
import Bulk from './Bulk'; // eslint-disable-line
import ForPartsModal from './Components/ForPartsModal';
import Cosmetic from './Cosmetic';
import gradingRules from './grading';
import gradingRulesFunctions from './gradingFunctions';
import GraphicCard from './GraphicCard';
import HardDrive from './HardDrive';
import Hardware from './Hardware';
import History from './History';
import Keyboard from './Keyboard';
import NewHistory from './New History';
import Software from './Software';
import { Container } from './styles';
import { assignKBType } from './utils/assignKBType';
import { ChangeStorageRam } from './utils/changeStorageRam';
import { fetchItems } from './utils/fetchItems';
import { handleItemResults } from './utils/handleItemResults';
import { handleRAMCheck } from './utils/ramCheckForOrder';
import { reserveTypeOptions } from './utils/reserveTypeOptions';

const { Title, Text } = Typography;
const { TextArea } = Input;

export default function InventoryItemProcessing({ match, location }) {
  const profile = useSelector((state) => state.user.profile);

  const params = new URLSearchParams(location.search);
  const generalOrderId = params.get('orderId');
  const orderType = params.get('orderType');
  const checklistOrderItem = params.get('checklistOrderItem');

  const [form] = Form.useForm();
  const { confirm } = Modal;

  const searchInputRef = useRef('');

  const [assetDiagnosticId, setAssetDiagnosticId] = useState('');
  const [cameraOptions, setCameraOptions] = useState([]);
  const [changeBeingDone, setChangeBeingDone] = useState('');
  const [changeItemNotes, setChangeItemNotes] = useState('');
  const [confirmChangeModal, setConfirmChangeModal] = useState(false);
  const [confirmChangeModalOnFinish, setConfirmChangeModalOnFinish] = useState(false);
  const [confirmChangeModalForParts, setConfirmChangeModalForParts] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cosmeticsScreenOptions, setCosmeticsScreenOptions] = useState([]);
  const [disabledMessage, setDisabledMessage] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [gradeDescription, setGradeDescription] = useState('');
  const [gradeFunctionDescription, setGradeFunctionDescription] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [inventoryItemsId, setInventoryItemsId] = useState('');
  const [inventoryItemsLogId, setInventoryItemsLogId] = useState(null);
  const [isAssignToSignedUser, setIsAssignToSignedUser] = useState(true);
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [isWarrantyVisible, setIsWarrantyVisible] = useState(false);
  const [itemCondition, setItemCondition] = useState('');
  const [itemDisabled, setItemDisabled] = useState(false);
  const [itemHistory, setItemHistory] = useState([]);
  const [itemInfo, setItemInfo] = useState({ item: { brand: {} }, category: {}, cpu: {} });
  const [itemResults, setItemResults] = useState({});
  const [keyboardLanguageOptions, setKeyboardLanguageOptions] = useState([]);
  const [keyboardOptions, setKeyboardOptions] = useState([]);
  const [keyboardPortOptions, setKeyboardPortOptions] = useState([]);
  const [loading, setLoading] = useState();
  const [locationLog, setLocationLog] = useState([]);
  const [overrideBattery, setOverrideBattery] = useState(false);
  const [overrideHardDrive, setOverrideHardDrive] = useState(false);
  const [overrideModalFlag, setOverrideModalFlag] = useState('');
  const [overrideModalIsOpen, setOverrideModalIsOpen] = useState(false);
  const [screenOptions, setScreenOptions] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');
  const [smartCardOptions, setSmartCardOptions] = useState([]);
  const [stylusOptions, setStylusOptions] = useState([]);
  const [warrantyCheck, setWarrantyCheck] = useState([]);
  const [onFinishData, setOnFinishData] = useState({});

  const [checkUsb, setCheckUsb] = useState(false);
  const [checkGraphicsCard, setCheckGraphicsCard] = useState(false);
  const [checkCpu, setCheckCpu] = useState(false);
  const [checkStorage1, setCheckStorage1] = useState(false);
  const [checkStorage2, setCheckStorage2] = useState(false);
  const [checkRam1, setCheckRam1] = useState(false);
  const [checkRam2, setCheckRam2] = useState(false);

  const [techManagerOverride, setTechManagerOverride] = useState(false);

  const bulkInventoryItemInfo = useMemo(() => [], []);

  const useBeforeUnload = ({ when, message: msg }) => {
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = msg;
        return msg;
      };

      if (when) {
        window.addEventListener('beforeunload', handleBeforeUnload);
      }

      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [when, msg]);
  };

  function getKBType(type, language) {
    if (language === 'EN') {
      switch (type) {
        case 'standard':
          return 3;
        case 'rubber':
          return 1;
        case 'emissive':
          return 5;
        default:
          return '';
      }
    } else if (language === 'Dual') {
      switch (type) {
        case 'standard':
          return 9;
        case 'rubber':
          return 8;
        case 'emissive':
          return 10;
        default:
          return '';
      }
    }
    switch (type) {
      case 'standard':
        return 4;
      case 'rubber':
        return 2;
      case 'emissive':
        return 6;
      default:
        return '';
    }
  }

  const handleSerialNumberSearch = useCallback(
    async (id, inventory_items_log) => {
      setLoading(true);
      const { data: unitData } = await api.get(`inventory_items/showById/${id}`);
      const serialNumber = unitData.serial_number;

      const itemUserInfo = await api.get(`inventory_items/getUserAssignedToItem/${id}`);

      const isItemAssignedToDifferentUser = itemUserInfo.data.userId !== profile.id;

      if (isItemAssignedToDifferentUser) {
        setLoading(false);
        setItemDisabled(true);

        setIsAssignToSignedUser(false);
      }

      setSerialNumber(serialNumber);
      form.resetFields();
      searchInputRef.current.value = serialNumber;
      if (serialNumber === '') {
        toast.error('Enter the serial number first');
        setLoading(false);
        return;
      }

      let itemInventoryData;

      if (inventory_items_log === undefined || inventory_items_log === null) {
        itemInventoryData = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumber });
      } else {
        itemInventoryData = await api.get(`inventoryItemsLog/${Number(inventory_items_log)}`);
      }

      if (itemInventoryData.headers.itemnotfound) {
        alert('This unit is marked as Not Found. Put this item aside and inform manager');
      }
      if (itemInventoryData.data.error) {
        toast.error('Serial number not found');
        setItemInfo(() => ({ item: { brand: {} }, category: {}, cpu: {} }));
        setLoading(false);
        return;
      }

      if (itemInventoryData.data === null) {
        toast.error('Serial number not found');
        return;
      }

      setWarrantyCheck(await Promise.resolve(WarrantyCheck(serialNumber, itemInventoryData)));

      let assetDiagnosticData;

      if (inventory_items_log === undefined || inventory_items_log === null) {
        assetDiagnosticData = await api.post('assetDiagnostic/showBySerialNumber', { serialNumber });
      } else {
        assetDiagnosticData = await api.get(`assetDiagnostic/showById/${itemInventoryData.data.asset_diagnostic_id}`);
      }

      let buildSpecification = null;
      let storeNotes = null;
      let rbmsNotes = null;
      if (orderType !== null && orderType !== undefined) {
        if (orderType === 'internal') {
          const internalOrderChecklist = await api.get(`internal_order_checklist/${generalOrderId}/${itemInventoryData.data.id}`);
          buildSpecification = internalOrderChecklist.data.internal_order_item.title;
          storeNotes = internalOrderChecklist.data.internal_order_item.notes;
        } else {
          const orderChecklist = await api.get(`ordersChecklist/${generalOrderId}`);
          if (orderChecklist.data.length > 0) {
            orderChecklist.data.map((orderChecklistItem) => {
              if (orderChecklistItem.serial_number === serialNumber) {
                buildSpecification = orderChecklistItem.order_items.title;
                storeNotes = orderChecklistItem.order_items.notes;
                rbmsNotes = orderChecklistItem.order_items.rbms_notes;
              }
              return true;
            });
          }
        }
      }
      const itemHistoryData = await api.get(`inventoryHistory/${itemInventoryData.data.id}`);

      if (itemHistoryData.data !== null) {
        setItemHistory(
          itemHistoryData.data.map((el) => ({
            key: el.id,
            inventoryItemsId: el.inventory_items_id,
            date: el.date,
            description: el.description,
            user: el.users.name,
            route: el.route,
            id_reference: el.id_reference,
            notes: el.notes,
          }))
        );
      }

      switch (assetDiagnosticData.data?.certified_grade) {
        case 'C0':
          setGradeDescription('BIOS password');
          break;
        case 'C1':
        case 'C2':
          setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          break;
        case 'C3':
          setGradeDescription('Used fair, missing critical parts');
          break;
        case 'C4':
        case 'C5':
          setGradeDescription('Used very good, minor imperfections no affecting functionality');
          break;
        case 'C6':
          setGradeDescription('Used excellent, refurbished equipment in like-new condition');
          break;
        case 'C7':
        case 'C8':
          setGradeDescription('Unused, like new');
          break;
        case 'C9':
          setGradeDescription('New open box, original unsealed package');
          break;
        case 'C10':
          setGradeDescription('Brand New');
          break;
        default:
          setGradeDescription('');
          break;
      }

      setItemInfo(() => itemInventoryData.data);
      setInventoryItemsId(itemInventoryData.data.id);
      setAssetDiagnosticId(assetDiagnosticData.data?.id);
      setItemCondition(itemInventoryData.data.condition);

      const keyboardType = assignKBType(itemInventoryData.data.keyboard_type_id);

      let inventoryItemInfo = {
        acAdapter: itemInventoryData.data.ac_adapter,
        acAdapterAmp: itemInventoryData.data.ac_adapter_amp,
        acAdapterPartNumber: { value: itemInventoryData.data.ac_adapter_item?.id, label: itemInventoryData.data.ac_adapter_item?.name } || null,
        batteryFile: itemInventoryData.data.battery_file,
        batteryFile2: itemInventoryData.data.battery_file2,
        batteryFile3: itemInventoryData.data.battery_file3,
        batteryItemName: itemInventoryData.data.battery_item?.name,
        batteryItemName2: itemInventoryData.data.battery_item2?.name,
        batteryItemName3: itemInventoryData.data.battery_item3?.name,
        batteryPartNumber: itemInventoryData.data.battery_item_id,
        batteryPartNumber2: itemInventoryData.data.battery_item_id2,
        batteryPartNumber3: itemInventoryData.data.battery_item_id3,
        batteryPercentage: itemInventoryData.data.battery,
        batteryPercentage2: itemInventoryData.data.battery2,
        batteryPercentage3: itemInventoryData.data.battery3,
        batterySerialNumber: itemInventoryData.data.battery_serial_number?.toUpperCase(),
        batterySerialNumber2: itemInventoryData.data.battery_serial_number2?.toUpperCase(),
        batterySerialNumber3: itemInventoryData.data.battery_serial_number3?.toUpperCase(),
        cameraType: itemInventoryData.data.camera,
        coaReported: itemInventoryData.data.coa_reported,
        condition: itemInventoryData.data.condition,
        cosmeticsScreen: itemInventoryData.data.cosmetic_screen?.split(',').map(Number),
        cpuModel: { value: itemInventoryData.data.cpu?.id, label: itemInventoryData.data.cpu?.name } || null,
        dedicatedGpu: itemInventoryData.data.dedicated_gpu_model,
        graphicsCard: itemInventoryData.data.graphics_card,
        hours: itemInventoryData.data.hours,
        is_processed: itemInventoryData.data.is_processed,
        itemBuildDescription: buildSpecification,
        itemDescription: itemInventoryData.data.item.description,
        itemLocation: `${itemInventoryData.data?.locations?.name || ''} ${itemInventoryData.data?.usersLocation?.name || ''}`,
        keyboardLanguage: keyboardType.language,
        keyboardPorts: itemInventoryData.data.keyboard_ports?.split(',').map(Number),
        keyboardType: keyboardType.type,
        name: itemInventoryData.data.processed_by,
        notes: itemInventoryData.data.description,
        operatingSystem: itemInventoryData.data.operating_system,
        originalCoa: itemInventoryData.data.original_coa,
        post_result: itemInventoryData.data.post_result,
        ramSerialNumber1: itemInventoryData.data.ram_serial_number?.trim()?.toUpperCase(),
        ramSerialNumber2: itemInventoryData.data.ram_serial_number_2?.trim()?.toUpperCase(),
        ramSize1: { value: itemInventoryData.data.ram, label: itemInventoryData.data.ram } || null,
        ramSize2: { value: itemInventoryData.data.ram_2, label: itemInventoryData.data.ram_2 } || null,
        rbmsOrderItemNotes: rbmsNotes,
        screenType: itemInventoryData.data.touch,
        serialNumber: serialNumber.toUpperCase(),
        smartCardType: itemInventoryData.data.smart_card_type,
        storageHealth1: itemInventoryData.data.storage_health,
        storageHealth2: itemInventoryData.data.storage_health_2,
        storageSerialNumber1: itemInventoryData.data.storage_serial_number?.trim()?.toUpperCase(),
        storageSerialNumber2: itemInventoryData.data.storage_serial_number_2?.trim()?.toUpperCase(),
        storageSize1: itemInventoryData.data.storage,
        storageSize2: itemInventoryData.data.storage_2,
        storeOrderItemNotes: storeNotes,
        stylusType: itemInventoryData.data.stylus,
        usbPorts: itemInventoryData.data.usb_ports,
        windowsActivated: itemInventoryData.data.windows_activated,
        wipingReportFile: itemInventoryData.data.wiping_report_file,
        wwanModel: itemInventoryData.data.wwan_model,
      };

      if (assetDiagnosticData.data !== null) {
        inventoryItemInfo = {
          ...inventoryItemInfo,
          certifiedGrade: assetDiagnosticData.data.certified_grade || 'na',
          grade: itemInventoryData.data.status === 'Failed' ? 'FA' : assetDiagnosticData.data.company_grade || 'N/A',
          resultStorage1: assetDiagnosticData.data.function_storage || 'na',
          resultRam1: assetDiagnosticData.data.function_ram || null,
          resultStorage2: assetDiagnosticData.data.function_storage_2 || 'na',
          resultRam2: assetDiagnosticData.data.function_ram_2 || null,
          resultDiagnosticUtility: assetDiagnosticData.data.function_diagnostic_utility || 'na',
          resultScreen: assetDiagnosticData.data.function_screen || 'na',
          resultLcdScreen: assetDiagnosticData.data.function_lcd_screen || 'na',
          resultStylus: assetDiagnosticData.data.cosmetics_stylus || 'na',
          resultMousePad: assetDiagnosticData.data.function_mousepad || 'na',
          resultUsb: assetDiagnosticData.data.function_usb || 'na',
          resultWlan: assetDiagnosticData.data.function_wlan || 'na',
          resultBluetooth: assetDiagnosticData.data.function_bluetooth || 'na',
          resultAudio: assetDiagnosticData.data.function_audio || 'na',
          resultKeyboard: assetDiagnosticData.data.function_keyboard || 'na',
          resultBattery: assetDiagnosticData.data.function_battery || 'na',
          resultBattery2: assetDiagnosticData.data.function_battery2 || 'na',
          resultBattery3: assetDiagnosticData.data.function_battery3 || 'na',
          resultOpticalDrive: assetDiagnosticData.data.function_optical_drive || 'na',
          resultWwan: assetDiagnosticData.data.function_wwan || 'na',
          resultGps: assetDiagnosticData.data.function_gps || 'na',
          resultCamera: assetDiagnosticData.data.function_camera || 'na',
          resultFaceRecognition: assetDiagnosticData.data.function_face_recognition || 'na',
          resultRfid: assetDiagnosticData.data.function_rfid || 'na',
          resultFingerprint: assetDiagnosticData.data.function_fingerprint || 'na',
          resultSmartCard: assetDiagnosticData.data.function_smartcard || 'na',
          resultBarcodeReader: assetDiagnosticData.data.function_barcode_reader || 'na',
          resultOperatingSystem: assetDiagnosticData.data.function_operating_system || 'na',
          resultWindowsActivation: assetDiagnosticData.data.function_windows_activation ? 'passed' : 'na',
          resultCoaReport: assetDiagnosticData.data.function_coa_report || 'na',
          resultDriversInstalled: assetDiagnosticData.data.function_drivers_installed || 'na',
          resultStorageExtended: assetDiagnosticData.data.function_storage_extended ? 'passed' : 'na',
          resultRecoveryMedia: assetDiagnosticData.data.function_recovery_media || 'na',
          resultCfc2BiosUpdate: assetDiagnosticData.data.function_cfc2_bios_update || 'na',
          resultCovers: assetDiagnosticData.data.cosmetics_covers || 'na',
          resultScrews: assetDiagnosticData.data.cosmetics_screws || 'na',
          resultTetherStylus: assetDiagnosticData.data.cosmetics_tether || 'na',
          resultCosmeticsScreen: assetDiagnosticData.data.cosmetics_screen || 'na',
          resultCosmeticsBattery: assetDiagnosticData.data.cosmetics_battery || 'na',
          resultAcAdapter: assetDiagnosticData.data.cosmetics_ac_adapter || 'na',
          visualAppearance: assetDiagnosticData.data.cosmetics_visual_appearance || null,
          labelCondition: assetDiagnosticData.data.cosmetics_label_condition || null,
          dentsScratches: assetDiagnosticData.data.cosmetics_dents_scratches || null,
          discoloration: assetDiagnosticData.data.cosmetics_discoloration || null,
          missingParts: assetDiagnosticData.data.cosmetics_missing_parts === false ? 'no' : 'yes',
          latchesHingesDamaged: assetDiagnosticData.data.cosmetics_latches_hinges_damaged === false ? 'no' : 'yes',
          cleaned: assetDiagnosticData.data.cosmetics_cleaned === false ? 'no' : 'yes',
          packaging: assetDiagnosticData.data.cosmetics_packaging || null,
          biosPassword: assetDiagnosticData.data.bios_password || null,
          cracks: assetDiagnosticData.data.cosmetics_cracks || null,
          resultWipingReport: assetDiagnosticData.data.result_wiping_report || null,
          resultBatteryReport: assetDiagnosticData.data.result_battery_report || null,
          resultBatteryReport2: assetDiagnosticData.data.result_battery_report3 || null,
          resultBatteryReport3: assetDiagnosticData.data.result_battery_report3 || null,
          technicianName: assetDiagnosticData.data.user.name || 'na',
          resultGraphicsCard: assetDiagnosticData.data.function_graphics_card || 'na',
          resultDedicatedGpu: assetDiagnosticData.data.function_dedicated_gpu || 'na',
          resultKeyboardPorts: assetDiagnosticData.data.function_keyboard_ports || 'na',
          resultCPU: assetDiagnosticData.data.function_cpu || null,
        };
      }
      form.setFieldsValue(inventoryItemInfo);
      setItemResults(inventoryItemInfo);

      /**
       * Checking the functionality grade, since is not storaged in the database
       */
      if (itemInventoryData.data.status === 'Failed') {
        if (
          form.getFieldValue('resultStorage1') === 'failed' ||
          form.getFieldValue('resultStorage2') === 'failed' ||
          form.getFieldValue('resultRam1') === 'failed' ||
          form.getFieldValue('resultRam2') === 'failed' ||
          form.getFieldValue('resultBattery') === 'failed' ||
          form.getFieldValue('resultBattery2') === 'failed' ||
          form.getFieldValue('resultBattery3') === 'failed' ||
          form.getFieldValue('resultGraphicsCard') === 'failed' ||
          form.getFieldValue('resultDedicatedGpu') === 'failed' ||
          form.getFieldValue('resultScreen') === 'failed' ||
          form.getFieldValue('resultLcdScreen') === 'failed' ||
          form.getFieldValue('resultMousePad') === 'failed' ||
          form.getFieldValue('resultKeyboard') === 'failed' ||
          form.getFieldValue('resultCPU') === 'failed'
        ) {
          setGradeFunctionDescription(['FA', 'Key Functions not working']);
        } else {
          setGradeFunctionDescription(['F3', 'Key Functions Working, May be missing components not essential to key functions']);
        }
      } else if (
        [1, 2, 3, 5].includes(itemInventoryData.data.item.category?.id) &&
        ((form.getFieldValue('resultStorage1') === 'na' && form.getFieldValue('resultStorage2') === 'na') ||
          (form.getFieldValue('resultRam1') === 'na' && form.getFieldValue('resultRam2') === 'na') ||
          (form.getFieldValue('resultBattery') === 'na' && form.getFieldValue('resultBattery2') === 'na' && form.getFieldValue('resultBattery3') === 'na'))
      ) {
        setGradeFunctionDescription(['F3', 'Key Functions Working, May be missing components not essential to key functions']);
      } else if ([1, 2, 3, 5].includes(itemInventoryData.data.item.category?.id) && form.getFieldValue('resultOperatingSystem') === 'na') {
        setGradeFunctionDescription(['F4', 'Hardware Functional, No missing or damaged components or parts, Software not Loaded']);
      } else {
        setGradeFunctionDescription(['F5', 'Refurbished, No missing or damaged components or parts, Software Loaded']);
      }
      setLoading(false);
    },
    [form, generalOrderId, orderType, profile.id]
  );

  useEffect(() => {
    async function loadDropdownboxes() {
      const instructionsData = await api.get('instructions');
      const otherPortsList = await api.get('other_ports_options');
      const cosmeticScreenData = await api.get('cosmetic_screen_options');

      const isTechManager = await api.get(`isTechManager/${profile.id}`);

      setTechManagerOverride(isTechManager.data.isTechManager);

      setInstructions(instructionsData.data);

      setScreenOptions([
        { label: 'Touchscreen', value: 'touchscreen' },
        { label: 'Digitizer', value: 'digitizer' },
        { label: 'Dual Touch', value: 'dual_touch' },
        { label: 'Non-touch', value: 'non_touch' },
      ]);

      setStylusOptions([
        { label: 'Touchscreen', value: 'touchscreen' },
        { label: 'Digitizer', value: 'digitizer' },
      ]);

      setKeyboardOptions([
        { label: 'Standard', value: 'standard' },
        { label: 'Emissive', value: 'emissive' },
        { label: 'Rubber', value: 'rubber' },
      ]);

      setKeyboardLanguageOptions([
        { label: 'English', value: 'EN' },
        { label: 'French', value: 'CA-FR' },
        { label: 'Dual', value: 'Dual' },
      ]);

      setCameraOptions([
        { label: 'Front', value: 'front' },
        { label: 'Rear', value: 'rear' },
        { label: 'Dual Camera', value: 'dualCamera' },
      ]);

      setSmartCardOptions([
        { label: 'Insertable', value: 'insertable' },
        { label: 'Contactless', value: 'contactless' },
        { label: 'Both', value: 'insertable, contactless' },
      ]);

      setCosmeticsScreenOptions(
        cosmeticScreenData.data
          .filter((port) => port.active === true)
          .map((port) => ({
            value: port.id,
            label: port.name,
            severity: port.severity,
          }))
      );

      setKeyboardPortOptions(
        otherPortsList.data
          .filter((port) => port.active === true)
          .map((port) => ({
            value: port.id,
            label: port.name,
          }))
      );
    }
    loadDropdownboxes();
    searchInputRef.current.focus();
  }, []); //eslint-disable-line

  useEffect(() => {
    const inventory_items_log = params.get('inventory_items_log'); // Coming from log

    if (match.params.id !== null && typeof match.params.id !== 'undefined') {
      setInventoryItemsLogId(inventory_items_log);
      handleSerialNumberSearch(match.params.id, inventory_items_log);
    }
  }, [match]); //eslint-disable-line

  const onFinishFailed = ({ _values, errorFields, _outOfDate }) => {
    errorFields.forEach((el) => {
      const message = el.errors[0] === ' ' ? `${el.name[0]} has an error message` : el.errors[0];
      toast.error(message);
    });
  };

  function handleFormChange() {
    setFormChanged(true);
  }

  const calculateGrade = useCallback(
    (packaging, biosPassword, labelCondition, dentsScratches, discoloration, missingParts, latchesHingesDamaged, hours, cracks, cosmeticsScreen) => {
      let cosmeticScreenSeverity = 'low';
      if (cosmeticsScreen !== undefined) {
        const cosmeticScreenSeverityList = cosmeticsScreen.map((id) => cosmeticsScreenOptions.find((screen) => screen.value === id).severity);
        cosmeticScreenSeverity = cosmeticScreenSeverityList.includes('high') ? 'high' : cosmeticScreenSeverityList.includes('moderate') ? 'moderate' : 'low';
      }

      if (itemInfo.item.subcategory?.id === 10 && form.getFieldValue('resultKeyboard') === 'failed') {
        form.setFieldsValue({ certifiedGrade: 'FA' });
        return { certified_grade: 'FA', company_grade: 'FA' };
      }

      if (itemInfo?.item?.category?.id === 6) {
        return { certified_grade: 'na', company_grade: 'U', condition: 'Used' };
      }
      if (itemInfo.post_result === 'new' || itemInfo.condition === 'New') {
        return { certified_grade: 'C10', company_grade: 'New', condition: 'New' };
      }

      if (biosPassword === 'yes') {
        setGradeDescription('BIOS password');
        return { certified_grade: 'C0', company_grade: 'FA', condition: '', status: 'failed' };
      }

      if (
        biosPassword === 'no' &&
        cracks === 'fixable-noCracks' &&
        labelCondition === 'excellent' &&
        dentsScratches === 'none' &&
        discoloration === 'none' &&
        missingParts === 'no' &&
        latchesHingesDamaged === 'no' &&
        packaging === 'unsealedFactory' &&
        +hours <= 10
      ) {
        if (![1, 2, 3, 10].includes(itemInfo?.item?.category?.id)) {
          // If the item doesn't have screen, don't need to check screen damage
          setGradeDescription('New open box, original unsealed package');
          return { certified_grade: 'C9', company_grade: 'OB', condition: 'Open Box' };
        }

        if (cosmeticScreenSeverity === 'low') {
          setGradeDescription('New open box, original unsealed package');
          return { certified_grade: 'C9', company_grade: 'OB', condition: 'Open Box' };
        }
      }

      const grade = { certified_grade: 'C9', company_grade: 'A', condition: 'Refurbished' };

      if (cracks === 'unfixable') {
        grade.certified_grade = 'C2';
        grade.company_grade = 'D';
        setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
        return grade;
      }

      if (missingParts === 'yes' || latchesHingesDamaged === 'yes' || cosmeticScreenSeverity === 'moderate') {
        grade.certified_grade = 'C3';
        grade.company_grade = 'C';
        setGradeDescription('Used fair, missing critical parts');
      }

      if (cosmeticScreenSeverity === 'high') {
        grade.certified_grade = 'C1';
        grade.company_grade = 'D';
        setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
      }

      switch (labelCondition) {
        case 'excellent':
          if (grade.certified_grade > 'C6') {
            if (+hours <= 10) {
              grade.certified_grade = 'C8';
              grade.company_grade = 'A';
              setGradeDescription('Unused, like new');
            } else {
              grade.certified_grade = 'C6';
              grade.company_grade = 'A';
              setGradeDescription('Used excellent, refurbished equipment in like-new condition');
            }
          }
          break;
        case 'good':
          if (grade.certified_grade > 'C5') {
            grade.certified_grade = 'C5';
            grade.company_grade = 'B';
            setGradeDescription('Used very good, minor imperfections no affecting functionality');
          }
          break;
        case 'fair':
          if (grade.certified_grade > 'C3') {
            grade.certified_grade = 'C3';
            grade.company_grade = 'C';
            setGradeDescription('Used fair, missing critical parts');
          }
          break;
        case 'poor':
          if (grade.certified_grade > 'C2') {
            grade.certified_grade = 'C2';
            grade.company_grade = 'D';
            setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          }
          break;
        default:
          break;
      }

      switch (dentsScratches) {
        case 'none':
          if (grade.certified_grade > 'C8') {
            grade.certified_grade = 'C8';
            grade.company_grade = 'A';
            if (+hours <= 10) setGradeDescription('Unused, like new');
            else setGradeDescription('Used excellent, refurbished equipment in like-new condition');
          }
          break;
        case 'minor':
          if (grade.certified_grade > 'C5') {
            grade.certified_grade = 'C5';
            grade.company_grade = 'B';
            setGradeDescription('Used very good, minor imperfections no affecting functionality');
          }
          break;
        case 'moderate':
          if (grade.certified_grade > 'C3') {
            grade.certified_grade = 'C3';
            grade.company_grade = 'C';
            setGradeDescription('Used fair, missing critical parts');
          }
          break;
        case 'major':
          if (grade.certified_grade > 'C2') {
            grade.certified_grade = 'C2';
            grade.company_grade = 'D';
            setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          }
          break;
        default:
          break;
      }

      switch (discoloration) {
        case 'none':
          if (grade.certified_grade > 'C8') {
            grade.certified_grade = 'C8';
            grade.company_grade = 'A';
            if (+hours <= 10) setGradeDescription('Unused, like new');
            else setGradeDescription('Used excellent, refurbished equipment in like-new condition');
          }
          break;
        case 'minor':
          if (grade.certified_grade > 'C5') {
            grade.certified_grade = 'C5';
            grade.company_grade = 'B';
            setGradeDescription('Used very good, minor imperfections no affecting functionality');
          }
          break;
        case 'moderate':
          if (grade.certified_grade > 'C3') {
            grade.certified_grade = 'C3';
            grade.company_grade = 'C';
            setGradeDescription('Used fair, missing critical parts');
          }
          break;
        case 'major':
          if (grade.certified_grade > 'C2') {
            grade.certified_grade = 'C2';
            grade.company_grade = 'D';
            setGradeDescription('Used poor, damaged, no reusable, missing critical parts');
          }
          break;
        default:
          break;
      }

      return grade;
    },
    [itemInfo, cosmeticsScreenOptions] //eslint-disable-line
  );

  const handleBulkInventoryItemInfo = useCallback(
    (inventoryItemData) => {
      bulkInventoryItemInfo.push(inventoryItemData);
    },
    [bulkInventoryItemInfo]
  );

  function showOverrideRamConfirm(values) {
    confirm({
      title: 'Ram Validation for Order',
      content: 'Please make sure you add the RAM serial number.',
      okText: 'I added no RAMs to this unit',
      cancelText: 'I will scan the RAM',
      onOk() {
        continueOnFinishAfterRamValidation(values);
      },
      onCancel() {},
      width: 500,
    });
  }

  function handleAssetDiagnosticData(values) {
    return {
      bios_password: values.biosPassword,
      cosmetics_ac_adapter: values.resultAcAdapter,
      cosmetics_battery: values.resultCosmeticsBattery,
      cosmetics_cleaned: values.cleaned === 'yes',
      cosmetics_covers: values.resultCovers,
      cosmetics_cracks: values.cracks,
      cosmetics_dents_scratches: values.dentsScratches,
      cosmetics_discoloration: values.discoloration,
      cosmetics_label_condition: values.labelCondition,
      cosmetics_latches_hinges_damaged: values.latchesHingesDamaged === 'yes',
      cosmetics_missing_parts: values.missingParts === 'yes',
      cosmetics_packaging: values.packaging,
      cosmetics_screen: values.resultCosmeticsScreen,
      cosmetics_screws: values.resultScrews,
      cosmetics_stylus: values.resultStylus,
      cosmetics_tether: values.resultTetherStylus,
      cosmetics_visual_appearance: values.visualAppearance,
      function_audio: values.resultAudio,
      function_barcode_reader: values.resultBarcodeReader,
      function_battery: values.resultBattery,
      function_battery2: values.resultBattery2,
      function_battery3: values.resultBattery3,
      function_bluetooth: values.resultBluetooth,
      function_camera: values.resultCamera,
      function_cfc2_bios_update: values.resultCfc2BiosUpdate,
      function_coa_report: values.resultCoaReport,
      function_cpu: values.resultCPU,
      function_dedicated_gpu: values.resultDedicatedGpu,
      function_diagnostic_utility: values.resultDiagnosticUtility,
      function_drivers_installed: values.resultDriversInstalled,
      function_face_recognition: values.resultFaceRecognition,
      function_fingerprint: values.resultFingerprint,
      function_gps: values.resultGps,
      function_graphics_card: values.resultGraphicsCard,
      function_keyboard_ports: values.resultKeyboardPorts,
      function_keyboard: values.resultKeyboard,
      function_lcd_screen: values.resultLcdScreen,
      function_mousepad: values.resultMousePad,
      function_operating_system: values.resultOperatingSystem,
      function_optical_drive: values.resultOpticalDrive,
      function_ram_2: values.resultRam2,
      function_ram: values.resultRam1,
      function_recovery_media: values.resultRecoveryMedia,
      function_rfid: values.resultRfid,
      function_screen: values.resultScreen,
      function_smartcard: values.resultSmartCard,
      function_storage_2: values.resultStorage2,
      function_storage_extended: values.resultStorageExtended === 'passed',
      function_storage: values.resultStorage1,
      function_usb: values.resultUsb,
      function_windows_activation: values.resultWindowsActivation === 'passed',
      function_wlan: values.resultWlan,
      function_wwan: values.resultWwan,
      inventory_items_id: inventoryItemsId,
      result_battery_report: values.resultBatteryReport,
      result_battery_report2: values.resultBatteryReport2,
      result_battery_report3: values.resultBatteryReport3,
      result_wiping_report: values.resultWipingReport,
      serial_number: serialNumber.toUpperCase(),
      user_id: profile.id,
    };
  }

  function handleInventoryItemData(values) {
    return {
      ac_adapter_amp: values.acAdapterAmp || null,
      ac_adapter_item_id: values?.acAdapterPartNumber?.value || null,
      ac_adapter: values.acAdapter || null,
      battery_item_id: values.batteryPartNumber || null,
      battery_item_id2: values.batteryPartNumber2 || null,
      battery_item_id3: values.batteryPartNumber3 || null,
      battery_file_id: values?.batteryFile !== undefined && values?.batteryFile?.length > 0 ? values.batteryFile[0]?.uid : null || null,
      battery_file_id2: values?.batteryFile2 !== undefined && values?.batteryFile2?.length > 0 ? values.batteryFile2[0]?.uid : null || null,
      battery_file_id3: values?.batteryFile3 !== undefined && values?.batteryFile3?.length > 0 ? values.batteryFile3[0]?.uid : null || null,
      battery_serial_number: values?.batterySerialNumber?.toUpperCase() || null,
      battery_serial_number2: values?.batterySerialNumber2?.toUpperCase() || null,
      battery_serial_number3: values?.batterySerialNumber3?.toUpperCase() || null,
      battery: values.batteryPercentage || null,
      battery2: values.batteryPercentage2 || null,
      battery3: values.batteryPercentage3 || null,
      camera: values.cameraType || null,
      coa_reported: values.coaReported || null,
      cosmetic_screen: values.cosmeticsScreen?.sort().join() || null,
      cpu_id: typeof values.cpuModel === 'object' ? values.cpuModel?.value : values.cpuModel || null,
      dedicated_gpu_model: values.dedicatedGpu || null,
      description: values.notes,
      graphics_card: values.graphicsCard || null,
      hours: values.hours ?? null,
      keyboard_ports: values.keyboardPorts?.sort().join() || null,
      keyboard_type_id: getKBType(values.keyboardType, values.keyboardLanguage) || null,
      operating_system: values.operatingSystem || null,
      original_coa: values.originalCoa || null,
      processed_by: profile.name || null,
      ram_2: typeof values.ramSize2 === 'object' ? values.ramSize2?.value : values.ramSize2 || null,
      ram_serial_number_2: values.ramSerialNumber2?.toUpperCase() || null,
      ram_serial_number: values.ramSerialNumber1?.toUpperCase() || null,
      ram: typeof values.ramSize1 === 'object' ? values.ramSize1?.value : values.ramSize1 || null,
      serial_number: serialNumber.toUpperCase(),
      smart_card_type: values.smartCardType || null,
      storage_2: values.storageSize2 || null,
      storage_health_2: values.storageHealth2,
      storage_health: values.storageHealth1,
      storage_serial_number_2: values.storageSerialNumber2?.trim().toUpperCase() || null,
      storage_serial_number: values.storageSerialNumber1?.trim().toUpperCase() || null,
      storage: values.storageSize1 || null,
      stylus: values.stylusType || null,
      touch: values.screenType || null,
      usb_ports: values.usbPorts || null,
      windows_activated: values.windowsActivated || null,
      wiping_report_file_id: values.wipingReportId,
      wwan_model: values.wwanModel || null,
    };
  }

  const onFinish = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      error.errorFields.forEach((field) => {
        notification.error({
          description: `${field.name[0]} has validation error`,
          className: 'custom-class',
          // style: {
          //   backgroundColor: '#ff4d4f',
          //   color: 'white',
          // },
        });
      });
      return;
    }

    // validate has storage and ram for orders
    try {
      const values = form.getFieldsValue();
      // return;

      if (techManagerOverride) {
        continueOnFinishAfterRamValidation(values);
        return;
      }

      // if (orderType === 'order' && !overrideHardDrive) {
      if (orderType === 'order' && !overrideHardDrive && ![10].includes(itemInfo.item.subcategory?.id)) {
        if (
          (values.storageSerialNumber1 === '' || values.storageSerialNumber1 === undefined) &&
          (values.storageSerialNumber2 === '' || values.storageSerialNumber2 === undefined)
        ) {
          setOverrideModalFlag('Hard Drive');
          setOverrideModalIsOpen(true);
          return;
        }
      }

      if (orderType === 'order' && [2, 5].includes(itemInfo.item.category?.id)) {
        const RAM_VALIDATION_FOR_ORDER = handleRAMCheck(values);
        if (RAM_VALIDATION_FOR_ORDER.hasError) {
          showOverrideRamConfirm(values);
          return;
        }
      }
      continueOnFinishAfterRamValidation(values);
    } catch (error) {
      toast.error(`${error}. Something went wrong. Contact support to explain what happened. Code: 1`);
    }
  };

  const continueOnFinishAfterRamValidation = async (values) => {
    try {
      if (!techManagerOverride) {
        if (itemInfo.item.battery_type?.split(',').includes('removable')) {
          if (orderType === 'order' && !overrideBattery && !values.batterySerialNumber2) {
            setOverrideModalFlag('Removable Battery');
            setOverrideModalIsOpen(true);
            return;
          }
        }

        if (itemInfo.item.battery_type?.split(',').includes('removable2')) {
          if (orderType === 'order' && !overrideBattery && !values.batterySerialNumber3) {
            setOverrideModalFlag('Removable Battery 2');
            setOverrideModalIsOpen(true);
            return;
          }
        }
      }

      const currentItemStatus = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumber });
      if (currentItemStatus.data.status !== 'Sold') {
        const grade = calculateGrade(
          values.packaging,
          values.biosPassword,
          values.labelCondition,
          values.dentsScratches,
          values.discoloration,
          values.missingParts,
          values.latchesHingesDamaged,
          values.hours,
          values.cracks,
          values.cosmeticsScreen
        );

        const assetDiagnosticData = handleAssetDiagnosticData(values);
        assetDiagnosticData.certified_grade = grade?.certified_grade || 'na';
        assetDiagnosticData.company_grade = grade?.company_grade || 'na';

        const inventoryItemData = handleInventoryItemData(values);
        inventoryItemData.action = 'completeInspection';
        inventoryItemData.grade = grade?.company_grade || 'TBI';
        inventoryItemData.condition = grade?.condition || itemInfo.condition;

        // Check if storage status is InUse and failed or not
        if (itemInfo.item.category.id === 6 && itemInfo?.status === 'InUse') {
          if (values.resultWipingReport === 'failed') {
            toast.error(
              'The failed storage cannot be used. Before inspecting it, please remove it physically from the unit and also remove it from the unit in RBMS.'
            );
            return;
          }
        } else {
          inventoryItemData.status = !['AwaitingShipment', 'InUse', 'Reserved'].includes(itemInfo?.status) ? 'InStock' : itemInfo?.status;
        }

        inventoryItemData.is_processed = true;

        const itemResultsData = handleItemResults(values, inventoryItemsId);
        setItemResults(itemResultsData);

        setOnFinishData({ values, assetDiagnosticData, inventoryItemData, grade });

        if (itemInfo?.is_ready) {
          setConfirmChangeModalOnFinish(true);
        } else {
          continueOnFinish({ values, assetDiagnosticData, inventoryItemData, grade });
        }
      } else {
        toast.error('Item is Sold');
      }
    } catch (error) {
      toast.error(`${error}. Something went wrong. Contact support to explain what happened. Code: 2`);
    }
  };

  const continueOnFinish = async ({ values, assetDiagnosticData, inventoryItemData, grade }) => {
    // Check POST result to set the correct condition
    switch (form.getFieldValue('postResult')) {
      case 'na':
      case 'passed':
      case 'failed': {
        if ([1, 2, 3, 5].includes(itemInfo.item.category?.id)) {
          inventoryItemData.condition = 'Refurbished';
        } else {
          inventoryItemData.condition = 'Used';
        }
        break;
      }
      case 'new':
        inventoryItemData.condition = 'New';
        break;
      default:
        break;
    }

    inventoryItemData.notes = changeItemNotes;
    inventoryItemData.post_result = form.getFieldValue('postResult') || itemInfo?.post_result;

    setConfirmChangeModalOnFinish(false);
    form.setFieldsValue({ postResult: '', changeNotes: '' });

    try {
      /**
       * Removing Cosmetics from status calculation equation
       */
      ['resultCovers', 'resultScrews', 'resultTetherStylus', 'resultCosmeticsScreen', 'resultCosmeticsBattery', 'resultAcAdapter'].forEach(
        (e) => delete values[e]
      );

      if (grade?.status === 'failed' || Object.values(values).find((el) => el === 'failed') === 'failed') {
        inventoryItemData.status = 'Failed';
        inventoryItemData.grade = 'FA';
        inventoryItemData.condition = '';
      }

      // If the storage inspection got to this point, it can means 2 cases:
      // 1- the storage is NOT in use and is passed,
      // 2- the storage is not in use and must get the status according to the inspection results
      if (itemInfo.item.category.id === 6 && itemInfo?.status === 'InUse') {
        inventoryItemData.status = itemInfo?.status;
      } else if (itemInfo.item.category.id === 6 && itemInfo?.status !== 'InUse') {
        inventoryItemData.status = values.resultWipingReport === 'passed' ? 'InStock' : 'Failed';
      }

      inventoryItemData.last_inspection_at = new Date();

      await api.put('inventory_items/completeInspection', { items: [inventoryItemData], assetDiagnosticData });
      setItemInfo(() => ({
        ...itemInfo,
        status: inventoryItemData.status,
        grade: inventoryItemData.grade,
        last_inspection_at: inventoryItemData.last_inspection_at,
        condition: inventoryItemData.condition,
        post_result: inventoryItemData.post_result,
        is_processed: true,
        storage_serial_number: inventoryItemData.storage_serial_number,
        storage_serial_number_2: inventoryItemData.storage_serial_number_2,
        ram_serial_number: inventoryItemData.ram_serial_number,
        ram_serial_number_2: inventoryItemData.ram_serial_number_2,
        battery_serial_number: inventoryItemData.battery_serial_number,
        battery_serial_number2: inventoryItemData.battery_serial_number2,
        battery_serial_number3: inventoryItemData.battery_serial_number3,
      }));

      if (values.bulkUnit?.length > 0) {
        values.bulkUnit.forEach(async (value) => {
          const bulkInventoryItem = bulkInventoryItemInfo.find((info) => info.serial_number.toUpperCase() === value.unitSerialNumber.toUpperCase());
          assetDiagnosticData.inventory_items_id = bulkInventoryItem.id;
          const bulkAssetDiagnosticData = { ...assetDiagnosticData };
          bulkAssetDiagnosticData.serial_number = value.unitSerialNumber.toUpperCase();

          if (value.batteryUploadFileId !== undefined && value.batteryUploadFileId !== null) {
            bulkAssetDiagnosticData.function_battery = 'passed';
          }
          if (value.batteryUploadFileId2 !== undefined && value.batteryUploadFileId2 !== null) {
            bulkAssetDiagnosticData.function_battery2 = 'passed';
          }
          if (value.batteryUploadFileId3 !== undefined && value.batteryUploadFileId3 !== null) {
            bulkAssetDiagnosticData.function_battery3 = 'passed';
          }
          if (value.copyRam === undefined || value.copyRam === false) {
            if (value.unitHasRam === true || value.ramSize1 !== undefined) {
              bulkAssetDiagnosticData.function_ram = 'passed';
            } else {
              bulkAssetDiagnosticData.function_ram = 'na';
            }
            if (value.unitHasRam === true || value.ramSize2 !== undefined) {
              bulkAssetDiagnosticData.function_ram_2 = 'passed';
            } else {
              bulkAssetDiagnosticData.function_ram_2 = 'na';
            }
          }

          const bulkinventoryItemData = { ...inventoryItemData };

          bulkinventoryItemData.battery_file_id = value?.batteryFileId;
          bulkinventoryItemData.battery_file_id2 = value?.batteryFileId2;
          bulkinventoryItemData.battery_file_id3 = value?.batteryFileId3;
          bulkinventoryItemData.battery_item_id = value?.batteryItemId;
          bulkinventoryItemData.battery_item_id2 = value?.batteryItemId2;
          bulkinventoryItemData.battery_item_id3 = value?.batteryItemId3;
          bulkinventoryItemData.battery_serial_number = value.batterySerialNumber?.trim().toUpperCase() || bulkInventoryItem.battery_serial_number;
          bulkinventoryItemData.battery_serial_number2 = value.batterySerialNumber2?.trim().toUpperCase() || bulkInventoryItem.battery_serial_number2;
          bulkinventoryItemData.battery_serial_number3 = value.batterySerialNumber3?.trim().toUpperCase() || bulkInventoryItem.battery_serial_number3;
          bulkinventoryItemData.battery = value.batteryHealth;
          bulkinventoryItemData.battery2 = value.batteryHealth2;
          bulkinventoryItemData.battery3 = value.batteryHealth3;
          bulkinventoryItemData.coa_reported = value.coaReported;
          bulkinventoryItemData.description = value.notes;
          bulkinventoryItemData.hours = value.hours;
          bulkinventoryItemData.original_coa = value.originalCoa;
          bulkinventoryItemData.ram_serial_number = value.ramSerialNumber1?.trim().toUpperCase() || bulkInventoryItem.ram_serial_number;
          bulkinventoryItemData.ram_serial_number_2 = value.ramSerialNumber2?.trim().toUpperCase() || bulkInventoryItem.ram_serial_number_2;
          bulkinventoryItemData.serial_number = value.unitSerialNumber.toUpperCase();
          bulkinventoryItemData.status = bulkInventoryItem.status === 'AwaitingShipment' ? 'AwaitingShipment' : 'InStock';
          bulkinventoryItemData.storage_health = value.storageHealth;
          bulkinventoryItemData.storage_health_2 = value.storageHealth2;
          bulkinventoryItemData.storage_serial_number = value.storageSerialNumber?.trim().toUpperCase() || bulkInventoryItem.storage_serial_number;
          bulkinventoryItemData.storage_serial_number_2 = value.storageSerialNumber2?.trim().toUpperCase() || bulkInventoryItem.storage_serial_number_2;
          if (value.copyRam === undefined || value.copyRam === false) {
            bulkinventoryItemData.ram = value.ramSize1;
            bulkinventoryItemData.ram_2 = value.ramSize2;
          }
          if (value.finishInspection === false) {
            bulkinventoryItemData.is_processed = false;
            bulkinventoryItemData.status = bulkInventoryItem.status === 'AwaitingShipment' ? 'AwaitingShipment' : 'InStock';
            bulkinventoryItemData.action = 'bulkCopyInspection';
          }
          bulkinventoryItemData.last_inspection_at = new Date();

          await api.put('inventory_items/completeInspection', { items: [bulkinventoryItemData], assetDiagnosticData: bulkAssetDiagnosticData });
        });
      }
      setFormChanged(false);

      if (orderType === 'internal') {
        history.push(`/internalOrderEdit?internal_order_id=${generalOrderId}`);
        return;
      }
      if (orderType === 'order') {
        const orderChecklistData = await api.post('ordersChecklist', {
          order_id: generalOrderId,
          serial_number: itemInfo.serial_number,
          technician_id: profile.technician_id,
          order_items_id: checklistOrderItem,
          item_id: itemInfo.item_id,
        });

        let orderChecklist = null;

        if (orderChecklistData.data.error) {
          orderChecklist = orderChecklistData.data.orderChecklistExists;
        } else {
          orderChecklist = orderChecklistData.data;
        }

        await api.put('inventory_items', {
          items: [
            {
              serial_number: itemInfo.serial_number,
              status: 'AwaitingShipment',
              action: 'addedToOrder',
              orderNumber: orderChecklist.order_num,
            },
          ],
        });

        history.push(`/ordersEdit?order_id=${generalOrderId}`);
        return;
      }
      toast.success('Item registered successfully');
    } catch (error) {
      toast.error(`${error.response.data.message}. Something went wrong. Contact support to explain what happened. Code: 3`);
    }
  };
  const handleClear = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (isAssignToSignedUser === true) {
      const statusAllowedToEdit = ['AwaitingShipment', 'Failed', 'InStock', 'NotFound', 'ToBeInspected', 'ToBeInspected-RMA', 'InUse'];
      if (itemInfo?.is_ready === true) {
        if (itemInfo?.item?.category?.id === 6 && itemInfo?.condition === 'New') {
          setDisabledMessage('This item cannot be edited due to its status or condition');
          setItemDisabled(true);
          return;
        }

        if (inventoryItemsLogId !== null) {
          setDisabledMessage('This item cannot be edited due to be a record from the history');
          setItemDisabled(true);
          return;
        }

        if (statusAllowedToEdit.includes(itemInfo.status)) {
          setDisabledMessage(null);
          setItemDisabled(false);
          return;
        }

        setDisabledMessage('This item cannot be edited due to its status or condition. Code: 1');
        setItemDisabled(true);
      } else {
        setDisabledMessage('This item cannot be edited due to not having the first assessment done');
        setItemDisabled(true);
      }
    } else {
      setDisabledMessage('This item cannot be edited because is not under your name');
      setItemDisabled(true);
    }
  }, [itemInfo]); //eslint-disable-line

  const updateUnitToForParts = async () => {
    try {
      await api.patch(`inventory_items/forParts/${itemInfo.id}/${assetDiagnosticId}`);

      setConfirmLoading(false);
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
      setSerialNumber('');
      setItemInfo(() => ({ item: { brand: {} }, category: {}, cpu: {} }));

      setConfirmChangeModalForParts(false);
      toast.success('Item condition was changed to "For Parts" and will not be available anymore');
      history.push('/inventoryProcessing');
    } catch (error) {
      toast.error(`Something went wrong. Contact administrator to report this message. ${error.response.data.error}`);
    }
  };

  const handleChangeToForParts = async (selectedOptions, qrCode) => {
    try {
      setConfirmLoading(true);
      const ALL_CHECKBOXES_WERE_NOT_CHECKED = selectedOptions.length < 3;
      if (ALL_CHECKBOXES_WERE_NOT_CHECKED) {
        toast.error('Please check all the boxes to continue with this action.');
        setConfirmLoading(false);
        return;
      }
      // TODO: add techManagerOverride to for parts
      if (techManagerOverride) {
        updateUnitToForParts();
        return;
      }

      const QR_CODE_NOT_SCANNED = qrCode === '';
      if (QR_CODE_NOT_SCANNED) {
        toast.error('Please ask your supervisor for authorization.');
        setConfirmLoading(false);
        return;
      }

      const CHECK_IF_IT_IS_A_USER = qrCode.substring(0, 4) === 'USR:';
      if (CHECK_IF_IT_IS_A_USER) {
        const qrCodeFormated = qrCode.substring(qrCode.indexOf('USR:') + 4);
        const possibleAuthorizedUser = await api.get(`locations/validateUserQrCode/${qrCodeFormated}`);
        const POSSIBLE_AUTHORIZED_USER_ERROR = possibleAuthorizedUser.data.error;
        if (POSSIBLE_AUTHORIZED_USER_ERROR) {
          toast.error(POSSIBLE_AUTHORIZED_USER_ERROR);
          return;
        }

        const IS_AUTHORIZED_USER = possibleAuthorizedUser.data.user.type === 999 || possibleAuthorizedUser.data.user.email === 'alozano@ruggedbooks.com';
        if (IS_AUTHORIZED_USER) {
          updateUnitToForParts();
          return;
        }
        toast.error('QR code not authorized. Please ask your supervisor for authorization.');
        setConfirmLoading(false);
      }
    } catch (error) {
      toast.error(`Something went wrong. Contact administrator to report this message. ${error.response.data.error}`);
    }
  };

  const handleChangeReserve = async (type) => {
    const values = form.getFieldsValue();
    try {
      const reservation = { serial_number: serialNumber.toUpperCase(), notes: changeItemNotes };
      reservation.status = type;
      reservation.action = 'changeToReserved';
      reservation.description = `${values.notes} ${changeItemNotes}`;

      setItemInfo(() => ({ ...itemInfo, status: type }));
      form.setFieldsValue({ notes: `${values.notes} ${changeItemNotes}` });

      setConfirmLoading(false);
      await api.put('inventory_items', { items: [reservation] });
      toast.success('Item changed status.');
    } catch (error) {
      toast.error(`${error}. Contact administrator to report this message`);
    }
  };

  const handleChangeUnreserve = async () => {
    try {
      const reservation = { serial_number: serialNumber.toUpperCase(), notes: changeItemNotes };
      if (itemCondition === 'New') {
        reservation.status = 'InStock';
        reservation.action = 'changeToUnreserved';

        setItemInfo(() => ({ ...itemInfo, status: 'InStock' }));
      } else {
        reservation.status = 'InStock';
        reservation.action = 'changeToUnreserved';
        reservation.is_processed = false;
        setItemInfo(() => ({ ...itemInfo, is_processed: false, status: 'InStock' }));
        form.setFieldsValue({ is_processed: false });
      }

      setConfirmLoading(false);
      await api.put('inventory_items', { items: [reservation] });
      toast.success('Item changed status.');
    } catch (error) {
      toast.error(`${error}. Contact administrator to report this message`);
    }
  };

  const handleChangeNew = async () => {
    try {
      await api.put('assetDiagnostic', {
        serial_number: serialNumber.toUpperCase(),
        user_id: profile.id,
        inventory_items_id: inventoryItemsId,
        company_grade: 'New',
        certified_grade: 'C10',
      });
      const postValue = form.getFieldValue('postResult') || 'new';
      const changeNew = {
        serial_number: serialNumber.toUpperCase(),
        notes: changeItemNotes,
        condition: 'New',
        grade: 'New',
        status: 'InStock',
        action: 'changeToNew',
        post_result: postValue,
      };

      await api.put('inventory_items', { items: [changeNew] });

      setItemCondition('New');
      form.setFieldsValue({ condition: 'New' });
      form.setFieldsValue({ grade: 'New' });
      form.setFieldsValue({ post_result: postValue });
      setItemInfo(() => ({ ...itemInfo, is_processed: false, condition: 'New', status: 'InStock', grade: 'New', post_result: postValue }));
      form.setFieldsValue({ is_processed: false });

      ChangeStorageRam(itemInfo, 'InUse');

      toast.success('Unit changed to New');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeUsed = async () => {
    try {
      const postValue = form.getFieldValue('postResult') || 'na';
      const changeUsed = {
        serial_number: serialNumber.toUpperCase(),
        notes: changeItemNotes,
        condition: 'Used',
        grade: 'U',
        status: 'InStock',
        action: 'changeToUsed',
        post_result: postValue,
      };
      await api.put('inventory_items', { items: [changeUsed] });
      setItemCondition('Used');
      form.setFieldsValue({ condition: 'Used' });
      form.setFieldsValue({ grade: 'U' });
      form.setFieldsValue({ post_result: postValue });
      setItemInfo(() => ({ ...itemInfo, is_processed: false, condition: 'Used', status: 'InStock', grade: 'U', post_result: postValue }));
      form.setFieldsValue({ is_processed: false });

      toast.success('Unit changed to Used');
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangePartNumber = async (itemId) => {
    try {
      const newPartNumberInfo = await api.get(`/items/${itemId}`);
      const changePartNumber = {
        serial_number: serialNumber.toUpperCase(),
        notes: changeItemNotes,
        item_id: itemId,
        action: 'changePartNumber',
      };
      await api.put('inventory_items', { items: [changePartNumber] });
      setItemInfo((state) => ({
        ...state,
        item: { ...state.item, part_number: newPartNumberInfo.data.part_number, categoryId: newPartNumberInfo.data.category_id },
      }));
      setItemResults((state) => ({ ...state, itemDescription: newPartNumberInfo.data.description }));
      form.setFieldsValue({ itemId });
      toast.success(`Unit's Part Number has been changed.`);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeItem = async (type) => {
    setConfirmChangeModal(true);
    setChangeBeingDone(type);
  };

  const handleConfirmChangeItem = async () => {
    const notes = form.getFieldValue('changeNotes') || '';
    const itemId = form.getFieldValue('itemId') || null;
    const reserveType = form.getFieldValue('reserveType') || null;
    if (changeBeingDone === 'Part Number') {
      if (itemId !== null && notes.length >= 15) {
        handleChangePartNumber(itemId);
        form.setFieldsValue({ changeNotes: '' });
        form.setFieldsValue({ itemId: null });

        setFormChanged(false);
        setConfirmChangeModal(false);
      } else {
        toast.error('Please select a Part Number and write the reason for the change.');
      }
    } else if (changeBeingDone === 'Reservation') {
      if (reserveType !== null && notes.length >= 15) {
        handleChangeReserve(reserveType);
        form.setFieldsValue({ changeNotes: '' });
        form.setFieldsValue({ reserveType: null });

        setFormChanged(false);
        setConfirmChangeModal(false);
      } else {
        toast.error('Please select a Type of reservation and write the reason for the change.');
      }
    } else if (notes.length >= 15) {
      switch (changeBeingDone) {
        case 'Unreserve':
          handleChangeUnreserve();
          break;
        case 'New':
          handleChangeNew();
          break;
        case 'Used':
          handleChangeUsed();
          break;
        default:
          setChangeItemNotes(null);
          break;
      }
      form.setFieldsValue({ changeNotes: '' });
      form.setFieldsValue({ postResult: null });

      setFormChanged(false);
      setConfirmChangeModal(false);
    } else {
      toast.error('Please enter a minimum of 15 characters');
    }
  };

  const handleCancel = () => {
    form.setFieldsValue({ changeNotes: '' });
    setChangeItemNotes(null);
    setChangeBeingDone('');
    setConfirmChangeModal(false);
  };

  const cleanFieldsNotPassed = (fields) => {
    fields.forEach((field) => {
      if (field === 'keyboardPorts' || field === 'cosmeticsScreen') {
        form.setFieldsValue({ [field]: [] });
        return;
      }
      if (field === 'cpuModel' || field === 'acAdapterPartNumber') {
        form.setFieldsValue({ [field]: null });
        return;
      }
      if (field === 'batteryFile') {
        form.setFieldsValue({ [field]: [{ uid: null }] });
        return;
      }
      if (field === 'batteryFile2') {
        form.setFieldsValue({ [field]: [{ uid: null }] });
        return;
      }
      if (field === 'batteryFile3') {
        form.setFieldsValue({ [field]: [{ uid: null }] });
        return;
      }
      form.setFieldsValue({ [field]: '' });
    });
  };

  const changeDropdown = [
    {
      label: 'Item',
      key: 'Item',
      children: [
        {
          key: 'Part Number',
          label: 'Part Number',
          onClick: () => handleChangeItem('Part Number'),
          disabled:
            inventoryItemsLogId !== null ||
            itemInfo.is_ready === false ||
            itemInfo.is_ready === null ||
            itemInfo.condition === 'For Parts' ||
            itemInfo.status === 'InUse' ||
            itemInfo.status === 'Reserved' ||
            itemInfo.status === 'InternalAsset' ||
            itemInfo.status === 'Gift' ||
            itemInfo.status === 'Allocated',
        },
      ],
    },
    {
      label: 'Condition',
      key: 'Condition',
      children: [
        {
          key: 'For Parts',
          label: 'For Parts',
          onClick: () => setConfirmChangeModalForParts(true),
          disabled:
            inventoryItemsLogId !== null ||
            itemInfo.is_processed === false ||
            itemInfo.is_processed === null ||
            itemInfo.condition === 'For Parts' ||
            itemInfo.status === 'InUse' ||
            itemInfo.status === 'Reserved' ||
            itemInfo.status === 'InternalAsset' ||
            itemInfo.status === 'Gift' ||
            itemInfo.status === 'Allocated',
        },
        {
          key: 'New',
          label: 'New',
          onClick: () => handleChangeItem('New'),
          disabled:
            inventoryItemsLogId !== null ||
            itemInfo.status === 'AwaitingShipment' ||
            itemInfo.status === 'InUse' ||
            itemInfo.status === 'Returning' ||
            itemInfo.status === 'Failed' ||
            itemInfo.status === 'Returned' ||
            itemInfo.status === 'NotFound' ||
            itemInfo.condition === 'For Parts' ||
            itemInfo.condition === 'New',
        },
      ],
    },
    // {
    //   type: 'divider',
    // },
    {
      label: 'Status',
      key: 'Status',
    },
  ];

  if ([6].includes(itemInfo.item.category?.id)) {
    changeDropdown[1].children.push({
      key: 'Used',
      label: 'Used',
      onClick: () => handleChangeItem('Used'),
      disabled:
        inventoryItemsLogId !== null ||
        itemInfo.status === 'AwaitingShipment' ||
        itemInfo.status === 'InUse' ||
        itemInfo.status === 'Returning' ||
        itemInfo.status === 'Failed' ||
        itemInfo.status === 'Returned' ||
        itemInfo.status === 'NotFound' ||
        itemInfo.condition === 'For Parts' ||
        itemInfo.condition === 'Used',
    });
  }

  if (!['InternalAsset', 'Gift', 'Allocated', 'Reserved'].includes(itemInfo.status)) {
    changeDropdown[2].children = [
      {
        key: 'Reserved',
        label: 'Reserved',
        onClick: () => handleChangeItem('Reservation'),
        disabled:
          inventoryItemsLogId !== null ||
          itemInfo.status === 'AwaitingShipment' ||
          itemInfo.status === 'InUse' ||
          itemInfo.status === 'Returning' ||
          itemInfo.status === 'Returned' ||
          itemInfo.status === 'NotFound' ||
          itemInfo.condition === 'For Parts',
      },
    ];
  } else {
    changeDropdown[2].children = [
      {
        key: 'Unreserved',
        label: 'Unreserved',
        onClick: () => handleChangeItem('Unreserve'),
        disabled:
          inventoryItemsLogId !== null ||
          itemInfo.status === 'AwaitingShipment' ||
          itemInfo.status === 'InUse' ||
          itemInfo.status === 'Returning' ||
          itemInfo.status === 'Returned' ||
          itemInfo.status === 'NotFound' ||
          itemInfo.condition === 'For Parts',
      },
    ];
  }

  const showWarranty = () => {
    setIsWarrantyVisible(true);
  };

  const closeWarranty = () => {
    setIsWarrantyVisible(false);
  };

  const showLocationModal = async () => {
    const locationLogData = await api.post('logs/getBySerialNumber', { serialNumber });
    setLocationLog(locationLogData.data);
    setIsLocationModalVisible(true);
  };

  const closeLocationModal = () => {
    setIsLocationModalVisible(false);
  };

  const warrantyColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Expire Date',
      dataIndex: 'expDate',
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) =>
        text ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '20px' }} /> : <CloseCircleOutlined style={{ color: '#eb2f96', fontSize: '20px' }} />,
    },
  ];

  const locationColumns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, row, index) => index + 1,
      width: 60,
    },
    {
      title: 'Previous Location',
      dataIndex: 'previous_data',
      width: 100,
    },
    {
      title: 'Current Location',
      dataIndex: 'new_data',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (text) => moment(text).format('MMM DD YYYY, h:mm a'),
      width: 100,
    },
  ];

  const modalLocationsLog = (
    <Modal title="Location Log" open={isLocationModalVisible} onOk={closeLocationModal} onCancel={closeLocationModal}>
      <Table
        style={{ marginTop: '5px' }}
        columns={locationColumns}
        dataSource={locationLog}
        size="small"
        bordered
        pagination={false}
        rowKey={(record) => record.id}
      />
    </Modal>
  );

  useBeforeUnload({
    when: formChanged,
    message: 'Are you sure you want to leave?',
  });

  const handleOnKeyDownSerialNumberSearch = async (value) => {
    if (value === '') {
      toast.error('Please enter a serial number.');
      return;
    }

    const serial_numberTrimmed = value.trim();
    const serialNumberFormatted =
      serial_numberTrimmed.substring(0, 4) === 'P/N:' ? serial_numberTrimmed.substring(serial_numberTrimmed.indexOf('S/N:') + 4) : serial_numberTrimmed;

    const { data: inventoryItemData } = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted });
    if (inventoryItemData.error) {
      toast.error(inventoryItemData.error);
      return;
    }
    history.replace(`/inventoryProcessing/${inventoryItemData.id}`);
  };

  const openNotification = () => {
    notification.info({
      message: `Override of ${overrideModalFlag} need`,
      description: `This unit has been authorized to be shipped without ${overrideModalFlag}. Please press Complete again and it will finish the inspection.`,
      placement: 'top',
      duration: 6,
    });
  };

  const handleOverrideHardDrive = async () => {
    const qrCode = form.getFieldValue('qrCode').trim();
    const CHECK_IF_IT_IS_A_USER = qrCode.substring(0, 4) === 'USR:';
    if (CHECK_IF_IT_IS_A_USER) {
      const qrCodeFormated = qrCode.substring(qrCode.indexOf('USR:') + 4);
      const possibleAuthorizedUser = await api.get(`locations/validateUserQrCode/${qrCodeFormated}`);
      const POSSIBLE_AUTHORIZED_USER_ERROR = possibleAuthorizedUser.data.error;
      if (POSSIBLE_AUTHORIZED_USER_ERROR) {
        toast.error(POSSIBLE_AUTHORIZED_USER_ERROR);
        return;
      }

      const IS_AUTHORIZED_USER = possibleAuthorizedUser.data.user.type === 999 || possibleAuthorizedUser.data.user.email === 'alozano@ruggedbooks.com';

      if (IS_AUTHORIZED_USER) {
        if (overrideModalFlag === 'Hard Drive') {
          setOverrideHardDrive(true);
        } else {
          setOverrideBattery(true);
        }
        openNotification();
        setOverrideModalIsOpen(false);
        form.setFieldsValue({ qrCode: null });
      }
    }
  };

  const handleCloseOverrideModal = () => {
    form.setFieldsValue({ qrCode: null });
    setOverrideModalIsOpen(false);
    setOverrideHardDrive(false);
  };
  const itemsBattery = [
    {
      label: 'Battery',
      key: 'Battery',
      forceRender: true,
      children: (
        <Battery
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'History',
      key: 'History',
      forceRender: true,
      children: <History itemHistory={itemHistory} serialNumber={serialNumber.toUpperCase()} />,
    },
    {
      label: 'New History Log',
      key: 'New History Log',
      forceRender: true,
      children: <NewHistory inventoryItemsId={inventoryItemsId} />,
    },
  ];
  const itemsRam = [
    {
      label: 'Memory RAM',
      key: 'Memory RAM',
      forceRender: true,
      children: (
        <GraphicCard
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'History',
      key: 'History',
      forceRender: true,
      children: <History itemHistory={itemHistory} serialNumber={serialNumber.toUpperCase()} />,
    },
    {
      label: 'New History Log',
      key: 'New History Log',
      forceRender: true,
      children: <NewHistory inventoryItemsId={inventoryItemsId} />,
    },
  ];

  const itemsStorage = [
    {
      label: 'Hard Drive',
      key: 'Hard Drive',
      forceRender: true,
      children: (
        <HardDrive
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'History',
      key: 'History',
      forceRender: true,
      children: <History itemHistory={itemHistory} serialNumber={serialNumber.toUpperCase()} />,
    },
    {
      label: 'New History Log',
      key: 'New History Log',
      forceRender: true,
      children: <NewHistory inventoryItemsId={inventoryItemsId} />,
    },
  ];
  const itemsKeyboard = [
    {
      label: 'Keyboard Inspection',
      key: 'Keyboard Inspection',
      forceRender: true,
      children: (
        <Keyboard
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
          keyboardLanguageOptions={keyboardLanguageOptions}
          keyboardOptions={keyboardOptions}
          keyboardPortOptions={keyboardPortOptions}
          profile={profile}
        />
      ),
    },
    {
      label: 'Additional Information',
      key: 'Additional Information',
      forceRender: true,
      children: <Additional itemInfo={itemInfo} itemDisabled={itemDisabled} />,
    },
    {
      label: 'Bulk',
      key: 'Bulk',
      forceRender: true,
      children: (
        <Bulk form={form} handleBulkInventoryItemInfo={handleBulkInventoryItemInfo} itemDisabled={itemDisabled} itemInfo={itemInfo} profile={profile} />
      ),
    },
    {
      label: 'History',
      key: 'History',
      forceRender: true,
      children: <History itemHistory={itemHistory} serialNumber={serialNumber.toUpperCase()} />,
    },
    {
      label: 'New History Log',
      key: 'New History Log',
      forceRender: true,
      children: <NewHistory inventoryItemsId={inventoryItemsId} />,
    },
  ];
  const itemsComputer = [
    {
      label: 'Functionality Inspection',
      key: 'Hardware',
      forceRender: true,
      children: (
        <Hardware
          cameraOptions={cameraOptions}
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
          keyboardLanguageOptions={keyboardLanguageOptions}
          keyboardOptions={keyboardOptions}
          keyboardPortOptions={keyboardPortOptions}
          profile={profile}
          screenOptions={screenOptions}
          smartCardOptions={smartCardOptions}
          stylusOptions={stylusOptions}
          setCheckUsb={setCheckUsb}
          checkUsb={checkUsb}
          checkGraphicsCard={checkGraphicsCard}
          setCheckGraphicsCard={setCheckGraphicsCard}
          checkCpu={checkCpu}
          setCheckCpu={setCheckCpu}
          checkStorage1={checkStorage1}
          setCheckStorage1={setCheckStorage1}
          checkStorage2={checkStorage2}
          setCheckStorage2={setCheckStorage2}
          checkRam1={checkRam1}
          setCheckRam1={setCheckRam1}
          checkRam2={checkRam2}
          setCheckRam2={setCheckRam2}
        />
      ),
    },
    {
      label: 'Software Inspection',
      key: 'Software Inspection',
      forceRender: true,
      children: (
        <Software
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemInfo={itemInfo}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'Cosmetic Inspection',
      key: 'Cosmetic Inspection',
      forceRender: true,
      children: (
        <Cosmetic
          cleanFieldsNotPassed={cleanFieldsNotPassed}
          cosmeticsScreenOptions={cosmeticsScreenOptions}
          form={form}
          instructions={instructions}
          itemDisabled={itemDisabled}
          itemResults={itemResults}
        />
      ),
    },
    {
      label: 'Additional Information',
      key: 'Additional Information',
      forceRender: true,
      children: <Additional itemInfo={itemInfo} itemDisabled={itemDisabled} />,
    },
    {
      label: 'Bulk',
      key: 'Bulk',
      forceRender: true,
      children: (
        <Bulk form={form} handleBulkInventoryItemInfo={handleBulkInventoryItemInfo} itemDisabled={itemDisabled} itemInfo={itemInfo} profile={profile} />
      ),
    },
    {
      label: 'History',
      key: 'History',
      forceRender: true,
      children: <History itemHistory={itemHistory} serialNumber={serialNumber.toUpperCase()} />,
    },
    {
      label: 'New History Log',
      key: 'New History Log',
      forceRender: true,
      children: <NewHistory inventoryItemsId={inventoryItemsId} />,
    },
  ];

  return (
    <Container>
      <Prompt when={formChanged} message="You have unsaved changes, are you sure you want to leave?" />

      <span style={{ display: 'inline' }}>
        <Title level={3}>Technician Inspection Report for {serialNumber.toUpperCase()}</Title>
        <InputText
          placeholder="Enter the serial number"
          onPressEnter={handleOnKeyDownSerialNumberSearch}
          autoComplete="off"
          onBlur={(e) => {
            e.target.value = e.target.value.toUpperCase().trim();
          }}
          ref={searchInputRef}
          IconAfterProp={<MagnifyingGlass size={20} />}
        />
      </span>

      {/* {orderType && match.params.serial_number.toUpperCase() === serialNumber.toUpperCase() && orderType === 'internal' && ( */}
      {orderType && orderType === 'internal' && (
        <Button type="link" onClick={() => history.push(`/internalOrderEdit?internal_order_id=${generalOrderId}`)}>
          Back to Internal Order
        </Button>
      )}

      {/* {orderType && match.params.serial_number.toUpperCase() === serialNumber.toUpperCase() && orderType === 'order' && ( */}
      {orderType && orderType === 'order' && (
        <Button type="link" onClick={() => history.push(`/ordersEdit?order_id=${generalOrderId}`)}>
          Back to order
        </Button>
      )}

      {loading ? (
        <Spin tip="Loading...">
          <Alert
            message="Inspection page is loading. Hold on a second"
            description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
            type="info"
          />
        </Spin>
      ) : (
        <Form
          form={form}
          name="inspectionForm"
          // onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onChange={() => handleFormChange()}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ForPartsModal open={confirmChangeModalForParts} setOpen={setConfirmChangeModalForParts} loading={confirmLoading} handleOk={handleChangeToForParts} />

          <Modal
            title="Why are you changing this item?"
            open={confirmChangeModalOnFinish}
            onOk={() => {
              if (changeItemNotes.length >= 15) {
                continueOnFinish(onFinishData);
                form.setFieldsValue({ postResult: '', changeNotes: '' });
              } else {
                toast.error('Must write an explanation before completing this inspection');
              }
            }}
            confirmLoading={confirmLoading}
            onCancel={() => setConfirmChangeModalOnFinish(false)}
          >
            <Title level={4}>{changeBeingDone} Change</Title>
            <Form.Item name="changeNotes" rules={[{ min: 15, message: 'Notes must be minimum 15 characters.' }]}>
              <TextArea
                onBlur={(e) => setChangeItemNotes(e.target.value)}
                showCount
                maxLength={255}
                allowClear
                style={{ width: 465 }}
                placeholder="Explain why you are changing this item"
                rows={4}
              />
            </Form.Item>

            <>
              <Title level={5}>POST</Title>
              <p>Only change POST if needs changing</p>
              <Form.Item name="postResult">
                <Radio.Group buttonStyle="solid" name="postResult">
                  <Radio.Button value="passed">Passed</Radio.Button>
                  <Radio.Button value="failed">Failed</Radio.Button>
                  <Radio.Button value="new">New</Radio.Button>
                  <Radio.Button value="na">N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          </Modal>

          <Modal
            title="Why are you changing this item?"
            open={confirmChangeModal}
            onOk={handleConfirmChangeItem}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <Title level={4}>{changeBeingDone} Change</Title>
            <Form.Item name="changeNotes" rules={[{ min: 15, message: 'Notes must be minimum 15 characters.' }]}>
              <TextArea
                onBlur={(e) => setChangeItemNotes(e.target.value)}
                showCount
                maxLength={255}
                allowClear
                style={{ width: 465 }}
                placeholder="Explain why you are changing this item"
                rows={4}
              />
            </Form.Item>
            {changeBeingDone === 'New' || changeBeingDone === 'Used' || changeBeingDone === 'Not Inspected' ? (
              <>
                <Title level={5}>POST</Title>
                <p>Only change POST if needs changing</p>
                <Form.Item name="postResult">
                  <Radio.Group buttonStyle="solid" name="postResult">
                    {changeBeingDone === 'Not Inspected' && (
                      <>
                        <Radio.Button value="passed">Passed</Radio.Button>
                        <Radio.Button value="failed">Failed</Radio.Button>
                        <Radio.Button value="new">New</Radio.Button>
                        <Radio.Button value="na">N/A</Radio.Button>
                      </>
                    )}
                    {changeBeingDone === 'New' && <Radio.Button value="new">New</Radio.Button>}
                    {changeBeingDone === 'Used' && <Radio.Button value="na">N/A</Radio.Button>}
                  </Radio.Group>
                </Form.Item>
              </>
            ) : (
              ''
            )}
            {changeBeingDone === 'Part Number' && (
              <Form.Item
                name="itemId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DebounceSelect
                  showSearch
                  allowClear
                  style={{ width: 465 }}
                  placeholder="Item / Part Number"
                  optionFilterProp="label"
                  fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                />
              </Form.Item>
            )}
            {changeBeingDone === 'Reservation' && (
              <Form.Item
                name="reserveType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select showSearch allowClear style={{ width: 465 }} placeholder="Reserve Type" options={reserveTypeOptions} />
              </Form.Item>
            )}
          </Modal>

          {itemInfo?.serial_number && (
            <Row style={{ marginRight: '10px' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="end" align="middle">
              <Space>
                {/* <Form.Item>
                  <Button disabled={itemDisabled} type="primary" icon={<CloudDownloadOutlined />} onClick={handleRetrieveData}>
                    Retrieve data
                  </Button>
                </Form.Item> */}

                {!['Sold', 'Downstream', 'SentToFBA'].includes(itemInfo.status) && isAssignToSignedUser && (
                  <Form.Item>
                    <Dropdown menu={{ items: changeDropdown }} trigger={['click']}>
                      <Button href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        Change <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Form.Item>
                )}
                {warrantyCheck.length !== 0 ? (
                  <Form.Item>
                    <Button style={{ background: '#3EB19E', borderColor: '#3EB19E', color: 'white' }} onClick={showWarranty} icon={<InfoCircleOutlined />}>
                      MFGR Warranty
                    </Button>
                    <Modal title="Warranty Details" open={isWarrantyVisible} onOk={closeWarranty} onCancel={closeWarranty} width={1000}>
                      <Table columns={warrantyColumns} dataSource={warrantyCheck} pagination={false} />
                    </Modal>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Button style={{ background: '#3EB19E', borderColor: '#3EB19E', color: 'white' }} onClick={showWarranty} icon={<InfoCircleOutlined />}>
                      MFGR Warranty
                    </Button>
                    <Modal title="Warranty Details" open={isWarrantyVisible} onOk={closeWarranty} onCancel={closeWarranty}>
                      <CloseCircleOutlined style={{ color: '#eb2f96', fontSize: '20px' }} /> No Warranty.
                    </Modal>
                  </Form.Item>
                )}

                <Form.Item>
                  <Tooltip title={disabledMessage} color="orange">
                    <Badge color="volcano" dot={formChanged} style={{ width: 10, height: 10 }}>
                      <Button disabled={itemDisabled} type="primary" onClick={onFinish} icon={<SaveOutlined />}>
                        Complete
                      </Button>
                    </Badge>
                  </Tooltip>
                </Form.Item>

                <Form.Item>
                  <Popconfirm
                    title="Are you sure to cancel this task?"
                    onConfirm={async () => {
                      async function setWarningOff() {
                        setFormChanged(false);
                        window.onbeforeunload = undefined;
                      }
                      await setWarningOff();
                      form.resetFields();
                      setSerialNumber('');
                      searchInputRef.current.value = '';
                      searchInputRef.current.focus();
                      setItemInfo(() => ({ item: { brand: {} }, category: {}, cpu: {} }));
                      history.push('/inventoryProcessing');
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button disabled={itemDisabled} type="primary" danger htmlType="button" icon={<CloseOutlined />}>
                      Cancel
                    </Button>
                  </Popconfirm>
                </Form.Item>
                <Form.Item>
                  <Popconfirm
                    title="Are you sure to clear all fields?"
                    onConfirm={() => {
                      handleClear();
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={itemDisabled}
                      type="primary"
                      style={{ backgroundColor: 'orange', borderColor: 'orange' }}
                      htmlType="button"
                      icon={<CloseOutlined />}
                    >
                      Clear
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </Space>
            </Row>
          )}
          {itemInfo?.serial_number && (
            <>
              {!itemInfo.is_ready && (
                <Alert
                  message="This unit didn't complete the first assessment. Please talk to the Processing area or the RBMS admins."
                  type="warning"
                  style={{ marginBottom: 10, textAlign: 'center' }}
                />
              )}
              <Descriptions title="" size="small" bordered>
                <Descriptions.Item label="Original Specification" span={3}>
                  <Tooltip title={itemResults?.itemDescription} color="blue">
                    {itemResults?.itemDescription && `${itemResults?.itemDescription.substring(0, 180)}...`}
                  </Tooltip>
                </Descriptions.Item>

                {orderType && (
                  <>
                    <Descriptions.Item label="Build Specification" span={3}>
                      <Tooltip title={form.getFieldValue('itemBuildDescription')} color="blue">
                        {form.getFieldValue('itemBuildDescription') && `${form.getFieldValue('itemBuildDescription').substring(0, 180)}...`}
                      </Tooltip>
                    </Descriptions.Item>

                    {form.getFieldValue('storeOrderItemNotes') && (
                      <Descriptions.Item label="Store Notes" span={3} contentStyle={{ background: '#3EB19E', color: 'white' }}>
                        <Tooltip title={form.getFieldValue('storeOrderItemNotes')} color="blue">
                          {form.getFieldValue('storeOrderItemNotes')}
                        </Tooltip>
                      </Descriptions.Item>
                    )}

                    {form.getFieldValue('rbmsOrderItemNotes') && (
                      <Descriptions.Item label="Manual Notes" span={3} contentStyle={{ background: '#3EB19E', color: 'white' }}>
                        <Tooltip title={form.getFieldValue('rbmsOrderItemNotes')} color="blue">
                          {form.getFieldValue('rbmsOrderItemNotes')}
                        </Tooltip>
                      </Descriptions.Item>
                    )}
                  </>
                )}

                <Descriptions.Item label="Inspected">
                  {itemInfo?.is_processed ? <Tag color="success">INSPECTED</Tag> : <Tag color="error">NOT INSPECTED</Tag>}
                </Descriptions.Item>

                <Descriptions.Item label="Location">
                  <Button type="link" onClick={showLocationModal} style={{ paddingLeft: 0 }}>
                    {`${itemInfo?.locations?.name || ''} ${itemInfo?.usersLocation?.name || ''}`}
                  </Button>
                  {modalLocationsLog}
                </Descriptions.Item>

                <Descriptions.Item label="Status">{itemInfo.status}</Descriptions.Item>

                <Descriptions.Item label="POST">
                  {itemInfo?.post_result === 'passed' ? (
                    <Tag color="success">PASSED</Tag>
                  ) : itemInfo?.post_result === 'new' ? (
                    <Tag color="success">NEW</Tag>
                  ) : itemInfo?.post_result === 'failed' ? (
                    <Tag color="error">FAILED</Tag>
                  ) : itemInfo?.post_result === 'na' ? (
                    <Tag color="warning">N/A</Tag>
                  ) : (
                    <Tag color="warning">NOT TESTED</Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Condition">{itemInfo?.condition}</Descriptions.Item>
                <Descriptions.Item label="Technician">{itemResults.technicianName}</Descriptions.Item>

                <Descriptions.Item label="Company Grade">{itemInfo?.grade}</Descriptions.Item>
                <Descriptions.Item label="Part Number">{itemInfo?.item.part_number}</Descriptions.Item>

                <Descriptions.Item label="Last inspected at">{moment(itemInfo.last_inspection_at).format('MMM DD, YYYY HH:mm')}</Descriptions.Item>

                <Descriptions.Item
                  label={
                    <Popover content={<div dangerouslySetInnerHTML={{ __html: gradingRules }} />} title="Cosmetic Grading" trigger="click">
                      <Button style={{ padding: 0 }} type="link">
                        Cosmetic Grade
                      </Button>
                    </Popover>
                  }
                >
                  {itemResults.certifiedGrade}
                </Descriptions.Item>

                <Descriptions.Item label="Cosmetic Grade Description" span={2}>
                  {gradeDescription}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <Popover content={<div dangerouslySetInnerHTML={{ __html: gradingRulesFunctions }} />} title="Functionality Grading" trigger="click">
                      <Button style={{ padding: 0 }} type="link">
                        Functionality Grade
                      </Button>
                    </Popover>
                  }
                >
                  {gradeFunctionDescription[0]}
                </Descriptions.Item>

                <Descriptions.Item label="Functionality Grade Description" span={2}>
                  {gradeFunctionDescription[1]}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              {inventoryItemsLogId !== null && (
                <Alert
                  message="Informational Notes"
                  description="This Inspection Data Sheet has been queried from Asset History, and you won't be allowed to edit information."
                  type="info"
                  showIcon
                />
              )}
              <Modal title="Authorize override" onOk={handleOverrideHardDrive} onCancel={handleCloseOverrideModal} open={overrideModalIsOpen}>
                <Text>
                  This inspection is missing the {overrideModalFlag}. Since this is for an order all units need to have a {overrideModalFlag}. If this order
                  does not need one, please ask your Supervisor to clear this with his qrCode.
                </Text>
                <Divider />
                <Form.Item name="qrCode" label="QR Code">
                  <Input autoFocus name="qrCode" type="password" autoComplete="new-password" placeholder="Please scan the QR Code" style={{ width: 300 }} />
                </Form.Item>
              </Modal>
              {inventoryItemsLogId !== null && <Divider />}

              {[4].includes(itemInfo.item.category?.id) && [10].includes(itemInfo.item.subcategory?.id) ? (
                <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsKeyboard} />
              ) : [9].includes(itemInfo.item.category?.id) && [4].includes(itemInfo.item.subcategory?.id) ? (
                <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsBattery} />
              ) : [6].includes(itemInfo.item.category?.id) ? (
                <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsStorage} />
              ) : [7, 15].includes(itemInfo.item.category?.id) ? (
                <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsRam} />
              ) : (
                <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }} items={itemsComputer} />
              )}
            </>
          )}
        </Form>
      )}
    </Container>
  );
}
