import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import New from './New';

import api from '~/services/api';

import { Container } from './styles';

const statusColors = {
  'Received RB': '#b2fab4',
  Returning: '#FA8D8D',
  'Received CBI': '#FBE6A8',
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  rows: {
    '&:hover': {
      background: '#ddd',
      cursor: 'pointer',
    },
  },
  search: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Returns() {
  const classes = useStyles();

  const inputSearch = useRef('');

  const [returnList, setReturnList] = useState([
    {
      order: {
        customer: {},
      },
    },
  ]);
  const [rmaData, setRmaData] = useState({});
  const [query /* setQuery */] = useState('status=open');

  useEffect(() => {
    async function loadReturns() {
      const returnsList = await api.get(`/returns?${query}`);
      setReturnList(returnsList.data);
    }

    loadReturns();
  }, [query]);

  const [open, setOpen] = useState(false);

  const handleClose = async () => {
    const returnsList = await api.get('/returns?status=open');
    inputSearch.current.value = '';
    setReturnList(returnsList.data);
    setOpen(false);
  };

  const handleEdit = async (returnId) => {
    const returnData = await api.get(`/returns/${returnId}`);

    const returnInfo = returnData.data;

    const orderData = await api.get(`/orders/${returnInfo.order_id}`);

    returnInfo.id = returnId;
    returnInfo.items_returned = returnInfo.items_returned.split(',');
    returnInfo.customer = orderData.data.customer.name;
    returnInfo.company = orderData.data.customer.company;
    returnInfo.store = orderData.data.store.name;
    returnInfo.order_date = orderData.data.order_date;
    returnInfo.order_items = orderData.data.order_items;
    returnInfo.checklist = orderData.data.checklist;

    setRmaData(returnInfo);
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
    setRmaData({});
  };

  const handleSearch = useCallback(
    async (filter) => {
      if (filter) {
        const returnsList = await api.get(`/returns?filter=${filter.trim().toUpperCase()}`);
        if (returnsList.data.error) {
          toast.error(returnsList.data.error);
          return;
        }
        setReturnList(returnsList.data);
      } else {
        const returnsList = await api.get('/returns?status=open');
        setReturnList(returnsList.data);
      }
    },
    [] // eslint-disable-line
  );

  return (
    <Container>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />} onClick={() => handleOpen()}>
              Add
            </Button>
            <TextField
              label="Search"
              id="outlined-margin-dense"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              inputRef={inputSearch}
              onBlur={(e) => {
                e.target.value = e.target.value.trim().toUpperCase();
              }}
              autoFocus
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: <Search className={classes.search} onClick={() => handleSearch(inputSearch.current.value)} />,
              }}
            />

            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>RMA</StyledTableCell>
                    <StyledTableCell>Order #</StyledTableCell>
                    <StyledTableCell>Return Date</StyledTableCell>
                    <StyledTableCell>Order Date</StyledTableCell>
                    <StyledTableCell>Customer</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Tracking Code</StyledTableCell>
                    <StyledTableCell>Tracking Status</StyledTableCell>
                    <StyledTableCell>Return Status</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returnList.map((row, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index} className={classes.rows} onDoubleClick={() => handleEdit(row.id)}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.order_num}</TableCell>
                      <TableCell>{moment(row.createdAt).format('MMMM D, YYYY')}</TableCell>
                      <TableCell>{moment(row.order.order_date).format('MMMM D, YYYY')}</TableCell>
                      <TableCell>
                        {row.order.customer.company ? `${row.order.customer.name} (${row.order.customer.company})` : row.order.customer.name || ''}
                      </TableCell>

                      <TableCell>
                        {row.order.customer.street1}, {row.order.customer.city}, {row.order.customer.state}, {row.order.customer.country}
                      </TableCell>
                      <TableCell>
                        {row.tracking && row.tracking.substring(0, 2) === '1Z' ? (
                          <a rel="noopener noreferrer" target="_blank" href={`https://www.ups.com/track?loc=en_ca&tracknum=${row.tracking.replace(/\s/g, '')}`}>
                            {row.tracking}
                          </a>
                        ) : (
                          row.tracking
                        )}
                      </TableCell>
                      <TableCell style={{ backgroundColor: statusColors[row.tracking_status] }}>{row.tracking_status}</TableCell>
                      <TableCell>{row.return_status}</TableCell>
                      <TableCell>{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
      <New open={open} handleClose={handleClose} rma={rmaData} />
    </Container>
  );
}
