import api from '~/services/api';

export async function fetchStorageSize(search) {
  if (!search) {
    return '';
  }
  const productsData = await api.get(`/storages?search=${search}`);

  return productsData.data.map((el) => ({
    value: `${el.name}`,
    label: `${el.name}`,
  }));
}
