import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { GridContainer, GridItems, GridTitle } from './styles';

const sortAlphaNum = (a, b) => parseInt(a.name.replaceAll(/[A-Z]+/ig, '')) - parseInt(b.name.replaceAll(/[A-Z]+/ig, '')) || a.name.localeCompare(b.name); //eslint-disable-line

export default function GridBox({ locationGroup, locationList }) {
  return (
    <GridContainer>
      <GridTitle>{locationGroup}</GridTitle>
      <GridItems>
        {locationList?.sort(sortAlphaNum).map(location => {
          return location.location_parent_id !== null ? (
            <div key={location.id}>
              <Link to={`/locationList?location=${location.location_parent_id}`}>{location.name}</Link>
            </div>
          ) : (
            <div key={location.id}>
              <Link to={`/locationList?location=${location.id}`}>{location.name}</Link>
            </div>
          );
        })}
      </GridItems>
    </GridContainer>
  );
}

GridBox.propTypes = {
  locationGroup: PropTypes.string.isRequired,
  locationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // rowKey: PropTypes.string.isRequired,
};
