/* eslint-disable no-loop-func */
export default function ReportFields(reportsData) {
  const report = [];

  reportsData.forEach(data => {
    report.push({
      id: data.id,
      part_number: data.item?.part_number,
      name: data.item?.name,
      description: data.item?.description,
      category: data.item?.category.name,
      serial_number: data.serial_number,
      status: data.status,
      hours: data.hours,
      condition: data.condition,
      cpu: `${data.item?.cpu.brand} ${data.item?.cpu.number} ${data.item?.cpu.speed}`,
      grade: data.grade,
      location: `${data.locations?.name || ''} ${data.usersLocation?.name || ''}`,
      touch: data.touch,
      stylus: data.stylus,
      camera: data.camera,
      storage: data.storage,
      storage_2: data.storage_2,
      ram: data.ram,
      ram_2: data.ram_2,
      operating_system: data.operating_system,
      processed_by: data.processed_by,
      notes: data.description,
      receive_date: data.createdAt,
      received_by: data.users?.name,
      post: data.post_result,
      last_updated: data.updatedAt,
    });
  });

  return report;
}
