import React, { useEffect, useState } from 'react';

import { Column } from '@ant-design/plots';
import { Button, DatePicker, Typography } from 'antd';
import moment from 'moment-timezone';

import api from '~/services/api';
import { Container } from './styles';

const { Title } = Typography;
const CURRENT_YEAR = new Date().getFullYear();
const TWO_YEARS_AGO = new Date().getFullYear() - 2;

export function OrderOnTime() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearlyAverage, setYearlyAverage] = useState(0);
  const [togglePercentQty, setTogglePercentQty] = useState(true);
  const [stackedList, setStackedList] = useState([]);
  const [percentList, setPercentList] = useState([]);

  async function loadData(yearToReport) {
    const kpiOrdersOnTime = await api.get(`kpi/ordersOnTimeYearly?year=${yearToReport}`);

    function calculateLateToOnTimeRatio(data) {
      // Use the reduce method to sum onTime and late values
      const { totalOnTime, totalLate } = data.reduce(
        (accumulator, entry) => ({
          totalOnTime: accumulator.totalOnTime + entry.onTime,
          totalLate: accumulator.totalLate + entry.late,
        }),
        { totalOnTime: 0, totalLate: 0 }
      );

      // Calculate the ratio of late to onTime
      const lateToOnTimeRatio = totalLate / totalOnTime;

      return lateToOnTimeRatio;
    }

    const yearlyAvg = calculateLateToOnTimeRatio(kpiOrdersOnTime.data);

    // const yearlyAvg =
    //   kpiOrdersOnTime.data.reduce((previous, current) => {
    //     const sum = previous + Number(current.percent);
    //     return sum;
    //   }, 0) / kpiOrdersOnTime.data.length;

    const lateOrdersArr = kpiOrdersOnTime.data.map((el) => ({
      monthName: el.monthName,
      month: el.month,
      type: 'Late',
      value: el.late,
    }));

    const onTimeArr = kpiOrdersOnTime.data.map((el) => ({
      monthName: el.monthName,
      month: el.month,
      type: 'On Time',
      value: el.onTime,
    }));

    setStackedList(lateOrdersArr.concat(onTimeArr));
    setPercentList(kpiOrdersOnTime.data);
    setYearlyAverage((1 - yearlyAvg) * 100);
  }

  useEffect(() => {
    loadData(year);
  }, []); //eslint-disable-line

  const handleChangeYear = async (newYear) => {
    loadData(moment(newYear).year());
    setYear(moment(newYear).year());
  };

  const config = {
    data: togglePercentQty ? percentList : stackedList,
    isStack: !togglePercentQty,
    yAxis: {
      min: 80,
    },
    xField: 'monthName',
    yField: togglePercentQty ? 'percent' : 'value',
    seriesField: togglePercentQty ? null : 'type',
    label: {
      content: function content(item) {
        return togglePercentQty ? ''.concat(item.percent, '%') : item.value;
      },
      position: 'middle',
      style: {
        fill: '#000',
        opacity: 0.6,
      },
    },
  };

  const handleTogglePercentQty = async () => {
    setTogglePercentQty(!togglePercentQty);
  };

  return (
    <Container>
      <div style={{ width: '100%', padding: '10px 0 20px' }}>
        <Button type={togglePercentQty ? 'primary' : 'default'} onClick={handleTogglePercentQty}>
          {togglePercentQty ? 'Show Quantity' : 'Show Percentage'}
        </Button>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 0, margin: 0 }}>
        <Title level={2}>Orders On Time Report for {year}</Title>
        <DatePicker
          allowClear={false}
          disabledDate={(d) => !d || d.isAfter(`${CURRENT_YEAR}-12-31`) || d.isSameOrBefore(`${TWO_YEARS_AGO}-01-01`)}
          format="YYYY"
          defaultPickerValue={moment().year(Number)}
          defaultValue={moment().year(Number)}
          picker="year"
          onChange={handleChangeYear}
        />
      </div>
      {togglePercentQty && <Title level={4}>{`Yearly Average: ${yearlyAverage.toFixed(2)}%`}</Title>}

      <Column {...config} style={{ width: '65vw' }} />
    </Container>
  );
}
