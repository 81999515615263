import React, { useEffect, useState } from 'react';
import { Form, Input, Select } from '@rocketseat/unform';

// import { useDispatch } from 'react-redux';
// import { createItemRequest } from '~/store/modules/item/actions';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function ItemNew() {
  const [items, setItems] = useState([]);
  const [storages, setStorages] = useState([]);
  const [rams, setRams] = useState([]);
  const [operatingSystems, setOperatingSystems] = useState([]);
  // const [keyboards, setKeyboards] = useState([]);

  useEffect(() => {
    async function loadDropdownboxes() {
      const itemList = await api.get('items');
      const storagesList = await api.get('storages');
      const ramsList = await api.get('rams');
      const operatingSystemsList = await api.get('operating_systems');
      // const keyboardsList = await api.get('keyboard_types');

      setItems(
        itemList.data.map(list => {
          return { id: list.id, title: `${list.sku} - ${list.name}` };
        })
      );
      setStorages(
        storagesList.data.map(list => {
          return { id: list.id, title: `${list.capacity} ${list.unit} ${list.type}` };
        })
      );
      setRams(
        ramsList.data.map(list => {
          return { id: list.id, title: `${list.capacity} ${list.type}` };
        })
      );
      setOperatingSystems(
        operatingSystemsList.data.map(list => {
          return { id: list.id, title: list.name };
        })
      );
      // setKeyboards(
      //   keyboardsList.data.map(list => {
      //     return {
      //       id: list.id,
      //       title: `${list.type} ${list.rubber ? 'Rubber' : ''} ${list.water_resistant ? 'Water Resistant' : ''} (${list.language})`,
      //     };
      //   })
      // );
    }

    loadDropdownboxes();
  }, []);

  async function handleSubmit(data) {
    await api.post('listings', data);
    history.push('/listings');
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {/* <Form> */}
        <Select name="item_id" options={items} placeholder="Item" />
        <Select name="storage_id" options={storages} placeholder="Storage" />
        <Select name="ram_id" options={rams} placeholder="RAM" />
        <Select name="operating_system_id" options={operatingSystems} placeholder="Operating System" />
        {/* <Select name="keyboard_type_id" options={keyboards} placeholder="Keyboard" /> */}
        <Input name="details" placeholder="Details" />
        <Input name="price_usd" placeholder="Price USD" />
        <Input name="price_usd_wholesale" placeholder="Price USD Wholesale" />
        <button type="submit">Add Listing/Variation</button>
      </Form>
    </Container>
  );
}
