import { createTheme, MuiThemeProvider } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Empty, Space, Spin, Typography } from 'antd';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';

import { Datatable } from '../Inventory/Components/Datatable';
import { Container } from './styles';

export default function InventorySearch() {
  const [searchTxt, setSearchTxt] = useState('');
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [checkedCategoryId, setCheckedCategoryId] = useState([]);

  const searchInputRef = useRef('');

  const { Paragraph } = Typography;

  const handleSearch = useCallback(
    (searchQueryParam = '') => {
      async function loadInventory() {
        try {
          setLoading(true);
          const search = searchQueryParam.trim() || searchTxt.trim() || '';
          const params = new URLSearchParams();
          params.append('searchText', encodeURIComponent(search));
          params.append('categories', checkedCategoryId);

          const inventoryList = await api.get(`inventorySearchByCategories?${params}`);

          setSearchTxt(() => search);

          setInventory(() => inventoryList.data);
          setLoading(() => false);
        } catch (error) {
          toast.error(error.result.data.message);
        }
      }
      loadInventory();
    },
    [checkedCategoryId, searchTxt]
  );

  useEffect(() => {
    async function loadItems() {
      try {
        const categoriesData = await api.get('categories');
        setCategoriesList(() => categoriesData.data);
        handleSearch();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    loadItems();
  }, []); // eslint-disable-line

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  function columnLink(value, tableMeta) {
    return (
      <Link
        to={() => {
          const id = tableMeta.tableData.find((el) => el[3] === value || el.part_number === value);
          if (searchTxt === null || searchTxt === undefined) {
            return `inventoryEdit/${id[0] || id.id}`;
          }
          return `inventoryEdit/${id[0] || id.id}?searchQuery=${searchTxt}`;
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paragraph style={{ color: '#1890ff' }} copyable>
          {value}
        </Paragraph>
      </Link>
    );
  }

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'brand',
      label: 'Brand',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'part_number',
      label: 'Part Number',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => columnLink(value, tableMeta),
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <Paragraph style={{ maxWidth: '450px' }} copyable>
            {value}
          </Paragraph>
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'cpu',
      label: 'CPU',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'ram',
      label: 'RAM',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'screen_type',
      label: 'Screen Type',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'New',
      label: 'New',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'OB',
      label: 'OB',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'U',
      label: 'U',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'A',
      label: 'A',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },

    {
      name: 'B',
      label: 'B',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'C',
      label: 'C',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'D',
      label: 'D',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'FA',
      label: 'FA',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'TBI',
      label: 'TBI',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/inventoryDetails?partNumber=${tableMeta.rowData[3]}&searchQuery=${encodeURIComponent(searchTxt.trim())}`);
            }}
          >
            <InfoIcon />
          </Button>
        ),
      },
    },
  ];

  const options = {
    fixedHeaderOptions: { xAxis: true, yAxis: true },
    filterType: 'multiselect',
    expandableRowsOnClick: false,
    pagination: true,
    download: true,
    print: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 150],
    search: false,
    responsive: 'scrollMaxHeight',
    checkboxSelection: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    tableBodyHeight: '799px',
    tableBodyMaxHeight: 'calc(100vh - 348px) !important',
    customToolbarSelect: () => {},
    customSort: (data, colIndex, order) =>
      data.sort((a, b) => {
        a = a.data[colIndex] || '';
        b = b.data[colIndex] || '';
        if (order === 'asc') return a.toString().localeCompare(b, undefined, { numeric: true });

        return b.toString().localeCompare(a, undefined, { numeric: true });
      }),
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            margin: 0,
            paddingBottom: '2px',
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: 'calc(100vh - 350px) !important',
          },
        },
      },
    });

  const theme = getMuiTheme();

  if (loading) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  const handleOnClick = async (category_id) => {
    if (checkedCategoryId.includes(category_id)) {
      const newCategoryList = checkedCategoryId.filter((category) => category !== category_id);
      setCheckedCategoryId(() => newCategoryList);
    } else {
      setCheckedCategoryId([...checkedCategoryId, category_id]);
    }
  };

  return (
    <Container>
      {categoriesList.length > 0 && (
        <Space wrap style={{ marginBlock: '1rem' }}>
          {categoriesList.map((category) => (
            <Button key={category.id} type={checkedCategoryId.includes(category.id) ? 'primary' : 'default'} onClick={() => handleOnClick(category.id)}>
              {category.name}
            </Button>
          ))}
        </Space>
      )}

      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <InputText
          ref={searchInputRef}
          placeholder="Search"
          autoComplete="off"
          autoFocus
          onBlur={(e) => {
            e.target.value = e.target.value.toUpperCase().trim();
          }}
          IconAfterProp={<MagnifyingGlass size={20} />}
          onPressEnter={handleSearch}
        />
      </div>

      {inventory.inventoryCount && inventory.inventoryCount.length > 0 ? (
        <MuiThemeProvider theme={theme}>
          <Datatable title="Inventory" data={inventory.inventoryCount} columns={columns} options={options} />
        </MuiThemeProvider>
      ) : (
        <Empty
          description={<span>Use the search box above to list inventory</span>}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh',
          }}
        />
      )}
    </Container>
  );
}
