import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  div#content {
    background-color: #fff;
    border-radius: 4px;
    /* margin: 30px; */
    padding: 30px;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 15px;
  }

  a {
    text-decoration: none;
    /* color: #c12; */
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;
