/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactExport from 'react-data-export';

import api from '~/services/api';
import { Container } from './styles';

export default function DownloadInventory(props) {
  const [dataSet, setDataSet] = useState([{}]);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const { search } = props.location;
  const params = new URLSearchParams(search);
  const preparedBy = params.get('preparedBy');

  function getQueryStringAPI() {
    const queryString = '';

    return queryString;
  }

  const queryStringAPI = getQueryStringAPI(preparedBy);

  useEffect(() => {
    async function getOrders() {
      const response = await api.get(`/ordersChecklist?${queryStringAPI}`);
      setDataSet(response.data);
    }
    getOrders();
  }, [queryStringAPI]);

  return (
    <Container>
      <h1>Download Orders Report</h1>
      <h3>The report File is ready</h3>
      <br />
      <ExcelFile
        filename="Orders"
        hideElement={false}
        element={
          <button type="button" className="pure-button pure-button-primary">
            Download
          </button>
        }
      >
        <ExcelSheet data={dataSet} name="Inventory">
          <ExcelColumn label="Order Num" value="order_num" />
          <ExcelColumn label="Technician" value={col => col.technician.name} />
          <ExcelColumn label="Technician Email" value={col => col.technician.email} />
          <ExcelColumn label="Serial Number" value="serial_number" />
        </ExcelSheet>
      </ExcelFile>
    </Container>
  );
}
