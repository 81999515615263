import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  height: 100vh;
  padding: 75px 20px 0 250px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: gray;
  margin-left: 10px;
  overflow: hidden;
  background-color: #fff;

  thead {
    th {
      font-size: 14px;
      padding: 2px;
      border: 1px solid #ccc;
    }
  }

  tbody {
    border-color: inherit;
    text-align: center;

    td {
      font-size: 14px;
      border: 1px solid #ccc;
    }
  }
`;
