import React, { useEffect, useState } from 'react';
import { Form, Input } from '@rocketseat/unform';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function StoreEdit(params) {
  const [store, setStore] = useState([]);

  useEffect(() => {
    async function loadStore() {
      const branchInfo = await api.get(`stores/${params.match.params.id}`);

      setStore(branchInfo.data);
    }
    loadStore();
  }, []); // eslint-disable-line

  async function handleSubmit(data) {
    await api.put(`stores/${params.match.params.id}`, data);
    history.push('/stores');
  }

  return (
    <Container>
      <Form initialData={store} onSubmit={handleSubmit}>
        <Input name="name" placeholder="Name" />

        <button type="submit">Edit Branch</button>
      </Form>
    </Container>
  );
}
