import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

// eslint-disable-next-line react/prop-types
export default function ItemEdit(params) {
  const classes = useStyles();
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [manufacturersOptions, setManufacturersOptions] = useState([]);
  const [storagesOptions, setStoragesOptions] = useState([]);
  const [cpusOptions, setCpusOptions] = useState([]);
  const [ramsOptions, setRamsOptions] = useState([]);
  const [operatingSystemsOptions, setOperatingSystemsOptions] = useState([]);
  const [keyboardsOptions, setKeyboardsOptions] = useState([]);
  const [screenSizesOptions, setScreenSizesOptions] = useState([]);
  const [screenTypesOptions, setScreenTypesOptions] = useState([]);
  const [screenResolutionsOptions, setScreenResolutionsOptions] = useState([]);
  const [screenTechnologiesOptions, setScreenTechnologiesOptions] = useState([]);
  const [subcategoriesOptions, setSubcategoriesOptions] = useState([]);
  const [locationsOptions, setLocationsOptions] = useState([]);

  const [category, setCategory] = useState({});
  const [brand, setBrand] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [storage, setStorage] = useState({});
  const [cpu, setCpu] = useState({});
  const [ram, setRam] = useState({});
  const [operatingSystem, setOperatingSystem] = useState({});
  const [keyboard, setKeyboard] = useState({});
  const [screenSize, setScreenSize] = useState({});
  const [screenType, setScreenType] = useState({});
  const [screenResolution, setScreenResolution] = useState({});
  const [screenTechnology, setScreenTechnology] = useState({});
  const [subcategory, setSubcategory] = useState({});
  const [currentLocation, setCurrentLocation] = useState({});

  const [itemInfo, setItemInfo] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    async function loadDropdownboxes() {
      const categoriesList = await api.get('categories');
      const brandsList = await api.get('brands');
      const manufacturersList = await api.get('manufacturers');
      const storagesList = await api.get('storages');
      const cpusList = await api.get('cpus');
      const ramsList = await api.get('rams');
      const operatingSystemsList = await api.get('operating_systems');
      const keyboardsList = await api.get('keyboard_types');
      const screenSizesList = await api.get('screen_sizes');
      const screenTypesList = await api.get('screen_types');
      const screenResolutionsList = await api.get('screen_resolutions');
      const screenTechnologiesList = await api.get('screen_technologies');
      const subcategoriesList = await api.get('subcategories');
      const itemInfoList = await api.get(`items/${params.match.params.id}`);
      const locationsList = await api.get('locations');

      const location = new URLSearchParams(params.location.search);
      setSearchQuery(location.get('searchQuery'));

      setItemInfo(itemInfoList.data);
      const itemCategory = categoriesList.data.find(e => e.id === itemInfoList.data?.category_id);
      setCategory({ value: itemCategory.id, label: itemCategory.name });
      const itemBrand = brandsList.data.find(e => e.id === itemInfoList.data?.brand_id) || '';
      setBrand({ value: itemBrand.id, label: itemBrand.name });
      const itemManufacturer = manufacturersList.data.find(e => e.id === itemInfoList.data?.manufacturer_id) || '';
      setManufacturer({ value: itemManufacturer.id, label: itemManufacturer.name });
      const itemStorage = storagesList.data.find(e => e.id === itemInfoList.data?.storage_id) || '';
      setStorage({ value: itemStorage.id, label: itemStorage.name });
      const itemCpu = cpusList.data.find(e => e.id === itemInfoList.data?.cpu_id) || '';
      setCpu({ value: itemCpu.id, label: itemCpu.name });
      const itemRam = ramsList.data.find(e => e.id === itemInfoList.data?.ram_id) || '';
      setRam({ value: itemRam.id, label: itemRam.name });
      const itemOperatingSystem = operatingSystemsList.data.find(e => e.id === itemInfoList.data?.operating_system_id) || '';
      setOperatingSystem({ value: itemOperatingSystem.id, label: itemOperatingSystem.name } || '');
      const itemKeyboard = keyboardsList.data.find(e => e.id === itemInfoList.data?.keyboard_type_id) || '';
      setKeyboard({ value: itemKeyboard.id, label: itemKeyboard.name });
      const itemScreenSize = screenSizesList.data.find(e => e.id === itemInfoList.data?.screen_size_id) || '';
      setScreenSize({ value: itemScreenSize.id, label: itemScreenSize.name });
      const itemScreenType = screenTypesList.data.find(e => e.id === itemInfoList.data?.screen_type_id) || '';
      setScreenType({ value: itemScreenType.id, label: itemScreenType.name });
      const itemScreenResolution = screenResolutionsList.data.find(e => e.id === itemInfoList.data?.screen_resolution_id) || '';
      setScreenResolution({ value: itemScreenResolution.id, label: itemScreenResolution.name });
      const itemScreenTechnology = screenTechnologiesList.data.find(e => e.id === itemInfoList.data?.screen_technology_id) || '';
      setScreenTechnology({ value: itemScreenTechnology.id, label: itemScreenTechnology.name });
      const itemSubcategory = subcategoriesList.data.find(e => e.id === itemInfoList.data?.subcategory_id) || '';
      setSubcategory({ value: itemSubcategory.id, label: itemSubcategory.name });
      const itemLocation = locationsList.data.find(e => e.id === itemInfoList.data?.location_id) || '';
      setCurrentLocation({ value: itemLocation.id, label: itemLocation.name });

      setCategoriesOptions(
        categoriesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setBrandsOptions(
        brandsList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setManufacturersOptions(
        manufacturersList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setStoragesOptions(
        storagesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setCpusOptions(
        cpusList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setRamsOptions(
        ramsList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setOperatingSystemsOptions(
        operatingSystemsList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setKeyboardsOptions(
        keyboardsList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setScreenSizesOptions(
        screenSizesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
      setScreenTypesOptions(
        screenTypesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );

      setScreenResolutionsOptions(
        screenResolutionsList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );

      setScreenTechnologiesOptions(
        screenTechnologiesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );

      setSubcategoriesOptions(
        subcategoriesList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );

      setLocationsOptions(
        locationsList.data.map(list => {
          return { value: list.id, label: list.name };
        })
      );
    }

    loadDropdownboxes();
  }, []); //eslint-disable-line

  const handleSubmit = async e => {
    e.preventDefault();
    await api.put('items', itemInfo);
    history.push('/items');
  };

  return (
    <Container>
      <Card>
        <div>
          <Link
            to={() => {
              if (searchQuery === null) {
                return `/items`;
              }
              return `/items?searchQuery=${searchQuery}`;
            }}
          >
            <Button variant="contained" color="primary" className={classes.button} startIcon={<KeyboardBackspace />}>
              Back
            </Button>
          </Link>
        </div>
        <form autoComplete="off" noValidate>
          <CardHeader subheader={`${itemInfo.sku} - ${itemInfo.name}`} title="Update Item" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, name: e.target.value })}
                  value={itemInfo.name || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="description"
                  label="Item description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, description: e.target.value })}
                  value={itemInfo.description || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name="model"
                  label="Item model"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, model: e.target.value })}
                  value={itemInfo.model || ''}
                  fullWidth
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  name="sku"
                  label="SKU"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, sku: e.target.value })}
                  value={itemInfo.sku || ''}
                  fullWidth
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  name="part_number"
                  label="Item part number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, part_number: e.target.value })}
                  value={itemInfo.part_number || ''}
                  fullWidth
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="category_id"
                  value={category || ''}
                  options={categoriesOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, category_id: newValue ? newValue.value : '' });
                    setCategory(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Category" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="brand_id"
                  value={brand || ''}
                  options={brandsOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, brand_id: newValue ? newValue.value : '' });
                    setBrand(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Brand" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="manufacturer_id"
                  options={manufacturersOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={manufacturer || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, manufacturer_id: newValue ? newValue.value : '' });
                    setManufacturer(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Manufacturer" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="storage_id"
                  options={storagesOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={storage || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, storage_id: newValue ? newValue.value : '' });
                    setStorage(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Storage" margin="normal" fullWidth />}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="cpu_id"
                  options={cpusOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={cpu || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, cpu_id: newValue ? newValue.value : '' });
                    setCpu(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="CPU" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="ram_id"
                  options={ramsOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={ram || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, ram_id: newValue ? newValue.value : '' });
                    setRam(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="RAM" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="operating_system_id"
                  options={operatingSystemsOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={operatingSystem || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, operating_system_id: newValue ? newValue.value : '' });
                    setOperatingSystem(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Operating System" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="keyboard_id"
                  options={keyboardsOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={keyboard || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, keyboard_type_id: newValue ? newValue.value : '' });
                    setKeyboard(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Keyboard" margin="normal" fullWidth />}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="screen_size_id"
                  options={screenSizesOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={screenSize || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, screen_size_id: newValue ? newValue.value : '' });
                    setScreenSize(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Screen Size" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="screen_type_id"
                  options={screenTypesOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={screenType || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, screen_type_id: newValue ? newValue.value : '' });
                    setScreenType(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Screen Type" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="screen_resolution_id"
                  options={screenResolutionsOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={screenResolution || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, screen_resolution_id: newValue ? newValue.value : '' });
                    setScreenResolution(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Screen Resolution" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="screen_technology_id"
                  options={screenTechnologiesOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={screenTechnology || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, screen_technology_id: newValue ? newValue.value : '' });
                    setScreenTechnology(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Screen Technology" margin="normal" fullWidth />}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="subcategory_id"
                  options={subcategoriesOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={subcategory || ''}
                  // value={(itemInfo.subcategory_id < 27 ? subcategories[itemInfo.subcategory_id - 1] : subcategories[itemInfo.subcategory_id - 2]) || null}
                  // value={subcategories[itemInfo.subcategory_id] || null}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, subcategory_id: newValue ? newValue.value : '' });
                    setSubcategory(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Subcategory" margin="normal" fullWidth />}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Autocomplete
                  id="location_id"
                  options={locationsOptions}
                  getOptionLabel={option => option.label || ''}
                  style={{ width: '100%' }}
                  value={currentLocation || ''}
                  onChange={(e, newValue) => {
                    setItemInfo({ ...itemInfo, location_id: newValue ? newValue.value : '' });
                    setCurrentLocation(newValue);
                  }}
                  renderInput={params2 => <TextField {...params2} label="Location" margin="normal" fullWidth />}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Update item
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
