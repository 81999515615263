/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Card, Checkbox, Col, Dropdown, Menu, Row, Space, Table, Typography } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Container } from './styles';

import api from '~/services/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const { Title } = Typography;

const columns = [
  {
    title: 'Serial Number',
    dataIndex: 'serial_number',
    render: (text, row) => (
      <Link to={`/inventoryProcessing/${row.id}`} target="_blank">
        {text}
      </Link>
    ),
  },
  {
    title: 'PO',
    dataIndex: 'po_number',
    render: (text, row) => (
      <Link to={`/purchaseOrders/${row.po_number}`} target="_blank">
        {text}
      </Link>
    ),
  },
  {
    title: 'Purchased At',
    dataIndex: 'purchased_at',
    sorter: (a, b) => new Date(a.purchased_at) - new Date(b.purchased_at),
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier_name',
  },
  {
    title: 'Country',
    dataIndex: 'supplier_country',
  },
  {
    title: 'Received At',
    dataIndex: 'received_at',
  },
  {
    title: 'Received By',
    dataIndex: 'received_by',
  },
  {
    title: 'Receiving Description',
    dataIndex: 'receiving_item_description',
  },
  {
    title: 'PO Description',
    dataIndex: 'po_item_description',
  },
  {
    title: 'Unit Cost',
    dataIndex: 'unit_cost',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Sales Order Date',
    dataIndex: 'sales_order_date',
  },
  {
    title: 'Sales Order Num',
    dataIndex: 'order_num',
    render: (text, row) => (
      <Link to={`/order/${row.order_num}`} target="_blank">
        {text}
      </Link>
    ),
  },
  {
    title: 'Store',
    dataIndex: 'store_name',
  },
  {
    title: 'Customer',
    dataIndex: 'customer_name',
  },
  {
    title: 'Sales Order Item Price',
    dataIndex: 'sales_order_item_price',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  // {
  //   title: 'Actions',
  //   dataIndex: 'actions',
  //   render: (text, row) => (
  //     <Link to={`inventoryAuditItems/${row.id}`}>
  //       <Button type="primary">Audit scanned units</Button>
  //     </Link>
  //   ),
  //   // render: (text, row) => <Link to={`inventoryAuditItems/${row.id}`}>Audit scanned units</Link>,
  // },
];

export default function CostReport(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);
  const [costBreakdownData, setCostBreakdownData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [zeroQty, setZeroQty] = useState();
  const [filterStatus, setFilterStatus] = useState(['InStock', 'ToBeInspected', 'Failed']);
  const [visibleStatus, setVisibleStatus] = useState(false);
  // const [partNumber, setPartNumber] = useState();
  const [inventoryItemId, setinventoryItemId] = useState();

  useEffect(() => {
    async function loadCost() {
      // eslint-disable-next-line react/prop-types
      const { search } = props.location;
      const params = new URLSearchParams(search);
      setSearchQuery(params.get('searchQuery'));
      setZeroQty(params.get('showZero'));
      // setPartNumber(params.get('partNumber'));
      setinventoryItemId(params.get('inventoryItemId'));

      const costAverage = await api.put('/report/CostAverage', { partNumber: params.get('partNumber') });
      setDataHeader(costAverage.data[0]);
      const costBreakdown = await api.put('/report/CostBreakdown', { partNumber: params.get('partNumber') });
      setCostBreakdownData(costBreakdown.data);
      const filteredData = [];
      filterStatus.forEach((filter) => {
        filteredData.push(...costBreakdown.data.filter((cost) => cost.status === filter));
      });
      setData(filteredData);
    }
    loadCost();
  }, []); //eslint-disable-line

  useEffect(() => {
    const filteredData = [];
    filterStatus.forEach((filter) => {
      filteredData.push(...costBreakdownData.filter((cost) => cost.status === filter));
    });
    setData(filteredData);
  }, [filterStatus, costBreakdownData]);

  const menu = (
    <Menu>
      <Menu.Item>
        <Checkbox.Group
          onChange={(value) => {
            setFilterStatus(value);
          }}
          value={filterStatus}
        >
          <Row>
            <Checkbox value="InUse">In Use</Checkbox>
          </Row>
          <Row>
            <Checkbox value="ToBeInspected">To Be Inspected</Checkbox>
          </Row>
          <Row>
            <Checkbox value="ToBeInspected-RMA">To Be Inspected-RMA</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Downstream">Downstream</Checkbox>
          </Row>
          <Row>
            <Checkbox value="AwaitingShipment">Awaiting Shipment</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Sold">Sold</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Returning">Returning</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Failed">Failed</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Returned">Returned</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Reserved">Reserved</Checkbox>
          </Row>
          <Row>
            <Checkbox value="InStock">In Stock</Checkbox>
          </Row>
        </Checkbox.Group>
      </Menu.Item>
    </Menu>
  );

  const handleVisibleChangeStatus = (flag) => {
    setVisibleStatus(flag);
  };

  return (
    <Container>
      <div id="cost_title">
        <Link
          to={() => {
            if (searchQuery === null) {
              return `/inventoryEdit/${inventoryItemId}`;
            }
            return `/inventoryEdit/${inventoryItemId}?searchQuery=${searchQuery}&showZero=${zeroQty}`;
          }}
        >
          <Button variant="contained" type="primary" className={classes.button} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </Link>
        <Title level={3}>Cost Report</Title>
        <div />
      </div>

      <Card>
        <Space direction="vertical">
          <Row>
            <Col xs={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 2 }}>
              <h2>Item Name: {dataHeader.name}</h2>
            </Col>
            <Col xs={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 2 }}>
              <h2>Part Number: {dataHeader.part_number}</h2>
            </Col>
            <Col xs={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 2 }}>
              <h2>Total Cost: ${dataHeader.total_unit_price}</h2>
            </Col>
            <Col xs={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 2 }}>
              <h2>Total Qty: {dataHeader.total_qty}</h2>
            </Col>
            <Col xs={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 2 }}>
              <h2>Average Cost: ${dataHeader.average_cost}</h2>
            </Col>
          </Row>
          <Space size={20}>
            <Title level={5}>Filter:</Title>
            <Dropdown overlay={menu} trigger={['click']} onVisibleChange={(e) => handleVisibleChangeStatus(e)} visible={visibleStatus}>
              <Button variant="outlined" href="#" className="ant-dropdown-link" style={{ width: 140 }} onClick={() => setVisibleStatus(true)}>
                Status
              </Button>
            </Dropdown>
          </Space>
          <Table
            rowKey={(record) => record.serial_number}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{
              pageSizeOptions: [25, 50, 100],
              defaultPageSize: 25,
              showSizeChanger: true,
              showQuickJumper: true,
              total: data.length,
              size: 'small',
            }}
          />
        </Space>
      </Card>
    </Container>
  );
}
