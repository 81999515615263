import React from 'react';

// import { useSelector } from 'react-redux';

// import Table from '~/components/Table';
import { Tabs } from 'antd';
import LocationTypes from './LocationTypes';
import UserGroups from './UserGroups';
import UserPermissions from './UserPermissions';

export default function LocationsPermissions() {
  // const [form] = Form.useForm();

  // const profile = useSelector(state => state.user.profile);

  // const columns = [
  //   { name: 'name1', style: { width: '50%' }, render: value => <Link to={`/locationPermission/${value}`}>{value}</Link> },
  //   { name: 'name2', render: (value, row) => <Link to={`/locationPermission/${row.date}`}>{value}</Link> },
  //   { name: 'name3' },
  //   { name: 'date' },
  // ];

  // const data = [
  //   { name1: 'abc', name2: 'def', name3: 'ghi', date: new Date().toDateString() },
  //   { name1: 'abc1', name2: 'def', name3: 'ghi', date: new Date().toDateString() },
  //   { name1: 'abc2', name2: 'def', name3: 'ghi', date: new Date().toDateString() },
  //   { name1: 'abc3', name2: 'def', name3: 'ghi', date: new Date().toDateString() },
  //   { name1: 'abc4', name2: 'def', name3: 'asdas', date: new Date().toDateString() },
  // ];

  const tabs = [
    {
      label: 'User Permissions',
      key: 1,
      children: <UserPermissions />,
    },
    {
      label: 'User Groups',
      key: 2,
      children: <UserGroups />,
    },
    {
      label: 'Location Types',
      key: 3,
      children: <LocationTypes />,
    },
  ];

  return (
    <Tabs defaultActiveKey={1} type="card" size="small" items={tabs} destroyInactiveTabPane />
    // <Container>
    //   {/* <ActionsBar>
    //     <Link to="/newPermission">
    //       <Button>Create new group</Button>
    //     </Link>
    //   </ActionsBar> */}

    //   {/* <Table columns={columns} data={data} rowKey="name1" style={{ width: '50%' }} /> */}
    // </Container>
  );
}
