import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;

  width: 100%;
  height: 100vh;
  padding: 75px 20px 0 250px;

  strong {
    display: block;
    margin-top: 1rem;
    font-size: 2rem;
  }
  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }

  input {
    min-width: 500px;
  }
`;

export const Card = styled.div`
  background-color: #fff;

  height: 100%;
  /* margin: 0px 20px; */
  width: 100%;
  padding: 0px 0px 10px 10px;
`;
