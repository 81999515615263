/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import api from '~/services/api';

import { Container } from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  rows: {
    '&:hover': {
      background: '#ddd',
      cursor: 'pointer',
    },
  },

  button: {
    margin: theme.spacing(1),
  },

  textField: {
    backgroundColor: '#fff',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  actions: {
    margin: theme.spacing(0.8, 0),
  },
  search: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function InventoryPartsLog({ location }) {
  const classes = useStyles();

  const [logList, setLogList] = useState([]);

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const sku = params.get('sku');

    let query = 'routine=InventoryParts/BulkImport';

    if (sku) {
      query += `&sku=${sku}`;
    }

    async function loadReturns() {
      const returnsList = await api.get(`inventoryLog?${query}`);
      setLogList(returnsList.data);
    }
    loadReturns();
  }, [location]); //eslint-disable-line

  return (
    <Container>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Log ID</StyledTableCell>
                      <StyledTableCell>Part Number</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                      <StyledTableCell>Previous Data</StyledTableCell>
                      <StyledTableCell>New Data</StyledTableCell>
                      <StyledTableCell>User</StyledTableCell>
                      <StyledTableCell>Logged at</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logList.map(row => (
                      <TableRow key={row.id} className={classes.rows}>
                        <TableCell component="th" scope="row">
                          {row.log_id}
                        </TableCell>
                        <TableCell>{row.item.part_number}</TableCell>
                        <TableCell>{row.item.description}</TableCell>
                        <TableCell>{row.action}</TableCell>
                        <TableCell>{row.previous_data}</TableCell>
                        <TableCell>{row.new_data}</TableCell>
                        <TableCell>{row.user.name}</TableCell>
                        <TableCell>{moment(row.createdAt).format('MMMM DD, YYYY')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
