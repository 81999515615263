/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import { CloseSquareOutlined } from '@ant-design/icons';
import { Button, Descriptions, Form, Popover, Radio, Select, Space } from 'antd';

import api from '~/services/api';

export default function Software({ itemResults, form, instructions, cleanFieldsNotPassed, itemDisabled }) {
  const [checkOperatingSystem, setCheckOperatingSystem] = useState(false);
  const [checkWindowsActivation, setCheckWindowsActivation] = useState(false);

  const [operatingSystemOptions, setOperatingSystemOptions] = useState([]);

  useEffect(() => {
    setCheckOperatingSystem(itemResults.resultOperatingSystem === 'passed');
    setCheckWindowsActivation(itemResults.resultWindowsActivation === 'passed');
  }, [itemResults.resultCoaReport, itemResults.resultOperatingSystem, itemResults.resultWindowsActivation]);

  useEffect(() => {
    async function loadDropdownboxes() {
      const operatingSystemData = await api.get('operating_systems');

      setOperatingSystemOptions(
        operatingSystemData.data
          .filter(list => list.active === true)
          .map(list => ({ label: list.name, value: list.name }))
      );
    }
    loadDropdownboxes();
  }, []);


  return (
    <Descriptions
      size="small"
      bordered
    >
      <Descriptions.Item label="Operating System" span={2}>
        <Space>
          <Form.Item hasFeedback name="operatingSystem" rules={[{ required: checkOperatingSystem }]}>
            <Select
              showSearch
              allowClear
              disabled={!checkOperatingSystem || itemDisabled}
              style={{ width: 200 }}
              placeholder="Operating System"
              optionFilterProp="label"
              options={operatingSystemOptions}
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'software_operating_system')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultOperatingSystem" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            name="operatingSystemTest"
            onChange={e =>
              e.target.value === 'passed' ? setCheckOperatingSystem(true) : (setCheckOperatingSystem(false), cleanFieldsNotPassed(['operatingSystem']))
            }
            buttonStyle="solid"
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Windows Activation" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="windowsActivated" rules={[{ required: checkWindowsActivation }]}>
            <Radio.Group buttonStyle="solid" disabled={!checkWindowsActivation || itemDisabled}>
              <Radio.Button value="MAR_win10">MAR WIN10</Radio.Button>
              <Radio.Button value="upgrade">Upgrade</Radio.Button>
              <Radio.Button value="other">Other</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ windowsActivated: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'software_windows_activation')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultWindowsActivation" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="windowsActivationTest"
            onChange={e =>
              e.target.value === 'passed' ? setCheckWindowsActivation(true) : (setCheckWindowsActivation(false), cleanFieldsNotPassed(['windowsActivated']))
            }
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Drivers installed/updated" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'software_drivers_installed')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultDriversInstalled" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" name="driversInstalledTest" rules={[{ required: true }]} disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Partition extended" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'software_storage_extended')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultStorageExtended" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" name="storageExtentedTest" rules={[{ required: true }]} disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Recovery Media" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'software_recovery_media')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultRecoveryMedia" rules={[{ required: true, message: 'Recovery Media result must be selected' }]}>
          <Radio.Group buttonStyle="solid" name="recoveryMediaTest" rules={[{ required: true }]} disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="For CF-C2 (MK1/MK2) only: Bios update latest version" span={2}>
        <Space />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find(el => el.field === 'software_cfc2_bios_update')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultCfc2BiosUpdate" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" name="Cfc2BiosUpdateTest" rules={[{ required: true }]} disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>
    </Descriptions>
  );
}
