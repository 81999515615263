import React, { useCallback } from 'react';

export function useDebouncedCallback(callback, delay) {
  const timeoutRef = React.useRef();

  return useCallback(
    (...args) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
}
