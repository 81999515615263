import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;

  width: 100%;
  padding: 75px 20px 0 250px;
  height: 100vh;

  strong {
    display: block;
    margin-top: 1rem;
    font-size: 2rem;
  }

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;

    /* strong {
      display: block;
      margin-top: 1rem;
      font-size: 2rem;
    } */
  }
`;

export const StyledButton = styled.button`
  /* height: 50px; */
  border: 0;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  padding: 0 1.25rem;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: #137dd6;
    transition: background-color 0.2s;
  }

  :disabled {
    background-color: ${(props) => props.theme['gray-300']};

    &:hover {
      background-color: ${(props) => props.theme['gray-400']};
      transition: background-color 0.2s;
    }
  }
`;

export const NewInventoryButton = styled.button`
  height: 50px;
  border: 0;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  padding: 0 1.25rem;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: #1378d6;
    transition: background-color 0.2s;
  }
`;

export const TransactionContainer = styled.main`
  width: 100%;
  min-width: 1120px;
  overflow-x: scroll;
  /* margin: 4rem auto 0;
  padding: 0 1.5rem; */
`;

export const TransactionTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0 2px;
  width: 100%;
  border-radius: 10px;
  /* margin-top: 1.2rem; */
  background: #ededed;
  padding: 0.7rem;

  thead {
    background: #e0e0e0;
    border-bottom: 1px solid #fff;
    font-weight: bold;

    td:first-child {
      border-top-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  tr,
  td {
    padding: 5px 10px;
  }

  tr:nth-child(even) {
    background: ${(props) => props.theme['gray-100']};
  }

  td {
    text-align: center;
    min-width: 150px;
  }

  td:first-child {
    text-align: left;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 6; /* Increase z-index to be higher than the header */
    background-color: #e0e0e0;
    border-right: 1px solid #ddd;
  }

  tr:hover {
    background: #e0e0e0;
  }
`;
