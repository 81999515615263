/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Button, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { Container } from './styles';

import api from '~/services/api';
import history from '~/services/history';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function InventoryExport({ match }) {
  const classes = useStyles();

  // const [currentInstruction, setCurrentInstruction] = useState('');
  const [newInstruction, setNewInstruction] = useState('');
  const [instructionName, setInstructionname] = useState('');

  useEffect(() => {
    async function loadInitialData() {
      const instructionData = await api.get(`instructions/${match.params.field}`);

      // setCurrentInstruction(instructionData.data.instructions);
      setNewInstruction(instructionData.data.instructions);
      setInstructionname(instructionData.data.name);
    }
    loadInitialData();
  }, []); //eslint-disable-line

  const handleSave = async () => {
    try {
      const instructionToUpdate = {
        name: instructionName,
        field: match.params.field,
        instructions: newInstruction,
      };

      await api.put('instructions', instructionToUpdate);
      toast.success('Instruction saved');
      history.push('/instructions');
    } catch (error) {
      toast.error('Error trying to save instruction');
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      ['link', 'image', 'video'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'script',
    'size',
    'color',
    'background',
    'font',
    'align',
    'image',
    'video',
  ];

  return (
    <Container>
      <Paper className={classes.paper}>
        <Space direction="vertical">
          <h1>Instructions for {instructionName}</h1>

          <Button onClick={handleSave} type="primary" icon={<SaveOutlined />} size="small">
            Save
          </Button>
          {newInstruction !== '' && <ReactQuill theme="snow" value={newInstruction} onChange={setNewInstruction} modules={modules} formats={formats} />}
        </Space>
      </Paper>
    </Container>
  );
}
