/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Alert, Button, Card, Checkbox, Dropdown, Input, Menu, Pagination, Row, Slider, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { Container } from './styles';
// import ReportFields from './utils/ReportFields';
// import { CounterFields } from './utils/counterFields';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CheckboxGroup = Checkbox.Group;

export default function BatteryListByPN(props) {
  const classes = useStyles();
  const searchTextRef = useRef('');

  const [inventoryItemHeader, setInventoryItemHeader] = useState({});
  const [inventoryItems, setInventoryItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [zeroQty, setZeroQty] = useState();

  const [filterStatus, setFilterStatus] = useState(['ToBeInspected', 'InStock', 'Failed']);
  const [visibleStatus, setVisibleStatus] = useState(false);
  // const [visibleHealthRange, setVisibleHealthRange] = useState(false);
  const [indeterminateStatusFilter, setIndeterminateStatusFilter] = useState(true);
  // const [quantityModalVisible, setQuantityModalVisible] = useState(false);

  const [loading, setLoading] = useState(true);

  const [filterHealthRange, setFilterHealthRange] = useState([0, 100]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [paginationTotalItems, setPaginationTotalItems] = useState();
  const [paginationSize, setPaginationSize] = useState(25);
  const [paginationPage, setPaginationPage] = useState(1);

  // const [csvReport, setCsvReport] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [sortOrder, setSortOrder] = useState(undefined);
  // const [sortField, setSortFiled] = useState('');

  // const [checkedListStatusFilter, setCheckedListStatusFilter] = useState([]);

  const { Title, Text, Paragraph } = Typography;
  const { Search } = Input;
  const width = 120;

  const handleSearch = useCallback(
    async (filterText) => {
      setLoading(true);
      try {
        const unitSerialNumber = filterText?.trim() || searchTextRef.current.input.value.trim() || '';
        const serialNumberFormatted =
          unitSerialNumber.substring(0, 4) === 'P/N:'
            ? unitSerialNumber.substring(unitSerialNumber.indexOf('S/N:') + 4)
            : unitSerialNumber.includes('S/N:')
            ? unitSerialNumber.substring(unitSerialNumber.indexOf('S/N:') + 4)
            : unitSerialNumber;

        searchTextRef.current.input.value = serialNumberFormatted;

        const itemInventoryData = await api.get(`inventory_batteries/getInventoryBatteryListByPN/${props.match.params.item_id}`, {
          headers: {
            serial_number: serialNumberFormatted || '',
            status: filterStatus.join() || '',
            size: paginationSize,
            page: paginationPage - 1,
            health: filterHealthRange.join() || '',
            order: sortOrder,
            // sortField,
          },
        });

        // const report = ReportFields(itemInventoryData.data.rows, otherPortsList.data);
        setInventoryItems(itemInventoryData.data.rows);
        setPaginationTotalItems(itemInventoryData.data.totalItems);
        setPaginationSize(itemInventoryData.data.sizePage);
        setPaginationPage(itemInventoryData.data.currentPage + 1);
      } catch (error) {
        toast.error(error.response.data.message);
      }
      setLoading(false);

      // setCsvReport(report);
    },
    [filterHealthRange, filterStatus, paginationPage, paginationSize, props.match.params.item_id, sortOrder]
  );

  useEffect(() => {
    async function loadDropdownboxes() {
      // eslint-disable-next-line react/prop-types
      const { search } = props.location;
      const params = new URLSearchParams(search);
      const itemsData = await api.get(`items/${props.match.params.item_id}`);
      setSearchQuery(params.get('searchQuery'));
      setZeroQty(params.get('showZero'));

      const headerData = {
        item_description: `${itemsData.data.part_number} - ${itemsData.data.description}`,
        item_sku: itemsData.data.sku,
        branch: 1,
      };
      setInventoryItemHeader(headerData);

      handleSearch();
    }
    loadDropdownboxes();
  }, []); //eslint-disable-line

  // function downloadExcel(data) {
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   XLSX.writeFile(workbook, `${inventoryItemHeader.item_sku}.xlsx`);
  // }

  const statusOptions = [
    { value: 'InUse', label: 'In Use' },
    { value: 'ToBeInspected', label: 'To Be Inspected' },
    { value: 'ToBeInspected-RMA', label: 'To Be Inspected-RMA' },
    { value: 'Downstream', label: 'Downstream' },
    { value: 'AwaitingShipment', label: 'Awaiting Shipment' },
    { value: 'Sold', label: 'Sold' },
    { value: 'Returning', label: 'Returning' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Returned', label: 'Returned' },
    { value: 'Allocated', label: 'Allocated' },
    { value: 'InStock', label: 'In Stock' },
  ];

  const onChangeStatusFilter = (list) => {
    setFilterStatus(list);
    setIndeterminateStatusFilter(!!list.length && list.length < statusOptions.length);
    setCheckAll(list.length === statusOptions.length);
  };

  const onCheckAllChangeStatusFilter = (e) => {
    setFilterStatus(e.target.checked ? statusOptions.map((location) => location.value) : []);
    setIndeterminateStatusFilter(false);
    setCheckAll(e.target.checked);
  };

  const menu = (
    <Menu mode="inline">
      <Menu.Item>
        <Row>
          <Checkbox indeterminate={indeterminateStatusFilter} onChange={onCheckAllChangeStatusFilter} checked={checkAll}>
            Check all
          </Checkbox>
        </Row>
        <CheckboxGroup onChange={onChangeStatusFilter} value={filterStatus}>
          <Row>
            <Checkbox value="InUse">In Use</Checkbox>
          </Row>
          <Row>
            <Checkbox value="ToBeInspected">To Be Inspected</Checkbox>
          </Row>
          <Row>
            <Checkbox value="ToBeInspected-RMA">To Be Inspected-RMA</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Downstream">Downstream</Checkbox>
          </Row>
          <Row>
            <Checkbox value="AwaitingShipment">Awaiting Shipment</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Sold">Sold</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Returning">Returning</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Failed">Failed</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Returned">Returned</Checkbox>
          </Row>
          <Row>
            <Checkbox value="Allocated">Allocated</Checkbox>
          </Row>
          <Row>
            <Checkbox value="InStock">In Stock</Checkbox>
          </Row>
        </CheckboxGroup>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: 150,
      render: (text, record) => (
        <a rel="noopener noreferrer" target="_blank" href={`/inventoryProcessing/${record.id}`}>
          {text}
        </a>
      ),
    },
    {
      title: 'Battery Health',
      dataIndex: 'battery',
      render: (text, row) => {
        if (row.condition === 'New') return '100%';
        if (text === null) return '';
        return `${text}%`;
      },
      sorter: (a, b) => {
        if (a.battery === b.battery) return 0;
        if (a.battery === null) return 1;
        if (b.battery === null) return -1;
        return a.battery.localeCompare(b.battery, undefined, { numeric: true, sensitivity: 'base' });
      },
      sortOrder,
      sortDirections: ['descend', 'ascend'],
      width,
    },
    {
      title: 'Battery File',
      dataIndex: ['battery_file', 'name'],
      render: (text, row) => {
        if (row.battery_file !== null) {
          return (
            <a rel="noopener noreferrer" target="_blank" href={row.battery_file.url}>
              {text}
            </a>
          );
        }
        return text;
      },
      width,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      width,
    },
    {
      title: 'Location',
      dataIndex: ['locations', 'name'],
      width,
      ellipsis: {
        showTitle: false,
      },
      render: (text, row) => (
        <Tooltip title={`${row?.locations?.description} ${row?.usersLocation?.name || ''}`} color="blue">
          {text} {row?.usersLocation?.name || ''}
        </Tooltip>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'description',
      width: 600,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip title={text} color="blue">
          {text}
        </Tooltip>
      ),
      //  <span style={{ textOverflow: 'ellipsis' }}>{text}</span>,
    },
    {
      title: 'Last Updated by',
      dataIndex: 'processed_by',
      width: 150,
    },
  ];

  const handleVisibleChangeStatus = (flag) => {
    setVisibleStatus(() => flag);
    setIsFiltered(() => true);
  };

  const handleClearFilters = useCallback(() => {
    setIsFiltered(() => false);
    setFilterStatus(() => ['ToBeInspected', 'InStock', 'Failed']);
    setFilterHealthRange(() => [0, 100]);
    setSortOrder(() => undefined);
  }, []);

  function handleChangePage(page, pageSize) {
    setPaginationPage(() => page);
    setPaginationSize(() => pageSize);
  }

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  function handleSort(_pagination, _filter, sorter) {
    setIsFiltered(() => true);
    setSortOrder(() => sorter.order);
    // setSortFiled(() => sorter.field);
  }

  function handleChangeHealthReange(values) {
    setIsFiltered(() => true);
    setFilterHealthRange(() => values);
  }

  const healthMarks = {
    0: '0%',
    100: '100%',
  };

  return (
    <Container>
      <div id="item_description">
        <Space size={1}>
          <Link
            to={() => {
              if (searchQuery === null) {
                return `/inventoryBatteries`;
              }
              return `/inventoryBatteries?searchQuery=${searchQuery}&showZero=${zeroQty}`;
            }}
          >
            <Button variant="contained" type="primary" className={classes.button} icon={<ArrowLeftOutlined />}>
              Back
            </Button>
          </Link>
        </Space>
        <Title level={4}>
          <Tooltip title={inventoryItemHeader.item_description} color="blue">
            <Paragraph
              copyable={{
                text: inventoryItemHeader.item_description,
              }}
            >
              {inventoryItemHeader.item_description && `${inventoryItemHeader.item_description.substring(0, 120)}...`}
            </Paragraph>
          </Tooltip>
        </Title>
        <div />
      </div>
      <Card>
        <div id="item_search">
          <Space>
            <Text>Search Serial Number: </Text>
            <Search placeholder="Search" ref={searchTextRef} allowClear onSearch={handleSearch} style={{ width: 300 }} enterButton />
            <Dropdown overlay={menu} trigger={['click']} onOpenChange={(e) => handleVisibleChangeStatus(e)} open={visibleStatus}>
              <Button variant="outlined" href="#" className="ant-dropdown-link" style={{ width: 140 }} onClick={() => setVisibleStatus(true)}>
                Status
              </Button>
            </Dropdown>
            <Text>Battery Health: </Text>
            <div id="slider">
              <Slider range defaultValue={filterHealthRange} min={0} max={100} step={10} marks={healthMarks} onAfterChange={handleChangeHealthReange} />
            </div>
            {isFiltered && (
              <Tag
                closable
                color="error"
                onClose={(e) => {
                  e.preventDefault();
                  handleClearFilters();
                }}
              >
                Clear Filters
              </Tag>
            )}
          </Space>
          {/* <Button variant="outlined" type="primary" className="ant-dropdown-link" style={{ width: 200 }} onClick={() => downloadExcel(csvReport)}>
              Download page to Excel
            </Button> */}
        </div>
      </Card>
      {loading ? (
        <Spin tip="Loading...">
          <Alert message="Loading inventory data" description="Once all items are loaded, you will be able to read items." type="info" />
        </Spin>
      ) : (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={inventoryItems}
            bordered
            pagination={false}
            scroll={{ x: 1500, y: 'calc(100vh - 25em)' }}
            rowKey={(record) => record.serial_number}
            size="small"
            onChange={handleSort}
          />
          <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}
