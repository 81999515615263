/* eslint-disable react/prop-types */
import React from 'react';

import { Table } from 'antd';

import { Container } from './styles';

const columns = [
  {
    title: 'Shipped',
    dataIndex: 'shipDate',
    render: (text, record) => <div style={{ textDecoration: record.voided ? 'line-through' : 'none', fontSize: '11px' }}>{text}</div>,
  },
  {
    title: 'Tracking',
    dataIndex: 'trackingNumber',
    render: (text, record) => (
      <>
        <div style={{ textDecoration: record.voided ? 'line-through' : 'none', fontSize: '11px' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wwwapps.ups.com/WebTracking/processRequest?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=${text}/trackdetails`}
          >
            {text}
          </a>
        </div>
        <div style={{ textDecoration: record.voided ? 'line-through' : 'none', fontSize: '11px' }}>{record.serviceCode}</div>
      </>
    ),
  },
];

export default function Shipments({ shipments }) {
  return (
    <Container>
      <Table columns={columns} dataSource={shipments} size="small" pagination={false} rowKey="shipmentId" />
    </Container>
  );
}
