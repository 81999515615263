import styled from 'styled-components';

export const InputContainer = styled.div`
  display: inline-block;
  /* align-items: center;
  justify-content: space-between; */

  position: relative;
`;

export const PrimaryInput = styled.input`
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 2px;
  transition: all 0.2s;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;

  &:hover {
    border-color: #4096ff;
    border-inline-end-width: 1px;
  }

  /* label {
    text-align: right;
    width: 130px;
  }

  label:after {
    content: ':';
  } */
`;

export const IconAfter = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  /* height: 1.5rem;
  width: 1.5rem;
  background-color: red;
  padding: 4px;
  box-sizing: border-box;
  left: 2px;
  */

  cursor: ${(props) => (props.cursor ? props.cursor : 'default')};
`;

export const IconBefore = styled.div`
  /* height: 1.5rem;
  width: 1.5rem;
  background-color: red;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 2px;
  transform: translateY(-50%); */
`;
