/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';

import { ArrowLeftOutlined, SwapOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Empty, Form, Select, Space, Spin, Table, Typography } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';

import useDispatchPromise from '~/hooks/useDispatchPromise';
import api from '~/services/api';
import history from '~/services/history';
import { getCurrentCountRequest } from '~/store/modules/itemsUnderMyName/actions';
import { Container } from './styles';

export default function LocationList({ location }) {
  const [unitList, setUnitList] = useState([]);
  const [filteredUnitList, setFilteredUnitList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [locationName, setLocationName] = useState('');
  // const [userSelected, setUserSelected] = useState(null);

  const [form] = Form.useForm();
  const dispatchPromise = useDispatchPromise();

  const { Title } = Typography;
  const location_id = new URLSearchParams(location.search).get('location');
  const userId = new URLSearchParams(location.search).get('userId');

  const onFinish = useCallback(
    values => {
      const newFilterList = unitList
        .filter(user => user.user_id === values.user)
        .sort((a, b) => new Date(b.previous_location_date) - new Date(a.previous_location_date));
      setFilteredUnitList(newFilterList);
      setIsFiltered(true);
    },
    [unitList]
  );

  useEffect(() => {
    async function getUnitList() {
      const unitListData = await api.get(`/locations/getLocationUnitList/${location_id}`);

      const activeUsers = [];
      unitListData.data.userList.forEach(user => {
        activeUsers.push({
          label: user.name,
          value: user.id,
        });
      });

      const unitListFiltered = unitListData.data.inventoryLocationLog.sort((a, b) => a.previous_location_date > b.previous_location_date);
      setFilteredUnitList(unitListFiltered);
      setUnitList(unitListFiltered);
      setUserList(activeUsers);
      setLocationName(unitListData.data.locationName.description);
      setLoading(false);
      await dispatchPromise(getCurrentCountRequest());
    }
    getUnitList();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (userId) {
      onFinish({ user: Number(userId) });
      // setUserSelected(userList.find(el => el.value === Number(userId)));
      form.setFieldsValue({ user: Number(userId) });
    }
  }, [form, onFinish, userId, userList]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (_text, _row, index) => index + 1,
      width: 60,
    },
    {
      title: 'Part Number',
      dataIndex: 'item.part_number',
      width: 100,
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: 100,
    },
    {
      title: 'Date Location Changed',
      dataIndex: 'previous_location_date',
      // sorter: (a, b) => new Date(a.previous_location_date) - new Date(b.previous_location_date),
      render: text => (text === '' ? 'No previous location' : moment(text).format('MMM DD YYYY, h:mm a')),
      width: 100,
    },
    {
      title: 'Previous Location',
      dataIndex: 'previous_location',
      render: text => (text === '' ? 'No previous location' : text),
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: 60,
    },
    {
      title: 'Description',
      dataIndex: 'item.description',
    },
    {
      title: 'Location',
      dataIndex: 'locations.name',
      render: (text, row) => {
        if (text === 'Tech') {
          return `${text} ${row['usersLocation.name']}`;
        }
        return text;
      },
      width: 100,
    },
  ];

  const handleClearFilter = () => {
    setFilteredUnitList(unitList);
    form.setFieldsValue({ user: null });
    setIsFiltered(false);
  };

  function downloadExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${locationName}_unit_list.xlsx`);
  }

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Link to="/location">
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            <Button onClick={() => history.push('/changeLocation')} variant="contained" type="primary" icon={<SwapOutlined />}>
              Change Location
            </Button>
          </Space>
          <Title level={3}>Unit list for {locationName} </Title>
          <div />
        </div>
      </Card>
      {location_id === '219' ? ( // location_id 219 = Tech
        <Card elevation={3} size="small">
          <Form form={form} name="nest-messages" onFinish={onFinish}>
            <Space>
              <Form.Item name="user" label="Filter by User">
                <Select
                  // value={userSelected}
                  // onChange={el => setUserSelected(el)}
                  placeholder="Users"
                  options={userList}
                  optionFilterProp="label"
                  showSearch
                  style={{ width: 300 }}
                />
              </Form.Item>
              <Form.Item>
                <Button variant="contained" type="primary" htmlType="submit">
                  Filter
                </Button>
              </Form.Item>
              <Form.Item>
                <Button variant="contained" type="primary" disabled={!isFiltered} onClick={() => handleClearFilter()}>
                  Clear Filter
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
      ) : (
        <></>
      )}
      <Card elevation={3} size="small">
        <Space wrap>
          <Button variant="outlined" type="primary" className="ant-dropdown-link" style={{ width: 200 }} onClick={() => downloadExcel(unitList)}>
            Download page to Excel
          </Button>
          {/* <CSVLink filename={`${location}_unit_list.csv`} data={unitList} className="btn btn-primary">
            Download to CSV
          </CSVLink> */}
        </Space>
      </Card>

      {loading ? (
        <Spin tip="Loading...">
          <Alert
            message="Search is looking for the items. Hold on a second"
            description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
            type="info"
          />
        </Spin>
      ) : filteredUnitList.length <= 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Use the search box above to list location unit list</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      ) : (
        <Table
          style={{ marginTop: '5px' }}
          columns={columns}
          dataSource={filteredUnitList}
          size="small"
          bordered
          pagination={false}
          scroll={{ y: 'calc(100vh - 25em)' }}
          rowKey={record => record.id}
        />
      )}
    </Container>
  );
}
