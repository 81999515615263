import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px 50px;
  }
`;

export const GridContainer = styled.div`
  /* border: solid 1px ${props => props.theme['gray-300']}; */
  display: inline-block;
`;

export const GridBox = styled.div`
  border: solid 1px ${props => props.theme['gray-300']};
  display: inline-block;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem;
`;

export const GridTitle = styled.div`
  border-bottom: solid 1px ${props => props.theme['gray-300']};
  color: ${props => props.theme['gray-800']};
  padding: 10px 15px;
`;

export const GridItems = styled.div`
  /* border-bottom: solid 1px ${props => props.theme['gray-300']}; */
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  max-width: 1080px;
  height: auto;

  div {
    background-color: ${props => props.theme['blue-300']};
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 8px;
    margin-top: 1rem;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: ${props => props.theme.white};
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  hr {
    border: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 10px 0 20px;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #3b9eff;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
  }
`;
