/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core/styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Alert, Button, Card, Divider, Form, Input, Popconfirm, Select, Space, Spin, Table, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';

const { Title, Text } = Typography;
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function InternalOrderEdit({ location }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const classes = useStyles();

  const profile = useSelector((state) => state.user.profile);

  const [loading, setLoading] = useState(true);

  const [orderInfoData, setOrderInfoData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [orderChecklistItems, setOrderChecklistItems] = useState([]);
  const [orderItemsOptions, setOrderItemsOptions] = useState([]);
  const [technician, setTechnician] = useState({});

  const [orderStatus, setOrderStatus] = useState('');
  const [internalOrderId, setInternalOrderId] = useState('');

  const [iORequestedBy, setIORequestedBy] = useState('');
  const [iONotes, setIONotes] = useState('');

  const serialNumberRef = useRef('');
  const notesRef = useRef('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const internalOrderId = params.get('internal_order_id');
    setInternalOrderId(internalOrderId);

    async function loadDropdownboxes() {
      const internalOrderInfo = await api.get(`internal_orders/${internalOrderId}`);
      const orderChecklistItemsList = await api.get(`internal_order_checklist/${internalOrderId}`);

      setOrderStatus(internalOrderInfo.data.status);
      setOrderInfoData(internalOrderInfo.data);

      setOrderItems(internalOrderInfo.data.internal_order_items);
      setOrderItemsOptions(internalOrderInfo.data.internal_order_items.map((item) => ({ label: item.title, value: item.id })));

      setIORequestedBy(internalOrderInfo.data.requested_by.name);
      setIONotes(internalOrderInfo.data.notes);

      const techniciansData = await api.get('technicians?list=true');

      const newData = orderChecklistItemsList.data.map(async (ioItem) => ({
        index: ioItem.id,
        notes: ioItem.notes,
        partNumber: ioItem.inventory_item.item.part_number,
        item: ioItem.inventory_item.item.name,
        itemId: ioItem.inventory_item.item.id,
        serialNumber: ioItem.inventory_item.serial_number,
        inventoryItemId: ioItem.inventory_items_id,
        technician: techniciansData.data.filter((tech) => tech.id === ioItem.technician_id)[0].name,
        isProcessed: ioItem.inventory_item.is_processed,
      }));

      const results = await Promise.all(newData);
      setOrderChecklistItems(results);

      setLoading(false);

      setTechnician(techniciansData.data.filter((tech) => tech.user_id === profile.id)[0]);
    }
    loadDropdownboxes();
  }, []); //eslint-disable-line

  const handleRemoveChecklistItem = async (rowInfo) => {
    try {
      const { index, serialNumber } = rowInfo;

      const getAssetInfo = await api.post('assetDiagnostic/showBySerialNumber', { serialNumber });

      await api.put('inventory_items', {
        items: [
          {
            serial_number: serialNumber,
            asset_diagnostic_id: getAssetInfo.data.id,
            action: 'removedFromInternalOrder',
            orderNumber: orderInfoData.internal_order_number,
          },
        ],
      });

      await api.delete(`internal_order_checklist/${index}`);
      setOrderChecklistItems(orderChecklistItems.filter((el) => el.index !== index));
      toast.success('Item removed successfully and is back in stock');
    } catch (error) {
      toast.error('Could not remove this item. Contact RBMS admin');
    }
  };

  const onFinish = useCallback(
    async (values) => {
      if (technician === undefined) {
        throw new Error('Your user is not registered as Technician. Contact RBMS admin');
      }

      const { checklistNotes, checklistOrderItem, checklistSerialNumber } = values;

      const serialNumberFormatted =
        checklistSerialNumber.substring(0, 4) === 'P/N:'
          ? checklistSerialNumber
              .substring(checklistSerialNumber.indexOf('S/N:') + 4)
              .trim()
              .toUpperCase()
          : checklistSerialNumber.trim().toUpperCase();

      try {
        /**
         * Adding item using SERIAL number
         */
        if (serialNumberFormatted !== undefined && serialNumberFormatted !== '') {
          const getItemInfo = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted });

          if (getItemInfo.data === null || getItemInfo.data?.length === 0) {
            serialNumberRef.current.focus();
            throw new Error('Serial number not found');
          }

          if (getItemInfo.data.status === 'Downstream') {
            serialNumberRef.current.focus();
            throw new Error('This serial number status is Downstream and cannot be added to an order');
          }

          if (getItemInfo.data.status === 'Sold') {
            serialNumberRef.current.focus();
            throw new Error('This serial number is Sold');
          }

          if (getItemInfo.data.status === 'AwaitingShipment') {
            serialNumberRef.current.focus();
            throw new Error('This serial number is assigned to another order');
          }

          await api.put('inventory_items', {
            items: [
              {
                serial_number: serialNumberFormatted,
                action: 'addedToInternalOrder',
                orderNumber: orderInfoData.internal_order_number,
              },
            ],
          });

          const newOrderChecklistItem = await api.post('internal_order_checklist', {
            internal_order_items_id: checklistOrderItem,
            technician_id: technician.id,
            inventory_items_id: getItemInfo.data.id,
            internal_order_id: internalOrderId,
            notes: checklistNotes,
          });

          const newChecklist = {
            index: newOrderChecklistItem.data.id,
            isProcessed: getItemInfo.data.is_processed,
            serialNumber: serialNumberFormatted,
            technician: technician.name,
            partNumber: getItemInfo.data.item.part_number,
            item: getItemInfo.data.item.name,
            itemId: getItemInfo.data.item.id,
            notes: checklistNotes,
          };

          if (orderChecklistItems.length === 0) {
            await api.put(`internal_orders/${internalOrderId}`, { status: 'in_progress' });
          }

          setOrderChecklistItems([...orderChecklistItems, newChecklist]);
          form.setFieldsValue({ checklistSerialNumber: '' });
          serialNumberRef.current.focus();
        }
      } catch (error) {
        toast.error(error.message);
      }
      form.setFieldsValue({ checklistNotes: '' });
      return '';
    },
    [form, internalOrderId, orderChecklistItems, orderInfoData.internal_order_number, technician]
  );
  const handleComplete = async () => {
    if (orderChecklistItems.length > 0) {
      const result = await api.put(`internal_orders/${internalOrderId}`, { status: 'complete' });

      if (result.data.error) {
        toast.error(`${result.data.error}`);
        return;
      }

      toast.success('Item registered successfully');
      history.push('/internalOrders');
    } else {
      toast.error('No serial number was added.');
    }
  };

  const columnsChecklist = [
    {
      title: '#',
      dataIndex: 'index',
      render: (_text, _row, index) => index + 1,
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
    },
    {
      title: 'Item',
      dataIndex: 'item',
    },
    {
      title: 'Technician',
      dataIndex: 'technician',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_text, record) => {
        if (orderStatus !== 'complete' || profile?.type === 999) {
          return (
            <Popconfirm
              onConfirm={() => handleRemoveChecklistItem(record)}
              title="Are you sure you want to delete?"
              trigger="click"
              placement="left"
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className="button-error pure-button">
                Remove
              </button>
            </Popconfirm>
          );
        }
        return (
          <button disabled type="button" className="button-error pure-button">
            Remove
          </button>
        );
      },
    },
    {
      title: 'Inspection',
      dataIndex: 'inspection',
      render: (_text, row) =>
        row.serialNumber === null ? (
          <Button type="link" href={row.workInstructionUrl} target="_blank">
            {row.workInstructionName}
          </Button>
        ) : (
          <Link to={`inventoryProcessing/${row.inventoryItemId}?orderId=${orderInfoData.id}&orderType=internal`}>Test</Link>
        ),
    },
    {
      title: 'Inspected',
      dataIndex: 'isProcessed',
      render: (text, row) =>
        row.serialNumber === null ? (
          <ThumbUpIcon style={{ color: 'green' }} />
        ) : text ? (
          <ThumbUpIcon style={{ color: 'green' }} />
        ) : (
          <ThumbDownIcon style={{ color: 'red' }} />
        ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  const columnsItems = [
    {
      title: 'Item',
      dataIndex: 'title',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Item Notes',
      dataIndex: 'notes',
    },
  ];

  return (
    <Container>
      <div>
        <Space>
          <Link to={() => `/internalOrders`}>
            <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />} className={classes.button}>
              Back
            </Button>
          </Link>
          <Popconfirm onConfirm={handleComplete} title="Are you sure you want to complete?" trigger="click" placement="left" okText="Yes" cancelText="No">
            <Button variant="contained" type="primary" icon={<CheckOutlined />} className={classes.button} disabled={orderStatus === 'complete'}>
              Complete
            </Button>
          </Popconfirm>
        </Space>
      </div>
      <Title level={3}>Items for internal order</Title>
      <Form
        form={form}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 14,
        }}
        name="checklistForm"
        onFinish={onFinish}
        layout="horizontal"
        initialValues={{ size: 'small' }}
        size="small"
      >
        <Card title="Order Info" headStyle={{ backgroundColor: 'whitesmoke' }}>
          <Space size={100}>
            <Text>Requested by: {iORequestedBy}</Text>
            <Text>Notes: {iONotes}</Text>
          </Space>
        </Card>

        <Divider />

        <Table style={{ marginBottom: '20px' }} columns={columnsItems} dataSource={orderItems} size="small" bordered pagination={false} rowKey="id" />

        {loading ? (
          <Spin tip="Loading...">
            <Alert message="Loading order data" description="Once all items are loaded, you will be able to add/remove items." type="info" />
          </Spin>
        ) : (
          <>
            <Divider />
            <Form.Item
              label="Internal Order Item:"
              hasFeedback
              name="checklistOrderItem"
              rules={[{ required: true, message: 'Internal Order Item must be selected' }]}
            >
              <Select allowClear style={{ width: 600 }} placeholder="Select an item" optionFilterProp="children" options={orderItemsOptions} />
            </Form.Item>
            <Form.Item
              label="Serial Number:"
              hasFeedback
              name="checklistSerialNumber"
              validateTrigger="onSubmit"
              rules={[{ required: true, message: 'Serial Number cannot be blank' }]}
            >
              <Input allowClear autoComplete="off" placeholder="Serial Number" style={{ width: 200 }} ref={serialNumberRef} />
            </Form.Item>

            <Form.Item label="Notes:" hasFeedback validateTrigger="onSubmit" name="checklistNotes">
              <TextArea ref={notesRef} placeholder="Add any additional notes related to this unit" rows={4} style={{ width: 600 }} showCount />
            </Form.Item>

            {orderStatus !== 'complete' || profile?.type === 999 ? (
              <Button type="primary" className={classes.button} size="default" htmlType="submit">
                Add
              </Button>
            ) : (
              <Tooltip title="This internal order was completed and cannot be edited" color="orange">
                <span>
                  <Button disabled type="primary" className={classes.button} size="default">
                    Add
                  </Button>
                </span>
              </Tooltip>
            )}
            <Divider />

            <Table columns={columnsChecklist} dataSource={orderChecklistItems} size="small" bordered pagination={false} rowKey={(record) => record.index} />
          </>
        )}
      </Form>
    </Container>
  );
}
