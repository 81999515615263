/* eslint-disable react/prop-types */
import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

import { StyledTable } from './styles';

export default function Table({ columns, data, rowKey, ...rest }) {
  // const [form] = Form.useForm();

  // const profile = useSelector(state => state.user.profile);

  // <Link to="/locationList?location=">linklink</Link>

  return (
    <StyledTable {...rest}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th style={column.style} key={column.dataIndex}>
              {column.name.toUpperCase()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={row[rowKey]}>
            {columns.map((column) => (
              <td key={column.dataIndex}>{column.render?.(row[column.dataIndex], row, index) || row[column.dataIndex]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

// Table.propTypes = {
//   columns: PropTypes.arrayOf(PropTypes.element).isRequired,
//   data: PropTypes.arrayOf(PropTypes.element).isRequired,
//   rowKey: PropTypes.string.isRequired,
// };
