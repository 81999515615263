import { Alert } from 'antd';
import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  background-color: ${props => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    padding: 50px 10px;
  }
`;

export const OrderAlert = styled(Alert)`
  margin-bottom: 5px;
  margin-left: 140px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: gray;
  margin-left: 10px;
  overflow: hidden;
  background-color: #fff;

  thead {
    th {
      font-size: 12px;
      padding: 2px;
      border: 1px solid #ccc;
    }
  }

  tbody {
    border-color: inherit;
    text-align: center;

    td {
      font-size: 12px;
      border: 1px solid #ccc;
    }
  }
`;
