/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import React, { useState, useCallback } from 'react';

import { Input, Card, Button, Typography, Table, Spin, Alert, Empty } from 'antd';

import { toast } from 'react-toastify';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Container } from './styles';
import api from '~/services/api';
import history from '~/services/history';

export default function LocationLogs() {
  const [locationLog, setLocationLog] = useState([]);
  const [serialNumberLog, setSerialNumberLog] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTextState, setSearchTextState] = useState('');

  const { Search } = Input;
  const { Title } = Typography;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, row, index) => index + 1,
      width: 60,
    },
    {
      title: 'Previous Location',
      dataIndex: 'previous_data',
      width: 100,
    },
    {
      title: 'Current Location',
      dataIndex: 'new_data',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (text) => moment(text).format('MMM DD YYYY, h:mm a'),
      width: 100,
    },
  ];

  const handleSearch = useCallback(async (value) => {
    if (value !== '' && value !== null) {
      setLoading(true);

      const serialNumberTrimmed = value.trim();

      const serialNumberFormatted =
        serialNumberTrimmed.substring(0, 4) === 'P/N:' ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4) : serialNumberTrimmed;
      setSearchTextState(serialNumberFormatted);

      const response = await api.post('logs/getBySerialNumber', { serialNumber: serialNumberFormatted });

      if (response.data.length === 0 || response.data.error) {
        toast.warn('No Location Log found!');
        return;
      }

      setSerialNumberLog(serialNumberFormatted);
      setLocationLog(response.data);
      setLoading(false);
      return;
    }
    toast.error('Please enter a Serial Number');
  }, []);

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => history.push('/location')} variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
          <Title level={3}>Location Logs</Title>
          <div />
        </div>
      </Card>
      <Card elevation={3} title="Search Unit Logs">
        {/* <Space wrap direction="vertical"> */}
        <Search
          placeholder="Enter Unit Serial Number"
          value={searchTextState}
          allowClear
          onSearch={(value) => {
            handleSearch(value);
          }}
          onChange={(el) => setSearchTextState(el.target.value)}
          style={{ width: 300, marginBottom: 20 }}
          enterButton
        />
        {loading ? (
          <Spin tip="Loading...">
            <Alert
              message="Search is looking for the items. Hold on a second"
              description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
              type="info"
            />
          </Spin>
        ) : locationLog.length > 0 ? (
          <>
            <Title level={4}>{serialNumberLog} Location Logs</Title>
            <Table
              style={{ marginTop: '5px' }}
              columns={columns}
              dataSource={locationLog}
              size="small"
              bordered
              pagination={false}
              scroll={{ y: 'calc(100vh - 22em)' }}
              rowKey={(record) => record.id}
            />
          </>
        ) : (
          <Empty
            description={<span>No data</span>}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              minHeight: '60vh',
            }}
          />
        )}
        {/* </Space> */}
      </Card>
    </Container>
  );
}
