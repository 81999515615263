export default function AssignOtherPorts(ports_array, keyboardPortOptions) {
  if (!ports_array) return '';
  const OPArray = ports_array.split(',').map(Number);
  const otherPorts = [];

  OPArray.forEach(op => {
    otherPorts.push(keyboardPortOptions.find(kp => kp.id === op).name);
  });

  return otherPorts.join();
}
