import React, { useEffect } from 'react';

import { AmazonOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';

import quickBooksLogo from '~/assets/qb-icon.svg';
import history from '~/services/history';
import Amazon from './Amazon';
import GradingWeight from './GradingWeight';
import LocationsPermissions from './LocationsPermissions';
import QBO from './QBO';
import { Container } from './styles';

export default function Admin() {
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    if (![999, 9].includes(profile?.type)) {
      history.push('/');
    }
  }, [profile.type]);

  const tabs = [
    {
      label: (
        <span>
          <AmazonOutlined />
          Amazon US
        </span>
      ),
      key: 1,
      children: <Amazon />,
    },
    {
      label: (
        <span>
          <img src={quickBooksLogo} width="20" alt="QB Logo" />
          QuickBooks
        </span>
      ),
      key: 2,
      children: <QBO />,
    },
    {
      label: 'Grading Weight',
      key: 3,
      children: <GradingWeight />,
    },
    {
      label: 'Locations Permissions',
      key: 4,
      children: <LocationsPermissions />,
    },
  ];

  return (
    <Container>
      <Tabs defaultActiveKey={1} type="card" size="small" items={tabs} />
    </Container>
  );
}
