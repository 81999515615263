/* eslint-disable react/no-danger */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Divider, Empty, Modal, Space, Tag, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import errorAudioFile from '~/assets/audio/error.mp3';
import successAudioFile from '~/assets/audio/success.mp3';
import warningAudioFile from '~/assets/audio/warning.mp3';
import api from '~/services/api';
import history from '~/services/history';
import { Container, ContainerForTables, LevelContainer, SearchContainer, SearchSummary, StyledButton, TransactionContainer, TransactionTable } from './styles';

const { confirm } = Modal;
const { Title } = Typography;

const statusColor = {
  'Not Found': 'red',
  'Item not in RBMS': 'red',
  'Different Location': 'orange',
  'Marked not found previously': 'orange',
  'TBI for more than 30 days': 'orange',
  OK: 'green',
  'OK-AAPIE': 'green',
  'Not in RBMS': 'purple',
  'Incorrect Status': 'red',
};

const itemStatusColor = {
  Failed: <Tag color="error">Failed</Tag>,
  InStock: <Tag color="default">In Stock</Tag>,
  ToBeInspected: <Tag color="warning">To Be Inspected</Tag>,
  'ToBeInspected-RMA': <Tag color="warning">To Be Inspected-RMA</Tag>,
  AwaitingShipment: <Tag color="warning">Awaiting Shipment</Tag>,
  Sold: <Tag color="default">Sold</Tag>,
  Downstream: <Tag color="error">Downstream</Tag>,
  ForParts: <Tag color="error">For Parts</Tag>,
  NotFound: <Tag color="error">Not Found</Tag>,
};

export default function PhysicalInventoryLocationItems({ match, location }) {
  const [itemsList, setItemsList] = useState([]);
  const [locationInfo, setLocationInfo] = useState(null);
  const [searchSummary, setSearchSummary] = useState(null);
  const [locationStatus, setLocationStatus] = useState(null);
  const [counterWrongLocation, setCounterWrongLocation] = useState(0);
  const [assignedTechnician, setAssignedTechnician] = useState('');
  const [disableSerialNumberInput, setDisableSerialNumberInput] = useState(false);
  const [inventoryManagerId, setInventoryManagerId] = useState(null);
  const [scannedLevel, setScannedLevel] = useState({ toLocationId: null, toLocationName: '' });
  const [isParentLocation, setIsParentLocation] = useState(false);
  const [levelSummary, setLevelSummary] = useState([]);
  const [parentLocationLevels, setParentLocationLevels] = useState([]);
  const [timesScannedSummary, setTimesScannedSummary] = useState([]);

  const profile = useSelector((state) => state.user.profile);

  const params = new URLSearchParams(location.search);
  const returnUrl = params.get('return') === 'dashboard' ? '/physicalInventoryLocationsDashboard' : '/physicalInventoryLocations';

  const inputSearchRef = useRef('');

  useEffect(() => {
    async function loadItems() {
      const locationItems = await api.get(`/physical_inventory/locationItems/${match.params.physicalInventoryLocationId}`);
      setItemsList(locationItems.data.items.sort((a, b) => a.serial_number.localeCompare(b.serial_number)));
      setLocationInfo({ locationName: locationItems.data.name, locationDescription: locationItems.data.description, locationId: locationItems.data.id });
      setLocationStatus(locationItems.data.status === 'Complete');
      setAssignedTechnician(locationItems.data.user || '');
      setDisableSerialNumberInput(locationItems.data.user !== profile.name);
      setInventoryManagerId(locationItems.data.inventory_manager_id);

      const timesScannedData = locationItems.data.items
        .filter((item) => item.times_scanned > 1)
        .map((item) => ({ serial_number: item.serial_number, times_scanned: item.times_scanned }));

      setTimesScannedSummary(() => timesScannedData);
      if (locationItems.data.is_parent) {
        setIsParentLocation(() => locationItems.data.is_parent || false);
        setParentLocationLevels(() => locationItems.data.levels);
      } else {
        setScannedLevel({ toLocationId: locationItems.data.id, toLocationName: locationItems.data.name }); // Maybe it will not be necessary
      }
    }
    loadItems();
    inputSearchRef.current.focus();
  }, []); //eslint-disable-line

  useEffect(() => {
    const count = [...itemsList]
      .filter((el) => el.status !== 'Not Scanned')
      .reduce((acc, curr) => {
        const key = curr.status;

        acc[key] = acc[key] + 1 || 1;

        return acc;
      }, {});
    setSearchSummary(count);

    if (isParentLocation) {
      const levelsData = parentLocationLevels.map((level) => ({
        name: level.name,
        scanned: 0,
        rbms: 0,
      }));

      [...itemsList].forEach((item) => {
        const levelInfo = levelsData.find((level) => level.name === item['inventory_items.locations.name']);
        const scanned = item.status !== 'Not Scanned' ? 1 : 0;
        if (!levelInfo) {
          levelsData.unshift({ name: item['inventory_items.locations.name'], scanned, rbms: 1 });
        } else {
          levelInfo.scanned += scanned;
          levelInfo.rbms += 1;
        }
      });

      setLevelSummary(() => levelsData);
    }
  }, [isParentLocation, itemsList]); //eslint-disable-line

  const errorModal = ({ title, content }) => {
    Modal.error({ title, content });
  };

  const warningModal = ({ title, content }) => {
    Modal.warning({ title, content });
  };

  async function handleScanSerialNumber(serialNumberScanned) {
    try {
      document.querySelector('.highlighted')?.classList.remove('highlighted');

      if (!serialNumberScanned) {
        inputSearchRef.current.focus();
        toast.error('Serial number cannot be empty');
        return;
      }
      const serialNumberTrimmed = serialNumberScanned.trim().toUpperCase();
      const serialNumberFormatted =
        serialNumberTrimmed.substring(0, 4) === 'P/N:'
          ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4).toLocaleUpperCase()
          : serialNumberTrimmed.toLocaleUpperCase();

      const itemIsPartNumber = await api.post('physical_inventory/checkIsPartNumber', { serialNumber: serialNumberFormatted });

      if (itemIsPartNumber.data.isPartNumber) {
        new Audio(errorAudioFile).play();
        errorModal({
          title: 'Invalid Serial Number',
          content: (
            <div>
              The bar code scanned is a <span style={{ fontWeight: 'bold', fontSize: 'large' }}>PART NUMBER</span>. Please, scan again.
            </div>
          ),
        });
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
        return;
      }

      const itemFoundInInventory = await api.post(`/physical_inventory/checkSerialNumber/${match.params.physicalInventoryId}`, {
        serialNumber: serialNumberFormatted,
      });

      const itemNotFoundButScanned = itemFoundInInventory.data.status === 'Not in RBMS, already scanned';

      if (itemNotFoundButScanned) {
        new Audio(errorAudioFile).play();
        errorModal({
          title: 'Item not in RBMS',
          content: (
            <span>
              This serial number is not in RBMS, but it was already scanned at <strong>{itemFoundInInventory.data.location}</strong>. Put this item aside and
              inform inventory manager
            </span>
          ),
        });
        return;
      }

      const itemNotFound = itemFoundInInventory.data.status === 'Not Found';

      if (itemNotFound) {
        new Audio(errorAudioFile).play();
        confirm({
          title: (
            <span>
              Are you sure the right Serial Number was scanned?{' '}
              <span style={{ fontWeight: 'bold', fontSize: 'large', color: 'red' }}>&lsquo;{serialNumberScanned}&rsquo;</span>
            </span>
          ),
          icon: <ExclamationCircleFilled />,
          content: `This record doesn't match any of the Serial Numbers in the RBMS. Make sure you are scanning the right serial number on the unit. If the problem persist, put this unit aside, and bring it to the attention of the inventory manager.`,
          okText: `Yes, I'm sure`,
          okType: 'success',
          cancelText: 'No, I will scan again',
          cancelButtonProps: { type: 'danger' },
          autoFocusButton: 'cancel',
          async onOk() {
            try {
              const itemScanned = await api.post(
                `/physical_inventory/${match.params.physicalInventoryId}/addSerialNumberToLocation/${match.params.physicalInventoryLocationId}`,
                {
                  serialNumber: serialNumberFormatted,
                  toLocationId: scannedLevel.toLocationId,
                }
              );

              if (itemScanned.data.status === 'Item not in RBMS') {
                const itemNotInRbms = {
                  id: itemScanned.data.id,
                  serial_number: serialNumberFormatted,
                  status: itemScanned.data.status,
                  scan_date: itemScanned.data.scan_date,
                  notes: itemScanned.data.notes,
                  'inventory_items.locations.name': scannedLevel.toLocationName,
                };

                setItemsList((state) => [...state, itemNotInRbms]);

                const rowInTable = document.getElementById(serialNumberFormatted);
                if (rowInTable) {
                  rowInTable.classList.add('highlighted');
                  rowInTable.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                }

                inputSearchRef.current.value = '';
                inputSearchRef.current.focus();
                return;
              }

              const newItem = [...itemsList];
              newItem.find((item) => item.serial_number === serialNumberFormatted).status = itemScanned.data.status;
              newItem.find((item) => item.serial_number === serialNumberFormatted).scan_date = itemScanned.data.scan_date;
              newItem.find((item) => item.serial_number === serialNumberFormatted).notes = itemScanned.data.notes;
              newItem.find((item) => item.serial_number === serialNumberFormatted).location_changed = itemScanned.data.location_changed;
              newItem.find((item) => item.serial_number === serialNumberFormatted)['inventory_items.locations.name'] = scannedLevel.toLocationName;

              setItemsList(() => newItem);
              inputSearchRef.current.value = '';
              inputSearchRef.current.focus();
              setLocationStatus(false);
            } catch (error) {
              toast.error(`Something went wrong. ${error.message}`);
            }
          },
          onCancel() {
            Modal.destroyAll();
            inputSearchRef.current.value = '';
            inputSearchRef.current.focus();
          },
        });
        return;
      }
      const itemScanned = await api.post(
        `/physical_inventory/${match.params.physicalInventoryId}/addSerialNumberToLocation/${match.params.physicalInventoryLocationId}`,
        {
          serialNumber: serialNumberFormatted,
          toLocationId: scannedLevel.toLocationId,
        }
      );

      if (itemScanned.data.alreadyScanned) {
        setTimesScannedSummary((state) => {
          const itemInStateExists = state.find((item) => item.serial_number === serialNumberFormatted);
          if (itemInStateExists) {
            itemInStateExists.times_scanned = itemScanned.data.times_scanned;
            return [...state];
          }
          const newItemDoubleScanned = { serial_number: serialNumberFormatted, times_scanned: itemScanned.data.times_scanned };
          return [...state, newItemDoubleScanned];
        });
        new Audio(warningAudioFile).play();
        toast.warn('Item already scanned');
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
        return;
      }

      if (itemScanned.data.status === 'Marked not found previously') {
        new Audio(errorAudioFile).play();

        const message = `Item status was <span style="font-weight: bold; font-size: large">Not Found</span> and it was changed to TBI. Put this item aside and inform inventory manager`;

        errorModal({
          title: itemScanned.data.status,
          content: <div dangerouslySetInnerHTML={{ __html: message }} />,
        });

        const itemMarkedAsNotFoundPreviously = {
          id: itemScanned.data.id,
          serial_number: serialNumberFormatted,
          status: itemScanned.data.status,
          scan_date: itemScanned.data.scan_date,
          notes: itemScanned.data.notes,
          location_changed: itemScanned.data.location_changed,
          'inventory_items.locations.name': scannedLevel.toLocationName,
        };
        setItemsList([...itemsList, itemMarkedAsNotFoundPreviously]);

        const rowInTable = document.getElementById(serialNumberFormatted);
        if (rowInTable) {
          rowInTable.classList.add('highlighted');
          rowInTable.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }

        setLocationStatus(() => false);
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
        return;
      }

      if (itemScanned.data.status === 'Incorrect Status') {
        new Audio(errorAudioFile).play();

        const rowInTable = document.getElementById(serialNumberFormatted);
        if (rowInTable) {
          rowInTable.classList.add('highlighted');

          rowInTable.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }

        // const locationsForThisItem = await api.get(
        //   `/locations?physical_location=true&ids=${itemScanned.data.previous_location_id},${itemScanned.data.scanned_location_id}`
        // );

        const message = `Item status is <span style="font-weight: bold; font-size: large">${itemScanned.data.item_status_when_incorrect_status_scanned}</span>. Put this item aside and inform inventory manager`;
        // const message = `Item status is <span style="font-weight: bold; font-size: large">${
        //   itemScanned.data.item_status_when_incorrect_status_scanned
        // }</span>. Item location was ${
        //   locationsForThisItem.data.find(location => location.id === itemScanned.data.previous_location_id).name
        // } and it was changed to ${
        //   locationsForThisItem.data.find(location => location.id === itemScanned.data.scanned_location_id).name
        // }. Put this item aside and inform inventory manager`;

        errorModal({
          title: itemScanned.data.status,
          content: <div dangerouslySetInnerHTML={{ __html: message }} />,
        });

        const itemIsInThisList = [...itemsList].find((item) => item.serial_number === serialNumberFormatted);

        if (!itemIsInThisList) {
          const itemToAdd = {
            id: itemScanned.data.id,
            serial_number: serialNumberFormatted,
            status: itemScanned.data.status,
            scan_date: itemScanned.data.scan_date,
            notes: itemScanned.data.notes,
            location_changed: itemScanned.data.location_changed,
            'inventory_items.locations.name': scannedLevel.toLocationName,
          };
          setItemsList((state) => [...state, itemToAdd]);

          setLocationStatus(() => false);
          inputSearchRef.current.value = '';
          inputSearchRef.current.focus();
          return;
        }

        const newItem = [...itemsList];
        newItem.find((item) => item.serial_number === serialNumberFormatted).status = itemScanned.data.status;
        newItem.find((item) => item.serial_number === serialNumberFormatted).scan_date = itemScanned.data.scan_date;
        newItem.find((item) => item.serial_number === serialNumberFormatted).notes = itemScanned.data.notes;
        newItem.find((item) => item.serial_number === serialNumberFormatted)['inventory_items.locations.name'] = scannedLevel.toLocationName;

        setItemsList(newItem);

        setLocationStatus(() => false);
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
        return;
      }

      if (itemScanned.data.status === 'TBI for more than 30 days') {
        new Audio(errorAudioFile).play();

        const rowInTable = document.getElementById(serialNumberFormatted);
        if (rowInTable) {
          rowInTable.classList.add('highlighted');

          rowInTable.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }

        const message = 'This item is marked as <span style="font-weight: bold; font-size: large">TBI for more than 30 days</span>.';

        errorModal({
          title: itemScanned.data.status,
          content: <div dangerouslySetInnerHTML={{ __html: message }} />,
        });

        const itemIsInThisList = [...itemsList].find((item) => item.serial_number === serialNumberFormatted);

        if (!itemIsInThisList) {
          const itemToAdd = {
            id: itemScanned.data.id,
            serial_number: serialNumberFormatted,
            status: itemScanned.data.status,
            scan_date: itemScanned.data.scan_date,
            notes: itemScanned.data.notes,
            location_changed: itemScanned.data.location_changed,
            'inventory_items.locations.name': scannedLevel.toLocationName,
          };
          setItemsList((state) => [...state, itemToAdd]);

          setLocationStatus(() => false);
          inputSearchRef.current.value = '';
          inputSearchRef.current.focus();
          return;
        }

        const newItem = [...itemsList];
        newItem.find((item) => item.serial_number === serialNumberFormatted).status = itemScanned.data.status;
        newItem.find((item) => item.serial_number === serialNumberFormatted).scan_date = itemScanned.data.scan_date;
        newItem.find((item) => item.serial_number === serialNumberFormatted).notes = itemScanned.data.notes;
        newItem.find((item) => item.serial_number === serialNumberFormatted)['inventory_items.locations.name'] = scannedLevel.toLocationName;

        setItemsList(newItem);

        setLocationStatus(() => false);
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
        return;
      }

      if (['OK', 'OK-AAPIE'].includes(itemScanned.data.status)) {
        new Audio(successAudioFile).play();

        const itemIsInThisList = [...itemsList].find((item) => item.serial_number === serialNumberFormatted);

        if (!itemIsInThisList) {
          const itemToAdd = {
            id: itemScanned.data.id,
            serial_number: serialNumberFormatted,
            status: itemScanned.data.status,
            scan_date: itemScanned.data.scan_date,
            notes: itemScanned.data.notes,
            location_changed: itemScanned.data.location_changed,
            'inventory_items.locations.name': scannedLevel.toLocationName,
          };

          setItemsList((state) => [...state, itemToAdd]);

          const rowInTable = document.getElementById(serialNumberFormatted);
          if (rowInTable) {
            rowInTable.classList.add('highlighted');

            rowInTable.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          }
          if (itemScanned.data.location_changed !== null) {
            const countUnitInWrongLocation = counterWrongLocation + 1;

            if (countUnitInWrongLocation === 3) {
              new Audio(errorAudioFile).play();

              warningModal({
                title: 'Possible Wrong Location',
                content: `Are you scanning in the right location? Current location: ${locationInfo?.locationDescription}`,
              });
            }
            setCounterWrongLocation(counterWrongLocation + 1);
          }

          setLocationStatus(() => false);
          inputSearchRef.current.value = '';
          inputSearchRef.current.focus();
          return;
        }
        setCounterWrongLocation(0);

        const rowInTable = document.getElementById(serialNumberFormatted);
        if (rowInTable) {
          rowInTable.classList.add('highlighted');

          rowInTable.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }

        const newItem = [...itemsList];
        newItem.find((item) => item.serial_number === serialNumberFormatted).status = itemScanned.data.status;
        newItem.find((item) => item.serial_number === serialNumberFormatted).scan_date = itemScanned.data.scan_date;
        newItem.find((item) => item.serial_number === serialNumberFormatted).notes = itemScanned.data.notes;
        newItem.find((item) => item.serial_number === serialNumberFormatted).location_changed = itemScanned.data.location_changed;
        newItem.find((item) => item.serial_number === serialNumberFormatted)['inventory_items.locations.name'] = scannedLevel.toLocationName;

        setItemsList(newItem);

        setLocationStatus(() => false);
        inputSearchRef.current.value = '';
        inputSearchRef.current.focus();
      }
    } catch (error) {
      toast.error(`Something went wrong. ${error.response.data.error}`);
    }
  }

  const errorMessage = (title, content) => {
    new Audio(errorAudioFile).play();
    errorModal({
      title,
      content,
    });
    inputSearchRef.current.value = '';
    inputSearchRef.current.focus();
  };

  async function handleLocationCheck(inputLocation) {
    const locationName = inputLocation.substring(inputLocation.indexOf(':') + 1);

    const locationData = await api.get(`/locations/getLocationByName/${locationName}`);

    if (!locationData.data) {
      const title = 'Invalid location';
      const content = (
        <div>
          <span style={{ fontWeight: 'bold', fontSize: 'large' }}>INVALID LOCATION</span>. Please, scan a level QR code.
        </div>
      );
      errorMessage(title, content);
      return;
    }

    const IS_SCANNED_LOCATION_FROM_PARENT = locationInfo.locationId === locationData.data.location_parent_id;

    if (!IS_SCANNED_LOCATION_FROM_PARENT) {
      const title = 'Scan a location level';
      const content = (
        <div>
          The bar code scanned is <span style={{ fontWeight: 'bold', fontSize: 'large' }}>NOT A LEVEL FROM THIS LOCATION</span>. This location has multiple
          levels. Please, scan a level QR code from this location.
        </div>
      );
      errorMessage(title, content);
      return;
    }

    const toLocationId = locationData.data.id;
    const toLocationName = locationData.data.name;
    setScannedLevel({ toLocationId, toLocationName });
    new Audio(successAudioFile).play();
    inputSearchRef.current.value = '';
    inputSearchRef.current.focus();
  }

  function handleSearchType(element) {
    if (element.key !== 'Enter') {
      return;
    }

    const elementScanned = element.target.value;

    const readingType = elementScanned.substring(0, elementScanned.indexOf(':'));

    if (isParentLocation) {
      if (readingType === 'LCT') {
        handleLocationCheck(elementScanned);
        return;
      }

      if (scannedLevel.toLocationName === '') {
        const title = 'Scan a location level';
        const content = (
          <div>
            The bar code scanned is <span style={{ fontWeight: 'bold', fontSize: 'large' }}>NOT A LOCATION LEVEL</span>. This location has multiple levels.
            Please, scan a level QR code.
          </div>
        );
        errorMessage(title, content);
        return;
      }

      handleScanSerialNumber(elementScanned);
      return;
    }

    if (readingType === 'LCT') {
      const title = 'Invalid Input';
      const content = (
        <div>
          The bar code scanned is a <span style={{ fontWeight: 'bold', fontSize: 'large' }}>LOCATION</span>. Please, scan again.
        </div>
      );
      errorMessage(title, content);
      return;
    }
    handleScanSerialNumber(elementScanned);
  }

  async function handleDeleteSerialNumber(itemId) {
    await api.delete(`/physical_inventory/deleteSerialNumber/${itemId}`);
    setItemsList((state) => state.filter((el) => el.id !== itemId));
    inputSearchRef.current.focus();
  }

  const handleCompleteLocation = async () => {
    const hasNotScannedItems = await api.get(`/physical_inventory/checkItemsNotScannedAtLocation/${match.params.physicalInventoryLocationId}`);

    if (hasNotScannedItems.data.status === 'nok') {
      confirm({
        title: (
          <span>
            Some items were not scanned
            {/* <span style={{ fontWeight: 'bold', fontSize: 'large', color: 'red' }}>&lsquo;{serialNumberScanned}&rsquo;</span> */}
          </span>
        ),
        icon: <ExclamationCircleFilled />,
        content: `There are ${hasNotScannedItems.data.notScannedCount} items not scanned in this location. Do you want to complete this location anyway?`,
        okText: `Yes, I'm sure`,
        okType: 'success',
        cancelText: 'No, I will keep scanning',
        cancelButtonProps: { type: 'danger' },
        autoFocusButton: 'cancel',
        async onOk() {
          await api.patch(`/physical_inventory/finishScanningLocation/${match.params.physicalInventoryLocationId}`);
          toast.success('Location complete');
          history.push(`/physicalInventoryLocations/${match.params.physicalInventoryId}`);
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    } else {
      await api.patch(`/physical_inventory/finishScanningLocation/${match.params.physicalInventoryLocationId}`);
      toast.success('Location complete');
      history.push(`/physicalInventoryLocations/${match.params.physicalInventoryId}`);
    }
  };

  return (
    <Container>
      <SearchContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Link to={`${returnUrl}/${match.params.physicalInventoryId}`}>
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {inventoryManagerId === profile?.id || profile?.type === 999 ? (
              <Button type="primary" onClick={handleCompleteLocation} disabled={locationStatus}>
                Complete scanning this location
              </Button>
            ) : (
              ''
            )}
          </Space>
          <Title level={2}>{locationInfo?.locationDescription}</Title>
          <div />
        </div>

        <Divider />

        <SearchSummary>
          <input
            placeholder="Scan label"
            type="text"
            id="inputSearch"
            name="inputSearch"
            onKeyDown={handleSearchType}
            ref={inputSearchRef}
            style={{ marginRight: '20px' }}
            disabled={disableSerialNumberInput}
          />
          <Tag>Total: {itemsList.length}</Tag>
          <Tag color="blue">Scanned: {itemsList.filter((item) => item.status !== 'Not Scanned').length}</Tag>
          <Tag color="cyan">Not Scanned: {itemsList.length - itemsList.filter((item) => item.status !== 'Not Scanned').length}</Tag>
          {itemsList.filter((item) => item.status !== 'Not Scanned').length > 0 && ' By Status: \u00A0 '}
          {searchSummary && Object.keys(searchSummary).map((el) => <Tag key={el} color={statusColor[el]}>{`${el}: ${searchSummary[el]}`}</Tag>)}
        </SearchSummary>
      </SearchContainer>

      <Divider />

      {itemsList.length > 0 ? (
        <ContainerForTables>
          <div>
            {isParentLocation && (
              <Title level={2}>
                Level being scanned: <span style={{ color: 'red' }}>{scannedLevel.toLocationName}</span>
              </Title>
            )}

            <TransactionContainer>
              <TransactionTable>
                <thead>
                  <tr>
                    <td width="30%">Serial Number</td>
                    {isParentLocation && <td>Level</td>}
                    <td>Scanning Status</td>
                    <td>Scanned Date</td>
                    <td>Item Current Status on the System</td>
                    <td>Notes</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {itemsList.map((item) => (
                    <tr key={item.serial_number} id={item.serial_number}>
                      <td>{item.serial_number}</td>
                      {isParentLocation && <td>{item['inventory_items.locations.name']}</td>}
                      <td>{item.status && <Tag color={statusColor[item.status]}>{item.status}</Tag>}</td>
                      <td>
                        {item.scan_date &&
                          new Intl.DateTimeFormat('en-CA', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            timeZone: 'America/Toronto',
                          }).format(new Date(item.scan_date))}
                      </td>
                      <td>{itemStatusColor[item['inventory_items.status']]}</td>
                      <td>
                        <div dangerouslySetInnerHTML={{ __html: item.notes }} style={{ fontSize: 'medium', fontWeight: '' }} />
                      </td>
                      <td>
                        {(item.status === 'Item not in RBMS' || item.location_changed) && (
                          <StyledButton onClick={() => handleDeleteSerialNumber(item.id)}>Delete</StyledButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TransactionTable>
            </TransactionContainer>
          </div>
          <div style={{ marginTop: '15px' }}>
            <Title level={4}>Assigned Technician: {assignedTechnician}</Title>

            {levelSummary.length > 0 && (
              <LevelContainer>
                <TransactionTable>
                  <thead>
                    <tr>
                      <td>Level</td>
                      <td>Scanned</td>
                      <td>RBMS Location</td>
                    </tr>
                  </thead>
                  <tbody>
                    {levelSummary.map((level) => (
                      <tr key={level.name} id={level.name}>
                        <td>{level.name || 'no Location'}</td>
                        <td>{level.scanned}</td>
                        <td>{level.rbms}</td>
                      </tr>
                    ))}
                  </tbody>
                </TransactionTable>
              </LevelContainer>
            )}
            {timesScannedSummary.length > 0 && (
              <LevelContainer>
                <TransactionTable>
                  <thead>
                    <tr>
                      <td>Serial Number</td>
                      <td>Times Scanned</td>
                    </tr>
                  </thead>
                  <tbody>
                    {timesScannedSummary.map((unit) => (
                      <tr key={unit.serial_number} id={unit.serial_number}>
                        <td>{unit.serial_number}</td>
                        <td>{unit.times_scanned}</td>
                      </tr>
                    ))}
                  </tbody>
                </TransactionTable>
              </LevelContainer>
            )}
          </div>
        </ContainerForTables>
      ) : (
        <Empty description="There is nothing to show" />
      )}
    </Container>
  );
}
