import styled from 'styled-components';

export const Container = styled.div`
  /* display: flex;
  flex-direction: column;

  width: 100%;
  padding: 80px 20px 30px 250px;

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  } */
  background-color: ${props => props.theme.white};
  height: 100vh;
  padding: 75px 20px 0 250px;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    padding: 50px 10px;
  }
`;