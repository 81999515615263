/* eslint-disable react/prop-types */
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function TechnicianEdit({match}) {
  const [itemInfo, setItemInfo] = useState({});

  useEffect(() => {
    async function loadTechnician() {
      const techInfo = await api.get(`technicians/${match.params.name}`);

      setItemInfo(techInfo.data);
    }
    loadTechnician();
  }, []); // eslint-disable-line

  const handleSubmit = async () =>  {
    await api.put(`technicians/${match.params.name}`, itemInfo);
    history.push('/technicians');
  }

  // return (
  //   <Container>
  //     <Form initialData={branch} onSubmit={handleSubmit}>
  //       <Input name="name" placeholder="Name" />
  //       <Input name="phone" placeholder="Phone" />
  //       <Input name="email" placeholder="Email" />

  //       <button type="submit">Edit Technician</button>
  //     </Form>
  //   </Container>
  // );

  return (
    <Container>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader subheader={`${itemInfo.name} - ${itemInfo.email}`} title="Update Technician" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, name: e.target.value })}
                  value={itemInfo.name || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="phone"
                  label="Phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, phone: e.target.value })}
                  value={itemInfo.phone || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setItemInfo({ ...itemInfo, email: e.target.value })}
                  value={itemInfo.email || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Update Technician
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
