// import React, { useCallback, useEffect, useRef, useState } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
// import { Space, Input, Card, DatePicker, Table, Tag, Select, Empty, Pagination, Button, Typography } from 'antd';
import { Button, Card, Empty, Space, Table, Tag, Typography } from 'antd';

import moment from 'moment';
import { Link } from 'react-router-dom';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function InternalOrders() {
  // const [filterCriteria, setFilterCriteria] = useState('order_num');
  // const [filterStore, setFilterStore] = useState('');
  // const [filterStatus, setFilterStatus] = useState('');
  // const [filterFulfillment, setFilterFulfillment] = useState('');
  // const [isFiltered, setIsFiltered] = useState(false);
  // const [filterDate, setfilterDate] = useState([undefined, undefined]);
  // const [paginationTotalItems, setPaginationTotalItems] = useState();
  // const [paginationSize, setPaginationSize] = useState(25);
  // const [paginationPage, setPaginationPage] = useState(1);
  const [orders, setOrders] = useState([]);

  // const searchTextRef = useRef('');

  // const { Search } = Input;
  // const { RangePicker } = DatePicker;
  // const { Option } = Select;
  const { Paragraph, Title } = Typography;

  const tagColor = {
    in_progress: {
      label: 'In Progress',
      color: 'orange',
    },
    cancelled: {
      label: 'Cancelled',
      color: 'red',
    },
    complete: {
      label: 'Complete',
      color: 'green',
    },
    pending: {
      label: 'Pending',
      color: 'blue',
    },
  };

  // const handleSearch = useCallback(async filterText => {
  const handleSearch = useCallback(async () => {
    // const [filterStartDate, filterEndDate] = filterDate || [];

    // if (filterCriteria === 'serial_number') {
    //   const ordersData = await api.get(`ordersChecklist?filterCriteria=${filterCriteria}&filterText=${searchTextRef.current.input.value}`);
    //   const newOrders = ordersData.data.rows.map(checklist => checklist.order_items.order);

    //   setPaginationTotalItems(ordersData.data.totalItems);
    //   setPaginationSize(ordersData.data.sizePage);
    //   setPaginationPage(ordersData.data.currentPage + 1);
    //   setOrders(newOrders);
    //   return;
    // }

    // if (filterCriteria === 'product') {
    //   const ordersData = await api.get(`orderItems?size=${paginationSize}&page=${paginationPage - 1}`, {
    //     headers: {
    //       filterCriteria: 'title',
    //       filterText: filterText?.trim() || searchTextRef.current.input.value || '',
    //       filterStore: filterStore || '',
    //       filterStatus: filterStatus || '',
    //       filterFulfillment: filterFulfillment || '',
    //       filterStartDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
    //       filterEndDate: filterEndDate
    //         ? moment(filterEndDate)
    //             .add(1, 'day')
    //             .format('YYYY-MM-DD')
    //         : '',
    //     },
    //   });

    //   setPaginationTotalItems(ordersData.data.totalItems);
    //   setPaginationSize(ordersData.data.sizePage);
    //   setPaginationPage(ordersData.data.currentPage + 1);
    //   setOrders(ordersData.data.rows);
    //   return;
    // }

    const ordersData = await api.get(`internal_orders`);
    // const ordersData = await api.get(`internal_orders?size=${paginationSize}&page=${paginationPage - 1}`, {
    //   headers: {
    //     filterCriteria,
    //     filterText: filterText?.trim() || searchTextRef.current.input.value || '',
    //     // filterStore: filterStore || '',
    //     filterStatus: filterStatus || '',
    //     // filterFulfillment: filterFulfillment || '',
    //     // filterStartDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
    //     // filterEndDate: filterEndDate
    //     //   ? moment(filterEndDate)
    //     //       .add(1, 'day')
    //     //       .format('YYYY-MM-DD')
    //     //   : '',
    //   },
    // });

    // setPaginationTotalItems(ordersData.data.totalItems);
    // setPaginationSize(ordersData.data.sizePage);
    // setPaginationPage(ordersData.data.currentPage + 1);
    setOrders(ordersData.data);
  }, []);

  // function handleChangePage(page, pageSize) {
  //   setPaginationPage(page);
  //   setPaginationSize(pageSize);
  // }

  // const handleClearFilters = useCallback(() => {
  //   setIsFiltered(false);
  //   setFilterStore('');
  //   setFilterStatus('');
  //   setFilterFulfillment('');
  //   setfilterDate([undefined, undefined]);
  // }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const columns = [
    {
      title: 'Internal Order Number',
      dataIndex: 'internal_order_number',
      render: (text, record) => (
        <Link to={`/InternalOrderEdit?internal_order_id=${record.id}`}>
          <Paragraph style={{ color: '#1890ff' }} copyable>
            {text}
          </Paragraph>
        </Link>
      ),
    },
    {
      title: 'Created By',
      dataIndex: ['created_by', 'name'],
    },
    {
      title: 'Requested By',
      dataIndex: ['requested_by', 'name'],
    },
    {
      title: 'Order Date',
      dataIndex: 'creation_date',
      render: (text) => moment(text).format('MMM DD, YYYY'),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <Tag color={tagColor[text]?.color} key={tagColor[text]?.label}>
          {tagColor[text]?.label.toUpperCase() || text}
        </Tag>
      ),
    },
  ];

  return (
    <Container>
      <Card size="small">
        <Space wrap style={{ display: 'flex' }}>
          <Button onClick={() => history.push('/orders')} type="primary">
            Back to Orders
          </Button>
          <Button onClick={() => history.push('/internalOrderNew')} type="primary">
            New
          </Button>
          {/* <Button type="primary">Export</Button> */}
        </Space>
      </Card>

      <Card size="small" bordered={false}>
        <Title level={3}>Internal Orders</Title>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
        {/* <Space wrap>
            <Select
              onChange={value => {
                setFilterCriteria(value);
                setPaginationPage(1);
                searchTextRef.current.input.value = '';
                searchTextRef.current.focus();
              }}
              value={filterCriteria}
              style={{ width: 145 }}
            >
              <Option value="order_num">Order Number</Option>
              <Option value="serial_number">Serial Number</Option>
              <Option value="customer">Customer Name</Option>
              <Option value="product">Product Name</Option>
            </Select>

            <Search placeholder="Search" ref={searchTextRef} allowClear onSearch={handleSearch} style={{ width: 200 }} enterButton />
          </Space> */}

        {/* <Space wrap>
            {isFiltered && (
              <Tag
                closable
                color="error"
                onClose={e => {
                  e.preventDefault();
                  handleClearFilters();
                }}
              >
                Clear Filters
              </Tag>
            )}

            <Select
              onChange={value => {
                setFilterStore(value);
                setPaginationPage(1);
                setIsFiltered(true);
                handleSearch();
              }}
              value={filterStore}
              style={{ width: 140 }}
              options={storeList}
            />

            <RangePicker
              onChange={date => {
                setfilterDate(date);
                setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterDate}
              allowClear
            />

            <Select
              onChange={value => {
                setFilterStatus(value);
                setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterStatus}
              style={{ width: 160 }}
            >
              <Option key="">All Status</Option>
              <Option key="shipped">Shipped</Option>
              <Option key="awaiting_shipment">Awaiting Shipment</Option>
              <Option key="cancelled">Cancelled</Option>
              <Option key="on_hold">On Hold</Option>
              <Option key="awaiting_payment">Awaiting Payment</Option>
            </Select> */}

        {/* <Select
              onChange={value => {
                setFilterFulfillment(value);
                setPaginationPage(1);
                setIsFiltered(true);
              }}
              value={filterFulfillment}
              style={{ width: 140 }}
            >
              <Option key="">All Channels</Option>
              <Option key="FBA">FBA</Option>
              <Option key="Rugged Books">Rugged Books</Option>
            </Select> */}
        {/* </Space> */}
        {/* </div> */}
      </Card>

      {orders.length > 0 ? (
        <>
          <Table
            style={{ marginTop: '5px' }}
            columns={columns}
            dataSource={orders}
            size="small"
            bordered
            pagination={false}
            scroll={{ y: 'calc(100vh - 22em)' }}
            rowKey={(record) => record.id}
          />

          {/* <Card size="small" bordered={false} style={{ marginTop: '5px' }}>
            <Pagination
              size="small"
              total={paginationTotalItems}
              showSizeChanger
              showQuickJumper
              defaultPageSize={paginationSize}
              pageSizeOptions={[25, 50, 100]}
              onChange={handleChangePage}
              current={paginationPage}
            />
          </Card> */}
        </>
      ) : (
        <Empty
          description={<span>No data</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      )}
    </Container>
  );
}
