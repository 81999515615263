/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { Button, Space, Tag } from 'antd';

import moment from 'moment';
import api from '~/services/api';
import { Container } from './styles';

export default function ShipmentInfo({ shipmentInfo, handleVoidLabel }) {
  const [shipment, setShipment] = useState({});

  useEffect(() => {
    async function loadShipmentInfo() {
      const carriersAndServicesList = await api.get('shipstation/carriers');

      let linkToTrackingCode = '';

      switch (shipmentInfo.carrierCode) {
        case 'ups':
          linkToTrackingCode = `https://wwwapps.ups.com/WebTracking/processRequest?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=${shipmentInfo.trackingNumber}/trackdetails`;
          break;
        case 'purolator_ca':
          linkToTrackingCode = `https://www.purolator.com/en/shipping/tracker?pins=${shipmentInfo.trackingNumber}`;
          break;
        case 'dhl_express_canada':
          linkToTrackingCode = `http://www.dhl.com/content/g0/en/express/tracking.shtml?brand=DHL&AWB=${shipmentInfo.trackingNumber}`;
          break;
        case 'fedex':
          linkToTrackingCode = `https://www.fedex.com/fedextrack/?action=track&trackingnumber=${shipmentInfo.trackingNumber}`;
          break;
        case 'canada_post':
          linkToTrackingCode = `http://www.canadapost.ca/cpotools/apps/track/personal/findByTrackNumber?trackingNumber=${shipmentInfo.trackingNumber}&LOCALE=en`;
          break;
        default:
          break;
      }
      const shipmentData = {
        Status: <Tag color="success">Shipped</Tag>,
        'Tracking #': (
          <a target="_blank" rel="noopener noreferrer" href={linkToTrackingCode}>
            {shipmentInfo.trackingNumber}
          </a>
        ),

        Service: carriersAndServicesList.data.find(el => el.code === shipmentInfo.carrierCode).services.find(el => el.code === shipmentInfo.serviceCode).name,
        'Ship Date': moment(shipmentInfo.shipDate).format('MMMM DD, YYYY'),
        Weight: `${shipmentInfo.weight.value * 0.0625} lb`,
        Package: shipmentInfo.packageCode,
        Confirmation: shipmentInfo.confirmation === null ? 'None' : shipmentInfo.confirmation,
        Insurance: shipmentInfo.insuranceCost === 0 ? 'None' : shipmentInfo.insuranceOptions.insuredValue,
      };
      if (shipmentInfo.dimensions) {
        shipmentData.Size = `${shipmentInfo.dimensions.length}l x ${shipmentInfo.dimensions.width}w x ${shipmentInfo.dimensions.height}h (${shipmentInfo.dimensions.units})`;
      }
      setShipment(shipmentData);
    }
    loadShipmentInfo();
  }, []); //eslint-disable-line
  return (
    <Container>
      {Object.keys(shipment).map(el => (
        <div key={el}>
          <span style={{ display: 'inline-block', width: '200px' }}>{el}</span>
          <span>{shipment[el]}</span>
        </div>
      ))}

      <div>
        <Space
          size="small"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#6ba03a', fontSize: '16px', fontWeight: 'bold' }}
        >
          <div>
            Total Cost:
            {new Intl.NumberFormat('us-CA', {
              style: 'currency',
              currency: 'CAD',
            }).format(shipmentInfo.shipmentCost)}
          </div>
          <div>
            <Button type="primary" danger onClick={() => handleVoidLabel(shipmentInfo.shipmentId)}>
              Void Label
            </Button>
          </div>
        </Space>
      </div>
    </Container>
  );
}
