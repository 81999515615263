import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 80px 20px 30px 280px;

  input {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 450px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;
