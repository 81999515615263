/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import { CloseSquareOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Form, Input, InputNumber, Popover, Radio, Select, Space, Spin, Tag, Tooltip, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { DebounceSelect } from '~/components/DebounceSelect';
import api from '~/services/api';

import { fetchBatteries } from '../utils/fetchBatteries';
import { fetchCpus } from '../utils/fetchCpus';
import { fetchItems } from '../utils/fetchItems';
import { fetchRams } from '../utils/fetchRams';
import { handleBatterySerialNumberValidation } from '../utils/handleBatterySerialNumberValidation';
import { handlePartsLocationCheck } from '../utils/handlePartsLocationCheck';

export default function Hardware({
  cameraOptions,
  cleanFieldsNotPassed,
  form,
  instructions,
  itemDisabled,
  itemInfo,
  itemResults,
  keyboardLanguageOptions,
  keyboardOptions,
  keyboardPortOptions,
  profile,
  screenOptions,
  smartCardOptions,
  stylusOptions,
  checkUsb,
  setCheckUsb,
  checkGraphicsCard,
  setCheckGraphicsCard,
  checkCpu,
  setCheckCpu,
  checkStorage1,
  setCheckStorage1,
  checkStorage2,
  setCheckStorage2,
  checkRam1,
  setCheckRam1,
  checkRam2,
  setCheckRam2,
}) {
  const [batteryFile, setBatteryFile] = useState([]);
  const [batteryUploadDisabled, setBatteryUploadDisabled] = useState(false);
  const [batteryFile2, setBatteryFile2] = useState([]);
  const [batteryUploadDisabled2, setBatteryUploadDisabled2] = useState(false);
  const [batteryFile3, setBatteryFile3] = useState([]);
  const [batteryUploadDisabled3, setBatteryUploadDisabled3] = useState(false);
  const [checkAcAdapter, setCheckAcAdapter] = useState(false);
  const [checkBattery, setCheckBattery] = useState(false);
  const [checkBattery2, setCheckBattery2] = useState(false);
  const [checkBattery3, setCheckBattery3] = useState(false);
  const [checkCamera, setCheckCamera] = useState(false);
  const [checkDedicatedGPU, setCheckDedicatedGPU] = useState(false);
  const [checkKeyboard, setCheckKeyboard] = useState(false);
  const [checkKeyboardPorts, setCheckKeyboardPorts] = useState(false);
  const [checkScreen, setCheckScreen] = useState(false);
  const [checkSmartCard, setCheckSmartCard] = useState(false);
  const [checkStylus, setCheckStylus] = useState(false);
  const [checkWwanModel, setCheckWwanModel] = useState(false);
  const [hardDriveInfo1, setHardDriveInfo1] = useState({});
  const [hardDriveInfo2, setHardDriveInfo2] = useState({});
  const [hardDriveNotes1, setHardDriveNotes1] = useState();
  const [hardDriveNotes2, setHardDriveNotes2] = useState();
  const [hardwareLoading, setHardwareLoading] = useState(true);
  const [ramError1, setRamError1] = useState('success');
  const [ramError2, setRamError2] = useState('success');
  const [ramMessage1, setRamMessage1] = useState(null);
  const [ramMessage2, setRamMessage2] = useState(null);
  const [storageError1, setStorageError1] = useState('success');
  const [storageError2, setStorageError2] = useState('success');
  const [storageMessage1, setStorageMessage1] = useState(null);
  const [storageMessage2, setStorageMessage2] = useState(null);

  useEffect(() => {
    async function getStorageName() {
      if (itemInfo.storage_serial_number !== '' && itemInfo.storage_serial_number !== null && itemInfo.storage_serial_number !== undefined) {
        const storageData = await api.post('inventory_items/showBySerialNumber', { serial_number: itemInfo.storage_serial_number.trim() });
        setStorageMessage1(storageData.data?.item?.name);

        if (storageData.data?.description !== null) {
          setHardDriveNotes1(storageData.data?.description);
        }

        if (storageData.data.condition === 'New') {
          const receivingData = await api.get('receiving', {
            headers: {
              filterCriteria: 'serial_number',
              filterText: itemInfo.storage_serial_number?.trim().toUpperCase() || itemInfo.storage_serial_number.trim().toUpperCase() || '',
            },
          });

          if (receivingData.data.error) {
            setHardDriveInfo1({ error: receivingData.data.error });
          } else {
            setHardDriveInfo1({
              error: '',
              label: 'Receiving Log',
              url: `/InventoryOfReceiving?id=${receivingData.data[0]?.id}&receivingItemId=${receivingData.data[0]?.receiving_items[0]?.id}`,
            });
          }
        } else if (storageData.data.condition === 'Used' && storageData.data.wiping_report_file !== null) {
          setHardDriveInfo1({ error: '', label: 'Wiping Report', url: storageData.data?.wiping_report_file?.url });
        } else {
          setHardDriveInfo1({ error: 'Wiping report not available' });
        }
      }
      if (itemInfo.storage_serial_number_2 !== '' && itemInfo.storage_serial_number_2 !== null && itemInfo.storage_serial_number_2 !== undefined) {
        const storageData = await api.get('inventory_items/showBySerialNumber', { serial_number: itemInfo.storage_serial_number_2 });
        setStorageMessage2(storageData.data?.item?.name);

        if (storageData.data?.description !== null) {
          setHardDriveNotes2(storageData.data?.description);
        }

        if (storageData.data.condition === 'New') {
          const receivingData = await api.get('receiving', {
            headers: {
              filterCriteria: 'serial_number',
              filterText: itemInfo.storage_serial_number_2?.trim() || itemInfo.storage_serial_number_2 || '',
            },
          });

          if (receivingData.data.error) {
            setHardDriveInfo2({ error: receivingData.data.error });
          } else {
            setHardDriveInfo2({
              error: '',
              label: 'Receiving Log',
              url: `/InventoryOfReceiving?id=${receivingData.data[0]?.id}&receivingItemId=${receivingData.data[0]?.receiving_items[0]?.id}`,
            });
          }
        } else if (storageData.data.condition === 'Used' && storageData.data.wiping_report_file !== null) {
          setHardDriveInfo2({ error: '', label: 'Wiping Report', url: storageData.data?.wiping_report_file?.url });
        } else {
          setHardDriveInfo2({ error: 'Wiping report not available' });
        }
      }
    }
    async function getBatteryFile() {
      if (itemInfo.battery_file) {
        setBatteryFile([
          {
            uid: itemInfo.battery_file.id,
            url: itemInfo.battery_file.url,
            name: itemInfo.battery_file.name,
          },
        ]);
        form.setFieldsValue({
          batteryFile2: [
            {
              uid: itemInfo.battery_file.id,
              url: itemInfo.battery_file.url,
              name: itemInfo.battery_file.name,
            },
          ],
        });
      }
      if (itemInfo.battery_file2) {
        setBatteryFile2([
          {
            uid: itemInfo.battery_file2.id,
            url: itemInfo.battery_file2.url,
            name: itemInfo.battery_file2.name,
          },
        ]);
        form.setFieldsValue({
          batteryFile2: [
            {
              uid: itemInfo.battery_file2.id,
              url: itemInfo.battery_file2.url,
              name: itemInfo.battery_file2.name,
            },
          ],
        });
      }
      if (itemInfo.battery_file3) {
        setBatteryFile3([
          {
            uid: itemInfo.battery_file3.id,
            url: itemInfo.battery_file3.url,
            name: itemInfo.battery_file3.name,
          },
        ]);
        form.setFieldsValue({
          batteryFile3: [
            {
              uid: itemInfo.battery_file3.id,
              url: itemInfo.battery_file3.url,
              name: itemInfo.battery_file3.name,
            },
          ],
        });
      }
    }

    async function getRamName() {
      if (itemInfo.ram_serial_number !== '' && itemInfo.ram_serial_number !== null && itemInfo.ram_serial_number !== undefined) {
        const ramData = await api.post('inventory_items/showBySerialNumber', { serial_number: itemInfo.ram_serial_number.trim() });
        setRamMessage1(ramData.data?.item?.name);
      }
      if (itemInfo.ram_serial_number_2 !== '' && itemInfo.ram_serial_number_2 !== null && itemInfo.ram_serial_number_2 !== undefined) {
        const ramData = await api.post('inventory_items/showBySerialNumber', { serial_number: itemInfo.ram_serial_number_2.trim() });
        setRamMessage2(ramData.data?.item?.name);
      }
    }
    setCheckStorage1(itemResults.resultStorage1 === 'passed');
    setCheckRam1(itemResults.resultRam1 === 'passed');
    setCheckStorage2(itemResults.resultStorage2 === 'passed');
    setCheckRam2(itemResults.resultRam2 === 'passed');
    setCheckScreen(itemResults.resultScreen === 'passed');
    setCheckGraphicsCard(itemResults.resultGraphicsCard === 'passed');
    setCheckStylus(itemResults.resultStylus === 'passed');
    setCheckUsb(itemResults.resultUsb === 'passed');
    setCheckKeyboard(itemResults.resultKeyboard === 'passed');
    setCheckBattery(itemResults.resultBattery === 'passed');
    setCheckBattery2(itemResults.resultBattery2 === 'passed');
    setCheckBattery3(itemResults.resultBattery3 === 'passed');
    setCheckCamera(itemResults.resultCamera === 'passed');
    setCheckWwanModel(itemResults.resultWwan === 'passed');
    setCheckCpu(itemResults.resultCPU === 'passed');
    setCheckKeyboardPorts(itemResults.resultKeyboardPorts === 'passed');
    setCheckAcAdapter(itemResults.resultAcAdapter === 'passed');
    setCheckDedicatedGPU(itemResults.resultDedicatedGpu === 'passed');
    setCheckSmartCard(itemResults.resultSmartCard === 'passed');

    getStorageName();
    getBatteryFile();
    getRamName();

    setHardwareLoading(false);
  }, [
    form,
    itemInfo,
    itemInfo.item.name,
    itemInfo.storage_serial_number,
    itemResults,
    setCheckUsb,
    setCheckGraphicsCard,
    setCheckCpu,
    setCheckStorage1,
    setCheckStorage2,
    setCheckRam1,
    setCheckRam2,
  ]);

  const validateStorageSerialNumber1 = useCallback(
    async (storageProp) => {
      const storageSerialNumberParam = storageProp?.trim().toUpperCase();

      const storageSerialNumber =
        storageSerialNumberParam?.substring(0, 4) === 'P/N:'
          ? storageSerialNumberParam?.substring((storageSerialNumberParam?.indexOf('S/N:') || 0) + 4)
          : storageSerialNumberParam;
      /** reset fields */
      form.setFieldsValue({ storageHealth1: '' });
      form.setFieldsValue({ storageSize1: '' });

      setStorageError1('error');
      setStorageMessage1(null);

      if ((storageSerialNumber === '' || storageSerialNumber === null) && checkStorage1) {
        form.setFieldsValue({ storageHealth1: '' });
        form.setFieldsValue({ storageSize1: '' });

        setStorageError1('error');
        setStorageMessage1('HD serial number is required');

        return false;
      }
      /** This situation is when is NA on the Inspection page */
      if (storageSerialNumber === '' || storageSerialNumber === null) {
        form.setFieldsValue({ storageHealth1: '' });
        form.setFieldsValue({ storageSize1: '' });

        setStorageMessage1(null);
        setStorageError1('error');
        return true;
      }
      if (storageSerialNumber !== '' && storageSerialNumber !== null && storageSerialNumber !== undefined) {
        const storageData = await api.post('inventory_items/showBySerialNumber', { serial_number: storageSerialNumber });
        const CAN_TECH_ADD_PART = await handlePartsLocationCheck(storageData.data, profile);

        if (!CAN_TECH_ADD_PART.allowed) {
          form.setFieldsValue({ storageHealth1: '' });
          form.setFieldsValue({ storageSize1: '' });

          setStorageError1('error');
          setStorageMessage1(CAN_TECH_ADD_PART.message);
          return false;
        }
        if (storageData.data.status === 'Sold') {
          form.setFieldsValue({ storageHealth1: '' });
          form.setFieldsValue({ storageSize1: '' });

          setStorageError1('error');
          setStorageMessage1('Hard Drive is Sold');
          return false;
        }

        if (form.getFieldValue('storageSerialNumber1') === form.getFieldValue('storageSerialNumber2')) {
          setStorageError1('error');
          setStorageMessage1('Both storage slots have the same serial number');
          return false;
        }

        const canUseStorage = await api.post('inventory_items/checkStorageInUnit', { unitId: itemInfo.id, storageSerialNumber });

        if (!canUseStorage.data.ok) {
          form.setFieldsValue({ storageHealth1: '' });
          form.setFieldsValue({ storageSize1: '' });

          setStorageError1('error');
          setStorageMessage1(canUseStorage.data.error);
          return false;
        }

        if (storageData.data?.item?.category?.name !== 'Hard Drive') {
          form.setFieldsValue({ storageHealth1: '' });
          form.setFieldsValue({ storageSize1: '' });

          setStorageError1('error');
          setStorageMessage1('Not a Hard Drive');
          return false;
        }
        if (storageData.data.condition === 'New' && storageData.data.status === 'InStock') {
          const receivingData = await api.get('receiving', {
            headers: {
              filterCriteria: 'serial_number',
              filterText: storageSerialNumber || storageSerialNumber || '',
            },
          });

          if (receivingData.data.id !== undefined) {
            setHardDriveInfo1({
              error: '',
              label: 'Receiving Log',
              url: `/InventoryOfReceiving?id=${receivingData.data[0]?.id}&receivingItemId=${receivingData.data[0]?.receiving_items[0]?.id}`,
            });
          }

          setStorageMessage1(storageData.data?.item?.name);
          setStorageError1('success');
          setHardDriveNotes1(storageData.data?.description);
          form.setFieldsValue({ storageSerialNumber1: storageSerialNumber });
          form.setFieldsValue({ storageSize1: storageData.data?.item?.storage?.name });
          return true;
        }
        if (storageData.data.is_processed && storageData.data.status === 'InStock' && storageData.data.condition === 'Used') {
          if (storageData.data.wiping_report_file !== null) {
            setHardDriveInfo1({ error: '', label: 'Wiping Report', url: storageData.data.wiping_report_file?.url });
          }

          setStorageMessage1(storageData.data?.item?.name);
          setStorageError1('success');
          form.setFieldsValue({ storageSerialNumber1: storageSerialNumber });
          form.setFieldsValue({ storageHealth1: storageData.data?.storage_health });
          form.setFieldsValue({ storageSize1: storageData.data?.item?.storage?.name });
          setHardDriveNotes1(storageData.data?.description);
          return true;
        }

        if (itemInfo.storage_serial_number === storageSerialNumber) {
          setStorageMessage1(storageData.data?.item?.name);
          setStorageError1('success');
          form.setFieldsValue({ storageHealth1: storageData.data?.storage_health });
          form.setFieldsValue({ storageSize1: storageData.data?.item?.storage?.name });
          setHardDriveNotes1(storageData.data?.description);
          return true;
        }

        if (
          storageData.data.condition !== 'New' &&
          !(storageData.data.is_processed && storageData.data.status === 'InStock' && storageData.data.condition === 'Used')
        ) {
          form.setFieldsValue({ storageHealth1: '' });
          form.setFieldsValue({ storageSize1: '' });
          setStorageError1('error');
          setStorageMessage1('You can only add new or inspected hard drives');
          return false;
        }
      }
      return true;
    },
    [checkStorage1, form, itemInfo, profile]
  );

  const validateStorageSerialNumber2 = useCallback(
    async (storageProp) => {
      const storageSerialNumberParam = storageProp?.trim().toUpperCase();

      const storageSerialNumber =
        storageSerialNumberParam?.substring(0, 4) === 'P/N:'
          ? storageSerialNumberParam?.substring((storageSerialNumberParam?.indexOf('S/N:') || 0) + 4)
          : storageSerialNumberParam;
      /** reset fields */
      form.setFieldsValue({ storageHealth2: '' });
      form.setFieldsValue({ storageSize2: '' });

      setStorageError2('error');
      setStorageMessage2(null);

      if ((storageSerialNumber === '' || storageSerialNumber === null) && checkStorage2) {
        form.setFieldsValue({ storageHealth2: '' });
        form.setFieldsValue({ storageSize2: '' });

        setStorageError2('error');
        setStorageMessage2('HD serial number is required');
        return false;
      }

      /** This situation is when is NA on the Inspection page */
      if (storageSerialNumber === '' || storageSerialNumber === null) {
        form.setFieldsValue({ storageHealth2: '' });
        form.setFieldsValue({ storageSize2: '' });

        setStorageMessage2(null);
        setStorageError2('error');
        return true;
      }

      if (form.getFieldValue('storageSerialNumber1') === form.getFieldValue('storageSerialNumber2')) {
        setStorageError2('error');
        setStorageMessage2('Both storage slots have the same serial number');
        return false;
      }

      if (storageSerialNumber !== '' && storageSerialNumber !== null && storageSerialNumber !== undefined) {
        const storageData = await api.post('inventory_items/showBySerialNumber', { serial_number: storageSerialNumber });

        const CAN_TECH_ADD_PART = await handlePartsLocationCheck(storageData.data, profile);

        if (!CAN_TECH_ADD_PART.allowed) {
          form.setFieldsValue({ storageHealth2: '' });
          form.setFieldsValue({ storageSize2: '' });

          setStorageError2('error');
          setStorageMessage2(CAN_TECH_ADD_PART.message);
          return false;
        }

        if (storageData.data.status === 'Sold') {
          form.setFieldsValue({ storageHealth2: '' });
          form.setFieldsValue({ storageSize2: '' });

          setStorageError2('error');
          setStorageMessage2('Hard Drive is Sold');
          return false;
        }

        const canUseStorage = await api.post('inventory_items/checkStorageInUnit', { unitId: itemInfo.id, storageSerialNumber });
        if (!canUseStorage.data.ok) {
          form.setFieldsValue({ storageHealth2: '' });
          form.setFieldsValue({ storageSize2: '' });

          setStorageError2('error');
          setStorageMessage2(storageData.data.error);
          return false;
        }

        if (storageData.data?.item?.category?.name !== 'Hard Drive') {
          form.setFieldsValue({ storageHealth2: '' });
          form.setFieldsValue({ storageSize2: '' });

          setStorageError2('error');
          setStorageMessage2('Not a Hard Drive');
          return false;
        }

        if (storageData.data.condition === 'New' && storageData.data.status === 'InStock') {
          const receivingData = await api.get('receiving', {
            headers: {
              filterCriteria: 'serial_number',
              filterText: storageSerialNumber || storageSerialNumber || '',
            },
          });

          if (receivingData.data.id !== undefined) {
            setHardDriveInfo2({
              error: '',
              label: 'Receiving Log',
              url: `/InventoryOfReceiving?id=${receivingData.data[0]?.id}&receivingItemId=${receivingData.data[0]?.receiving_items[0]?.id}`,
            });
          }

          setStorageMessage2(storageData.data?.item?.name);
          setStorageError2('success');
          setHardDriveNotes2(storageData.data?.description);
          form.setFieldsValue({ storageSerialNumber2: storageSerialNumber });
          form.setFieldsValue({ storageSize2: storageData.data?.item?.storage?.name });
          return true;
        }

        if (storageData.data.is_processed && storageData.data.status === 'InStock' && storageData.data.condition === 'Used') {
          if (storageData.data.wiping_report_file !== null) {
            setHardDriveInfo2({ error: '', label: 'Wiping Report', url: storageData.data.wiping_report_file?.url });
          }

          setStorageMessage2(storageData.data?.item?.name);
          setStorageError2('success');
          form.setFieldsValue({ storageSerialNumber2: storageSerialNumber });
          form.setFieldsValue({ storageHealth2: storageData.data?.storage_health });
          form.setFieldsValue({ storageSize2: storageData.data?.item?.storage?.name });
          setHardDriveNotes2(storageData.data?.description);
          return true;
        }

        if (
          storageData.data.condition !== 'New' &&
          !(storageData.data.is_processed && storageData.data.status === 'InStock' && storageData.data.condition === 'Used')
        ) {
          form.setFieldsValue({ storageHealth2: '' });
          form.setFieldsValue({ storageSize2: '' });
          setStorageError2('error');
          setStorageMessage2('You can only add new or inspected hard drives');
          return false;
        }
        if (itemInfo.storage_serial_number_2 === storageSerialNumber) {
          setStorageMessage2(storageData.data?.item?.name);
          setStorageError2('success');
          form.setFieldsValue({ storageHealth2: storageData.data?.storage_health });
          form.setFieldsValue({ storageSize2: storageData.data?.item?.storage?.name });
          setHardDriveNotes2(storageData.data?.description);
          return true;
        }
      }
      return true;
    },
    [checkStorage2, form, itemInfo.storage_serial_number_2, profile, itemInfo.id]
  );

  const validateRamSerialNumber1 = useCallback(
    async (ramProp) => {
      try {
        const ramSerialNumberParam = ramProp?.trim().toUpperCase();

        const ramSerialNumber =
          ramSerialNumberParam?.substring(0, 4) === 'P/N:'
            ? ramSerialNumberParam?.substring((ramSerialNumberParam?.indexOf('S/N:') || 0) + 4)
            : ramSerialNumberParam;
        /** reset fields */

        setRamError1('error');
        setRamMessage1(null);

        if (ramSerialNumber !== '' && ramSerialNumber !== null && ramSerialNumber !== undefined) {
          const ramData = await api.post('inventory_items/showBySerialNumber', { serial_number: ramSerialNumber });

          const CAN_TECH_ADD_PART = await handlePartsLocationCheck(ramData.data, profile);

          if (!CAN_TECH_ADD_PART.allowed) {
            form.setFieldsValue({ ramSize1: '' });

            setRamError1('error');
            setRamMessage1(CAN_TECH_ADD_PART.message);
            return false;
          }

          if (ramData.data.status === 'Sold') {
            form.setFieldsValue({ ramSize1: '' });

            setRamError1('error');
            setRamMessage1('Ram is Sold');
            return false;
          }

          if (form.getFieldValue('ramSerialNumber1') === form.getFieldValue('ramSerialNumber2')) {
            setRamError1('error');
            setRamMessage1('Both RAM slots have the same serial number');
            return false;
          }

          const canUseRam = await api.post('inventory_items/checkRamInUnit', { unitId: itemInfo.id, ramSerialNumber });

          if (!canUseRam.data.ok) {
            form.setFieldsValue({ ramSize1: '' });

            setRamError1('error');
            setRamMessage1(canUseRam.data.error);
            return false;
          }

          if (itemInfo.ram_serial_number === ramSerialNumber) {
            setRamMessage1(ramData.data?.item?.name);
            setRamError1('success');
            form.setFieldsValue({ ramSize1: ramData.data?.item?.ram?.name });
            return true;
          }

          if (ramData.data?.item?.category?.name !== 'Cards') {
            form.setFieldsValue({ ramSize1: '' });

            setRamError1('error');
            setRamMessage1('Not a Ram');
            return false;
          }

          if (ramData.data.status === 'InStock') {
            setRamMessage1(ramData.data?.item?.name);
            setRamError1('success');
            form.setFieldsValue({ ramSerialNumber1: ramSerialNumber });
            form.setFieldsValue({ ramSize1: ramData.data?.item?.ram?.name });
            return true;
          }
        }
        return true;
      } catch (error) {
        return false;
      }
    },

    [form, profile, itemInfo.id, itemInfo.ram_serial_number]
  );

  const validateRamSerialNumber2 = useCallback(
    async (ramProp) => {
      const ramSerialNumberParam = ramProp?.trim().toUpperCase();

      const ramSerialNumber =
        ramSerialNumberParam?.substring(0, 4) === 'P/N:'
          ? ramSerialNumberParam?.substring((ramSerialNumberParam?.indexOf('S/N:') || 0) + 4)
          : ramSerialNumberParam;
      /** reset fields */
      setRamError2('error');
      setRamMessage2(null);

      if (ramSerialNumber !== '' && ramSerialNumber !== null && ramSerialNumber !== undefined) {
        const ramData = await api.post('inventory_items/showBySerialNumber', { serial_number: ramSerialNumber });

        const CAN_TECH_ADD_PART = await handlePartsLocationCheck(ramData.data, profile);

        if (!CAN_TECH_ADD_PART.allowed) {
          form.setFieldsValue({ ramSize2: '' });
          setRamError2('error');
          setRamMessage2(CAN_TECH_ADD_PART.message);
          return false;
        }

        if (ramData.data.status === 'Sold') {
          form.setFieldsValue({ ramSize2: '' });
          setRamError2('error');
          setRamMessage2('Ram is Sold');
          return false;
        }

        if (form.getFieldValue('ramSerialNumber1') === form.getFieldValue('ramSerialNumber2')) {
          setRamError2('error');
          setRamMessage2('Both RAM slots have the same serial number');
          return false;
        }

        const canUseRam = await api.post('inventory_items/checkRamInUnit', { unitId: itemInfo.id, ramSerialNumber });
        if (!canUseRam.data.ok) {
          form.setFieldsValue({ ramSize2: '' });
          setRamError2('error');
          setRamMessage2(canUseRam.data.error);
          return false;
        }

        if (itemInfo.ram_serial_number_2 === ramSerialNumber) {
          setRamMessage2(ramData.data?.item?.name);
          setRamError2('success');
          form.setFieldsValue({ ramSize2: ramData.data?.item?.ram?.name });
          return true;
        }

        if (ramData.data?.item?.category?.name !== 'Cards') {
          form.setFieldsValue({ ramSize2: '' });
          setRamError2('error');
          setRamMessage2('Not a Ram');
          return false;
        }

        if (ramData.data.status === 'InStock') {
          setRamMessage2(ramData.data?.item?.name);
          setRamError2('success');
          form.setFieldsValue({ ramSerialNumber2: ramSerialNumber });
          form.setFieldsValue({ ramSize2: ramData.data?.item?.ram?.name });
          return true;
        }
      }
      return true;
    },
    [form, itemInfo.ram_serial_number_2, profile, itemInfo.id]
  );

  const handleChangeBatteryFile = useCallback(
    async (info, serialNumber, batteryHealth, setBatteryFileState, formField) => {
      const formData = new FormData();

      formData.append('file', info.file.originFileObj);

      let picturesList = [];

      try {
        const responseUploadPictures = await api.post('files', formData);

        await api.put('/inventory_items', {
          items: [
            {
              serial_number: serialNumber,
              battery_file_id: responseUploadPictures.data[0].id,
              battery: batteryHealth,
            },
          ],
        });

        picturesList = responseUploadPictures.data.map((el) => ({
          uid: el.id,
          url: el.url,
          name: el.name,
        }));
      } catch (error) {
        Alert.alert('Error', 'Something went wrong - Pictures');
        return;
      }

      setBatteryFileState(() => picturesList);
      form.setFieldsValue({ [`${formField}`]: picturesList });
    },
    [form]
  );

  if (hardwareLoading) {
    return (
      <Spin tip="Loading...">
        <Alert message="Hardware page is loading. Hold on a second" type="info" />
      </Spin>
    );
  }

  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="Storage 1" span={2}>
        <Space>
          <Form.Item
            hasFeedback
            name="storageSerialNumber1"
            rules={[
              { required: checkStorage1, message: 'Storage Serial Number is required' },
              {
                validator: async (_, value) =>
                  (await validateStorageSerialNumber1(value)) ? Promise.resolve() : !checkStorage1 ? Promise.resolve() : Promise.reject(),
              },
            ]}
            validateTrigger="onBlur"
            validateStatus={storageError1}
          >
            <Input
              onBlur={(e) => {
                const serialNumberTrimmed = e.target.value.trim().toUpperCase();

                const serialNumberFormatted =
                  serialNumberTrimmed?.substring(0, 4) === 'P/N:'
                    ? serialNumberTrimmed?.substring((serialNumberTrimmed?.indexOf('S/N:') || 0) + 4)
                    : serialNumberTrimmed;
                form.setFieldsValue({ storageSerialNumber1: serialNumberFormatted });
              }}
              placeholder="Storage Serial Number"
              allowClear
              disabled={!checkStorage1 || itemDisabled}
            />
          </Form.Item>
          <Form.Item hasFeedback name="storageSize1">
            <Input disabled placeholder="Storage Size" />
          </Form.Item>
          <Form.Item hasFeedback name="storageHealth1">
            <InputNumber
              disabled
              placeholder="Health"
              min={0}
              max={100}
              formatter={(value) => (value === '' ? '' : `${value}%`)}
              parser={(value) => value.replace('%', '')}
            />
          </Form.Item>

          {hardDriveInfo1.error === '' ? (
            <Button type="link" target="_blank" href={hardDriveInfo1.url}>
              {hardDriveInfo1.label}
            </Button>
          ) : hardDriveInfo1.error !== undefined ? (
            <Tag color="error">{hardDriveInfo1.error}</Tag>
          ) : (
            ''
          )}
          {hardDriveNotes1 && (
            <Popover content={hardDriveNotes1} title="Notes" trigger="click">
              <Button type="link">Notes</Button>
            </Popover>
          )}
        </Space>
        <div>{storageMessage1 && <Alert message={storageMessage1} type={storageError1 === 'error' ? 'error' : 'success'} showIcon />}</div>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_storage')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultStorage1" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            name="storageTest1"
            onChange={(e) =>
              e.target.value === 'passed'
                ? setCheckStorage1(true)
                : (setCheckStorage1(false), cleanFieldsNotPassed(['storageSerialNumber1', 'storageHealth1', 'storageSize1']), setStorageMessage1(''))
            }
            buttonStyle="solid"
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Storage 2" span={2}>
        <Space>
          <Form.Item
            hasFeedback
            name="storageSerialNumber2"
            rules={[
              { required: checkStorage2, message: 'Storage serial number is required' },
              {
                validator: async (_, value) =>
                  (await validateStorageSerialNumber2(value)) ? Promise.resolve() : !checkStorage2 ? Promise.resolve() : Promise.reject(),
              },
            ]}
            validateTrigger="onBlur"
            validateStatus={storageError2}
          >
            <Input
              onBlur={(e) => {
                const serialNumberTrimmed = e.target.value.trim().toUpperCase();

                const serialNumberFormatted =
                  serialNumberTrimmed?.substring(0, 4) === 'P/N:'
                    ? serialNumberTrimmed?.substring((serialNumberTrimmed?.indexOf('S/N:') || 0) + 4)
                    : serialNumberTrimmed;
                form.setFieldsValue({ storageSerialNumber2: serialNumberFormatted });
              }}
              placeholder="Storage Serial Number"
              allowClear
              disabled={!checkStorage2 || itemDisabled}
            />
          </Form.Item>
          <Form.Item hasFeedback name="storageSize2">
            <Input disabled placeholder="Storage Size" />
          </Form.Item>
          <Form.Item hasFeedback name="storageHealth2">
            <InputNumber
              disabled
              placeholder="Health"
              min={0}
              max={100}
              formatter={(value) => (value === '' ? '' : `${value}%`)}
              parser={(value) => value.replace('%', '')}
            />
          </Form.Item>

          {hardDriveInfo2.error === '' ? (
            <Button type="link" target="_blank" href={hardDriveInfo2.url}>
              {hardDriveInfo2.label}
            </Button>
          ) : hardDriveInfo2.error !== undefined ? (
            <Tag color="error">{hardDriveInfo2.error}</Tag>
          ) : (
            ''
          )}
          {hardDriveNotes2 && (
            <Popover content={hardDriveNotes2} title="Notes" trigger="click">
              <Button type="link">Notes</Button>
            </Popover>
          )}
        </Space>
        <div>{storageMessage2 && <Alert message={storageMessage2} type={storageError2 === 'error' ? 'error' : 'success'} showIcon />}</div>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_storage')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultStorage2" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            name="storageTest"
            onChange={(e) =>
              e.target.value === 'passed'
                ? setCheckStorage2(true)
                : (setCheckStorage2(false), cleanFieldsNotPassed(['storageSerialNumber2', 'storageHealth2', 'storageSize2']), setStorageMessage2(''))
            }
            buttonStyle="solid"
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="RAM 1" span={2}>
        <Space>
          <Form.Item
            hasFeedback
            name="ramSerialNumber1"
            rules={[
              {
                validator: async (_, value) =>
                  (await validateRamSerialNumber1(value)) ? Promise.resolve() : !checkRam1 ? Promise.resolve() : Promise.reject(),
              },
            ]}
            validateTrigger="onBlur"
            validateStatus={ramError1}
          >
            <Input
              onBlur={(e) => {
                const serialNumberTrimmed = e.target.value.trim().toUpperCase();

                const serialNumberFormatted =
                  serialNumberTrimmed?.substring(0, 4) === 'P/N:'
                    ? serialNumberTrimmed?.substring((serialNumberTrimmed?.indexOf('S/N:') || 0) + 4)
                    : serialNumberTrimmed;
                form.setFieldsValue({ ramSerialNumber1: serialNumberFormatted });
              }}
              placeholder="Ram Serial Number"
              allowClear
              disabled={!checkRam1 || itemDisabled}
            />
          </Form.Item>
          <Form.Item name="ramSize1" rules={[{ required: checkRam1, message: 'RAM size is required' }]}>
            <DebounceSelect
              allowClear
              showSearch
              disabled={!checkRam1 || itemDisabled}
              style={{ width: 200 }}
              placeholder="RAM Size"
              optionFilterProp="label"
              fetchOptions={fetchRams}
            />
          </Form.Item>
        </Space>
        <div>{ramMessage1 && <Alert message={ramMessage1} type={ramError1 === 'error' ? 'error' : 'success'} showIcon />}</div>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_ram')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultRam1" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="ramTest1"
            onChange={(e) =>
              e.target.value === 'passed'
                ? setCheckRam1(true)
                : (setCheckRam1(false), cleanFieldsNotPassed(['ramSerialNumber1', 'ramSize1'], setRamMessage1('')))
            }
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="RAM 2" span={2}>
        <Space>
          <Form.Item
            hasFeedback
            name="ramSerialNumber2"
            rules={[
              {
                validator: async (_, value) =>
                  (await validateRamSerialNumber2(value)) ? Promise.resolve() : !checkRam2 ? Promise.resolve() : Promise.reject(),
              },
            ]}
            validateTrigger="onBlur"
            validateStatus={ramError2}
          >
            <Input
              onBlur={(e) => {
                const serialNumberTrimmed = e.target.value.trim().toUpperCase();

                const serialNumberFormatted =
                  serialNumberTrimmed?.substring(0, 4) === 'P/N:'
                    ? serialNumberTrimmed?.substring((serialNumberTrimmed?.indexOf('S/N:') || 0) + 4)
                    : serialNumberTrimmed;
                form.setFieldsValue({ ramSerialNumber2: serialNumberFormatted });
              }}
              placeholder="Ram Serial Number"
              allowClear
              disabled={!checkRam2 || itemDisabled}
            />
          </Form.Item>
          <Form.Item name="ramSize2" rules={[{ required: checkRam2, message: 'RAM size is required' }]}>
            <DebounceSelect
              allowClear
              showSearch
              disabled={!checkRam2 || itemDisabled}
              style={{ width: 200 }}
              placeholder="RAM Size"
              optionFilterProp="label"
              fetchOptions={fetchRams}
            />
          </Form.Item>
        </Space>
        <div>{ramMessage2 && <Alert message={ramMessage2} type={ramError2 === 'error' ? 'error' : 'success'} showIcon />}</div>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_ram')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultRam2" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="ramTest"
            onChange={(e) =>
              e.target.value === 'passed'
                ? setCheckRam2(true)
                : (setCheckRam2(false), cleanFieldsNotPassed(['ramSerialNumber2', 'ramSize2'], setRamMessage2('')))
            }
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Diagnostic Utility" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_diagnostic_utility')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultDiagnosticUtility" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="CPU / Motherboard" span={2}>
        <Space>
          <Form.Item name="cpuModel" rules={[{ required: checkCpu, message: 'CPU is required' }]}>
            <DebounceSelect
              showSearch
              allowClear
              disabled={!checkCpu || itemDisabled}
              style={{ width: 240 }}
              placeholder="CPU"
              optionFilterProp="label"
              fetchOptions={fetchCpus}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_cpu')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultCPU" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            disabled={itemDisabled}
            onChange={(e) => (e.target.value === 'passed' ? setCheckCpu(true) : (setCheckCpu(false), cleanFieldsNotPassed(['cpuModel'])))}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            {![1, 2, 3].includes(itemInfo.item.category?.id) && <Radio.Button value="na">N/A</Radio.Button>}
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Bios Password" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="biosPassword" rules={[{ required: true }]}>
            <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
              <Radio.Button value="no">No</Radio.Button>
              <Radio.Button value="yes">Yes</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ biosPassword: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'bios_password')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      />

      <Descriptions.Item label="Graphics Card" span={2}>
        <Space>
          <Form.Item name="graphicsCard" validateTrigger="onSubmit" rules={[{ required: checkGraphicsCard, message: 'Graphics Card field is required' }]}>
            <Input placeholder="Graphics Card" allowClear disabled={!checkGraphicsCard || itemDisabled} />
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_graphics_card')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultGraphicsCard" rules={[{ required: true, message: 'Graphics Card result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="graphicsCardTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckGraphicsCard(true) : (setCheckGraphicsCard(false), cleanFieldsNotPassed(['graphicsCard'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Dedicated GPU" span={2}>
        <Space>
          <Form.Item
            name="dedicatedGpu"
            validateTrigger="onSubmit"
            rules={[{ required: checkDedicatedGPU, message: 'Dedicated Graphics Card field is required' }]}
          >
            <Input placeholder="Dedicated Graphics Card" allowClear disabled={!checkDedicatedGPU || itemDisabled} />
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_dedicated_gpu')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultDedicatedGpu" rules={[{ required: true, message: 'Dedicated Graphics Card result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="dedicatedGpuTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckDedicatedGPU(true) : (setCheckDedicatedGPU(false), cleanFieldsNotPassed(['dedicatedGpu'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="LCD Screen" span={2} />
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_lcd_screen')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultLcdScreen" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" name="lcdScreenTest" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Touchscreen" span={2}>
        <Space align="baseline">
          <Form.Item name="screenType" rules={[{ required: checkScreen }]}>
            <Radio.Group buttonStyle="solid" options={screenOptions} optionType="button" disabled={!checkScreen || itemDisabled} />
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ screenType: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_screen')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultScreen" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="screenTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckScreen(true) : (setCheckScreen(false), cleanFieldsNotPassed(['screenType'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Stylus" span={2}>
        <Space align="baseline">
          <Form.Item name="stylusType" rules={[{ required: checkStylus }]}>
            <Radio.Group buttonStyle="solid" options={stylusOptions} optionType="button" disabled={!checkStylus || itemDisabled} />
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ stylusType: null })} />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_stylus')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultStylus" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="stylusTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckStylus(true) : (setCheckStylus(false), cleanFieldsNotPassed(['stylusType'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      {itemInfo.item.category?.id !== 1 && itemInfo.item.category?.id !== 3 && (
        <>
          <Descriptions.Item label="Mouse pad" span={2}>
            <Space />
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <Popover
                content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_mousepad')?.instructions }} />}
                title="Instructions"
                trigger="click"
              >
                <Button type="link">Instructions</Button>
              </Popover>
            }
          >
            <Form.Item hasFeedback name="resultMousePad" rules={[{ required: true, message: 'Result must be selected' }]}>
              <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
                <Radio.Button value="passed">Passed</Radio.Button>
                <Radio.Button value="failed">Failed</Radio.Button>
                <Radio.Button value="na">N/A</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item label="USB Ports" span={2}>
        <Space>
          <Tooltip placement="top" title="Quantity of ports">
            <Form.Item name="usbPorts" rules={[{ required: checkUsb, message: 'USB Ports field is required' }]}>
              <InputNumber min={0} max={20} placeholder="# ports" disabled={!checkUsb || itemDisabled} />
            </Form.Item>
          </Tooltip>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_usb')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultUsb" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="usbTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckUsb(true) : (setCheckUsb(false), cleanFieldsNotPassed(['usbPorts'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="WLAN" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_wlan')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultWlan" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Bluetooth" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_bluetooth')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultBluetooth" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Audio" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_audio')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultAudio" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      {itemInfo.item.category?.id !== 1 && itemInfo.item.category?.id !== 3 && (
        <>
          <Descriptions.Item label="Keyboard" span={2}>
            <Space align="baseline">
              <Form.Item name="keyboardType" rules={[{ required: checkKeyboard }]}>
                <Radio.Group buttonStyle="solid" options={keyboardOptions} optionType="button" disabled={!checkKeyboard || itemDisabled} />
              </Form.Item>
              <Form.Item name="keyboardLanguage" rules={[{ required: checkKeyboard }]}>
                <Radio.Group buttonStyle="solid" options={keyboardLanguageOptions} optionType="button" disabled={!checkKeyboard || itemDisabled} />
              </Form.Item>
              <Button
                type="text"
                danger
                icon={<CloseSquareOutlined />}
                size="small"
                onClick={() => form.setFieldsValue({ keyboardType: null, keyboardLanguage: null })}
              />
            </Space>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Popover
                content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_keyboard')?.instructions }} />}
                title="Instructions"
                trigger="click"
              >
                <Button type="link">Instructions</Button>
              </Popover>
            }
          >
            <Form.Item hasFeedback name="resultKeyboard" rules={[{ required: true, message: 'Result must be selected' }]}>
              <Radio.Group
                buttonStyle="solid"
                name="keyboardTest"
                onChange={(e) =>
                  e.target.value === 'passed' ? setCheckKeyboard(true) : (setCheckKeyboard(false), cleanFieldsNotPassed(['keyboardType', 'keyboardLanguage']))
                }
                disabled={itemDisabled}
              >
                <Radio.Button value="passed">Passed</Radio.Button>
                <Radio.Button value="failed">Failed</Radio.Button>
                <Radio.Button value="na">N/A</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item label="Other Ports" span={2}>
        <Space>
          <Tooltip placement="top" title="Other types of ports">
            <Form.Item name="keyboardPorts" rules={[{ required: checkKeyboardPorts, message: 'Other ports field is required.' }]}>
              <Select
                mode="multiple"
                allowClear
                placeholder="Select the ports."
                options={keyboardPortOptions}
                disabled={!checkKeyboardPorts || itemDisabled}
                style={{ width: 200 }}
              />
            </Form.Item>
          </Tooltip>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_keyboard_ports')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultKeyboardPorts" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="KeyboardPortTest"
            onChange={(e) =>
              e.target.value === 'passed' ? setCheckKeyboardPorts(true) : (setCheckKeyboardPorts(false), cleanFieldsNotPassed(['keyboardPorts']))
            }
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      {itemInfo.item.battery_type?.split(',').includes('internal') && (
        <>
          <Descriptions.Item label="Internal Battery" span={2}>
            <Space>
              <Form.Item
                hasFeedback
                name="batterySerialNumber"
                rules={[
                  { required: false },
                  {
                    validator: async (_rule, value) => {
                      if (!value) return Promise.resolve();

                      return handleBatterySerialNumberValidation(value, setBatteryFile, setBatteryUploadDisabled, '', itemInfo, profile, form);
                    },
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="Battery Serial Number" allowClear disabled={!checkBattery || itemDisabled} />
              </Form.Item>

              <Form.Item hidden name="batteryPartNumber">
                <Input disabled placeholder="Part Number" />
              </Form.Item>

              <Form.Item name="batteryItemName" rules={[{ required: checkBattery, message: 'Battery Part Number is required' }]}>
                <DebounceSelect
                  showSearch
                  allowClear
                  disabled={!checkBattery || itemDisabled}
                  style={{ width: 240 }}
                  placeholder="Part Number"
                  optionFilterProp="label"
                  fetchOptions={fetchBatteries}
                />
              </Form.Item>

              <Form.Item name="batteryPercentage" rules={[{ required: checkBattery }]}>
                <InputNumber
                  disabled={!checkBattery || itemDisabled}
                  placeholder="Health"
                  min={0}
                  max={100}
                  formatter={(value) => (value === '' ? '' : `${value}%`)}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>

              <Form.Item name="batteryFile" rules={[{ required: !batteryUploadDisabled && checkBattery, message: 'Battery File Evidence is required' }]}>
                <Upload
                  onChange={(el) =>
                    handleChangeBatteryFile(
                      el,
                      form.getFieldValue('batterySerialNumber'),
                      form.getFieldValue('batteryPercentage'),
                      setBatteryFile,
                      'batteryFile'
                    )
                  }
                  showUploadList={{ showRemoveIcon: false }}
                  multiple={false}
                  fileList={batteryFile}
                  disabled={!checkBattery || itemDisabled}
                  customRequest={({ onSuccess }) => onSuccess('ok')}
                >
                  <Button icon={<UploadOutlined />} disabled={batteryUploadDisabled}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Popover
                content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_battery')?.instructions }} />}
                title="Instructions"
                trigger="click"
              >
                <Button type="link">Instructions</Button>
              </Popover>
            }
          >
            <Form.Item hasFeedback name="resultBattery" rules={[{ required: true, message: 'Result must be selected' }]}>
              <Radio.Group
                buttonStyle="solid"
                name="batteryTest"
                onChange={(e) =>
                  e.target.value === 'passed'
                    ? setCheckBattery(true)
                    : (setCheckBattery(false), cleanFieldsNotPassed(['batteryPercentage', 'batterySerialNumber', 'batteryPartNumber', 'batteryFile']))
                }
                disabled={itemDisabled}
              >
                <Radio.Button value="passed">Passed</Radio.Button>
                <Radio.Button value="failed">Failed</Radio.Button>
                {/* <Radio.Button value="na">N/A</Radio.Button> */}
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
        </>
      )}

      {itemInfo.item.battery_type?.split(',').includes('removable') && (
        <>
          <Descriptions.Item label="Removable Battery" span={2}>
            <Space>
              <Form.Item
                hasFeedback
                name="batterySerialNumber2"
                rules={[
                  { required: checkBattery2 },
                  {
                    validator: async (_rule, value) => {
                      if (!value) return Promise.resolve();

                      return handleBatterySerialNumberValidation(value, setBatteryFile2, setBatteryUploadDisabled2, '2', itemInfo, profile, form);
                    },
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="Battery Serial Number" allowClear disabled={!checkBattery2 || itemDisabled} />
              </Form.Item>

              <Form.Item hidden name="batteryPartNumber2">
                <Input placeholder="Part Number" />
              </Form.Item>

              <Form.Item name="batteryItemName2" rules={[{ required: checkBattery2, message: 'Battery Part Number is required' }]}>
                <Input disabled placeholder="Part Number" />
              </Form.Item>

              <Form.Item name="batteryPercentage2" rules={[{ required: checkBattery2 }]}>
                <InputNumber
                  disabled={!checkBattery2 || itemDisabled}
                  placeholder="Health"
                  min={0}
                  max={100}
                  formatter={(value) => (value === '' ? '' : `${value}%`)}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
              {form.getFieldValue('batterySerialNumber2') !== '' && (
                <Form.Item name="batteryFile2" rules={[{ required: !batteryUploadDisabled2 && checkBattery2, message: 'Battery File Evidence is required' }]}>
                  <Upload
                    onChange={(el) =>
                      handleChangeBatteryFile(
                        el,
                        form.getFieldValue('batterySerialNumber2'),
                        form.getFieldValue('batteryPercentage2'),
                        setBatteryFile2,
                        'batteryFile2'
                      )
                    }
                    showUploadList={{ showRemoveIcon: false }}
                    multiple={false}
                    fileList={batteryFile2}
                    disabled={!checkBattery2 || itemDisabled}
                    customRequest={({ onSuccess }) => onSuccess('ok')}
                  >
                    <Button icon={<UploadOutlined />} disabled={batteryUploadDisabled2}>
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Popover
                content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_battery')?.instructions }} />}
                title="Instructions"
                trigger="click"
              >
                <Button type="link">Instructions</Button>
              </Popover>
            }
          >
            <Form.Item hasFeedback name="resultBattery2" rules={[{ required: true, message: 'Result must be selected' }]}>
              <Radio.Group
                buttonStyle="solid"
                name="batteryTest2"
                onChange={(e) =>
                  e.target.value === 'passed'
                    ? setCheckBattery2(() => true)
                    : (setCheckBattery2(() => false),
                      cleanFieldsNotPassed(['batteryPercentage2', 'batterySerialNumber2', 'batteryPartNumber2', 'batteryFile2']))
                }
                disabled={itemDisabled}
              >
                <Radio.Button value="passed">Passed</Radio.Button>
                <Radio.Button value="failed">Failed</Radio.Button>
                <Radio.Button value="na">N/A</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
        </>
      )}

      {itemInfo.item.battery_type?.split(',').includes('removable2') && (
        <>
          <Descriptions.Item label="2nd Removable Battery" span={2}>
            <Space>
              <Form.Item
                hasFeedback
                name="batterySerialNumber3"
                rules={[
                  { required: checkBattery3 },
                  {
                    validator: async (_rule, value) => {
                      if (!value) return Promise.resolve();

                      return handleBatterySerialNumberValidation(value, setBatteryFile3, setBatteryUploadDisabled3, '3', itemInfo, profile, form);
                    },
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="Battery Serial Number" allowClear disabled={!checkBattery3 || itemDisabled} />
              </Form.Item>

              <Form.Item hidden name="batteryPartNumber3">
                <Input disabled placeholder="Part Number" />
              </Form.Item>

              <Form.Item name="batteryItemName3">
                <Input disabled placeholder="Part Number" />
              </Form.Item>

              <Form.Item name="batteryPercentage3" rules={[{ required: checkBattery3 }]}>
                <InputNumber
                  disabled={!checkBattery3 || itemDisabled}
                  placeholder="Health"
                  min={0}
                  max={100}
                  formatter={(value) => (value === '' ? '' : `${value}%`)}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
              {form.getFieldValue('batterySerialNumber3') !== '' && (
                <Form.Item name="batteryFile3" rules={[{ required: !batteryUploadDisabled3 && checkBattery3, message: 'Battery File Evidence is required' }]}>
                  <Upload
                    onChange={(el) =>
                      handleChangeBatteryFile(
                        el,
                        form.getFieldValue('batterySerialNumber3'),
                        form.getFieldValue('batteryPercentage3'),
                        setBatteryFile3,
                        'batteryFile3'
                      )
                    }
                    multiple={false}
                    fileList={batteryFile3}
                    showUploadList={{ showRemoveIcon: false }}
                    disabled={!checkBattery3 || itemDisabled}
                    customRequest={({ onSuccess }) => onSuccess('ok')}
                  >
                    <Button icon={<UploadOutlined />} disabled={batteryUploadDisabled3}>
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Popover
                content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_battery')?.instructions }} />}
                title="Instructions"
                trigger="click"
              >
                <Button type="link">Instructions</Button>
              </Popover>
            }
          >
            <Form.Item hasFeedback name="resultBattery3" rules={[{ required: true, message: 'Result must be selected' }]}>
              <Radio.Group
                buttonStyle="solid"
                name="batteryTest3"
                onChange={(e) =>
                  e.target.value === 'passed'
                    ? setCheckBattery3(true)
                    : (setCheckBattery3(false), cleanFieldsNotPassed(['batteryPercentage3', 'batterySerialNumber3', 'batteryPartNumber3', 'batteryFile3']))
                }
                disabled={itemDisabled}
              >
                <Radio.Button value="passed">Passed</Radio.Button>
                <Radio.Button value="failed">Failed</Radio.Button>
                <Radio.Button value="na">N/A</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item label="AC Adapter" span={2}>
        <Space align="baseline">
          <Form.Item hasFeedback name="acAdapter" rules={[{ required: checkAcAdapter }]}>
            <Radio.Group buttonStyle="solid" disabled={!checkAcAdapter || itemDisabled}>
              <Radio.Button value="compatible">Compatible</Radio.Button>
              <Radio.Button value="used">Used OEM</Radio.Button>
              <Radio.Button value="new">New OEM</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ acAdapter: null })} />
          <Tooltip placement="top" title="AMP">
            <Form.Item hasFeedback name="acAdapterAmp" rules={[{ required: checkAcAdapter }]}>
              <InputNumber min={0} max={10} step={0.1} placeholder="AMP" disabled={!checkAcAdapter || itemDisabled} />
            </Form.Item>
          </Tooltip>
          <Form.Item name="acAdapterPartNumber" rules={[{ required: checkAcAdapter, message: 'Ac Adapter Part Number is required' }]}>
            <DebounceSelect
              showSearch
              allowClear
              style={{ width: 220 }}
              placeholder="Part Number"
              optionFilterProp="label"
              fetchOptions={(el) => el && fetchItems(el, true, null, [12])}
              disabled={!checkAcAdapter || itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'cosmetics_ac_adapter')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultAcAdapter" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="acAdapterTest"
            onChange={(e) =>
              e.target.value === 'passed'
                ? setCheckAcAdapter(true)
                : (setCheckAcAdapter(false), cleanFieldsNotPassed(['acAdapter', 'acAdapterAmp', 'acAdapterPartNumber']))
            }
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Optical Drive" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_optical_drive')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultOpticalDrive" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="WWAN" span={2}>
        <Space>
          <Form.Item hasFeedback name="wwanModel" rules={[{ required: checkWwanModel }]}>
            <Input placeholder="WWAN card model" allowClear disabled={!checkWwanModel || itemDisabled} />
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_wwan')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultWwan" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => (e.target.value === 'passed' ? setCheckWwanModel(true) : (setCheckWwanModel(false), cleanFieldsNotPassed(['wwanModel'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="GPS" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_gps')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultGps" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Camera(s)" span={2}>
        <Space align="baseline">
          <Form.Item name="cameraType" rules={[{ required: checkCamera }]}>
            <Radio.Group buttonStyle="solid" options={cameraOptions} optionType="button" disabled={!checkCamera || itemDisabled} />
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ cameraType: null })} />
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_camera')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultCamera" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="cameraTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckCamera(true) : (setCheckCamera(false), cleanFieldsNotPassed(['cameraType'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Face Recognition" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_face_recognition')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultFaceRecognition" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="RFID" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_rfid')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultRfid" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Fingerprint" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_fingerprint')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultFingerprint" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Smart Card" span={2}>
        <Space align="baseline">
          <Form.Item name="smartCardType" rules={[{ required: checkSmartCard }]}>
            <Radio.Group buttonStyle="solid" options={smartCardOptions} optionType="button" disabled={!checkSmartCard || itemDisabled} />
          </Form.Item>
          <Button type="text" danger icon={<CloseSquareOutlined />} size="small" onClick={() => form.setFieldsValue({ smartCardType: null })} />
        </Space>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_smartcard')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultSmartCard" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            disabled={itemDisabled}
            onChange={(e) => (e.target.value === 'passed' ? setCheckSmartCard(true) : (setCheckSmartCard(false), cleanFieldsNotPassed(['smartCardType'])))}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Barcode Reader" span={2}>
        <Space />
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <Popover
            content={<div dangerouslySetInnerHTML={{ __html: instructions.find((el) => el.field === 'function_barcode_reader')?.instructions }} />}
            title="Instructions"
            trigger="click"
          >
            <Button type="link">Instructions</Button>
          </Popover>
        }
      >
        <Form.Item hasFeedback name="resultBarcodeReader" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group buttonStyle="solid" disabled={itemDisabled}>
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>
    </Descriptions>
  );
}
