import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';
import { updateProfileRequest } from '~/store/modules/user/actions';

// import AvatarInput from './AvatarInput';

import { Container } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  function handleSubmit(data) {
    dispatch(updateProfileRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Form initialData={profile} onSubmit={handleSubmit}>
        {/* <AvatarInput name="avatar_id" /> */}
        Name
        <Input name="name" placeholder="Full Name" />
        E-mail
        <Input name="email" placeholder="e-mail address" disabled />
        <hr />
        Current password
        <Input type="password" name="oldPassword" placeholder="Current Password" />
        New password
        <Input type="password" name="password" placeholder="New Password" />
        Confirm new password
        <Input type="password" name="confirmPassword" placeholder="Confirm Password" />
        <button type="submit">Update profile</button>
      </Form>
      <button type="button" onClick={handleSignOut}>
        Sign Out
      </button>
    </Container>
  );
}
