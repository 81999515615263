import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function Listings() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function loadItems() {
      const response = await api.get('listings');
      setItems(response.data);
    }
    loadItems();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'item.sku',
      label: 'Base SKU',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'variation_sku',
      label: 'Variation SKU',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'item.name',
      label: 'Title',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'storage.capacity',
      label: 'Storage Cap',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'storage.unit',
      label: 'Storage Un',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'storage.type',
      label: 'Storage Type',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'ram.capacity',
      label: 'RAM Cap',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ram.type',
      label: 'RAM Type',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'operatingSystem.name',
      label: 'OS',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'price_usd_wholesale',
      label: 'Price USD Wholesale',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'price_usd_wholesale',
      label: 'Price USD Wholesale',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'price_cad',
      label: 'Price CAD',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'stylus',
      label: 'Stylus',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'bluetooth',
      label: 'Bluetooth',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'wireless_lan',
      label: 'Wifi',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'battery',
      label: 'Battery',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ac_adapter',
      label: 'AC Adapter',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'grade',
      label: 'Grade',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  function updateItem(rowData) {
    history.push(`/listingsEdit/${rowData[0]}`);
  }

  async function deleteItem(rowsDeleted) {
    await api.delete(`listings/${items[rowsDeleted.data[0].dataIndex].id}`);
    items.splice(rowsDeleted.data[0].dataIndex, 1);
  }

  const options = {
    fixedHeaderOptions: { xAxis: true, yAxis: true },
    filterType: 'checkbox',
    expandableRowsOnClick: true,
    pagination: false,
    responsive: 'scrollFullHeight',

    selectableRowsOnClick: false,
    onRowClick: (rowData) => updateItem(rowData),
    onRowsDelete: (rowsDeleted) => deleteItem(rowsDeleted),
  };

  return (
    <Container>
      {/* <Button>
        <Link to="/listingsNew">
          <CardActions>
            <Button color="primary" variant="contained">
              New Catalog Variation
            </Button>
          </CardActions>
        </Link>
      </Button> */}
      <Link to="/listingsNew">
        <Button variant="contained" color="primary" startIcon={<AddIcon />}>
          Add
        </Button>
      </Link>

      <MUIDataTable title="Catalog" data={items} columns={columns} options={options} />
    </Container>
  );
}
