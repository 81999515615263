import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import { CardActions, Button } from '@material-ui/core';
import { Container } from './styles';

import api from '~/services/api';
import history from '~/services/history';

export default function Item() {
  const [branches, setBranches] = useState([]);
  // const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    async function loadBranches() {
      const response = await api.get('branches');
      setBranches(response.data);
    }
    loadBranches();
  }, []);

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address1',
      label: 'Address',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'zip_code',
      label: 'Postal Code',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  function updateItem(rowData) {
    history.push(`/branchesEdit/${rowData[5]}`);
  }

  async function deleteBranch(rowsDeleted) {
    await api.delete(`branches/${branches[rowsDeleted.data[0].dataIndex].zip_code}`);
    branches.splice(rowsDeleted.data[0].dataIndex, 1);
  }

  const options = {
    fixedHeaderOptions: { xAxis: true, yAxis: true },
    filterType: 'checkbox',
    expandableRowsOnClick: true,
    pagination: false,
    responsive: 'scrollFullHeight',

    selectableRowsOnClick: false,
    onRowClick: rowData => updateItem(rowData),
    onRowsDelete: rowsDeleted => deleteBranch(rowsDeleted),
    customToolbarSelect: () => {},
  };

  return (
    <Container>
      {/* <Button> */}
      <Link to="/branchesNew">
        <CardActions>
          <Button color="primary" variant="contained">
            New Branch
          </Button>
        </CardActions>
      </Link>
      {/* </Button> */}

      <MUIDataTable title="Branches" data={branches} columns={columns} options={options} />
    </Container>
  );
}
