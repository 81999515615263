/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const componentSize = {
  small: {
    'font-size': `12px;`,
    'line-height': `1.1;`,
    'font-weight': `200;`,
  },
  normal: {
    'font-size': `14px;`,
    'line-height': '1.3;',
    'font-weight': '300;',
  },
  large: {
    'font-size': `16px;`,
    'line-height': `1.5;`,
    'font-weight': `400;`,
  },
};

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: gray;
  /* border: ${(props) => props.theme['gray-100']}; */
  /* box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15); */

  overflow: hidden;
  border-radius: 4px;

  thead tr th:first-child,
  tbody tr td:first-child {
    padding-left: 40px;
  }

  thead {
    // font-weight: 400;
    font-weight: ${({ size }) => componentSize[size]['font-weight']};
    // line-height: 1.5;
    line-height: ${({ size }) => componentSize[size]['line-height']};

    th {
      font-size: ${({ size }) => componentSize[size]['font-size']};
      text-align: left;
      color: ${(props) => props.theme.white};
      /* line-height: 1.4; */
      background-color: ${(props) => props.theme['blue-400']};
      border: 1px solid ${(props) => props.theme['gray-100']};
      padding: 8px 4px;
    }
  }

  tbody {
    border-color: inherit;
    font-size: ${({ size }) => componentSize[size]['font-size']};

    tr:nth-child(even) {
      background: ${(props) => props.theme['gray-100']};
    }

    tr:hover {
      background: ${(props) => props.theme['gray-200']};
    }

    td {
      font-weight: ${({ size }) => componentSize[size]['font-weight']};
      color: gray;
      line-height: ${({ size }) => componentSize[size]['line-height']};
      border: 1px solid ${(props) => props.theme['gray-100']};
      padding: 8px 4px;
    }
  }
`;

StyledTable.defaultProps = {
  size: 'normal',
};
