/* eslint-disable func-names */
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
// import Table from '~/components/Table';
import api from '~/services/api';

export function UnitsByTechnicians() {
  const [unitsByTech, setUnitsByTech] = useState([]);

  useEffect(() => {
    async function getUnitInfo() {
      const techList = await api.put('report/UnitsByTechnician');

      setUnitsByTech(techList.data.unitQuantityByUserList);
    }
    getUnitInfo();
  }, []);

  const columns = [
    {
      title: 'User',
      dataIndex: 'name',
    },
    {
      title: 'Quantity of units',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      defaultSortOrder: 'descend',
    },
  ];

  // const columns = [
  //   {
  //     name: 'User',
  //     dataIndex: 'name',
  //   },
  //   {
  //     name: 'Quantity of units',
  //     dataIndex: 'quantity',
  //     sorter: (a, b) => a.quantity - b.quantity,
  //     defaultSortOrder: 'descend',
  //   },
  // ];

  // return <Table columns={columns} data={unitsByTech} rowKey="name" />;
  return <Table columns={columns} dataSource={unitsByTech} rowKey="name" pagination={false} style={{ maxWidth: '500px' }} />;
}
