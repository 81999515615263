export function handleItemResults(values, inventoryItemsId) {
  return {
    biosPassword: values.biosPassword,
    resultAcAdapter: values.resultAcAdapter,
    resultCosmeticsBattery: values.resultCosmeticsBattery,
    cleaned: values.cleaned === 'yes',
    resultCovers: values.resultCovers,
    cracks: values.cracks,
    dentsScratches: values.dentsScratches,
    discoloration: values.discoloration,
    labelCondition: values.labelCondition,
    latchesHingesDamaged: values.latchesHingesDamaged === 'yes',
    missingParts: values.missingParts === 'yes',
    packaging: values.packaging,
    resultCosmeticsScreen: values.resultCosmeticsScreen,
    resultScrews: values.resultScrews,
    resultStylus: values.resultStylus,
    resultTetherStylus: values.resultTetherStylus,
    visualAppearance: values.visualAppearance,
    resultAudio: values.resultAudio,
    resultBarcodeReader: values.resultBarcodeReader,
    resultBattery: values.resultBattery,
    resultBattery2: values.resultBattery2,
    resultBattery3: values.resultBattery3,
    resultBluetooth: values.resultBluetooth,
    resultCamera: values.resultCamera,
    resultCfc2BiosUpdate: values.resultCfc2BiosUpdate,
    resultCoaReport: values.resultCoaReport,
    resultCPU: values.resultCPU,
    resultDedicatedGpu: values.resultDedicatedGpu,
    resultDiagnosticUtility: values.resultDiagnosticUtility,
    resultDriversInstalled: values.resultDriversInstalled,
    resultFaceRecognition: values.resultFaceRecognition,
    resultFingerprint: values.resultFingerprint,
    resultGps: values.resultGps,
    resultGraphicsCard: values.resultGraphicsCard,
    resultKeyboardPorts: values.resultKeyboardPorts,
    resultKeyboard: values.resultKeyboard,
    resultLcdScreen: values.resultLcdScreen,
    resultMousePad: values.resultMousePad,
    resultOperatingSystem: values.resultOperatingSystem,
    resultOpticalDrive: values.resultOpticalDrive,
    resultRam2: values.resultRam2,
    resultRam1: values.resultRam1,
    resultRecoveryMedia: values.resultRecoveryMedia,
    resultRfid: values.resultRfid,
    resultScreen: values.resultScreen,
    resultSmartCard: values.resultSmartCard,
    resultStorage2: values.resultStorage2,
    resultStorageExtended: values.resultStorageExtended === 'passed',
    resultStorage1: values.resultStorage1,
    resultUsb: values.resultUsb,
    resultWindowsActivation: values.resultWindowsActivation === 'passed',
    resultWlan: values.resultWlan,
    resultWwan: values.resultWwan,
    inventory_items_id: inventoryItemsId,
    resultBatteryReport: values.resultBatteryReport,
    resultBatteryReport2: values.resultBatteryReport2,
    resultBatteryReport3: values.resultBatteryReport3,
    resultWipingReport: values.resultWipingReport,
  };
}