import axios from 'axios';

import { signOut } from '../store/modules/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.registerInterceptWithStore = store => {
  api.interceptors.response.use(
    api.interceptors.response.use(
      response => response,
      err => {
        // if (err.response.status === 400 || err.response.status === 401) {
        if (err.response?.status === 400) {
          store.dispatch(signOut());
        }
        return Promise.reject(err);
      }
    )
  );
};

export default api;
