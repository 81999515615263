import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Table, Tooltip } from 'antd';

import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

export default function InventoryExport() {
  const classes = useStyles();
  const [form] = Form.useForm();

  const [itemsToTransfer, setItemsToTransfer] = useState([]);

  const destinationRef = useRef('');
  const itemToAddRef = useRef('');
  const transferorRef = useRef('');
  const receiverRef = useRef('');

  const handleRemoveItem = async serialNumber => {
    try {
      setItemsToTransfer(itemsToTransfer.filter(el => el.serialNumber !== serialNumber));
    } catch (error) {
      toast.error('Could not remove this item. Contact RBMS admin');
    }
  };

  const addItem = useCallback(
    async serialNumber => {
      try {
        setItemsToTransfer([...itemsToTransfer, { serialNumber, name: serialNumber, description: serialNumber }]);

        form.setFieldsValue({ itemToAdd: '' });
      } catch (error) {
        toast.error(error);
      }
    },
    [form, itemsToTransfer]
  );

  const destinationList = [
    { label: 'Warehouse', value: 'warehouse' },
    { label: 'Processing', value: 'processing' },
    { label: 'Refurbishing', value: 'refurbishing' },
    { label: 'Cleaning', value: 'cleaning' },
    { label: 'Sales', value: 'sales' },
    { label: 'E-commerce', value: 'e-commerce' },
  ];

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, row, index) => index + 1,
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, row) => (
        <button type="button" className="button-error pure-button" onClick={() => handleRemoveItem(row.serialNumber)}>
          Remove
        </button>
      ),
    },
  ];

  return (
    <Container>
      <Paper className={classes.paper}>
        <h1>Inventory Transfer</h1>

        <Form
          form={form}
          style={{ maxWidth: '350px' }}
          name="basic"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            label="Destination"
            name="destination"
            rules={[
              {
                required: true,
                message: 'Item is required',
              },
            ]}
          >
            <Select
              ref={destinationRef}
              autoFocus
              showSearch
              style={{ width: 200 }}
              placeholder="Select a destination"
              optionFilterProp="children"
              filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              options={destinationList}
            />
          </Form.Item>

          <Form.Item
            label="Transferor"
            name="transferor"
            rules={[
              {
                required: true,
                message: 'Transferor is required',
              },
            ]}
          >
            <Input ref={transferorRef} onKeyPress={e => e.key === 'Enter' && receiverRef.current.focus()} />
          </Form.Item>

          <Form.Item
            label="Receiver"
            name="receiver"
            rules={[
              {
                required: true,
                message: 'receiver is required',
              },
            ]}
          >
            <Input ref={receiverRef} onKeyPress={e => e.key === 'Enter' && itemToAddRef.current.focus()} />
          </Form.Item>

          <Form.Item label="Item to transfer" name="itemToAdd">
            <Input
              ref={itemToAddRef}
              onKeyPress={e => e.key === 'Enter' && addItem(form.getFieldValue('itemToAdd'))}
              placeholder="Item to be transferred"
              suffix={
                <Tooltip title="Add item to be transferred">
                  <PlusOutlined style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }} />
                </Tooltip>
              }
            />
          </Form.Item>

          {itemsToTransfer.length > 0 && <Table columns={columns} dataSource={itemsToTransfer} bordered size="small" rowKey={record => record.item} />}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm transfer
            </Button>
          </Form.Item>
        </Form>
      </Paper>
    </Container>
  );
}
