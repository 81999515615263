export const reserveTypeOptions = [
  {
    value: 'InternalAsset',
    label: 'Internal Asset',
  },
  {
    value: 'Gift',
    label: 'Gift',
  },
  {
    value: 'Allocated',
    label: 'Allocated',
  },
];