import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
// import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable from 'mui-datatables';

import { Alert, Empty, Spin, Typography } from 'antd';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

// import Datatable from './Components/Datatable';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '30%',
    left: '20%',
  },
  textField: {
    backgroundColor: '#fff',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  actions: {
    margin: theme.spacing(0.8, 0),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  search: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function Item(props) {
  const classes = useStyles();
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(false);

  const [searchTxt, setSearchTxt] = useState('');
  const inputSearch = useRef(searchTxt);

  const { Paragraph } = Typography;

  async function loadItems(value) {
    setLoading(true);
    const response = await api.get(`items?search=${encodeURIComponent(value)}`);
    if (response.data.error) {
      toast.warn('Item has not been found!');
      return;
    }
    setLoading(false);
    setItems(response.data);
  }

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    if (searchQuery) {
      setSearchTxt(searchQuery);
      inputSearch.current.value = searchQuery;
      loadItems(searchQuery);
    }
  }, []); //eslint-disable-line

  const handleSearch = useCallback((value) => {
    if (value.length < 3) {
      toast.warn('Use at least 3 characters to search');
      return;
    }
    loadItems(value);
    setSearchTxt(value);
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'brand.name',
      label: 'Brand',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category.name',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'part_number',
      label: 'Part Number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <Link
            to={() => {
              const id = tableMeta.tableData.find((el) => el[3] === value || el.part_number === value);
              if (searchTxt === null || searchTxt === undefined) {
                return `itemNew/${id[0] || id.id}`;
              }
              return `itemNew/${id[0] || id.id}?id=${id[0] || id.id}&searchQuery=${searchTxt}`;
            }}
          >
            <Paragraph style={{ color: '#1890ff' }} copyable>
              {value}
            </Paragraph>
          </Link>
        ),
      },
    },
    {
      name: 'sku',
      label: 'SKU',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cpu.name',
      label: 'CPU',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'screensize.name',
      label: 'Screen Size',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'screentype.name',
      label: 'Screen Type',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  // async function deleteItem(rowsDeleted) {
  //   await api.delete(`items/${items[rowsDeleted.data[0].dataIndex].id}`);
  //   items.splice(rowsDeleted.data[0].dataIndex, 1);
  // }

  // function updateItem(rowData) {
  //   history.push(`/itemNew/${rowData[0]}?id=${rowData[0]}&searchQuery=${searchTxt}`);
  // }

  const options = {
    fixedHeaderOptions: { xAxis: true, yAxis: true },
    filterType: 'dropdown',
    // expandableRowsOnClick: true,
    pagination: false,
    responsive: 'scrollFullHeight',

    customToolbarSelect: () => {},

    checkboxSelection: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    // onRowClick: rowData => updateItem(rowData),
    // onRowsDelete: rowsDeleted => deleteItem(rowsDeleted),
  };

  return (
    <Container>
      <h1>Items</h1>
      <div>
        {/* <Button onClick={() => history.push(searchTxt === '' ? `/itemNew` : `/itemNew?searchQuery=${searchTxt}`)} variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}> */}
        <Button onClick={() => history.push('/itemNew')} variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}>
          Add
        </Button>
      </div>

      <div className={classes.actions}>
        <Paper elevation={3}>
          <TextField
            label="Search"
            id="outlined-margin-dense"
            className={classes.textField}
            margin="dense"
            variant="outlined"
            inputRef={inputSearch}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearch(e.target.value);
              }
            }}
            InputProps={{
              endAdornment: <Search className={classes.search} onClick={() => handleSearch(inputSearch.current.value)} />,
            }}
          />
        </Paper>
      </div>
      {loading ? (
        <Spin tip="Loading...">
          <Alert message="Loading items data" description="Once all items are loaded, you will be able to add/edit items." type="info" />
        </Spin>
      ) : (
        <>
          {items.length <= 0 ? (
            <Empty
              description={<span>Use the search box above to list items</span>}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '60vh',
              }}
            />
          ) : (
            // <Title className={classes.buttonProgress} level={2}>
            //   <br />
            //   Use the search box above to list items
            // </Title>
            <MUIDataTable title="Orders" data={items} columns={columns} options={options} />
          )}
        </>
      )}
    </Container>
  );
}
