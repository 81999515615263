/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function InventoryItemBulk() {
  const profile = useSelector(state => state.user.profile);

  const partNumberInput = useRef('');
  const serialNumberInput = useRef('');
  const locationInput = useRef('');

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    const newInventoryItem = {};

    const getItemInfo = await api.get(`itemsByPartNumber/${escape(data.part_number.trim())}`);
    if (!getItemInfo.data) {
      toast.error('Part number not found');
      partNumberInput.current.focus();
      return false;
    }

    const getItemSerialNumber = await api.get(`inventory_items?serial_number=${data.serial_number.trim()}`);
    if (getItemSerialNumber.data.length > 0) {
      toast.error('Serial number already registered');
      serialNumberInput.current.focus();
      return false;
    }

    if (data.post === '') {
      toast.error('POST Result must be selected');
      return false;
    }

    newInventoryItem.branch_id = 1;
    if (data.post === 'Passed') {
      newInventoryItem.status = 'ToBeInspected';
      newInventoryItem.condition = '';
      newInventoryItem.post_result = 'passed';
      newInventoryItem.grade = 'TBI';
    } else if (data.post === 'Failed') {
      newInventoryItem.status = 'Failed';
      newInventoryItem.post_result = 'failed';
      newInventoryItem.grade = 'FA';
    } else if (data.post === 'New') {
      newInventoryItem.status = 'InStock';
      newInventoryItem.condition = 'New';
      newInventoryItem.post_result = 'new';
      newInventoryItem.grade = 'New';
    } else if (data.post === 'na') {
      newInventoryItem.status = 'ToBeInspected';
      newInventoryItem.condition = '';
      newInventoryItem.post_result = 'na';
      newInventoryItem.grade = 'na';
    }

    newInventoryItem.received_by = profile.id;
    newInventoryItem.item_id = getItemInfo.data.id;
    newInventoryItem.serial_number = data.serial_number.trim();
    newInventoryItem.is_ready = true;
    if (data.location === '' && (data.post === 'Passed' || data.post === 'na')) {
      newInventoryItem.location = 'TBI';
    } else if (data.location === '' && data.post === 'Failed') {
      newInventoryItem.location = 'DOA';
    } else if (data.location === '' && data.post === 'New') {
      newInventoryItem.location = 'NSR';
    } else {
      newInventoryItem.location = data.location;
    }
    partNumberInput.current.focus();

    partNumberInput.current.value = '';
    serialNumberInput.current.value = '';
    locationInput.current.value = '';

    await api.post('inventory_items', newInventoryItem);
    toast.success('Item registered successfully');

    return true;
  };

  useEffect(() => {
    if (![999, 9].includes(profile?.type)) {
      history.push('/');
    }
  }, [profile.type]);

  return (
    <Container>
      <div id="msg" />

      <h1>Quick inventory</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="pure-form pure-form-aligned" autoComplete="off">
        <fieldset>
          <div className="pure-control-group">
            <label htmlFor="part_number">Part number</label>
            <input
              type="text"
              name="part_number"
              id="part_number"
              placeholder="Part Number"
              ref={e => {
                register(e, { required: true });
                partNumberInput.current = e; // you can still assign to ref
              }}
            />
            {errors.part_number && <span className="pure-form-message-inline">This field is required</span>}
          </div>
          <div className="pure-control-group">
            <label htmlFor="serial_number">Serial Number</label>
            <input
              type="text"
              name="serial_number"
              id="serial_number"
              placeholder="Serial Number"
              ref={e => {
                register(e, { required: true });
                serialNumberInput.current = e; // you can still assign to ref
              }}
            />
            {errors.serial_number && <span className="pure-form-message-inline">This field is required</span>}
          </div>
          <br />

          <h2>POST</h2>
          <h3>Initial Evaluation of Asset</h3>
          <br />
          <div className="switch-field">
            <input type="radio" id="new" name="post" value="New" ref={register({ required: true })} />
            <label htmlFor="new">NEW</label>
            {errors.post && <span className="pure-form-message-inline">This field is required</span>}
          </div>
          <div className="switch-field">
            <input type="radio" id="passed" name="post" value="Passed" ref={register({ required: true })} />
            <label htmlFor="passed">PASSED</label>
            {errors.post && <span className="pure-form-message-inline">This field is required</span>}
          </div>

          <div className="switch-field">
            <input type="radio" id="na" name="post" value="na" ref={register({ required: true })} />
            <label htmlFor="na">N/A</label>
            {errors.post && <span className="pure-form-message-inline">This field is required</span>}
          </div>

          <div className="switch-failed">
            <input type="radio" id="failed" name="post" value="Failed" ref={register({ required: true })} />
            <label htmlFor="failed">FAILED</label>
            {errors.post && <span className="pure-form-message-inline">This field is required</span>}
          </div>

          <h3>Location will be automatically set as 'TBI' for Passed assets, 'DOA' for Failed assets and 'NSR' for New assets when empty</h3>

          <div className="pure-control-group">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              name="location"
              id="location"
              placeholder="Location"
              ref={e => {
                register(e, { required: false });
                locationInput.current = e; // you can still assign to ref
              }}
            />
            {errors.location && <span className="pure-form-message-inline">This field is required</span>}
          </div>

          <button type="submit" className="pure-button pure-button-primary">
            Add
          </button>
        </fieldset>
      </form>
    </Container>
  );
}
