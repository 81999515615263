/* eslint-disable no-loop-func */
import AssignCosmeticScreen from '~/utils/AssignCosmeticScreen';
import AssignKBType from '~/utils/AssignKBType';
import AssignOtherPorts from '~/utils/AssignOtherPorts';

export default function ReportFields({ reportsData, status, cosmeticScreen, otherPorts }) {
  let table = '';
  let received = '';
  let receiving_type = '';
  let asset_diagnostic = '';

  const report = [];

  for (let i = 0; i <= 2; i++) {
    //eslint-disable-line
    const reports = reportsData[i];

    reports.forEach((data) => {
      switch (i) {
        case 0:
          table = status === 'complete' ? data.inventory_items_log : data;
          received = status === 'complete' ? data.inventory_items : table;
          asset_diagnostic = status === 'complete' ? table.asset_diagnostic : table.asset_diagnostic[table.asset_diagnostic.length - 1];
          receiving_type = 'device';
          break;
        case 1:
          table = data.inventory_items_attachment;
          received = table;
          asset_diagnostic = table.asset_diagnostic[table.asset_diagnostic.length - 1];
          receiving_type = 'attachment';
          break;
        case 2:
          table = data.inventory_items_parts;
          received = table;
          asset_diagnostic = table.asset_diagnostic[table.asset_diagnostic.length - 1];
          receiving_type = 'parts';
          break;
        default:
          break;
      }
      report.push({
        id: data.id,
        receiving_item_id: data.receiving_item_id,
        part_number: table.item.part_number,
        serial_number: table.serial_number,
        notes: table.description,
        description: table.item.description,
        processed_by: table.processed_by,
        condition: table.condition,
        status: table.status,
        category: table.item.category.name,
        cpu: `${table.item.cpu.brand} ${table.item.cpu.number} ${table.item.cpu.speed}`,
        received_by: received.users.name,
        location: table.location,
        storage_serial_number: table.storage_serial_number,
        storage: table.storage,
        storage_serial_number_2: table.storage_serial_number_2,
        storage_2: table.storage_2,
        ram: table.ram,
        ram_2: table.ram_2,
        hours: table.hours,
        grade: table.grade,
        touch: table.touch,
        camera: table.camera,
        keyboard_type_id: AssignKBType(table.keyboard_type_id),
        other_ports: AssignOtherPorts(table.keyboard_ports, otherPorts),
        operating_system: table.operating_system,
        usb_ports: table.usb_ports,
        wwan_model: table.wwan_model,
        cosmetic_screen: AssignCosmeticScreen(table.cosmetic_screen, cosmeticScreen),
        graphics_card: table.graphics_card,
        post_result: table.post_result,
        cosmetics_screen: asset_diagnostic.cosmetics_screen,
        function_storage: asset_diagnostic.function_storage,
        function_ram: asset_diagnostic.function_ram,
        function_storage_2: asset_diagnostic.function_storage_2,
        function_ram_2: asset_diagnostic.function_ram_2,
        function_mousepad: asset_diagnostic.function_mousepad,
        function_usb: asset_diagnostic.function_usb,
        function_wlan: asset_diagnostic.function_wlan,
        function_bluetooth: asset_diagnostic.function_bluetooth,
        function_audio: asset_diagnostic.function_audio,
        function_optical_drive: asset_diagnostic.function_optical_drive,
        function_wwan: asset_diagnostic.function_wwan,
        function_gps: asset_diagnostic.function_gps,
        function_fingerprint: asset_diagnostic.function_fingerprint,
        function_smartcard: asset_diagnostic.function_smartcard,
        function_diagnostic_utility: asset_diagnostic.function_diagnostic_utility,
        function_barcode_reader: asset_diagnostic.function_barcode_reader,
        function_screen: asset_diagnostic.function_screen,
        function_cpu: asset_diagnostic.function_cpu,
        function_face_recognition: asset_diagnostic.function_face_recognition,
        function_rfid: asset_diagnostic.function_rfid,
        function_battery: asset_diagnostic.function_battery === 'passed' ? 'present' : asset_diagnostic.function_battery === 'na' ? 'absent' : '',
        function_battery2: asset_diagnostic.function_battery2 === 'passed' ? 'present' : asset_diagnostic.function_battery2 === 'na' ? 'absent' : '',
        function_battery3: asset_diagnostic.function_battery3 === 'passed' ? 'present' : asset_diagnostic.function_battery3 === 'na' ? 'absent' : '',
        receiving_type,
      });
    });
  }

  return report;
}
