/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Form, Input, InputNumber, Radio, Space, Upload } from 'antd';

import api from '~/services/api';

const { TextArea } = Input;

export default function Battery({ itemInfo, form, itemResults, itemDisabled, cleanFieldsNotPassed }) {
  const [batteryFile, setBatteryFile] = useState([]);
  const [unitWhereBatteryIsInstalled, setUnitWhereBatteryIsInstalled] = useState(null);
  const [checkBattery, setCheckBattery] = useState(false);
  const [checkBatteryFile, setCheckBatteryFile] = useState(false);

  useEffect(() => {
    if (itemInfo.battery_file !== '' && itemInfo.battery_file !== null && itemInfo.battery_file !== undefined) {
      setBatteryFile([
        {
          uid: itemInfo.battery_file.id,
          url: itemInfo.battery_file.url,
          name: itemInfo.battery_file.name,
        },
      ]);
      form.setFieldsValue({
        batteryFile: [
          {
            uid: itemInfo.battery_file.id,
            url: itemInfo.battery_file.url,
            name: itemInfo.battery_file.name,
          },
        ],
      });
    }
    setCheckBattery(itemResults.resultBatteryReport === 'passed');
    setUnitWhereBatteryIsInstalled(null);
  }, [itemInfo]); //eslint-disable-line

  const handleChange = useCallback(
    async ({ file }) => {
      const formData = new FormData();

      formData.append('file', file.originFileObj);

      let picturesList = [];

      try {
        const responseUploadPictures = await api.post('files', formData);
        picturesList = responseUploadPictures.data.map((el) => ({
          uid: el.id,
          url: el.url,
          name: el.name,
        }));
      } catch (error) {
        Alert.alert('Error', 'Something went wrong - Battery File');
      }

      form.setFieldsValue({ batteryFile: picturesList });

      setBatteryFile(picturesList);
    },
    [form]
  );

  const handleRemove = async () => {
    form.setFieldsValue({ batteryFile: null });
  };

  const handleFindUnit = async () => {
    const unitData = await api.get(`inventory_items?battery_serial_number=${itemInfo.serial_number}`);

    if (unitData.data.length > 0) {
      setUnitWhereBatteryIsInstalled({ id: unitData.data[0].id, serialNumber: unitData.data[0].serial_number });
    }
  };

  return (
    <Descriptions size="small" bordered>
      <Descriptions.Item label="Battery Health" span={2}>
        <Space>
          <Form.Item name="batteryPercentage" rules={[{ required: checkBattery }]}>
            <InputNumber
              disabled={!checkBattery || itemDisabled}
              placeholder="Health"
              min={0}
              max={100}
              formatter={(value) => (value === '' ? '' : `${value}%`)}
              parser={(value) => value.replace('%', '')}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="">
        <Form.Item hasFeedback name="resultBattery" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="batteryTest"
            onChange={(e) => (e.target.value === 'passed' ? setCheckBattery(true) : (setCheckBattery(false), cleanFieldsNotPassed(['batteryPercentage'])))}
            disabled={itemDisabled}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="failed">Failed</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      {(itemInfo?.status === 'InUse' || itemInfo?.status === 'Sold') && (
        <Descriptions.Item label="Being Used At" span={3} style={{ width: '400px' }}>
          <Space>
            <Button onClick={handleFindUnit}>Find unit</Button>
            {unitWhereBatteryIsInstalled !== null && (
              <Button type="link" target="_blank" rel="noopener noreferrer" href={`/inventoryProcessing/${unitWhereBatteryIsInstalled.id}`}>
                {unitWhereBatteryIsInstalled.serialNumber}
              </Button>
            )}
          </Space>
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Battery File" span={2} style={{ width: '400px' }}>
        <Space>
          <Form.Item name="batteryFile">
            <Upload
              onChange={handleChange}
              onRemove={handleRemove}
              multiple={false}
              fileList={batteryFile}
              allowClear
              customRequest={({ onSuccess }) => onSuccess('ok')}
            >
              <Button icon={<UploadOutlined />} disabled={!checkBatteryFile || itemDisabled}>
                Upload
              </Button>
            </Upload>
          </Form.Item>
        </Space>
      </Descriptions.Item>

      <Descriptions.Item label={<Button type="link" />}>
        <Form.Item hasFeedback name="resultBatteryReport" rules={[{ required: true, message: 'Result must be selected' }]}>
          <Radio.Group
            buttonStyle="solid"
            name="reportTest"
            disabled={itemDisabled}
            onChange={(e) => (e.target.value === 'passed' ? setCheckBatteryFile(true) : (setCheckBatteryFile(false), cleanFieldsNotPassed(['batteryFile'])))}
          >
            <Radio.Button value="passed">Passed</Radio.Button>
            <Radio.Button value="na">N/A</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item label="Notes" span={3}>
        <Space>
          <Form.Item hasFeedback name="notes">
            <TextArea
              showCount
              maxLength={255}
              allowClear
              style={{ width: 500 }}
              placeholder="Add additional information about this item"
              rows={4}
              disabled={itemDisabled}
            />
          </Form.Item>
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
}
