import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function BranchEdit(match) {
  const [branchInfo, setBranchInfo] = useState([]);

  useEffect(() => {
    async function loadBranch() {
      const branch = await api.get(`branches/${match.match.params.zip_code}`);

      setBranchInfo(branch.data);
    }
    loadBranch();
  }, []); //eslint-disable-line

  const handleSubmit = async e => {
    e.preventDefault();
    await api.put(`branches/${match.params.zip_code}`, branchInfo);
    history.push('/branches');
  };

  return (
    // <Container>
    //   <Form initialData={branch} onSubmit={handleSubmit}>
    //     <Input name="name" placeholder="Name" />
    //     <Input name="address1" placeholder="Address" />
    //     <Input name="address2" placeholder="Address 2" />
    //     <Input name="city" placeholder="City" />
    //     <Input name="state" placeholder="State" />
    //     <Input name="country" placeholder="Country" />
    //     <Input name="zip_code" placeholder="Postal Code" />

    //     <button type="submit">Edit Branch</button>
    //   </Form>
    // </Container>

    <Container>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader subheader={`${branchInfo.name} - ${branchInfo.zip_code}`} title="Update Branch" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setBranchInfo({ ...branchInfo, name: e.target.value })}
                  value={branchInfo.name || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid item md={6} xs={12}>
              <TextField
                id="address1"
                label="Address"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setBranchInfo({ ...branchInfo, address1: e.target.value })}
                value={branchInfo.address1 || ''}
                fullWidth
              />
            </Grid>
          </CardContent>
          <CardContent>
            <Grid item md={12} xs={12}>
              <TextField
                id="city"
                label="City"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setBranchInfo({ ...branchInfo, city: e.target.value })}
                value={branchInfo.city || ''}
                fullWidth
              />
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={12}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="state"
                  label="State"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setBranchInfo({ ...branchInfo, state: e.target.value })}
                  value={branchInfo.state || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid item md={12} xs={12}>
              <TextField
                id="zip_code"
                label="Postal Code"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setBranchInfo({ ...branchInfo, zip_code: e.target.value })}
                value={branchInfo.zip_code || ''}
                fullWidth
              />
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Update Branch
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
