/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';

import { Button, Card, Divider, Form, Input, Radio, Select, Space, Transfer, Typography } from 'antd';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { Container } from './styles';

export default function InspectionOptions() {
  const profile = useSelector(state => state.user.profile);

  const [form] = Form.useForm();

  const { Title } = Typography;
  // Operating System
  const [osList, setOsList] = useState([]);
  const [osSelectedKeys, setOsSelectedKeys] = useState([]);
  const [osTargetedKeys, setOsTargetedKeys] = useState([]);
  // Cosmetic Screen
  const [csList, setCsList] = useState([]);
  const [csSelectedKeys, setCsSelectedKeys] = useState([]);
  const [csTargetedKeys, setCsTargetedKeys] = useState([]);
  // Other Ports
  const [opList, setOpList] = useState([]);
  const [opSelectedKeys, setOpSelectedKeys] = useState([]);
  const [opTargetedKeys, setOpTargetedKeys] = useState([]);

  const [addVisible, setAddVisible] = useState(false);
  const [tableChosen, setTableChosen] = useState('');

  const severityOptions = [
    {
      label: 'Low',
      value: 'low',
    },
    {
      label: 'Moderate',
      value: 'moderate',
    },
    {
      label: 'High',
      value: 'high',
    },
  ];

  useEffect(() => {
    async function loadTables() {
      const operatingSystemsData = await api.get('/operating_systems');
      const operatingSystemsKeys = operatingSystemsData.data.map(os => ({
          key: `${os.id}-os`,
          name: os.name,
          active: os.active,
        }));
      setOsTargetedKeys(
        operatingSystemsKeys
          .filter(os => os.active === true)
          .map(os => os.key)
      );
      setOsList(operatingSystemsKeys);
      const cosmeticScreenData = await api.get('/cosmetic_screen_options');
      const cosmeticScreenKeys = cosmeticScreenData.data.map(cs => ({
          key: `${cs.id}-cs`,
          // key: cs.id,
          name: `${cs.name} - severity: ${cs.severity}`,
          active: cs.active,
        }));
      setCsTargetedKeys(
        cosmeticScreenKeys
          .filter(cs => cs.active === true)
          .map(cs => cs.key)
      );
      setCsList(cosmeticScreenKeys);
      const otherPortsData = await api.get('/other_ports_options');
      const otherPortsKeys = otherPortsData.data.map(op => ({
          key: `${op.id}-op`,
          // key: op.id,
          name: op.name,
          active: op.active,
        }));
      setOpTargetedKeys(
        otherPortsKeys
          .filter(op => op.active === true)
          .map(op => op.key)
      );
      setOpList(otherPortsKeys);
    }

    loadTables();
  }, []); //eslint-disable-line

  const onChange = async (nextTargetKeys, direction, moveKeys) => {
    const table = moveKeys[0].substring(moveKeys[0].indexOf('-') + 1);
    const updateTable = {
      idList: moveKeys.map(key => key.substring(0, key.indexOf('-'))),
      active: direction === 'right',
    };
    let fullTableName = '';
    let nameList = [];
    switch (table) {
      case 'os':
        fullTableName = 'Operating System';
        await api.patch(`operating_systems`, updateTable);
        setOsTargetedKeys(nextTargetKeys);
        nameList = moveKeys.map(key => ({ name: osList.find(os => os.key === key).name, id: key.substring(0, key.indexOf('-')) }));
        break;
      case 'op':
        fullTableName = 'Other Ports';
        await api.patch(`other_ports_options`, updateTable);
        setOpTargetedKeys(nextTargetKeys);
        nameList = moveKeys.map(key => ({ name: opList.find(os => os.key === key).name, id: key.substring(0, key.indexOf('-')) }));
        break;
      case 'cs':
        fullTableName = 'Cosmetic Screen';
        await api.patch(`cosmetic_screen_options`, updateTable);
        setCsTargetedKeys(nextTargetKeys);
        nameList = moveKeys.map(key => ({ name: csList.find(os => os.key === key).name, id: key.substring(0, key.indexOf('-')) }));
        break;
      default:
        break;
    }
    nameList.forEach(async name => {
      await api.post('logs', {
        // log_id,
        routine: `Updated item as ${updateTable.active ? 'active' : 'innactive'} to ${fullTableName} `,
        action: 'Updated',
        previous_data: `${name.name} was ${updateTable.active ? 'innactive' : 'active'}`,
        new_data: `Set ${name.name} on table ${fullTableName} as ${updateTable.active ? 'active' : 'innactive'}`,
        user_id: profile.id,
        reference_id: name.id,
      });
    });
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    let table = '';
    if (sourceSelectedKeys.length > 0) {
      table = sourceSelectedKeys[0].substring(sourceSelectedKeys[0].indexOf('-') + 1);
    } else if (targetSelectedKeys.length > 0) {
      table = targetSelectedKeys[0].substring(targetSelectedKeys[0].indexOf('-') + 1);
    }
    switch (table) {
      case 'os':
        setOsSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        break;
      case 'op':
        setOpSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        break;
      case 'cs':
        setCsSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        break;
      default:
        break;
    }
  };

  const onFinish = async values => {
    const newInfo = {
      name: values.name,
      active: true,
    };
    let fullTableName = '';
    try {
      switch (values.table) {
        case 'os':
          {
            const newItem = await api.post('operating_systems', newInfo);
            newInfo.key = `${newItem.data.id}-os`;
            fullTableName = 'Operating System';
            setOsList([...osList, newInfo]);
            setOsTargetedKeys([...osTargetedKeys, newInfo.key]);
          }
          break;
        case 'op':
          {
            const newItem = await api.post('other_ports_options', newInfo);
            newInfo.key = `${newItem.data.id}-op`;
            fullTableName = 'Other Ports';
            setOpList([...opList, newInfo]);
            setOpTargetedKeys([...opTargetedKeys, newInfo.key]);
          }
          break;
        case 'cs':
          {
            newInfo.severity = values.severity;
            const newItem = await api.post('cosmetic_screen_options', newInfo);
            newInfo.key = `${newItem.data.id}-cs`;
            newInfo.name = `${newInfo.name} - severity: ${newInfo.severity}`;
            fullTableName = 'Cosmetic Screen';
            setCsList([...csList, newInfo]);
            setCsTargetedKeys([...csTargetedKeys, newInfo.key]);
          }
          break;
        default:
          break;
      }

      await api.post('logs', {
        // log_id,
        routine: `Added new item to ${fullTableName} `,
        action: 'Created',
        new_data: `${values.name} on table ${fullTableName}`,
        user_id: profile.id,
        reference_id: newInfo.key.substring(0, newInfo.key.indexOf('-')),
      });

      form.resetFields();
      setAddVisible(false);
      toast.success('New item added successfully');
    } catch (error) {
      toast.error(`Please contact your RBMS administrator. ${error.message}`);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setAddVisible(false);
  };

  return (
    <Container>
      <Card>
        <Card size="small" bordered={false}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div />
            <Title level={3}>Inspection Options</Title>
            <div />
          </div>
        </Card>
        <Space direction="vertical">
          <Space wrap>
            <Card title="Other Ports" headStyle={{ backgroundColor: 'whitesmoke' }}>
              <Space direction="vertical">
                <Transfer
                  dataSource={opList}
                  titles={['Innactive', 'Active']}
                  targetKeys={opTargetedKeys}
                  selectedKeys={opSelectedKeys}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                  render={item => item.name}
                  listStyle={{
                    height: 300,
                  }}
                />
              </Space>
            </Card>
            <Card title="Cosmetic Screen" headStyle={{ backgroundColor: 'whitesmoke' }}>
              <Space direction="vertical">
                <Transfer
                  dataSource={csList}
                  titles={['Innactive', 'Active']}
                  targetKeys={csTargetedKeys}
                  selectedKeys={csSelectedKeys}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                  render={item => item.name}
                  listStyle={{
                    width: 300,
                    height: 300,
                  }}
                />
              </Space>
            </Card>
            <Card title="Operating System" headStyle={{ backgroundColor: 'whitesmoke' }}>
              <Space direction="vertical">
                <Transfer
                  dataSource={osList}
                  titles={['Innactive', 'Active']}
                  targetKeys={osTargetedKeys}
                  selectedKeys={osSelectedKeys}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                  render={item => item.name}
                  listStyle={{
                    height: 300,
                  }}
                />
              </Space>
            </Card>
          </Space>
          <Button type="primary" onClick={() => setAddVisible(true)}>
            Add New Item
          </Button>
        </Space>
        {addVisible && (
          <>
            <Divider />
            <Card title="Add New Option" headStyle={{ backgroundColor: 'whitesmoke' }}>
              <Form form={form} name="optionsForm" onFinish={onFinish}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Name is required.',
                    },
                  ]}
                >
                  <Input placeholder="Ex. DVI or Blemish or Windows XP" allowClear style={{ width: '700px' }} />
                </Form.Item>
                <Form.Item
                  name="severity"
                  label="Severity"
                  rules={[
                    {
                      message: 'Severity is required for Cosmetic Screen.',
                      required: tableChosen === 'cs',
                    },
                  ]}
                >
                  <Select placeholder="For Cosmetic Screen choose severity" options={severityOptions} style={{ width: '700px' }} />
                </Form.Item>
                <Form.Item
                  name="table"
                  label="Which Table"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose a table to enter new info.',
                    },
                  ]}
                >
                  <Radio.Group onChange={value => setTableChosen(value.target.value)}>
                    <Radio.Button value="op">Other Ports</Radio.Button>
                    <Radio.Button value="cs">Cosmetic Screen</Radio.Button>
                    <Radio.Button value="os">Operating System</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Space>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={() => onCancel()}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </Card>
          </>
        )}
      </Card>
    </Container>
  );
}
