import api from '~/services/api';

export async function ChangeStorageRam(itemInfo, status) {
  const fieldsToUpdate = [
    'storage_serial_number',
    'storage_serial_number_2',
    'ram_serial_number',
    'ram_serial_number_2',
    'battery_serial_number',
    'battery_serial_number2',
    'battery_serial_number3',
  ];

  fieldsToUpdate.map(async field => {
    if (itemInfo[field] !== null && itemInfo[field] !== '') {
      const itemStorageToUpdate = {
        serial_number: itemInfo[field],
        status,
      };
      await api.put('inventory_items', { items: [itemStorageToUpdate] });
    }
  });
}
