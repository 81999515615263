import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline:0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    background: #E5E5E5;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;

  }

  body, input, button {
    color: #222;
    font: 14px 'Roboto', Helvetica, Arial, sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

`;
