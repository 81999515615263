import React, { useState } from 'react';

import { Checkbox, Input, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';

const CheckboxGroup = Checkbox.Group;
const { Text } = Typography;

export default function ForPartsModal({ open, setOpen, loading, handleOk }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [qrCode, setQrCode] = useState('');
  const options = [
    {
      label: 'I confirm that I have removed any hard drive from the unit',
      value: 'hard_drive',
    },
    {
      label: 'I confirm I have removed any RAM/Battery that has a serial number',
      value: 'ram_battery',
    },
    {
      label:
        'I understand that the location of any part with serial number (e.g. SSD/RAM/Battery) that may be associated with this unit on RBMS will be transferred to MY NAME when I complete this action',
      value: 'location',
    },
  ];

  const handleCancel = () => {
    setOpen(() => false);
    setSelectedOptions(() => []);
    setQrCode(() => '');
  };

  const handleChange = (value, setState) => {
    setState(value);
  };

  return (
    <Modal
      title="Why are you changing this item to For Parts?"
      open={open}
      onOk={() => handleOk(selectedOptions, qrCode.trim())}
      confirmLoading={loading}
      onCancel={handleCancel}
    >
      <CheckboxGroup options={options} onChange={e => handleChange(e, setSelectedOptions)} value={selectedOptions} />
      <Text>To complete this step, ask your supervisor to approve this via scanning his QR code.</Text>
      <br />
      <Input
        name="qrCode"
        type="password"
        autoComplete="new-password"
        placeholder="Please scan the QR Code"
        style={{ width: 300 }}
        onChange={e => handleChange(e.target.value, setQrCode)}
        value={qrCode}
      />
    </Modal>
  );
}

ForPartsModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  loading: PropTypes.bool,
  handleOk: PropTypes.func,
};

ForPartsModal.defaultProps = {
  open: false,
  setOpen: () => {},
  loading: false,
  handleOk: () => {},
};
