import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Pagination, Space, Table, Tabs } from 'antd';

import { toast } from 'react-toastify';
import { Container } from './styles';

import DrawerComponent from './DrawerComponent';

import api from '~/services/api';

const { TabPane } = Tabs;

export default function InventoryExport() {
  const [instructions, setInstructions] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [workInstructions, setWorkInstructions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [record, setRecord] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    async function loadInitial() {
      const instructionsData = await api.get('instructions');
      setInstructions(instructionsData.data);

      const workInstructionsData = await api.get('work_instructions');
      setWorkInstructions(workInstructionsData.data);
    }
    loadInitial();
  }, []);

  useEffect(() => {
    async function refreshTable() {
      const workInstructionsData = await api.get(`work_instructions?page=${currentPage - 1}&size=${pageSize}`);
      setCurrentPage(currentPage);
      setPageSize(pageSize);
      setWorkInstructions(workInstructionsData.data);
    }
    refreshTable();
    // return () => {
    //   cleanup
    // }
  }, [currentPage, drawerVisible, pageSize]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setRecord(null);
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      if (!record) {
        const newWorkInstruction = await api.post(`work_instructions`, values);
        if (newWorkInstruction.data.error) {
          throw new Error(newWorkInstruction.data.error);
        }
        toast.success('Work Instruction added successfully');
        setDrawerVisible(false);
        return;
      }
      await api.put(`work_instructions/${record.id}`, values);

      // const workInstructionsData = await api.get(`work_instructions?page=${currentPage - 1}&size=${pageSize}`);
      // setCurrentPage(currentPage);
      // setPageSize(pageSize);
      // setWorkInstructions(workInstructionsData.data);

      toast.success('Work Instruction updated successfully');
      setDrawerVisible(false);
    } catch (error) {
      toast.error(error);
    }
  };

  async function handleChangePage(page, pageSizeToShow) {
    const workInstructionsData = await api.get(`work_instructions?page=${page - 1}&size=${pageSizeToShow}`);
    setCurrentPage(page);
    setPageSize(pageSizeToShow);
    setWorkInstructions(workInstructionsData.data);
  }

  async function handleDelete(row) {
    await api.delete(`work_instructions/${row.id}`);
    const workInstructionsData = await api.get(`work_instructions?page=${currentPage - 1}&size=${pageSize}`);
    setCurrentPage(currentPage);
    setPageSize(pageSize);
    setWorkInstructions(workInstructionsData.data);
  }

  function confirm(row) {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      onOk: () => handleDelete(row),
      // onCancel={this.hideModal}
      content: 'This is permanent',
      okText: 'Delete',
      cancelText: 'Cancel',
    });
  }

  async function handleSave(row) {
    setRecord(row);
    setDrawerVisible(true);
  }

  const columns = [
    {
      title: 'Spec Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, row) => <Link to={`/instructionsEdit/${row.field}`}>Edit</Link>,
    },
  ];

  const workInstructionsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'URL',
      dataIndex: 'url',
    },
    {
      title: 'Subcategory',
      // dataIndex: 'subcategory_id'
      dataIndex: ['subcategory', 'name'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, row) => (
        <Space>
          <Button type="link" onClick={() => handleSave(row)}>
            Edit
          </Button>
          <Button type="link" onClick={() => confirm(row)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ fontWeight: 'bold' }}>
        <TabPane forceRender tab="Inspection Instructions" key="1">
          <Table dataSource={instructions} columns={columns} size="small" pagination={false} rowKey="field" />
        </TabPane>

        <TabPane forceRender tab="Work Instructions" key="2">
          <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
            New Work Instruction
          </Button>

          <Space direction="vertical" style={{ display: 'flex' }}>
            <Table
              dataSource={workInstructions.rows}
              columns={workInstructionsColumns}
              pageSize={pageSize}
              size="small"
              pagination={false}
              rowKey="id"
              style={{ flex: 1 }}
            />
            <Pagination
              showSizeChanger
              onShowSizeChange={() => {}}
              onChange={handleChangePage}
              size="small"
              defaultCurrent={1}
              current={currentPage}
              total={workInstructions.totalItems}
            />
          </Space>

          <DrawerComponent onClose={onClose} drawerVisible={drawerVisible} form={form} onFinish={onFinish} record={record} />
        </TabPane>
      </Tabs>
    </Container>
  );
}
