import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;

  width: 100%;
  padding: 75px 20px 0 250px;

  @media only screen and (max-width: 1024px) {
    padding: 70px 10px;
    display: block;
    width: 100%;
  }
`;
