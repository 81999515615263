/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Upload } from 'antd';
import { ExcelRenderer } from 'react-excel-renderer';

import api from '~/services/api';
import { Container } from './styles';

export default function BulkImport() {
  const profile = useSelector((state) => state.user.profile);

  const [rows, setRows] = useState([]);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {}, []); //eslint-disable-line

  const fileHandler = (fileList) => {
    const fileObj = fileList;
    if (!fileObj) {
      return false;
    }
    if (!(fileObj.type === 'application/vnd.ms-excel' || fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      return false;
    }
    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      // eslint-disable-next-line no-empty
      if (err) {
      } else {
        const newRows = [];
        resp.rows.slice(1).map(async (row, index) => {
          if (row && row !== 'undefined') {
            newRows.push({
              key: index,
              branch: row[0],
              part_number: row[1],
              serial_number: row[2],
              condition: row[3],
              grade: row[4],
              supplier: row[5],
              cost: row[6],
              hours: row[7],
              storage: row[8],
              ram: row[9],
              operating_system: row[10],
              display: row[11],
              touch: row[12],
              description: row[13],
            });
          }
          return false;
        });
        if (newRows.length === 0) {
          return false;
        }
        setRows(newRows);
      }
      return false;
    });
    return false;
  };

  const handleCheckFile = async () => {
    rows.map(async (row) => {
      if (row.branch === '' || typeof row.branch === 'undefined') {
        toast.error(`Branch is empty on Row number ${row.key}`);
        return;
      }
      if (row.part_number === '' || typeof row.part_number === 'undefined') {
        toast.error(`Part Number is empty on Row number ${row.key}`);
        return;
      }
      if (row.serial_number === '' || typeof row.serial_number === 'undefined') {
        toast.error(`Serial Number is empty on Row number ${row.key}`);
      }

      try {
        const getBranch = await api.get(`branches/${row.branch}`);

        if (getBranch.data) {
          const getItem = await api.get(`itemsByPartNumber/${row.part_number}`);

          if (getItem.data) {
            // const getInventoryItem = await api.get(`inventory_items?serial_number=${row.serial_number}`);
            const getInventoryItem = { data: [] };

            if (getInventoryItem.data.length === 0) {
              const getSupplier = await api.get(`suppliers/${row.supplier}`);

              if (getSupplier.data) {
                if (row.key === rows.length - 1) {
                  toast.success('File ready to be submitted!');
                  setSubmit(true);
                }
              } else {
                toast.error(`Supplier ${row.supplier} not found on Row number ${row.key}`);
                return;
              }
            } else {
              toast.error(`Serial Number ${row.serial_number} already registered on Row number ${row.key}`);
              return;
            }
          } else {
            toast.error(`Part Number ${row.part_number} not found on Row number ${row.key}`);
            return;
          }
        } else {
          toast.error(`Branch ${row.branch} not found on Row number ${row.key}`);
          return;
        }
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    });
  };

  const handleSubmit = async () => {
    let newStatus = 'InStock';
    rows.map(async (row) => {
      try {
        const getBranch = await api.get(`branches/${row.branch}`);
        const branch_id = getBranch.data.id;

        const getItem = await api.get(`itemsByPartNumber/${row.part_number}`);
        const item_id = getItem.data.id;

        const getSupplier = await api.get(`suppliers/${row.supplier}`);
        const supplier_id = getSupplier.data.id;

        const getInventoryItem = await api.post('inventory_items/showBySerialNumber', { serial_number: row.serial_number });

        if (getInventoryItem.data !== null) {
          if (getInventoryItem.data.status === 'ToBeInspected') {
            newStatus = 'InStock';
          } else {
            newStatus = getInventoryItem.data.status;
          }

          await api.put('inventory_items', {
            items: [
              {
                // branch_id,
                status: newStatus,
                processed_by: profile?.name?.trim(),
                // item_id,
                serial_number: row?.serial_number?.trim(),
                condition: row?.condition?.trim(),
                grade: row?.grade?.trim(),
                supplier: row?.supplier?.trim(),
                supplier_id,
                cost: row?.cost,
                hours: row?.hours,
                storage: row?.storage,
                ram: row?.ram,
                operating_system: row?.operating_system?.trim(),
                display: row?.display?.trim(),
                touch: row?.touch?.trim(),
                description: row?.description?.trim(),
              },
            ],
          });
        } else {
          await api.post('inventory_items', {
            branch_id,
            status: newStatus,
            processed_by: profile?.name?.trim(),
            item_id,
            serial_number: row?.serial_number?.trim(),
            condition: row?.condition?.trim(),
            grade: row?.grade?.trim(),
            supplier: row?.supplier?.trim(),
            supplier_id,
            cost: row?.cost,
            hours: row?.hours,
            storage: row?.storage,
            ram: row?.ram,
            operating_system: row?.operating_system?.trim(),
            display: row?.display?.trim(),
            touch: row?.touch?.trim(),
            description: row?.description?.trim(),
          });
        }

        if (row.key === rows.length - 1) {
          toast.success('File uploaded with success!');
          setRows([]);
          setSubmit(false);
        }
      } catch (error) {
        toast.error(error);
      }
    });
  };

  return (
    <Container>
      <h1>
        Bulk Import
        <span id="itemTitle" />
      </h1>

      <br />
      <h3>1. Upload Excel file;</h3>
      <br />
      <h3>2. If file fits structure requirements, the button &quot;Check Data&quot; will be displayed. Click on Check Data;</h3>
      <br />
      <h3>
        3. If data in the file is consistent, the button &quot;Submit Data&quot; will be displayed. Click on Submit Data. If data is not consistent, error(s)
        will be displayed at the top right corner of the screen, please fix the errors and upload file again;
      </h3>

      <br />

      <div span={8} align="right" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {rows.length > 0 && submit && (
          <>
            <button type="submit" onClick={() => handleSubmit()} className="pure-button pure-button-primary">
              Submit Data
            </button>
          </>
        )}
      </div>
      <br />
      <br />
      <div span={8} align="right" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {rows.length > 0 && (
          <>
            <button type="submit" onClick={() => handleCheckFile()} className="pure-button pure-button-primary">
              Check Data
            </button>
          </>
        )}
      </div>
      <div gutter={16}>
        <a
          href="https://res.cloudinary.com/dyfmhehsn/raw/upload/v1587344460/sheets/RBMS_Sample_Excel_Sheet_caz2ts.xlsx"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Sample excel sheet (click here to Download)
        </a>

        <br />
      </div>
      <br />
      <div>
        <Upload name="file" beforeUpload={fileHandler} onRemove={() => setRows([])} multiple={false}>
          <button type="submit" className="pure-button pure-button-primary">
            <icon type="upload" /> Click to Upload Excel File
          </button>
        </Upload>
      </div>

      <div style={{ marginTop: 20 }}>
        <table className="pure-table">
          <thead>
            <tr>
              <th>Row</th>
              <th>Branch</th>
              <th>Part Number</th>
              <th>Serial Number</th>
              <th>Condition</th>
              <th>Grade</th>
              <th>Supplier</th>
              <th>Cost</th>
              <th>Hours</th>
              <th>Storage</th>
              <th>RAM</th>
              <th>OS</th>
              <th>Display</th>
              <th>Touch</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.key}>
                  <td>{row.key}</td>
                  <td>{row.branch}</td>
                  <td>{row.part_number}</td>
                  <td>{row.serial_number}</td>
                  <td>{row.condition}</td>
                  <td>{row.grade}</td>
                  <td>{row.supplier}</td>
                  <td>{row.cost}</td>
                  <td>{row.hours}</td>
                  <td>{row.storage}</td>
                  <td>{row.ram}</td>
                  <td>{row.operating_system}</td>
                  <td>{row.display}</td>
                  <td>{row.touch}</td>
                  <td>{row.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Container>
  );
}
