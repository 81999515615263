import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { IconAfter, IconBefore, InputContainer, PrimaryInput } from './styles';

// function InputText({ label, onPressEnter, IconAfterProp, ...rest }, ref) {
const InputText = forwardRef(({ label, onPressEnter, IconAfterProp, IconBeforeProp, ...rest }, ref) => (
  // const [form] = Form.useForm();

  // const profile = useSelector(state => state.user.profile);

  // <Link to="/locationList?location=">linklink</Link>

  // function onSearch(value, event) {
  //   if (event.key === 'Enter') {
  //     console.log('Enter key pressed');
  //     console.log(value, event);
  //   }
  // }

  <InputContainer>
    {label && <label htmlFor={rest.id || 'input-text'}>{label}</label>}
    {/* <IconBefore /> */}
    <IconBefore>{IconBeforeProp}</IconBefore>
    <PrimaryInput
      {...rest}
      ref={ref}
      // onKeyDown={(e) => onPressEnter && e.key === 'Enter' && onPressEnter(e.currentTarget.value, e)}
      onKeyDown={(e) => e.key === 'Enter' && onPressEnter(e.currentTarget.value, e)}

      // onChange={e => e.key === 'Enter' && console.log(e)}
      // onKeyPress={e => e.key === 'Enter' && console.log(e.currentTarget.value)}

      // autoComplete="off"
      // type="text"
      // name="groupName"
      // id="groupName"
      // value={userGroupName}
      // onChange={el => setUserGroupName(el.target.value)}
      // onBlur={el => setUserGroupName(el.target.value.trim())}
      // placeholder="Group name"
      // style={{ width: '400px' }}
    />
    <IconAfter>{IconAfterProp}</IconAfter>
  </InputContainer>
));

InputText.displayName = 'InputText';

InputText.propTypes = {
  // columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  // data: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  onPressEnter: PropTypes.func,
  IconAfterProp: PropTypes.element,
  IconBeforeProp: PropTypes.element,
};

InputText.defaultProps = {
  label: '',
  onPressEnter: () => {},
  IconAfterProp: null,
  IconBeforeProp: null,
};

export default InputText;
