/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import { Card, Table, Button, Popconfirm, Typography } from 'antd';

// import { UserOutlined } from '@ant-design/icons';

import { Paper } from '@material-ui/core';

import { Container } from './styles';

import api from '~/services/api';
import history from '~/services/history';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const { Title } = Typography;

const columns = [
  {
    title: '#',
    // dataIndex: 'index',
    render: (text, row, index) => index + 1,
  },
  {
    title: 'Serial Number',
    dataIndex: 'serial_number',
  },
  {
    title: 'Part Number',
    dataIndex: 'part_number',
  },
  {
    title: 'Audit Status',
    dataIndex: 'action',
    render: (text) => (text === null ? 'RBMS only' : text),
  },
  {
    title: 'Item Status',
    dataIndex: 'status',
  },
  {
    title: 'Item Condition',
    dataIndex: 'condition',
  },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  // },
  // {
  //   title: 'Actions',
  //   dataIndex: 'actions',
  //   render: (text, row) => (
  //     <Button type="primary" href={`inventoryAuditItems/${row.id}`}>
  //       Audit scanned units
  //     </Button>
  //   ),
  //   // render: (text, row) => <Link to={`inventoryAuditItems/${row.id}`}>Audit scanned units</Link>,
  // },
];

// const data = [];
// for (let i = 0; i < 46; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

export default function InventoryAuditItems() {
  const classes = useStyles();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState();

  useEffect(() => {
    async function loadAudits() {
      const openAuditItems = await api.get(`inventoryAuditItems/${id}`);

      const newAuditItems = await Promise.all(
        openAuditItems.data.map(async (el) => {
          const inventoryItemsData = await api.post('inventory_items/showBySerialNumber', { serial_number: el.serial_number });
          return { ...el, status: inventoryItemsData.data?.status, condition: inventoryItemsData.data?.condition };
        })
      );

      setData(newAuditItems);

      function groupBy(list, groupBy) {
        return list.reduce((data, unit) => {
          data[unit[groupBy]] = (data[unit[groupBy]] || 0) + 1;
          return data;
        }, {});
      }

      setSummary(groupBy(newAuditItems, 'action'));
    }
    loadAudits();
  }, [id]);

  async function handlesCompleteAudit() {
    try {
      await api.put(`inventoryAudit/${id}`, { status: 'complete' });
      toast.success(`Audit ${id} completed`);
      history.push('/inventoryAudit');
    } catch (error) {
      toast.error(`Audit ${id} was not completed due to an error`);
    }
  }

  return (
    <Container>
      <Paper className={classes.paper}>
        <Title level={3}>Inventory Audit Items</Title>
        <div style={{ marginBottom: '15px' }}>
          <Popconfirm title="Are you sure to delete this task?" onConfirm={handlesCompleteAudit} onCancel={() => {}} okText="Yes" cancelText="No">
            <Button type="primary">Complete Audit</Button>
          </Popconfirm>
        </div>

        {summary && (
          <div style={{ marginBottom: '15px' }}>
            <Card size="small" title="Summary" style={{ width: 300 }} headStyle={{ backgroundColor: '#ccc', borderColor: '#ccc' }}>
              {Object.keys(summary).map((el, idx) => (
                <p key={idx}>
                  {el}: {summary[el]}
                </p>
              ))}
              <p>Total: {data.length}</p>
            </Card>
          </div>
        )}

        <Table rowKey={(record) => record.serial_number} size="small" columns={columns} dataSource={data} pagination={false} />
      </Paper>
    </Container>
  );
}
