/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';

import { ToolOutlined } from '@ant-design/icons';
import { Timeline, Typography } from 'antd';

const { Link } = Typography;

export default function History({ itemHistory, serialNumber, orderId, orderType }) {
  return (
    <Timeline mode="left">
      {itemHistory.map((item) => (
        <Timeline.Item
          color={item.description === 'Shipped' ? 'red' : item.description === 'Received' ? 'green' : 'blue'}
          key={item.key}
          dot={item.description.indexOf('Inspected') === 0 && <ToolOutlined style={{ fontSize: '16px' }} />}
          label={moment(item.date).format('MMM DD, YYYY - HH:mm')}
        >
          <div>
            {item.route !== 'inventoryProcessing' && <strong>{item.description}</strong>}
            {item.route === 'inventoryProcessing' && !orderId && (
              <Link href={`/inventoryProcessing/${item.inventoryItemsId}?inventory_items_log=${item.id_reference}`}>{item.description}</Link>
            )}
            {item.route === 'inventoryProcessing' && orderId && (
              <Link
                href={`/inventoryProcessing/${item.inventoryItemsId}?inventory_items_log=${item.id_reference}&order_number=${orderId}&orderType=${orderType}`}
              >
                {item.description}
              </Link>
            )}
            {item.notes && <div>{item.notes}</div>}
          </div>
          <div>{item.description === 'Shipped' ? 'ShipStation' : item.user}</div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}
