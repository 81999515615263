/* eslint-disable react/jsx-no-bind */
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { Space } from 'antd';
import { DebounceSelect } from '~/components/DebounceSelect';
import InputText from '~/components/InputText';
import { fetchItems } from '~/pages/Inventory/Processing/utils/fetchItems';
import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

export default function InventoryPartsNew() {
  const [inventoryItem, setInventoryItem] = useState({});
  async function handleSubmit() {
    await api.post('inventory', inventoryItem);
    history.push('/inventoryParts');
  }

  return (
    <Container>
      <Card style={{ width: '100%' }}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="" title="New Inventory Item" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Space>
                  <DebounceSelect
                    id="item_id"
                    showSearch
                    allowClear
                    style={{ width: 465 }}
                    placeholder="ITEM / PART NUMBER"
                    optionFilterProp="label"
                    fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                    onSelect={(key) => setInventoryItem({ item_id: key, branch_id: 1, type: 'part' })}
                  />
                  <InputText
                    id="quantity"
                    placeholder="Quantity"
                    onChange={(e) => {
                      setInventoryItem({ ...inventoryItem, quantity: e.target.value || 0 });
                    }}
                  />
                </Space>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions onClick={handleSubmit}>
            <Button color="primary" variant="contained">
              Create New Inventory Item
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
