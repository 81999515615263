/* eslint-disable react/jsx-no-bind */
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { DebounceSelect } from '~/components/DebounceSelect';
import api from '~/services/api';
import history from '~/services/history';
import { fetchItems } from '../Processing/utils/fetchItems';
import { Container } from './styles';

export default function InventoryItemNew() {
  const [inventoryItem, setInventoryItem] = useState({});

  async function handleSubmit() {
    try {
      await api.post('inventory', inventoryItem);
      history.push('/inventory');
    } catch (error) {
      toast.error('Item already registered');
    }
  }

  return (
    <Container>
      <Card style={{ width: '100%' }}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="" title="New Inventory Item" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <DebounceSelect
                  id="item_id"
                  showSearch
                  allowClear
                  style={{ width: 465 }}
                  placeholder="Item / Part Number"
                  optionFilterProp="label"
                  fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                  onSelect={(key) => setInventoryItem({ item_id: key, branch_id: 1 })}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions onClick={handleSubmit}>
            <Button color="primary" variant="contained">
              Create New Inventory Item
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
