/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Form, Space } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ActionsBar, Container, InputContainer } from './styles';

export default function LocationTypeNewEdit({ handleCreateNew, addToList, locationTypeId, backToList }) {
  const [form] = Form.useForm();

  const [locationList, setLocationList] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [locationTypeName, setLocationTypeName] = useState('');
  const [locationTypeDescription, setLocationTypeDescription] = useState('');
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    async function loadLocationList() {
      try {
        const locationTypeData = await api.get(`location-type/${locationTypeId}`);

        const locationCheckboxList = [];

        if (locationTypeData.data.locationType) {
          const locationTypeLocations = locationTypeData.data.locationType.location_types.map(location => {
            locationCheckboxList.push({ value: location.id, label: location.name });
            return location.id;
          });
          setLocationTypeName(() => locationTypeData.data.locationType.name);
          setLocationTypeDescription(() => locationTypeData.data.locationType.description);
          setCheckedLocations(() => locationTypeLocations);
        }

        const availableLocation = locationTypeData.data.availableLocations.map(location => {
          return { value: location.id, label: location.name };
        });
        locationCheckboxList.push(...availableLocation);
        const NO_AVAILABLE_LOCATIONS = availableLocation.length === 0;

        if (NO_AVAILABLE_LOCATIONS) {
          locationCheckboxList.push({ value: 0, label: 'No available locations', disabled: true });
        }

        setLocationList(locationCheckboxList);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    loadLocationList();
  }, [form, locationTypeId]);

  const handleSaveLocationType = async () => {
    // if (values.locationTypeLocations === undefined) {
    //   toast.error('Please select a location, if there are any available.');
    //   return;
    // }
    const CHECK_IF_LOCATION_TYPE_DOESNT_HAVE_NAME = locationTypeName === '';
    if (CHECK_IF_LOCATION_TYPE_DOESNT_HAVE_NAME) {
      toast.error('Please enter a Name for the location type.');
      return;
    }

    const CHECK_IF_LOCATION_TYPE_DOESNT_HAVE_DESCRIPTION = locationTypeDescription === '';
    if (CHECK_IF_LOCATION_TYPE_DOESNT_HAVE_DESCRIPTION) {
      toast.error('Please enter a Description for the location type.');
      return;
    }

    const location_types =
      checkedLocations.length > 0
        ? checkedLocations.map(location => {
            return { name: locationList.find(el => el.value === location).label };
          })
        : [];

    const newLocationType = {
      name: locationTypeName,
      description: locationTypeDescription,
      active: isActive,
      locations: checkedLocations,
      location_types,
    };
    try {
      if (locationTypeId === 0) {
        const response = await api.post('location-type', newLocationType);

        newLocationType.id = +response.data.id;
        toast.success(response.data.message);
      } else {
        const updateResponse = await api.put(`location-type/${locationTypeId}`, newLocationType);
        newLocationType.id = +updateResponse.data.id;
        toast.success(updateResponse.data.message);
      }
      addToList(newLocationType);
      backToList(locationTypeId);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const onChange = values => {
    setCheckedLocations(() => values);
  };

  return (
    <Container>
      <ActionsBar>
        <Space>
          <Button type="primary" onClick={handleSaveLocationType}>
            Save
          </Button>
          <Button type="primary" danger onClick={() => handleCreateNew(locationTypeId)}>
            Cancel
          </Button>
        </Space>
      </ActionsBar>
      <Space direction="vertical" size={15}>
        <InputContainer>
          <label htmlFor="locationTypeName">Name</label>
          <input
            placeholder="Please type the Location Type's name."
            autoComplete="off"
            type="text"
            name="locationTypeName"
            id="locationTypeName"
            value={locationTypeName}
            onChange={el => setLocationTypeName(el.target.value)}
            onBlur={el => setLocationTypeName(el.target.value.trim())}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="locationTypeDescription">Description</label>
          <input
            placeholder="Please type the Location Type's description."
            autoComplete="off"
            type="text"
            name="locationTypeDescription"
            id="locationTypeDescription"
            value={locationTypeDescription}
            onChange={el => setLocationTypeDescription(el.target.value)}
            onBlur={el => setLocationTypeDescription(el.target.value.trim())}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="locationTypeLocations">Available Locations</label>
          <Checkbox.Group options={locationList} value={checkedLocations} onChange={onChange} style={{ width: 400 }} />
        </InputContainer>
        <Checkbox checked={isActive} onChange={e => setIsActive(e.target.checked)} style={{ marginLeft: 150 }}>
          Active
        </Checkbox>
      </Space>
    </Container>
  );
}

LocationTypeNewEdit.propTypes = {
  handleCreateNew: PropTypes.func.isRequired,
  addToList: PropTypes.func.isRequired,
  locationTypeId: PropTypes.number.isRequired,
  backToList: PropTypes.func.isRequired,
};
