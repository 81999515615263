/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import * as XLSX from 'xlsx';

import { Container } from './styles';
import api from '~/services/api';
import AssignKBType from '../Edit/utils/AssignKBType';
import AssignOtherPorts from '../Edit/utils/AssignOtherPorts';
import AssignAssetDiagnosticRender from '../Edit/utils/AssignAssetDiagnosticRender';

export default function InventoryArraySN() {
  const [reportData, setReportData] = useState([]);
  useEffect(() => {
    async function load() {
      const otherPortsList = await api.get('other_ports_options');

      const reportsData = await api.put('/report/InventoryByArraySN', {
        headers: {
          serial_numbers: [
            '2BKSA59388',
            '2BKSA59399',
            '2BKSA59406',
            '2EKYA70966',
            '2EKYA70993',
            '2FKYA74167',
            '2EKSA71146',
            '1LKSA53785',
            '2AKYA58087',
            '2GKSA77747',
            '0IKSA14922',
            '0LKYA2157',
            '1AKYA24885',
            '1AKYA25533',
            '1AKYA25553',
            '1CKYA31043',
            '1CKYA31141',
            '1CKYA31147',
            '1CKYA31158',
            '1CKYA31164',
            '1CKYA31173',
            '1CKYA31175',
            '1CKYA31512',
            '2LKSA40239',
            '2LKSA40268',
            '2LKSA40281',
            '2LKSA40304',
            '2LKSA40324',
            '2LKSA40343',
            '2LKSA40369',
            '2LKSA40397',
            '3AKSA40716',
            '0KKYA20027',
            '1AKYA24980',
            '2IKSA38790',
            '3AKSA40707',
            '1KKYA09318',
            '1KKYA09108',
            '1KKYA09146',
            '1KKYA09170',
            '2EKYA23579',
            '2EKYA24313',
            '2FKYA27741',
            '2FKYA28574',
            '2BKSA18722',
            '1KKSA09072',
            '6EDSA01578',
            '6DDSA01076',
            '6GDSA02183',
            '2110-274886',
            '2110-274898',
            '2110-274963',
            '2110-274973',
            '2111-277184',
            '2111-277201',
            '2111-277204',
            '2111-277209',
            '2111-277223',
            '2111-277242',
            '2111-277755',
            'RBA51V0187',
            'RH439V0104',
            'RH439V0117',
            '1FVJPQ1',
            '613JPQ1',
            '80527W1',
            'RGC51B0198',
            '2310-404634',
            'RE339B0026',
            'RDA39B0024',
            '2310-404635',
            '2310-404629',
            '2701-610757',
            'RF839B0079',
            '90527W1',
          ],
        },
      });
      const report = [];
      reportsData.data.forEach(data => {
        report.push({
          id: data.id,
          serial_number: data.serial_number,
          part_number: data.item.part_number,
          description: data.item.description,
          status: data.status,
          hours: data.hours,
          condition: data.condition,
          cpu: `${data.item.cpu.brand} ${data.item.cpu.number} ${data.item.cpu.speed}`,
          grade: data.grade,
          touch: data.touch,
          display: data.display,
          stylus: data.stylus,
          camera: data.camera,
          storage: data.storage,
          storage_serial_number: data.storage_serial_number,
          storage_2: data.storage_2,
          storage_serial_number_2: data.storage_serial_number_2,
          ram: data.ram,
          ram_serial_number: data.ram_serial_number,
          ram_2: data.ram_2,
          ram_serial_number_2: data.ram_serial_number_2,
          wwan: data.wwan,
          battery: data.battery,
          keyboard_type_id: AssignKBType(data.keyboard_type_id),
          other_ports: AssignOtherPorts(data.keyboard_ports, otherPortsList.data),
          function_audio: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_audio),
          function_barcode_reader: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_barcode_reader),
          function_bluetooth: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_bluetooth),
          function_fingerprint: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_fingerprint),
          function_gps: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_gps),
          usb_ports: data.usb_ports,
          function_mousepad: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_mousepad),
          function_optical_drive: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_optical_drive),
          function_smartcard: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_smartcard),
          function_wlan: AssignAssetDiagnosticRender(data.asset_diagnostic[0]?.function_wlan),
          operating_system: data.operating_system,
          processed_by: data.processed_by,
          notes: data.description,
        });
      });

      setReportData(report);
    }
    load();
  }, []); //eslint-disable-line

  function downloadExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    // XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `List_of_serial_numbers.xlsx`);
  }

  return (
    <Container>
      <div>
        <Link
          style={{ backgroundColor: 'red', display: 'inline-block' }}
          to={() => {
            return `/inventory`;
          }}
        >
          <Button type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </Link>
      </div>

      <Button
        variant="outlined"
        type="primary"
        className="ant-dropdown-link"
        style={{ background: '#3EB19E', borderColor: '#3EB19E', color: 'white', width: 150 }}
        onClick={() => downloadExcel(reportData)}
      >
        Download to Excel
      </Button>
    </Container>
  );
}
