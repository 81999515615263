/* eslint-disable react/prop-types */
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Divider, Empty, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { Container, StyledButton, TransactionContainer, TransactionTable } from './styles';

const { Title } = Typography;
const statusColor = {
  Pending: 'red',
  Complete: 'green',
  Started: 'orange',
};

export default function PhysicalInventoryLocations({ match }) {
  const [locationsList, setLocationsList] = useState([]);

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    async function loadLocations() {
      const locations = await api.get(`physical_inventory/${match.params.physicalInventoryId}/locations`);
      setLocationsList(locations.data);
    }
    loadLocations();
  }, []); //eslint-disable-line

  const handleAssignUserToLocation = async (locationId) => {
    const assignedTechnician = await api.post('physical_inventory/assignTechnician', {
      physical_inventory_location_id: locationId,
      user_id: profile?.id,
      physical_inventory_id: +match.params.physicalInventoryId,
    });

    const { result, message } = assignedTechnician.data;
    if (result === 'error') toast.error(message);
    else {
      setLocationsList((state) =>
        state.map((location) => {
          if (location.id === locationId) {
            location.physical_inventory_location_assigned_technician.push({ user_id: profile?.id, users: { name: profile?.name } });
          }
          return location;
        })
      );
      toast.success(message);
    }
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        <Link to="/physicalInventory">
          <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </Link>
        <Title level={2}>{locationsList[0]?.physical_inventory?.name} Physical Inventory Location</Title>
        <div />
      </div>
      <Divider />
      <strong>Manager: {locationsList[0]?.physical_inventory?.users?.name}</strong>
      {locationsList.length > 0 ? (
        <TransactionContainer>
          <TransactionTable>
            <thead>
              <tr>
                <td width="40%">Location</td>
                <td>Status</td>
                <td>Technician</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {locationsList.map((location) => (
                <tr key={location.id}>
                  <td width="40%">
                    <Link
                      to={`/physicalInventoryLocationItems/${match.params.physicalInventoryId}/${location.id}?return=techlist`}
                    >{`${location.locations.name} - ${location.locations.description}`}</Link>
                  </td>
                  <td>
                    <Tag color={statusColor[location.status]}>{location.status}</Tag>
                  </td>
                  <td>{location.physical_inventory_location_assigned_technician[0]?.users?.name}</td>
                  <td>
                    <StyledButton
                      onClick={() => handleAssignUserToLocation(location.id)}
                      disabled={
                        (location.physical_inventory_location_assigned_technician[0]?.users?.name !== null &&
                          location.physical_inventory_location_assigned_technician[0]?.users?.name !== undefined) ||
                        location.status === 'Complete'
                      }
                    >
                      Assign Myself
                    </StyledButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </TransactionTable>
        </TransactionContainer>
      ) : (
        <Empty description="There is no location to show" />
      )}
    </Container>
  );
}
