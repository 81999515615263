import { all, call, put, takeLatest } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import { getCurrentCountSuccess } from './actions';

export function* countItemsUnderMyName() {
  try {
    // console.log('third ');

    const response = yield call(api.get, 'countItemsUnderUser');
    // console.log('response ', response.data.count);

    yield put(getCurrentCountSuccess(response.data.count));
  } catch (err) {
    toast.error('Failed to get current count');
    // yield put(signFailure());
    // console.log(err);
  }
}

export default all([takeLatest('@get/CURRENT_COUNT_REQUEST', countItemsUnderMyName)]);
