import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Divider, Form, Input, Select, Space, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import { Card, Container } from './styles';

const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;

export default function NewPhysicalInventory() {
  const [locationOptions, setLocationOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < locationOptions.length);
    setCheckAll(list.length === locationOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? locationOptions.map((location) => location.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    async function loadOptions() {
      const locationsList = await api.get('/locations');
      const usersList = await api.get('users');

      setLocationOptions(locationsList.data.filter((el) => el.location_parent_id === null).map((data) => ({ label: data.description, value: data.id })));

      setUsersOptions(usersList.data.filter((data) => data.active === true).map((data) => ({ label: data.name, value: data.id })));
    }
    loadOptions();
  }, []);

  const disabledDate = (current) =>
    // Can not select days before today
    current && current < moment().startOf('day');
  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (checkedList.length === 0) {
        toast.error('Please select at least one location');
        return;
      }
      // inventoryManagerId

      const newPhysicalInventory = {
        name: values.inventoryTitle,
        startDate: moment(values.inventoryDate).startOf('day'),
        locations: checkedList,
        inventoryManagerId: values.inventoryManager || null,
      };

      await api.post('physical_inventory', newPhysicalInventory);
      toast.success(`${values.inventoryTitle} event was created successfully`);
      history.push('/physicalInventory');
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        <Link to="/physicalInventory">
          <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </Link>
        <strong>New Physical Inventory</strong>
        <div />
      </div>
      <Divider />
      <Form name="form" onFinish={onFinish}>
        <Space direction="vertical">
          <Space>
            <Form.Item name="inventoryTitle" label="Title" rules={[{ required: true, message: 'Physical Inventory event must have a title.' }]}>
              <Input placeholder="Enter title for this event" allowClear />
            </Form.Item>
            <Form.Item name="inventoryDate" label="Start Date" rules={[{ required: true, message: 'Physical Inventory event must have a start date.' }]}>
              <DatePicker disabledDate={disabledDate} allowClear />
            </Form.Item>
          </Space>
          <Form.Item name="inventoryManager" label="Inventory Manager" rules={[{ required: true, message: 'Physical Inventory event must have a Manager.' }]}>
            <Select
              showSearch
              placeholder="Select Inventory Manager"
              options={usersOptions}
              optionFilterProp="label"
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: '400px' }}
            />
          </Form.Item>
          <Card>
            <Space direction="vertical" size="middle">
              <Space size="middle">
                <Title level={4}>Assign Locations to be scanned</Title>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                  Check all
                </Checkbox>
              </Space>
              <CheckboxGroup
                options={locationOptions}
                value={checkedList}
                onChange={onChange}
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}
              />
              <Button loading={loading} type="primary" htmlType="submit">
                Create
              </Button>
            </Space>
          </Card>
        </Space>
      </Form>
    </Container>
  );
}
