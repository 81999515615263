/* eslint-disable react/prop-types */
import { Form, Input, Select, Space } from 'antd';
import React from 'react';

import { postcodeValidator } from 'postcode-validator';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function OtherOptions({
  shippingAccount,
  handleChangeShippingAccount,
  countries,
  labelForOrderInfo,
  setLabelForOrderInfo,
  setLoadingRate,
  // setInfoToCalculateRate,
  // infoToCalculateRate,
}) {
  const { Option } = Select;

  async function handleChangeOtherOptions(key, value) {
    const labelInfo = { ...labelForOrderInfo };
    // const rateInfo = { ...infoToCalculateRate };
    // labelInfo.advancedOptions.billToParty = 'recipient';

    if (key === 'billToPostalCode') {
      if (postcodeValidator(value, 'CA')) {
        labelInfo.advancedOptions[key] = value;
      } else {
        toast.error('Postal code is invalid');
        return;
      }
      setLabelForOrderInfo(labelInfo);
    } else {
      labelInfo.advancedOptions[key] = value;

      /** */

      setLoadingRate(true);

      const rate = await api.post('shipstation/getrates', labelInfo);

      if (Object.keys(rate.data).length === 0) {
        if (rate.data.error) {
          toast.error(rate.data.error);
          return;
        }
        toast.error('Could not load rate');
        return;
      }

      // setLabelForOrderInfo({
      //   ...rate.data,
      //   carrierCode: carriersAndServices.filter(carrier => carrier.services.some(item => item.code === serviceId)).map(carrier => carrier.code)[0],
      //   serviceCode: serviceId,
      // });

      setLabelForOrderInfo(labelInfo);
      setLoadingRate(false);
    }

    // labelInfo.advancedOptions.billToParty = 'recipient';
    // billToAccount
    // billToCountryCode
    // billToPostalCode
  }

  return (
    <>
      <Space align="top">
        <Form
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 15,
          }}
          // layout="horizontal"
          // initialValues={{
          //   size: componentSize,
          // }}
          // onValuesChange={onFormLayoutChange}
          size="small"
        >
          <Form.Item label="Shipping Account">
            <Space>
              <Select
                value={shippingAccount}
                onChange={handleChangeShippingAccount}
                disabled={Object.keys(labelForOrderInfo).length === 0}
                style={{ width: 250 }}
              >
                <Option value="primary">RB Account</Option>
                <Option value="other">Other Account</Option>
              </Select>
            </Space>
          </Form.Item>

          {shippingAccount === 'other' && (
            <Form.Item label="Other Account" validateStatus>
              <Space direction="vertical">
                <Input
                  placeholder="Account #"
                  style={{ width: 250 }}
                  onBlur={el => handleChangeOtherOptions('billToAccount', el.currentTarget.value)}
                  allowClear
                />
                {/* <Input placeholder="Country" /> */}
                <Select
                  options={countries}
                  placeholder="Country"
                  style={{ width: 250 }}
                  onChange={value => handleChangeOtherOptions('billToCountryCode', value)}
                  allowClear
                />
                <Input
                  placeholder="Postal Code"
                  style={{ width: 250 }}
                  onBlur={el => handleChangeOtherOptions('billToPostalCode', el.currentTarget.value)}
                  allowClear
                />
              </Space>
            </Form.Item>
          )}
          {/* <Form.Item label="Weight">
            <Input.Group compact>
              <Form.Item noStyle rules={[{ required: true, message: 'Weight is required' }]}>
                <InputNumber
                  // ref={lbInputRef}
                  value={lbWeight}
                  onChange={e => setLbWeight(e)}
                  min={0}
                  style={{ width: '30%' }}
                  placeholder="lb"
                />
              </Form.Item>
              <Form.Item noStyle rules={[{ required: true, message: 'Weight is required' }]}>
                <InputNumber
                  // ref={ozInputRef}
                  min={0}
                  // max={15}
                  style={{ width: '30%' }}
                  placeholder="oz"
                  onBlur={e => handleOzBlur(e.currentTarget.value)}
                  value={ozWeight}
                  onChange={handleOzChange}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item label="Service">
            <Select style={{ width: 300 }} value={serviceCode} onChange={serviceId => handleChangeService(serviceId)}>
                    {carriersAndServices.map(carrier => { //eslint-disable-line
                // if (carrier.services.length > 0) {
                if (recipient.country === 'CA' && carrier.services.filter(service => service.domestic === true).length > 0) {
                  return (
                    <OptGroup key={carrier.code} label={carrier.name}>
                      {carrier.services
                        .filter(service => service.domestic === true)
                        .map(service => (
                          <Option key={service.code} value={service.code}>
                            {service.name}
                          </Option>
                        ))}
                    </OptGroup>
                  );
                }
                if (
                  recipient.country !== 'CA' &&
                  carrier.services.filter(service => service.international === true && service.carrierCode !== 'purolator_ca').length > 0
                ) {
                  return (
                    <OptGroup key={carrier.code} label={carrier.name}>
                      {carrier.services
                        .filter(service => service.international === true)
                        .map(service => (
                          <Option key={service.code} value={service.code}>
                            {service.name}
                          </Option>
                        ))}
                    </OptGroup>
                  );
                }
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Package">
            <Select value={packageCode} onChange={packageId => setPackageCode(packageId)}>
              <Select.Option value="package">Package</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Size">
            <Input.Group compact>
              <Form.Item noStyle rules={[{ required: true, message: 'Size is required' }]}>
                <InputNumber min={0} style={{ width: '30%' }} placeholder="L" value={length} onChange={e => setLength(e)} />
              </Form.Item>
              <Form.Item noStyle rules={[{ required: true, message: 'Size is required' }]}>
                <InputNumber min={0} style={{ width: '30%' }} placeholder="W" value={width} onChange={e => setWidth(e)} />
              </Form.Item>
              <Form.Item noStyle rules={[{ required: true, message: 'Size is required' }]}>
                <InputNumber min={0} style={{ width: '30%' }} placeholder="H" value={height} onChange={e => setHeight(e)} />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item label="Confirmation">
            <Select value={confirmationCode} onChange={confirmationId => setConfirmationCode(confirmationId)}>
              <Select.Option value="none">None</Select.Option>
              <Select.Option value="signature">Electronic Signature</Select.Option>
            </Select>
          </Form.Item> */}
        </Form>
      </Space>
      {/* {loadingRate ? (
        <Spin />
      ) : (
        <Space
          size="small"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#6ba03a', fontSize: '16px', fontWeight: 'bold' }}
        >
          <div>
            Rate
            <br />
            {labelForOrderInfo.total || '--'}
          </div>
          <Button type="primary" icon={<PrinterOutlined />} size="small" onClick={handleGenerateLabel}>
            Generate Label
          </Button>
        </Space>
      )} */}
    </>
  );
}
