import React, { useEffect, useState } from 'react';
// import { Form, Input } from '@rocketseat/unform';
import { Card, CardHeader, CardContent, Divider, Grid, Button, TextField } from '@material-ui/core';

// import { useSelector } from 'react-redux';
// import { createItemRequest } from '~/store/modules/item/actions';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import api from '~/services/api';
// import history from '~/services/history';
import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CustomerEdit(params) {
  const classes = useStyles();
  const [customerInfo, setCustomerInfo] = useState(0);
  // const [customerId, setCustomerId] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    async function loadCustomer() {
      const location = new URLSearchParams(params.location.search);
      const customer_id = location.get('id');
      setSearchQuery(location.get('searchQuery'));
      // setCustomerId(customer_id);
      const customer_info = await api.get(`customers/${customer_id}`);
      setCustomerInfo(customer_info.data);
    }
    loadCustomer();
  }, []); // eslint-disable-line

  // async function handleSubmit(data) {
  //   await api.put(`customers/${customerId}`, data);
  //   history.push('/customers');
  // }

  // return (
  //   <Container>
  //     <Form initialData={customer} onSubmit={handleSubmit}>
  //       <Input name="name" placeholder="Name" />
  //       <Input name="phone" placeholder="Phone" />
  //       <Input name="email" placeholder="Email" />

  //       <button type="submit">Edit Customer</button>
  //     </Form>
  //   </Container>
  // );
  return (
    <Container>
      <Card>
        <div>
          <Link
            to={() => {
              if (searchQuery === null) {
                return `/customers`;
              }
              return `/customers?searchQuery=${searchQuery}`;
            }}
          >
            <Button variant="contained" color="primary" className={classes.button} startIcon={<KeyboardBackspace />}>
              Back
            </Button>
          </Link>
        </div>
        <form autoComplete="off" noValidate>
          <CardHeader subheader={`${customerInfo.name}`} title="Update Customer" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, name: e.target.value })}
                  value={customerInfo.name || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="phone"
                  label="Phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, phone: e.target.value })}
                  value={customerInfo.phone || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name="email"
                  label="E-mail"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, email: e.target.value })}
                  value={customerInfo.email || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={9} xs={12}>
                <TextField
                  name="company"
                  label="Company"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, company: e.target.value })}
                  value={customerInfo.company || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="street1"
                  label="Street 1"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, street1: e.target.value })}
                  value={customerInfo.street1 || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="street2"
                  label="Street 2"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, street2: e.target.value })}
                  value={customerInfo.street2 || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="city"
                  label="City"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, city: e.target.value })}
                  value={customerInfo.city || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name="postal_code"
                  label="Postal Code"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, postal_code: e.target.value })}
                  value={customerInfo.postal_code || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name="state"
                  label="State/Province"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, state: e.target.value })}
                  value={customerInfo.state || ''}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  id="country"
                  label="Country"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, country: e.target.value })}
                  value={customerInfo.country || ''}
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={3} xs={12}>
                <TextField
                  name="residential"
                  label="Residential/Business"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, residential: e.target.value })}
                  value={customerInfo.residential || ''}
                  fullWidth
                />
              </Grid> */}
              <Grid item md={9} xs={12}>
                <TextField
                  name="address_verified"
                  label="Address Verification"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setCustomerInfo({ ...customerInfo, address_verified: e.target.value })}
                  value={customerInfo.address_verified || ''}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          {/* <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Update Customer
            </Button>
          </CardActions> */}
        </form>
      </Card>
    </Container>
  );
}
