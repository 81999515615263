import React from 'react';

import { Modal } from 'antd';
import api from '~/services/api';
import { Table } from '../styles';

export async function handleItemTransfer(items, transferType) {
  const info = (returnCounterArray) => {
    Modal.info({
      title: 'ALERT: this item contains components that have been previously returned by clients. Shipping this item will require tech manager’s approval',
      content: (
        <div>
          <h3 style={{ fontWeight: 700 }}>Components in this item that have been previously returned</h3>
          <Table>
            <thead>
              <tr key="header">
                <th>SN</th>
                <th>PN</th>
                <th>Times Returned</th>
              </tr>
            </thead>
            <tbody>
              {returnCounterArray.map((el) => {
                return (
                  <tr key={el.serial_number}>
                    <td>{el.serial_number}</td>
                    <td>{el.part_number}</td>
                    <td>{el.return_counter}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ),
      onOk() {},
      width: '700px',
    });
  };

  if (transferType === 'now') {
    try {
      const transferResponse = await api.post('/locations/handleChangeLocationOfUnits', {
        items,
      });

      if (!transferResponse.data.ok) {
        return { error: transferResponse.data.error };
      }
      if (transferResponse.data.returnCounter.length > 0) info(transferResponse.data.returnCounter);

      return { ok: true };
    } catch (error) {
      return { ok: false, error };
    }
  }
  return { ok: true };

  // return [
  // {
  //   serial_number
  //   previous_location
  //   location
  //   status
  // }
  // ]
  // if (inventoryItem.data.error && type !== 'USR') {
  //   return { error: inventoryItem.data.error };
  // }
  // switch (type) {
  //   case 'P/N': {
  //     const toLocationId = 219;
  //     // if (inventoryItem.data.location_id === 219 && inventoryItem.data.user_id === userId) {
  //     //   return { error: 'This item is already assigned to you' };
  //     // }
  //     const groupPermission = await api.get(`/location-user-permission/${userId}/${fromLocationId}/${toLocationId}`);
  //     if (!groupPermission.data.ok) {
  //       return { error: groupPermission.data.error };
  //     }
  //     const fromUserInfo = inventoryItem.data.user_id ? await api.get(`/users/${inventoryItem.data.user_id}`) : null;
  //     const previousLocation = fromUserInfo
  //       ? `${groupPermission.data.permissions.fromLocation.name} ${fromUserInfo.data.name}`
  //       : groupPermission.data.permissions.fromLocation.name;
  //     const newLocation =
  //       groupPermission.data.permissions.toLocation.name === 'Tech'
  //         ? `${groupPermission.data.permissions.toLocation.name} ${userInfo.data.name}`
  //         : groupPermission.data.permissions.toLocation.name;
  //     const items = [
  //       {
  //         id: inventoryItem.data.id,
  //         location_id: toLocationId,
  //         user_id: userId,
  //         serial_number: serialNumber,
  //         location: newLocation,
  //         previous_location: previousLocation,
  //       },
  //     ];
  //     await api.post('locations/handleChangeLocationOfUnits', { items });
  //     const transferData = {
  //       id: inventoryItem.data.id,
  //       serial_number: serialNumber,
  //       user_id: userId,
  //       user: userInfo.data.name,
  //       location_id: toLocationId,
  //       location: newLocation,
  //       previous_location: previousLocation,
  //       // done: true,
  //     };
  //     return transferData;
  //   }
  //   case 'LCT': {
  //     if (inventoryItem.data.error) {
  //       return { error: inventoryItem.data.error };
  //     }
  //     const locationInfo = await api.get(`/locations/getLocationByName/${toLocation}`);
  //     const toLocationId = locationInfo.data.id;
  //     // if (inventoryItem.data.location_id === toLocationId) {
  //     //   return { error: `This item is already assigned to the location ${inventoryItem.data.locations.name}` };
  //     // }
  //     const groupPermission = await api.get(`/location-user-permission/${inventoryItem.data.user_id}/${fromLocationId}/${toLocationId}`);
  //     if (!groupPermission.data.ok) {
  //       return { error: groupPermission.data.error };
  //     }
  //     const fromUserInfo = inventoryItem.data.user_id ? await api.get(`/users/${inventoryItem.data.user_id}`) : null;
  //     const previousLocation = fromUserInfo
  //       ? `${groupPermission.data.permissions.fromLocation.name} ${fromUserInfo.data.name}`
  //       : groupPermission.data.permissions.fromLocation.name;
  //     const newLocation =
  //       groupPermission.data.permissions.toLocation.name === 'Tech'
  //         ? `${groupPermission.data.permissions.toLocation.name} ${userInfo.data.name}`
  //         : groupPermission.data.permissions.toLocation.name;
  //     const items = [
  //       {
  //         id: inventoryItem.data.id,
  //         location_id: toLocationId,
  //         user_id: userId,
  //         serial_number: serialNumber,
  //         location: newLocation,
  //         previous_location: previousLocation,
  //       },
  //     ];
  //     await api.post('locations/handleChangeLocationOfUnits', { items });
  //     const transferData = {
  //       id: inventoryItem.data.id,
  //       serial_number: serialNumber,
  //       user_id: userId,
  //       user: userInfo.data.name,
  //       location_id: toLocationId,
  //       location: newLocation,
  //       previous_location: previousLocation,
  //       // done: true,
  //     };
  //     return transferData;
  //   }
  //   case 'USR': {
  //     const toUserInfo = await api.get(`/users/getByEmail/${toLocation}`); // toLocation is the email of the user that the item will be transferred to
  //     if (!toUserInfo.data) {
  //       return { error: 'Could not find this user' };
  //     }
  //     const toLocationId = 219;
  //     const toUserId = toUserInfo.data.id;
  //     // if (inventoryItem.data.location_id === toLocationId && inventoryItem.data.user_id === toUserId) {
  //     //   return { error: `This item is already assigned to ${toUserInfo.data.name}` };
  //     // }
  //     // The block of code below is checking if the User QR Code was read to finish the transaction
  //     // if (`${type}:${toLocation}` === value && itemList.length > 0) {
  //     const buff = Buffer.from(toLocation, 'utf-8');
  //     const base64Email = buff.toString('base64');
  //     if (`${type}:${base64Email}` === value) {
  //       await api.post('locations/handleChangeLocationOfUnits', { items: itemList });
  //       return { done: true };
  //     }
  //     if (value.substring(0, 3) === 'USR' || value.substring(0, 3) === 'LCT') {
  //       return { error: 'You need to scan the QR code of user 1 to confirm the previous location changes.' };
  //     }
  //     if (inventoryItem.data.error && !value.includes('USR')) {
  //       return { error: inventoryItem.data.error };
  //     }
  //     const groupPermission = await api.get(`/location-user-permission/${inventoryItem.data.user_id}/${fromLocationId}/${toLocationId}?toUserId=${toUserId}`);
  //     if (!groupPermission.data.ok) {
  //       return { error: groupPermission.data.error };
  //     }
  //     const previousLocation =
  //       groupPermission.data.permissions.fromLocation.name === 'Tech'
  //         ? `${groupPermission.data.permissions.fromLocation.name} ${userInfo.data.name}`
  //         : groupPermission.data.permissions.fromLocation.name;
  //     const transferData = {
  //       id: inventoryItem.data.id,
  //       serial_number: serialNumber,
  //       user_id: toUserId,
  //       user: toUserInfo.data.name,
  //       location_id: toLocationId,
  //       location: `${groupPermission.data.permissions.toLocation.name} ${toUserInfo.data.name}`,
  //       previous_location: previousLocation,
  //       // done: true,
  //     };
  //     return transferData;
  //   }
  //   default:
  //     break;
  // }
  // return null;
}
